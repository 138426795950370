import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import AceOpportunityDetails from './AceOpportunityDetails/AceOpportunityDetails';
import { Loader } from '@tackle-io/platform-ui';
import useOpportunityEventsQuery from '../../hooks/useOpportunityEventsQuery/useOpportunityEventsQuery';
import isOpportunityBeingCreatedCheck from 'packages/cosell/api/utilities/IsOpportunityBeingCreatedCheck/isOpportunityBeingCreatedCheck';
import { useAceVendorConfig } from 'packages/cosell/api/hooks/useAceVendorConfig';
import { Redirect, useLocation } from 'react-router-dom';

const UnifiedOpportunityDetails = ({
  tackleOpportunityId,
}: {
  tackleOpportunityId: string;
}) => {
  const location = useLocation();
  const { isSaasDocumentationRequiredToLaunch } = useAceVendorConfig();
  const { isOpportunityEventsLoading } =
    useOpportunityEventsQuery(tackleOpportunityId);

  const { aceOpportunityQuery, updateAceOpportunity } = useAceOpportunity({
    opportunityId: tackleOpportunityId,
  });

  const opportunity = aceOpportunityQuery?.data;

  // this opportunityError property from useQuery
  if (aceOpportunityQuery.isError) {
    return <div>Error loading opportunity</div>;
  }

  const isOpportunityBeingCreated = isOpportunityBeingCreatedCheck({
    data: opportunity,
  });
  if (
    aceOpportunityQuery.isLoading ||
    updateAceOpportunity.isLoading ||
    isOpportunityEventsLoading ||
    isOpportunityBeingCreated
  ) {
    return <Loader />;
  }

  if (!opportunity) {
    return <div>No opportunity found</div>;
  }

  if (!opportunity.isCreatedInCloud && opportunity.metadata?.hasCloudErrors) {
    return <Redirect to={`${location.pathname}/edit`} />;
  }

  if (!opportunity.isCreatedInCloud) {
    return <div>Opportunity not created in the cloud </div>;
  }

  return (
    <AceOpportunityDetails
      opportunityId={tackleOpportunityId}
      opportunity={opportunity}
      requiresSaasDocumentationToLaunch={isSaasDocumentationRequiredToLaunch({
        deliveryModels: opportunity.project?.deliveryModels,
      })}
    />
  );
};

export default UnifiedOpportunityDetails;
