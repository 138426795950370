import {
  Box,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from 'vendor/material';
import { Banner, Button } from '@tackle-io/platform-ui';
import { useContext } from 'react';
import ApiContext, {
  OfferSubmissionError,
  SchemaValidationError,
} from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import {
  determineErrorType,
  errorsProducerForErrorType,
  ErrorType,
} from './offerSubmissionErrorUtils';
import { Marketplace } from '../types/TackleOffer';

interface OfferSubmissionErrorsBannerProps {
  marketplace: Marketplace;
  poId: string | null;
  offerSubmissionError: OfferSubmissionError;
}

const marketplaceTextForMarketplace = {
  [Marketplace.Aws]: 'AWS',
  [Marketplace.Azure]: 'Azure',
  [Marketplace.Gcp]: 'GCP',
};

const useStyles = makeStyles((theme) => ({
  errorList: {
    listStyleType: 'disc',
    paddingLeft: theme.spacing(3),
  },
  errorListItem: {
    padding: '0 16px 0 0',
    display: 'list-item',
  },
}));

const OfferSubmissionErrorsBanner = ({
  poId,
  marketplace,
  offerSubmissionError,
}: OfferSubmissionErrorsBannerProps) => {
  const classes = useStyles();
  const { dismissOfferSubmissionError } = useContext(ApiContext);
  const errorType = determineErrorType(offerSubmissionError);
  const errorsProducerForType = errorsProducerForErrorType[errorType];
  const errors = errorsProducerForType(offerSubmissionError);
  const marketplaceText = `${marketplaceTextForMarketplace[marketplace]} error`;

  return (
    <Banner
      type="danger"
      borderPosition="top"
      title={marketplaceText}
      body={
        <Box>
          <Box mb={2}>
            <Typography>The following items need to be corrected:</Typography>
          </Box>
          {errorType === ErrorType.SchemaValidationError && (
            <Box mb={2}>
              {(offerSubmissionError as SchemaValidationError).message}
            </Box>
          )}
          <Box mb={3}>
            <List className={classes.errorList} disablePadding>
              {errors.map((e: string, i: number) => (
                <ListItem
                  key={`offer-api-error-${i}`}
                  className={classes.errorListItem}
                >
                  <ListItemText primary={e} />
                </ListItem>
              ))}
            </List>
          </Box>
          <Button
            onClick={() => {
              dismissOfferSubmissionError(poId);
            }}
          >
            Dismiss
          </Button>
        </Box>
      }
      isCollapsible
      defaultOpen
    />
  );
};

export default OfferSubmissionErrorsBanner;
