import { OfferAPIKey } from 'pages/PrivateOffers/pages/Next/generic/ApiContext/offerAPIKey';
import { useCallback } from 'react';
import { ContextValueSetters } from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import { camelCase, convertKeysTo } from 'stores/utils';
import { addError } from 'utils/monitor/datadog';

export const convertJsonToType = <T,>(json: any) =>
  convertKeysTo(camelCase)(json) as T;

export const convertJsonToArrayType = <T,>(json: any): T[] =>
  json.map((i: any) => convertJsonToType<T>(i));

interface UseOfferApi {
  stateTrackingGet: <T>(
    apiCallKey: OfferAPIKey,
    getSupplier: () => Promise<Response>,
    jsonTransformer?: (responseJson: any) => Promise<T>,
  ) => Promise<T>;
  stateTrackingSubmit: <T>(
    apiKey: OfferAPIKey,
    postOrPutSupplier: () => Promise<Response>,
    jsonTransformer?: (responseJson: any) => Promise<T>,
    errorHandler?: (reason: any) => Promise<null>,
    responseTransformer?: (response: Response | null) => any,
  ) => Promise<T>;
  stateTrackingSubmitWithEmptyResponse: <T>(
    apiKey: OfferAPIKey,
    postOrPutSupplier: () => Promise<Response>,
    errorHandler?: (reason: any) => Promise<null>,
  ) => Promise<T>;
}

const useOfferApi = (contextValueSetters: ContextValueSetters): UseOfferApi => {
  const { setLoading, setSynced, setSubmitting } = contextValueSetters;

  const getResponseJson = async <T = any,>(
    response: Response | null,
  ): Promise<T> => (response ? response.json() : null);

  const noOpTransformer = <T,>(responseJson: T): T => responseJson;

  const handleError = async (reason: any): Promise<null> => {
    addError(reason);

    return null;
  };

  const afterGet = useCallback(
    (apiKey: OfferAPIKey) => async () => {
      setLoading((previousLoading) => ({
        ...previousLoading,
        [apiKey]: false,
      }));
      setSynced((previousSynced) => ({ ...previousSynced, [apiKey]: true }));
    },
    [setLoading, setSynced],
  );

  const afterPostOrPut = useCallback(
    (apiKey: OfferAPIKey) => async () => {
      setSubmitting((previousSubmitting) => ({
        ...previousSubmitting,
        [apiKey]: false,
      }));
    },
    [setSubmitting],
  );

  const stateTrackingGet = useCallback(
    async <T,>(
      apiCallKey: OfferAPIKey,
      getSupplier: () => Promise<Response>,
      jsonTransformer: (responseJson: any) => Promise<T> = noOpTransformer,
    ): Promise<T> => {
      setLoading((previousLoading) => ({
        ...previousLoading,
        [apiCallKey]: true,
      }));

      return getSupplier()
        .catch(handleError)
        .then(getResponseJson)
        .then((responseJson) =>
          responseJson ? jsonTransformer(responseJson) : null,
        )
        .finally(afterGet(apiCallKey));
    },
    [setLoading, afterGet],
  );

  const stateTrackingSubmit = useCallback(
    async <T,>(
      apiKey: OfferAPIKey,
      postOrPutSupplier: () => Promise<Response>,
      jsonTransformer: (responseJson: any) => Promise<T> = noOpTransformer,
      errorHandler: (reason: any) => Promise<null> = handleError,
      responseTransformer: (response: Response | null) => any = getResponseJson,
    ): Promise<T> => {
      setSubmitting((previousSubmitting) => ({
        ...previousSubmitting,
        [apiKey]: true,
      }));

      return postOrPutSupplier()
        .catch(errorHandler)
        .then(responseTransformer)
        .then((responseJson) =>
          responseJson ? jsonTransformer(responseJson) : null,
        )
        .finally(afterPostOrPut(apiKey));
    },
    [setSubmitting, afterPostOrPut],
  );

  const stateTrackingSubmitWithEmptyResponse = useCallback(
    async <T,>(
      apiKey: OfferAPIKey,
      postOrPutSupplier: () => Promise<Response>,
      errorHandler: (reason: any) => Promise<null> = handleError,
    ): Promise<T> =>
      stateTrackingSubmit(
        apiKey,
        postOrPutSupplier,
        null,
        errorHandler,
        () => {},
      ),
    [stateTrackingSubmit],
  );

  return {
    stateTrackingGet,
    stateTrackingSubmit,
    stateTrackingSubmitWithEmptyResponse,
  };
};

export default useOfferApi;
