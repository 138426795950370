import { useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { OffersCanvasAppContentPayload } from '../src/lib/salesforceCanvasSession';
import { useCanvasSession } from '../src/useCanvasSession';
import useOffersCanvasActions from './useOffersCanvasActions';
import { ErrorBoundary } from 'components';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import PrivateOffersDomain, {
  PO_BASE_PATH,
  poNewRoute,
  poEditRoute,
  poCloneRoute,
  poAmendRoute,
  poViewRoute,
} from 'pages/PrivateOffers/routes';

const GoBackToOpportunityRoot = () => {
  const { navigate } = useOffersCanvasActions();
  useEffect(() => {
    navigate({ action: 'back' });
  }, [navigate]);
  return null;
};

const SalesForceInitialRouteSelector = () => {
  const { payload } = useCanvasSession<OffersCanvasAppContentPayload>();
  const { action, cloud } = payload;
  const isOpportunityRecord = payload.record?.objectName === 'Opportunity';
  const opportunityId = isOpportunityRecord && payload.record?.Id;
  const opportunityName = isOpportunityRecord ? payload.record?.Name : null;

  const poId =
    ('poId' in payload && payload.poId) ||
    (payload.record?.objectName === 'TackleAwsOffer' &&
      payload.record?.Tackle_Id);

  switch (action) {
    case 'create':
      const { mode, offerType } = payload;
      return (
        <Redirect
          to={poNewRoute(
            cloud,
            mode,
            offerType,
            opportunityId,
            opportunityName,
          )}
        />
      );
    case 'view':
      return <Redirect to={poViewRoute(cloud, poId)} />;
    case 'edit':
      return <Redirect to={poEditRoute(cloud, poId)} />;
    case 'clone':
      return <Redirect to={poCloneRoute(cloud, poId)} />;
    case 'amend':
      return (
        <Redirect
          to={poAmendRoute(
            cloud,
            payload.offerId,
            poId,
            opportunityId,
            opportunityName,
          )}
        />
      );
    default:
      return <GoBackToOpportunityRoot />;
  }
};

const OffersCanvasApp = () => {
  return (
    <ErrorBoundary renderError={() => <ErrorPage title="Offers" />}>
      <Switch>
        <Route
          // make sure to exact trap this first in canvas since we will send you back to the opportunity root
          // we dont want it to make it to the next step to show the list
          exact
          path={PO_BASE_PATH}
        >
          <GoBackToOpportunityRoot />
        </Route>
        <Route path={PO_BASE_PATH}>
          <PrivateOffersDomain />
        </Route>
        <Route>
          <SalesForceInitialRouteSelector />
        </Route>
      </Switch>
    </ErrorBoundary>
  );
};

export default OffersCanvasApp;
