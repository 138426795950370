import {
  fontWeightMediumLarge,
  fontWeightBold,
} from 'utils/fontWeightConstants';
import { makeStyles } from 'vendor/material';

export const useStyles = makeStyles((theme) => ({
  editModalTitle: {
    fontWeight: fontWeightMediumLarge,
    fontSize: theme.typography.pxToRem(22),
    lineHeight: theme.typography.pxToRem(30),
    color: theme.palette.NEUTRAL700,
    marginBottom: theme.spacing(0.5),
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    color: `${theme.palette.BLUE400} !important`,
    fontWeight: fontWeightBold,
    borderColor: `${theme.palette.NEUTRAL040} !important`,
  },
  submitButton: {
    fontWeight: fontWeightBold,
  },
  modal: {
    width: '716px !important',
    minHeight: '400px',
  },
}));
