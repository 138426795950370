import { DateTime, Info } from 'luxon';
import { TimeRange } from './DateFilter.constants';
import type { TimeRangeOption } from './types';
import {
  TimeSeriesGranularity,
  RelativeTimeRange,
  UserBillingDetailsQuery,
} from 'generated/graphql';
import { Month } from 'pages/Overview/utils/constants';

export const ALL_LABEL = 'All time';

const getFiscalYearMonths = (startMonth: Month = Month.January): string[] => {
  let months = Info.months('long');
  const startMonthIndex = startMonth ? months.indexOf(startMonth) + 1 : 1;
  return [
    ...months.slice(startMonthIndex - 1),
    ...months.slice(0, startMonthIndex - 1),
  ];
};

export const getFiscalYearQuarters = (startMonth: Month = Month.January) => {
  const months = getFiscalYearMonths(startMonth);
  const quarters = Array(4)
    .fill(0)
    .map((_, i) => months.slice(i * 3, i * 3 + 3));
  return {
    Q1: quarters[0],
    Q2: quarters[1],
    Q3: quarters[2],
    Q4: quarters[3],
  };
};

function getAdjustedQuarter(date, fiscalYearStartMonth) {
  const quarters = getFiscalYearQuarters(fiscalYearStartMonth);
  const { monthLong: month } = date;

  return Object.keys(quarters).find((key) => quarters[key].includes(month));
}

export const formatDateFilter = (date) => {
  return DateTime.fromISO(date).toFormat('LLL dd, yyyy');
};

export function formatOptionDate(dateType, fiscalYearStartMonth, billingData) {
  const { start, end } =
    getTimeRangeFilterConfig(dateType, fiscalYearStartMonth, billingData) ||
    TIME_RANGE_FILTER_OPTIONS[0];

  switch (dateType) {
    case TimeRange.LAST_12_MONTHS:
      return `${start.toFormat('yyyy LLLL')} - ${end.toFormat('yyyy LLLL')}`;

    case TimeRange.PREVIOUS_8_QUARTERS:
      const startQuarterValue = getAdjustedQuarter(start, fiscalYearStartMonth);
      const endQuarterValue = getAdjustedQuarter(end, fiscalYearStartMonth);
      const startYearValue =
        fiscalYearStartMonth === Month.January
          ? start.toFormat('yyyy')
          : start.set({ year: start.year + 1 }).toFormat('yyyy');
      const endYearValue =
        fiscalYearStartMonth === Month.January
          ? end.toFormat('yyyy')
          : end.set({ year: end.year + 1 }).toFormat('yyyy');
      return `${startQuarterValue} ${startYearValue} - ${endQuarterValue} ${endYearValue}`;

    case TimeRange.PREVIOUS_3_YEARS:
      return `${start.toFormat('MMMM yyyy')} - ${end.toFormat('MMMM yyyy')}`;

    case TimeRange.TACKLE_CONTRACT_PERIOD:
    case TimeRange.CUSTOM:
      return `${start.toFormat('MMM dd yyyy')} - ${end.toFormat(
        'MMM dd yyyy',
      )}`;
    case TimeRange.ALL:
      return ALL_LABEL;
    case TimeRange.THIS_YEAR:
    case TimeRange.LAST_YEAR:
    default:
      return `${start.toFormat('yyyy LLLL')} - ${end.toFormat('yyyy LLLL')}`;
  }
}

const todayLocal = DateTime.local();
export const fiscalYearStartMonthLocalStorage =
  (localStorage.getItem('fiscalYearStartMonth') as Month) ?? Month.January;

export const getTimeRangeFilterConfig = (
  timeRange: TimeRange,
  fiscalYearStartMonth: Month = fiscalYearStartMonthLocalStorage,
  billingData?: UserBillingDetailsQuery,
) => {
  const filters = JSON.parse(localStorage.getItem('dashboardFilters') || '{}');

  const fiscalYearStartDay = fiscalYearStartMonth
    ? maybeAdjustFiscalStartDay(
        DateTime.local(
          todayLocal.year,
          Info.months('long').indexOf(fiscalYearStartMonth) + 1,
          1,
        ).toUTC(),
        todayLocal,
      )
    : todayLocal.startOf('year');

  const timeRangeFilterConfigs: Record<TimeRange, TimeRangeOption> = {
    [TimeRange.TACKLE_CONTRACT_PERIOD]: {
      key: TimeRange.TACKLE_CONTRACT_PERIOD,

      text: 'Tackle contract period',
      start: billingData
        ? DateTime.fromISO(
            billingData?.userBillingDetails?.subscriptionStartTime,
            { zone: 'utc' },
          )
        : todayLocal.minus({ months: 12 }),
      end: billingData
        ? DateTime.fromISO(
            billingData?.userBillingDetails?.subscriptionEndTime,
            { zone: 'utc' },
          )
        : todayLocal,
      isSelected: false,
      disabled: false,
      granularity: TimeSeriesGranularity.Day,
      timeRangeInput: billingData
        ? {
            start: DateTime.fromISO(
              billingData?.userBillingDetails?.subscriptionStartTime,
              { zone: 'utc' },
            ),
            stop: DateTime.fromISO(
              billingData?.userBillingDetails?.subscriptionEndTime,
              { zone: 'utc' },
            ),
          }
        : {
            relative: RelativeTimeRange.LastNMonths,
            n: 12,
          },
    },
    [TimeRange.LAST_12_MONTHS]: {
      key: TimeRange.LAST_12_MONTHS,
      text: 'Last 12 months',
      start: todayLocal.minus({ months: 12 }),
      end: todayLocal,
      isSelected: false,
      disabled: false,
      granularity: TimeSeriesGranularity.Month,
      timeRangeInput: {
        relative: RelativeTimeRange.LastNMonths,
        n: 12,
      },
    },
    [TimeRange.PREVIOUS_8_QUARTERS]: {
      key: TimeRange.PREVIOUS_8_QUARTERS,
      text: 'Last 8 quarters',
      start: todayLocal.minus({ months: 24 }).startOf('month'),
      end: todayLocal,
      isSelected: false,
      disabled: false,
      granularity: TimeSeriesGranularity.Month,
      timeRangeInput: {
        relative: RelativeTimeRange.LastNMonths,
        n: 24,
      },
    },
    [TimeRange.PREVIOUS_3_YEARS]: {
      key: TimeRange.PREVIOUS_3_YEARS,
      text: 'Last 3 years',
      start: fiscalYearStartDay.minus({ months: 36 }).startOf('month'),
      end: todayLocal,
      isSelected: false,
      disabled: false,
      granularity: TimeSeriesGranularity.Year,
      timeRangeInput: {
        start: fiscalYearStartDay.minus({ months: 36 }).toUTC(),
        stop: todayLocal.toUTC(),
      },
    },
    [TimeRange.THIS_YEAR]: {
      key: TimeRange.THIS_YEAR,
      text: 'This year',
      start: fiscalYearStartDay,
      end: todayLocal,
      isSelected: false,
      disabled: false,
      granularity: TimeSeriesGranularity.Month,
      timeRangeInput: {
        start: fiscalYearStartDay,
        stop: todayLocal?.toUTC(),
      },
    },
    [TimeRange.LAST_YEAR]: {
      key: TimeRange.LAST_YEAR,
      text: 'Last year',
      start: fiscalYearStartDay.minus({ years: 1 }).startOf('month'),
      end: fiscalYearStartDay.minus({ months: 1 }).endOf('month'),
      isSelected: false,
      disabled: false,
      granularity: TimeSeriesGranularity.Month,
      timeRangeInput: {
        start: fiscalYearStartDay.minus({ years: 1 }).startOf('month'),
        stop: fiscalYearStartDay.minus({ months: 1 }).endOf('month'),
      },
    },
    [TimeRange.CUSTOM]: {
      key: TimeRange.CUSTOM,
      text: 'Custom range',
      start: DateTime.fromISO(filters?.startDate),
      end: DateTime.fromISO(filters?.endDate),
      isSelected: false,
      disabled: false,
      granularity: TimeSeriesGranularity.Day,
      timeRangeInput: {
        start: DateTime.fromISO(filters?.startDate),
        stop: DateTime.fromISO(filters?.endDate),
      },
    },
    [TimeRange.ALL]: {
      key: TimeRange.ALL,
      text: ALL_LABEL,
      start: null,
      end: null,
      isSelected: false,
      disabled: false,
      granularity: TimeSeriesGranularity.Day,
      timeRangeInput: {
        start: null,
        stop: null,
      },
    },
  };

  if (!timeRange) return timeRangeFilterConfigs[TimeRange.LAST_12_MONTHS];

  return timeRangeFilterConfigs[timeRange];
};

export const maybeAdjustFiscalStartDay = (
  fiscalYearStartDay: DateTime,
  todayDateTime: DateTime,
) => {
  if (!fiscalYearStartDay) return todayDateTime;

  if (fiscalYearStartDay?.toMillis() > todayDateTime?.toMillis())
    return fiscalYearStartDay.minus({ year: 1 });

  return fiscalYearStartDay;
};

export const TIME_RANGE_FILTER_OPTIONS = [
  TimeRange.LAST_12_MONTHS,
  TimeRange.PREVIOUS_8_QUARTERS,
  TimeRange.PREVIOUS_3_YEARS,
  TimeRange.THIS_YEAR,
  TimeRange.LAST_YEAR,
].map((key) => getTimeRangeFilterConfig(key));

export const PAYMENTS_TIME_RANGE_FILTER_OPTIONS = [
  TimeRange.TACKLE_CONTRACT_PERIOD,
  TimeRange.LAST_12_MONTHS,
  TimeRange.PREVIOUS_8_QUARTERS,
  TimeRange.PREVIOUS_3_YEARS,
  TimeRange.THIS_YEAR,
  TimeRange.LAST_YEAR,
  TimeRange.CUSTOM,
].map((key) => getTimeRangeFilterConfig(key));

export const COSELL_TIME_RANGE_FILTER_OPTIONS = [
  TimeRange.ALL,
  TimeRange.LAST_12_MONTHS,
  TimeRange.PREVIOUS_8_QUARTERS,
  TimeRange.PREVIOUS_3_YEARS,
  TimeRange.THIS_YEAR,
  TimeRange.LAST_YEAR,
  TimeRange.CUSTOM,
].map((key) => getTimeRangeFilterConfig(key));
