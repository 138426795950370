import { Button, ProviderIcon } from '@tackle-io/platform-ui';
import { Box, Divider, Grid, makeStyles, Typography } from 'vendor/material';
import { useCanvasSession } from '../src/useCanvasSession';
import { useContext, useState } from 'react';
import classNames from 'classnames';
import { OfferType } from 'stores/privateOffers/typings';
import DirectOfferSvg from 'pages/PrivateOffers/pages/Next/assets/DirectOfferSvg';
import CreateOfferModalRadio from 'pages/PrivateOffers/pages/Next/generic/CreateOfferModal/CreateOfferModalRadio';
import { DataId } from 'pages/PrivateOffers/pages/Next/generic/analytics';
import {
  labelsByOfferCreationMode,
  labelsByOfferType,
} from 'pages/PrivateOffers/pages/Next/generic/CreateOfferModal/createOfferModalLabels';
import PartnerOfferSvg from 'pages/PrivateOffers/pages/Next/assets/PartnerOfferSvg';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import { CanvasAssociateImportDrawer } from 'pages/PrivateOffers/pages/Next/generic/AssociateFlow/AssociateFlow';
import { Marketplace } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';
import useOffersCanvasActions from './useOffersCanvasActions';
import { OffersTabContentContext } from './OffersTabContent';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  contents: {
    overflow: 'auto',
  },
  button: {
    backgroundColor: theme.palette.common.white,
    whiteSpace: 'nowrap',
  },
  offerTypeContainer: {
    border: '1px dotted',
    borderColor: theme.palette.NEUTRAL400,
    borderRadius: 5,
    width: '100%',
    height: '100%',
  },
  lightGray: {
    color: theme.palette.NEUTRAL300,
  },
  offerTypeContainerSelected: {
    border: '1px solid',
    borderColor: theme.palette.BLUE400,
  },
}));

const StartAWSOffer = ({ onCancel }: { onCancel: () => void }) => {
  const classes = useStyles();
  const { canvasClient, namespace, record } = useCanvasSession();
  const { navigate } = useOffersCanvasActions();
  const { fetchOffersForOpportunity } = useContext(OffersTabContentContext);

  const [offerType, setOfferType] = useState<OfferType>(OfferType.Direct);
  const [mode, setMode] = useState<OfferPageMode>(OfferPageMode.New);
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <Box px={2} height="100%">
      <Grid
        container
        direction="column"
        className={classes.container}
        spacing={2}
      >
        <Grid item>
          <Typography variant="h5">
            <Grid container spacing={2}>
              <Grid item>
                <ProviderIcon provider="aws" />
              </Grid>
              <Grid item>Create Offer</Grid>
            </Grid>
          </Typography>
        </Grid>
        <Grid
          item
          xs
          container
          direction="column"
          wrap="nowrap"
          spacing={2}
          className={classes.contents}
        >
          <Grid item>
            <Typography className={classes.lightGray}>Offer type</Typography>
          </Grid>
          <Grid item>
            <Box
              p={2}
              className={classNames(classes.offerTypeContainer, {
                [classes.offerTypeContainerSelected]:
                  offerType === OfferType.Direct,
              })}
            >
              <Grid container>
                <Grid item xs>
                  <CreateOfferModalRadio
                    dataId={DataId.DirectOfferRadioButton}
                    label={labelsByOfferType[OfferType.Direct]}
                    value={OfferType.Direct}
                    selectedValue={offerType}
                    onClick={() => setOfferType(OfferType.Direct)}
                  />
                </Grid>
                <Grid item>
                  <DirectOfferSvg />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box
              p={2}
              className={classNames(classes.offerTypeContainer, {
                [classes.offerTypeContainerSelected]:
                  offerType === OfferType.PartnerResale,
              })}
            >
              <Grid container>
                <Grid item xs>
                  <CreateOfferModalRadio
                    dataId={DataId.DirectOfferRadioButton}
                    label={labelsByOfferType[OfferType.PartnerResale]}
                    value={OfferType.PartnerResale}
                    selectedValue={offerType}
                    onClick={() => setOfferType(OfferType.PartnerResale)}
                  />
                </Grid>
                <Grid item>
                  <PartnerOfferSvg />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Typography className={classes.lightGray}>
              Create new or import an existing offer
            </Typography>
          </Grid>
          <Grid item>
            <CreateOfferModalRadio
              dataId={DataId.NewOfferRadioButton}
              label={labelsByOfferCreationMode[OfferPageMode.New]}
              value={OfferPageMode.New}
              selectedValue={mode}
              onClick={() => setMode(OfferPageMode.New)}
            />
          </Grid>
          <Grid item>
            <CreateOfferModalRadio
              dataId={DataId.AssociateOfferRadioButton}
              label={labelsByOfferCreationMode[OfferPageMode.Associate]}
              value={OfferPageMode.Associate}
              selectedValue={mode}
              onClick={() => setMode(OfferPageMode.Associate)}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs>
            <Button
              fullWidth
              variant="outlined"
              appearance="primary"
              className={classes.button}
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              fullWidth
              variant="contained"
              appearance="primary"
              className={classes.button}
              disabled={!offerType || !mode}
              onClick={() => {
                if (mode === OfferPageMode.Associate) {
                  setShowDrawer(true);
                } else {
                  Sfdc.canvas.client.publish(canvasClient, {
                    name: `${namespace}.offers`,
                    payload: {
                      navigate: 'CanvasApp',
                      action: 'create',
                      domain: 'offers',
                      cloud: 'aws',
                      record,
                      offerType,
                      mode,
                    },
                  });
                }
              }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
        <CanvasAssociateImportDrawer
          marketplace={Marketplace.Aws}
          offerType={offerType}
          onCancelAssociateImportFlow={() => setShowDrawer(false)}
          onClose={() => {
            fetchOffersForOpportunity();
            onCancel();
          }}
          opportunityId={
            record?.objectName === 'Opportunity' ? record.Id : undefined
          }
          showDrawer={showDrawer}
          onEditOffer={(importedOffer) =>
            navigate({
              action: 'edit',
              domain: 'offers',
              cloud: 'aws',
              poId: importedOffer.poId,
            })
          }
        />
      </Grid>
    </Box>
  );
};

export default StartAWSOffer;
