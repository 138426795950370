import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    '&:empty': {
      display: 'none',
    },
  },
  actionButton: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: theme.spacing(2),
  },
}));

export default useStyles;
