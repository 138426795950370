import { Button } from '@tackle-io/platform-ui';
import { useMemo } from 'react';
import FormBox from './components/FormBox';
import QuickLinks from './components/QuickLinks/QuickLinks';
import CustomerDetailsFormSection from './CustomerDetailsFormSection/CustomerDetailsFormSection';
import DealDetailsFormSection from './DealDetailsFormSection/DealDetailsFormSection';
import SolutionsFormSection from './SolutionsFormSection/SolutionsFormSection';
import TypeOfHelpFormSection from './TypeOfHelpFormSection/TypeOfHelpFormSection';
import PartnersFormSection from './PartnerFormSection/PartnersFormSection';
import TeamMembersFormSection from './TeamMembersFormSection/TeamMembersFormSection';
import { SharedHeader } from 'packages/cosell/src/components';
import { FormSectionConfig, type FormSection } from './constants';

/**
 * TEMP for development until we have real data
 */
const mockProgress: Record<FormSection, number> = {
  [FormSectionConfig.customerDetails.id]: 100,
  [FormSectionConfig.dealDetails.id]: 75,
  [FormSectionConfig.solutions.id]: 50,
  [FormSectionConfig.typeOfHelp.id]: 50,
  [FormSectionConfig.partners.id]: 0,
  [FormSectionConfig.teamMembers.id]: 0,
};

const ReferralForm = ({ isEdit }: { isEdit?: boolean }) => {
  /**
   * TODO: dynamically generate quick links with progress
   */
  const quickLinks = useMemo(() => {
    return Object.values(FormSectionConfig).map((section) => ({
      label: section.title,
      id: section.id,
      progress: mockProgress[section.id],
    }));
  }, []);

  const title = !isEdit ? 'Create co-sell' : 'Edit co-sell';

  return (
    <FormBox
      header={<SharedHeader cloud="microsoft" title={title} />}
      formFields={
        <>
          <CustomerDetailsFormSection />
          <DealDetailsFormSection />
          <SolutionsFormSection />
          <TypeOfHelpFormSection />
          <PartnersFormSection />
          <TeamMembersFormSection />
        </>
      }
      footer={
        <FormBox.Footer>
          <Button appearance="primary" variant="outlined" size="small">
            Cancel
          </Button>
          <Button appearance="primary" disabled size="small" variant="outlined">
            Save draft
          </Button>
          <Button
            appearance="primary"
            disabled
            variant="contained"
            size="small"
          >
            Submit to cloud
          </Button>
        </FormBox.Footer>
      }
      sidePanel={<QuickLinks sections={quickLinks} />}
    />
  );
};

export default ReferralForm;
