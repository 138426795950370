enum Email {
  Support = 'support@tackle.io',
  Onboarding = 'onboarding@tackle.io',
}

export const REGISTRATION_BASE_URL = 'register.tackle.io';

export default Email;

export const CONTACT_SUPPORT_URL = 'https://support.tackle.io/s/contactsupport';

// Possible values for vendor health status from reporting API
export enum ReportingStatus {
  N_A = 'N/A',
  GOOD = 'GOOD',
  NOT_CONFIGURED = 'NOT CONFIGURED',
  DISCONNECTED = 'DISCONNECTED',
}

// Possible values for vendor environments
export enum VendorEnvironmentEnum {
  PROD = 'prod',
  SANDBOX = 'sandbox',
  INTERNAL_TEST = 'internal test',
  CUSTOMER_TEST = 'customer test',
  DEMO = 'demo',
  DEV = 'dev',
}

///////// DOCS //////////
// Tackle Help Docs URLs
export const TACKLE_HELP_HOME_PAGE_DOCS_URL = 'https://help.tackle.io/';
export const DASHBOARD_BASICS_DOCS_URL =
  'https://help.tackle.io/en/articles/10389718-dashboard-basics';
export const DASHBOARD_BASICS_MARKETPLACE_CHARTS_DOCS_URL =
  'https://help.tackle.io/en/articles/10389718-dashboard-basics#h_4ec1316899';
export const YOUR_TACKLE_SUBSCRIPTIION_DOCS_URL =
  'https://help.tackle.io/en/articles/10439728-your-tackle-subscription';

export const COSELL_LANDING_PAGE_DOCS_URL =
  'https://help.tackle.io/en/collections/11438578-co-sell';
export const COSELL_AWS_DETERMINE_ACE_ELIGIBILITY_DOCS_URL =
  'https://help.tackle.io/en/articles/10514011-aws-marketplace-co-sell-faqs#h_2de29eb399';
export const COSELL_AWS_MIGRATE_FROM_OTHER_SOLUTION_DOCS_URL =
  'https://help.tackle.io/en/articles/10514011-aws-marketplace-co-sell-faqs#h_eaf7da825c';
export const COSELL_AWS_LOCATE_SOLUTION_IDS_DOCS_URL =
  'https://help.tackle.io/en/articles/10432749-connect-to-aws-partner-central#h_9389c385ac';
export const COSELL_AWS_CONNECT_TO_APN_DOCS_URL =
  'https://help.tackle.io/en/articles/10432749-connect-to-aws-partner-central';
export const COSELL_AWS_IMPORT_OPPORTUNITIES_LEGACY_DOCS_URL =
  'https://help.tackle.io/en/articles/10440213-import-co-sell-opportunities-from-aws-legacy';
export const COSELL_AWS_LINK_COSELLS_TO_SALESFORCE_OPPS_DOCS_URL =
  'https://help.tackle.io/en/articles/10753302-link-and-relink-aws-co-sells-to-salesforce-opportunities';

export const OFFERS_LANDING_PAGE_DOCS_URL =
  'https://help.tackle.io/en/collections/11458783-private-offers';
export const OFFERS_AWS_CURRENCY_DOCS_URL =
  'https://help.tackle.io/en/articles/10447246-pricing-and-payments-for-aws#h_c5211574a9';
export const OFFERS_GCP_FDA_DOCS_URL =
  'https://help.tackle.io/en/articles/10488334-manage-google-cloud-listings#h_89ea31c08d';
export const OFFERS_GCP_CONNECTION_ERRORS_DOCS_URL =
  'https://help.tackle.io/en/articles/10488125-google-connection-errors';

export const ONBOARDING_AWS_DOCS_URL =
  'https://help.tackle.io/en/articles/10432237-connect-to-aws-marketplace';
export const ONBOARDING_GCP_DOCS_URL =
  'https://help.tackle.io/en/articles/10488272-connect-to-google-cloud';
export const ONBOARDING_MSFT_DOCS_URL =
  'https://help.tackle.io/en/articles/10509617-connect-to-microsoft-commercial-marketplace';

export const INTEGRATIONS_SALESFORCE_DOCS_URL =
  'https://help.tackle.io/en/collections/11071594-salesforce';
export const INTEGRATIONS_SALESFORCE_PERMISSIONS_DOCS_URL =
  'https://help.tackle.io/en/articles/10746565-tackle-for-salesforce-system-permissions-reference-guide';
export const INTEGRATIONS_ORB_DOCS_URL =
  'https://help.tackle.io/en/articles/10458552-tackle-orb-integration';
export const INTEGRATIIONS_SLACK_DOCS_URL =
  'https://help.tackle.io/en/articles/10471258-tackle-slack-integration';

export const LISTINGS_AWS_ABOUT_DOCS_URL =
  'https://help.tackle.io/en/articles/10479689-about-aws-product-listings';
export const LISTINGS_AWS_EDIT_DOCS_URL =
  'https://help.tackle.io/en/articles/10486748-manage-aws-listings#h_5d0c08ea8d';
export const LISTINGS_AWS_SELF_HOSTED_REGISTRATION_DOCS_URL =
  'https://help.tackle.io/en/articles/10488214-set-up-your-aws-buyer-registration-page';
export const LISTINGS_GCP_SELF_HOSTED_REGISTRATION_DOCS_URL =
  'https://help.tackle.io/en/articles/10488448-set-up-your-google-cloud-buyer-registration-page';
export const LISTINGS_MSFT_SELF_HOSTED_REGISTRATION_DOCS_URL =
  'https://help.tackle.io/en/articles/10514683-set-up-your-microsoft-buyer-registration-page';

export const PROSPECT_SCORE_ACCOUNTS_IN_TACKLE_DOCS_URL =
  'https://help.tackle.io/en/articles/10471994-score-accounts-in-tackle';
export const PROSPECT_SCORE_YOUR_SALES_PIPELINE_DOCS_URL =
  'https://help.tackle.io/en/articles/10433963-score-your-sales-pipeline';

// Developer Docs URLs
export const LISTINGS_CREATE_REGISTRATION_DEV_DOCS_URL =
  'https://developers.tackle.io/reference/v1-create-registration';
