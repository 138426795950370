import { useCallback } from 'react';
import { Button, ProviderIcon, TextField } from '@tackle-io/platform-ui';
import { Formik, Field, useFormikContext } from 'formik';
import { Label } from 'packages/cosell/src/components';
import { DisplayCloudType } from 'packages/cosell/src/types/enums/CloudTypesEnum';
import { fontWeightMediumLarge } from 'utils/fontWeightConstants';
import { Box, Grid, makeStyles, Typography } from 'vendor/material';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    width: '400px',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    alignItems: 'space-between',
    gap: theme.spacing(3),
  },
  title: {
    fontWeight: fontWeightMediumLarge,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(30),
    color: theme.palette.NEUTRAL700,
    marginLeft: theme.spacing(1),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  formLabel: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: fontWeightMediumLarge,
    color: theme.palette.NEUTRAL300,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
  },
}));

const validationSchema = Yup.object().shape({
  crmId: Yup.string().required('CRM ID is required'),
});

export const CONTENT = {
  formName: 'link-crm-id-form',
  title: 'Link Salesforce opportunity',
  input: {
    name: 'crmId',
    label: 'CRM ID',
    helperText:
      'Unique Salesforce ID found on the Salesforce opportunity. Once added, this cannot be edited.',
  },
  button: {
    submit: 'Submit',
    cancel: 'Cancel',
  },
};

const LinkCrmIdForm = ({
  onClose,
  onSubmit,
}: {
  onClose: () => void;
  onSubmit: ({ crmId }: { crmId: string }) => Promise<unknown>;
}) => {
  const classes = useStyles();
  const inputFocusRef = useCallback((inputElement) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  return (
    <Formik
      initialValues={{ crmId: '' }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      <Grid className={classes.container} container>
        <DrawerTitle cloud={DisplayCloudType.AWS} title={CONTENT.title} />
        <Field name={CONTENT.input.name}>
          {({ field, form, meta }) => (
            <Grid item xs={12}>
              <Label
                className={classes.formLabel}
                label={CONTENT.input.label}
                required
              />
              <TextField
                {...field}
                disabled={form.isSubmitting}
                error={meta.touched && meta.error}
                helperText={CONTENT.input.helperText}
                inputRef={inputFocusRef}
                name="crmId"
                required
              />
            </Grid>
          )}
        </Field>
        <Grid className={classes.buttonGroup} item xs={12}>
          <DrawerActions onClose={onClose} />
        </Grid>
      </Grid>
    </Formik>
  );
};

const DrawerActions = ({ onClose }: { onClose: () => void }) => {
  const { isSubmitting, handleSubmit } = useFormikContext();

  return (
    <>
      <Button
        appearance="secondary"
        disabled={isSubmitting}
        onClick={onClose}
        type="button"
        variant="outlined"
      >
        {CONTENT.button.cancel}
      </Button>
      <Button
        appearance="primary"
        disabled={isSubmitting}
        onClick={() => handleSubmit()}
        variant="contained"
        loading={isSubmitting}
      >
        {CONTENT.button.submit}
      </Button>
    </>
  );
};

const DrawerTitle = ({
  cloud = DisplayCloudType.AWS,
  title,
}: {
  cloud: DisplayCloudType;
  title: string;
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.header}>
      <ProviderIcon fontSize={'medium'} provider={cloud} />
      <Typography variant="h3" className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
};

export default LinkCrmIdForm;
