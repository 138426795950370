import {
  AppBar,
  Drawer,
  IconButton,
  List,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Alert,
  Skeleton,
} from 'vendor/material';
import { Divider, Button } from '@tackle-io/platform-ui';
import classnames from 'classnames';
import {
  AccountCircle,
  CogOutline,
  FormatListBulleted,
  Gauge,
  LightbulbOnOutline,
  Menu as MenuIcon,
  Receipt,
  Sale,
  TransitConnection,
  TransitConnectionVariant,
  ViewDashboard,
  Alert as AlertIcon,
  HelpCircle,
} from 'mdi-material-ui';
import { ReactComponent as Handshake } from '@material-symbols/svg-400/rounded/handshake-fill.svg';
import { ReactComponent as ProspectIcon } from '@material-symbols/svg-400/outlined/track_changes.svg';
import { observer } from 'mobx-react-lite';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import TackleHorizontalWhite from '../../images/tackle-horizontal-white';
import APIDetailsProvider from '../../stores/api/provider';
import hasFeatures, {
  FEATURE_DOWNSTREAM_INSIGHTS_V1,
  ContractsFeatureFlags,
} from '../../utils/features';
import { pendoIdGenerator, TEST_ENV_SUFFIX } from '../../utils/idGenerator';
import { dashboardUIElements } from '../../utils/pendoEnums';
import NavTooltip from './NavTooltip';
import Profile from './Profile';
import VendorProfile from './VendorProfile';
import { gql } from '@apollo/client';
import {
  useApplicationShellQuery,
  useVendorWithSandboxesQuery,
} from 'generated/graphql';
import WrappedNavLink from 'components/WrappedNavLink';
import { useMinWidth } from 'utils/hooks';
import { isEmpty, pick } from 'lodash';
import { uniq } from 'lodash/fp';
import classNames from 'classnames';
import useStyles from './ApplicationShell.styles';
import useVendorMarketplaceStatus from 'hooks/useVendorMarketplaceStatus';
import VendorEnvironment from './VendorEnvironment/VendorEnvironment';
import VendorEnvironmentSignal from './VendorEnvironmentSignal/VendorEnvironmentSignal';
import {
  AppEnvironment,
  getProductionVendorId,
  getVendorEnvironmentsList,
} from './utils';
import {
  VendorEnvironmentEnum,
  TACKLE_HELP_HOME_PAGE_DOCS_URL,
} from 'utils/constants';
import {
  AREA_PERMISSION_ALL,
  AREA_PERMISSION_COSELL,
  AREA_PERMISSION_INTEGRATIONS,
  AREA_PERMISSION_LISTINGS,
  AREA_PERMISSION_METERING,
  AREA_PERMISSION_OFFERS,
  AREA_PERMISSION_PAYMENTS,
  AREA_PERMISSION_PROSPECT,
  AREA_PERMISSION_SETTINGS,
  useRbac,
} from 'utils/rbac';
import { shouldEnforceSDDSConfiguration } from 'utils/enforceSDDSConfiguration';
import { useAmpliFeatureFlags } from '@tackle-io/tackle-auth-tools';
import { ContractsIcon } from 'pages/Contracts/components/ContractsIcon';

// TODO: Potentially reduce performance for Tacklers who have access to many vendors
// It's a known tradeoff for MVP and will be addressed in the near future

export const APPLICATION_SHELL_QUERY = gql`
  query applicationShell {
    currentUser {
      id
      vendor {
        id
        name
        created_at
        vendor_type
        production_vendor_id
        feature_flags {
          is_co_sell_enabled
          is_co_sell_enabled_for_microsoft
          is_prospect_enabled
          is_marketplace_enabled
          is_listings_enabled
        }
        listingsByChannel {
          id
          aws {
            id
            status
            packages
            sns_configuration {
              value
            }
          }
          azure {
            id
            packages
          }
          gcp {
            id
            packages
          }
          redhat {
            id
            packages
          }
        }
        configuration {
          id
          status {
            sdds {
              value
            }
          }
        }
      }
      allowedVendors
    }
  }
`;

export const SANDBOX_VENDOR_QUERY = gql`
  query vendorWithSandboxes($vendorid: ID!) {
    vendor(id: $vendorid) {
      id
      vendor_type
      name
      linkedSandboxes {
        id
        name
      }
    }
  }
`;

const NavListItem = ({ drawerOpen, ...rest }) => {
  const classes = useStyles();
  return (
    <ListItem
      component={WrappedNavLink}
      {...rest}
      className={classnames(classes.navListItem, {
        [classes.listVertical]: !drawerOpen,
      })}
    />
  );
};

function ApplicationShell() {
  const classes = useStyles();
  const { flags: ampliFlags } = useAmpliFeatureFlags();
  const [drawerOpen, setDrawerOpen] = useLocalStorage('drawer-open', true);

  const { hasPermission } = useRbac();

  const {
    data,
    loading: appQueryLoading,
    error,
    refetch,
  } = useApplicationShellQuery({
    nextFetchPolicy: 'cache-only', // reduce flicker - probably fine, this data does not change that often
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const loading = appQueryLoading;

  // This is the production_vendor_id associated to the sandbox vendor_type
  const productionVendorId =
    data?.currentUser?.vendor && getProductionVendorId(data.currentUser.vendor);
  const isTestEnvironment =
    data?.currentUser?.vendor?.vendor_type === VendorEnvironmentEnum.SANDBOX;
  const testEnvSuffix = isTestEnvironment ? TEST_ENV_SUFFIX : '';

  // This is temporary logic to enforce vendors to configure SDDS
  const shouldEnforceSDDSConfig = shouldEnforceSDDSConfiguration(data);

  const variables = {
    vendorid: productionVendorId,
  };

  const { data: vendorWithSandboxes, loading: loadingVendorWithSandboxes } =
    useVendorWithSandboxesQuery({
      variables,
      notifyOnNetworkStatusChange: true,
    });

  const marketplaceStatus = useVendorMarketplaceStatus();

  const userFeatureFlags = data?.currentUser?.vendor?.feature_flags || {};
  const isMarketplaceEnabled = userFeatureFlags?.is_marketplace_enabled;
  const isListingsEnabled = userFeatureFlags?.is_listings_enabled;
  const isProspectEnabled = userFeatureFlags?.is_prospect_enabled;
  const isCoSellEnabled = userFeatureFlags?.is_co_sell_enabled;
  const isCoSellEnabledForMicrosoft =
    userFeatureFlags?.is_co_sell_enabled_for_microsoft;

  const hasFeature = useMemo(() => {
    const { id, __typename, ...listingsByChannel } =
      data?.currentUser?.vendor?.listingsByChannel || {};
    const uniqEnabledFeaturesAllListings = uniq(
      Object.values(listingsByChannel)
        .flat()
        .map((l) => l.packages)
        .flat(),
    );
    return (listingFeature: string, vendorFlags: Array<boolean>): boolean => {
      let hasListingFeature = false;
      if (!isEmpty(listingFeature)) {
        hasListingFeature = hasFeatures(uniqEnabledFeaturesAllListings, [
          listingFeature,
        ]);
      }
      const allFeatureFlagBooleans = [...vendorFlags, hasListingFeature];
      return allFeatureFlagBooleans.some((bool) => bool);
    };
  }, [data?.currentUser?.vendor?.listingsByChannel]);

  const isContractsEnabled =
    ampliFlags[ContractsFeatureFlags.UI_CONTRACTS] === 'on';

  const toggleDrawer = useCallback(() => {
    setDrawerOpen(!drawerOpen);
  }, [setDrawerOpen, drawerOpen]);

  const theme = useTheme();
  const mediaDownXS = useMediaQuery(theme.breakpoints.down('xs'));

  // Hide Mobile Drawer onClick
  const widthMedium = useMinWidth('md');

  useEffect(() => {
    if (!widthMedium) {
      setDrawerOpen(false);
    }
  }, [widthMedium, setDrawerOpen]);

  const toggleDrawerNavButton = useCallback(() => {
    if (!widthMedium) setDrawerOpen(false);
  }, [setDrawerOpen, widthMedium]);

  const hasVendorDisconnectedService =
    marketplaceStatus &&
    (marketplaceStatus?.isAwsDisconnected ||
      marketplaceStatus?.isAzureDisconnected ||
      marketplaceStatus?.isGcpDisconnected);

  const hasListingDisconnectedService =
    data?.currentUser?.vendor?.listingsByChannel?.aws
      ?.map((aws) => aws?.sns_configuration?.value)
      .some((status) => status === 'disconnected') &&
    data?.currentUser?.vendor?.vendor_type !== 'demo';
  const productionVendor = pick(vendorWithSandboxes?.vendor, [
    'id',
    'name',
    'vendor_type',
  ]);
  const sandboxes = vendorWithSandboxes?.vendor?.linkedSandboxes;
  const vendorEnvironmentsList = sandboxes?.length
    ? getVendorEnvironmentsList({
        productionVendor: productionVendor,
        sandboxes,
      })
    : [];

  const productionVendorName = vendorWithSandboxes?.vendor?.name;

  const activeEnvironment: AppEnvironment | undefined = data?.currentUser
    ?.vendor
    ? {
        name: data.currentUser.vendor.name,
        type: data.currentUser.vendor.vendor_type as VendorEnvironmentEnum,
      }
    : undefined;

  const shouldRenderVendorEnvironmentSignal: boolean =
    vendorEnvironmentsList?.length > 1 &&
    activeEnvironment?.type !== VendorEnvironmentEnum.PROD;

  const cssToolbar = classnames(classes.toolbar, {
    [classes.toolbarCollapsed]: !drawerOpen,
    [classes.shareWithSignal]: shouldRenderVendorEnvironmentSignal,
  });

  return (
    <>
      <Snackbar
        open={!!error}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          severity="error"
          action={
            <Button
              variant="text"
              appearance="destructive"
              onClick={() => refetch()}
            >
              Try again
            </Button>
          }
        >
          Failed to load some data. Our support team has been notified.
        </Alert>
      </Snackbar>
      {mediaDownXS && (
        <AppBar position="fixed" className={classes.appBar} id="navigation">
          <Toolbar classes={{ gutters: classes.toolbarGutters }}>
            <Tooltip
              title="Toggle sidebar open"
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={toggleDrawer}
                className={classes.menuButton}
                disableRipple
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>

            <Typography
              variant="h6"
              color="inherit"
              className={classes.grow}
              noWrap
            >
              <div className={classes.tackleLogo}>
                <TackleHorizontalWhite />
              </div>
            </Typography>
            <APIDetailsProvider>
              <Profile drawerOpen={drawerOpen} />
            </APIDetailsProvider>
          </Toolbar>
        </AppBar>
      )}

      <Drawer
        variant="permanent"
        className={classnames(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
          paper: classnames(classes.drawerPaper, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
        open={drawerOpen}
      >
        <div>
          {shouldRenderVendorEnvironmentSignal && (
            <VendorEnvironmentSignal
              activeEnvironment={activeEnvironment}
              loading={loading}
            />
          )}
          <div className={cssToolbar}>
            <Tooltip
              title="Toggle sidebar open"
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                id={pendoIdGenerator(
                  dashboardUIElements.MENU_TOGGLE,
                  testEnvSuffix,
                )}
                color="inherit"
                aria-label="Open drawer"
                onClick={toggleDrawer}
                className={classes.menuButton}
                disableRipple
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            {drawerOpen && (
              <Typography
                variant="h6"
                color="inherit"
                className={classes.grow}
                noWrap
              >
                <div
                  className={classes.tackleLogo}
                  id={pendoIdGenerator(dashboardUIElements.LOGO_HOME)}
                >
                  <TackleHorizontalWhite />
                </div>
              </Typography>
            )}
          </div>
          <List className={classes.list} onClick={toggleDrawerNavButton}>
            {mediaDownXS &&
              hasFeature(FEATURE_DOWNSTREAM_INSIGHTS_V1, [
                isMarketplaceEnabled,
              ]) &&
              hasPermission(AREA_PERMISSION_PAYMENTS) && (
                <NavTooltip text="Activity Stream" disabled={drawerOpen}>
                  <NavListItem to="/activity-stream" drawerOpen={drawerOpen}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <TransitConnection
                        className={classes.icon}
                        fontSize="inherit"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Activity Stream"
                      className={classes.text}
                    />
                  </NavListItem>
                </NavTooltip>
              )}
            <div id={pendoIdGenerator(dashboardUIElements.SIDEBAR_DASHBOARD)}>
              <NavTooltip text="Dashboard" disabled={drawerOpen}>
                <NavListItem to="/dashboard" drawerOpen={drawerOpen}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ViewDashboard
                      className={classes.icon}
                      fontSize="inherit"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" className={classes.text} />
                  {hasVendorDisconnectedService && (
                    <AlertIcon
                      className={classes.alertIcon}
                      fontSize="inherit"
                    />
                  )}
                </NavListItem>
              </NavTooltip>
            </div>
            {!shouldEnforceSDDSConfig && (
              <>
                {hasFeature(FEATURE_DOWNSTREAM_INSIGHTS_V1, [
                  isMarketplaceEnabled,
                ]) &&
                  hasPermission(AREA_PERMISSION_PAYMENTS) && (
                    <div
                      id={pendoIdGenerator(
                        dashboardUIElements.SIDEBAR_INSIGHTS,
                      )}
                    >
                      <NavTooltip text="Insights" disabled={drawerOpen}>
                        <NavListItem to="/insights" drawerOpen={drawerOpen}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <LightbulbOnOutline
                              className={classes.icon}
                              fontSize="inherit"
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Insights"
                            className={classes.text}
                          />
                        </NavListItem>
                      </NavTooltip>
                    </div>
                  )}
                {hasFeature('', [isProspectEnabled]) &&
                  hasPermission(AREA_PERMISSION_PROSPECT) && (
                    <div
                      id={pendoIdGenerator(
                        dashboardUIElements.SIDEBAR_PROSPECT,
                      )}
                    >
                      <NavTooltip text="Prospect" disabled={drawerOpen}>
                        <NavListItem to="/prospect" drawerOpen={drawerOpen}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <ProspectIcon className={classes.prospectIcon} />
                          </ListItemIcon>
                          <ListItemText
                            primary="Prospect"
                            className={classes.text}
                          />
                        </NavListItem>
                      </NavTooltip>
                    </div>
                  )}

                {hasFeature('', [isListingsEnabled, isMarketplaceEnabled]) &&
                  hasPermission(AREA_PERMISSION_LISTINGS) && (
                    <div
                      id={pendoIdGenerator(
                        dashboardUIElements.SIDEBAR_LISTINGS,
                      )}
                    >
                      <NavTooltip text="Listings" disabled={drawerOpen}>
                        <NavListItem to="/listings" drawerOpen={drawerOpen}>
                          <ListItemIcon className={classes.listItemIcon}>
                            <FormatListBulleted
                              className={classes.icon}
                              fontSize="inherit"
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Listings"
                            className={classes.text}
                          />
                          {hasListingDisconnectedService && (
                            <AlertIcon
                              className={classes.alertIcon}
                              fontSize="inherit"
                            />
                          )}
                        </NavListItem>
                      </NavTooltip>
                    </div>
                  )}
                {hasFeature('', [
                  isCoSellEnabled,
                  isCoSellEnabledForMicrosoft,
                ]) &&
                  hasPermission(AREA_PERMISSION_COSELL) && (
                    <div
                      id={pendoIdGenerator(dashboardUIElements.SIDEBAR_CO_SELL)}
                      role="listitem"
                    >
                      <NavTooltip text="Co-Sell" disabled={drawerOpen}>
                        <NavListItem
                          role="link"
                          to="/co-sell"
                          drawerOpen={drawerOpen}
                        >
                          <Handshake
                            className={classNames(
                              classes.listItemIcon,
                              classes.cosellListItem,
                            )}
                          />
                          <ListItemText
                            primary="Co-Sell"
                            className={classes.text}
                          />
                        </NavListItem>
                      </NavTooltip>
                    </div>
                  )}
                {hasPermission(AREA_PERMISSION_METERING) && (
                  <div
                    id={pendoIdGenerator(dashboardUIElements.SIDEBAR_METERING)}
                  >
                    <NavTooltip text="Metering" disabled={drawerOpen}>
                      <NavListItem to="/metering" drawerOpen={drawerOpen}>
                        <ListItemIcon className={classes.listItemIcon}>
                          <Gauge className={classes.icon} fontSize="inherit" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Metering"
                          className={classes.text}
                        />
                      </NavListItem>
                    </NavTooltip>
                  </div>
                )}
                {hasFeature('', [isListingsEnabled, isMarketplaceEnabled]) &&
                  hasPermission(AREA_PERMISSION_OFFERS) && (
                    <div
                      id={pendoIdGenerator(
                        dashboardUIElements.SIDEBAR_PRIVATE_OFFERS,
                      )}
                    >
                      <NavTooltip text="Offers" disabled={drawerOpen}>
                        <NavListItem
                          to="/private-offers"
                          drawerOpen={drawerOpen}
                        >
                          <ListItemIcon className={classes.listItemIcon}>
                            <Sale className={classes.icon} fontSize="inherit" />
                          </ListItemIcon>
                          <ListItemText
                            primary="Offers"
                            className={classes.text}
                          />
                        </NavListItem>
                      </NavTooltip>
                    </div>
                  )}
                {hasPermission(AREA_PERMISSION_PAYMENTS) && isContractsEnabled && (
                  <div
                    id={pendoIdGenerator(dashboardUIElements.SIDEBAR_CONTRACTS)}
                  >
                    <NavTooltip text="Contracts" disabled={drawerOpen}>
                      <NavListItem
                        to="/cloud-contracts"
                        drawerOpen={drawerOpen}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          <ContractsIcon className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText
                          primary="Contracts"
                          className={classes.text}
                        />
                        {hasVendorDisconnectedService && (
                          <AlertIcon
                            className={classes.alertIcon}
                            fontSize="inherit"
                          />
                        )}
                      </NavListItem>
                    </NavTooltip>
                  </div>
                )}
                {hasPermission(AREA_PERMISSION_PAYMENTS) && (
                  <div
                    id={pendoIdGenerator(
                      dashboardUIElements.SIDEBAR_DISBURSEMENTS,
                    )}
                  >
                    <NavTooltip text="Payments" disabled={drawerOpen}>
                      <NavListItem to="/payments" drawerOpen={drawerOpen}>
                        <ListItemIcon className={classes.listItemIcon}>
                          <Receipt
                            className={classes.icon}
                            fontSize="inherit"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Payments"
                          className={classes.text}
                        />
                        {hasVendorDisconnectedService && (
                          <AlertIcon
                            className={classes.alertIcon}
                            fontSize="inherit"
                          />
                        )}
                      </NavListItem>
                    </NavTooltip>
                  </div>
                )}
                <Divider light />
                {hasPermission(AREA_PERMISSION_INTEGRATIONS) && (
                  <div
                    id={pendoIdGenerator(
                      dashboardUIElements.SIDEBAR_INTEGRATIONS,
                    )}
                  >
                    <NavTooltip text="Integrations" disabled={drawerOpen}>
                      <NavListItem to="/integrations" drawerOpen={drawerOpen}>
                        <ListItemIcon className={classes.listItemIcon}>
                          <TransitConnectionVariant
                            className={classes.icon}
                            fontSize="inherit"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Integrations"
                          className={classes.text}
                        />
                      </NavListItem>
                    </NavTooltip>
                  </div>
                )}
              </>
            )}
            {hasPermission(AREA_PERMISSION_ALL) && (
              <div
                id={pendoIdGenerator(
                  dashboardUIElements.SIDEBAR_USER_MANAGEMENT,
                )}
              >
                <NavTooltip text="User management" disabled={drawerOpen}>
                  <NavListItem to="/settings/users" drawerOpen={drawerOpen}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <AccountCircle
                        className={classes.icon}
                        fontSize="inherit"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={drawerOpen ? 'User management' : 'Users'}
                      className={classes.text}
                    />
                  </NavListItem>
                </NavTooltip>
              </div>
            )}
            {hasPermission(AREA_PERMISSION_SETTINGS) && (
              <div
                id={pendoIdGenerator(
                  dashboardUIElements.SIDEBAR_ACCOUNT_SETTINGS,
                )}
              >
                <NavTooltip text="Account settings" disabled={drawerOpen}>
                  <NavListItem to="/settings/account" drawerOpen={drawerOpen}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <CogOutline className={classes.icon} fontSize="inherit" />
                    </ListItemIcon>
                    <ListItemText
                      primary={drawerOpen ? 'Account settings' : 'Settings'}
                      className={classes.text}
                    />
                    {hasVendorDisconnectedService && (
                      <AlertIcon
                        className={classes.alertIcon}
                        fontSize="inherit"
                      />
                    )}
                  </NavListItem>
                </NavTooltip>
              </div>
            )}
            <Divider light />
            <div id={pendoIdGenerator(dashboardUIElements.SIDEBAR_HELP)}>
              <NavTooltip text="Tackle Help" disabled={drawerOpen}>
                <ListItem
                  component="a"
                  href={TACKLE_HELP_HOME_PAGE_DOCS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classnames(classes.navListItem, {
                    [classes.listVertical]: !drawerOpen,
                  })}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <HelpCircle className={classes.icon} fontSize="inherit" />
                  </ListItemIcon>
                  <ListItemText primary="Help" className={classes.text} />
                </ListItem>
              </NavTooltip>
            </div>
          </List>
          {loading && (
            <List>
              {[80, 60, 70].map((x) => (
                <ListItem
                  key={x}
                  className={classnames(classes.navListItem, {
                    [classes.listVertical]: !drawerOpen,
                  })}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <Skeleton
                      variant="circle"
                      className={classes.skeleton}
                      width={20}
                      height={20}
                    />
                  </ListItemIcon>
                  <Skeleton
                    variant="text"
                    className={classes.skeleton}
                    width={x}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </div>
        <div>
          <VendorProfile
            drawerOpen={drawerOpen}
            productionVendorName={productionVendorName}
            productionVendorId={productionVendorId}
          />
          <VendorEnvironment
            drawerOpen={drawerOpen}
            activeVendor={data?.currentUser?.vendor}
            activeEnvironment={activeEnvironment}
            vendorEnvironmentsList={vendorEnvironmentsList}
            loading={loading}
            loadingVendors={loadingVendorWithSandboxes}
          />
          {!mediaDownXS && (
            <APIDetailsProvider>
              <Profile drawerOpen={drawerOpen} />
            </APIDetailsProvider>
          )}
        </div>
      </Drawer>
    </>
  );
}

export default memo(observer(ApplicationShell));
