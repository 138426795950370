import { DisplayCloudType } from 'packages/cosell/src/types/enums';

export const MsftRoutes = {
  root: `${DisplayCloudType.MICROSOFT}`,
  referrals: 'referrals',
} as const;

export const routeMap = {
  createReferral: `${MsftRoutes.referrals}/create/:crmId?`,
  viewReferral: `${MsftRoutes.referrals}/:tackleId`,
  editReferral: `${MsftRoutes.referrals}/:tackleId/edit`,
} as const;
