import { useState, useEffect } from 'react';

export function useOAuthPopup(
  getOAuthLink: string,
  packageNamespace: string,
  isSandbox: boolean,
  packageVersion: string,
) {
  const [salesforceLoginPopup, setSalesforceLoginPopup] =
    useState<Window | null>(null);
  const [isPendingConnection, setIsPendingConnection] = useState(false);

  const openSalesforceOAuthPopUp = () => {
    const queryParams = new URLSearchParams({
      client_id: process.env.REACT_APP_CRM_CONNECTOR_SALESFORCE_CLIENT_ID,
      redirect_uri:
        process.env.REACT_APP_CRM_CONNECTOR_SALESFORCE_REGISTER_REDIRECT_URL,
      response_type: 'code',
      state: JSON.stringify({
        packageNamespace,
        loginUrl: document.referrer,
        isSandbox,
        packageVersion,
      }),
    });

    const width = 500;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 1.5;
    const windowFeatures = `left=${left},top=${top},width=${width},height=${height}`;

    const popup = window.open(
      `${getOAuthLink}?${queryParams.toString()}`,
      'popup',
      windowFeatures,
    );

    setSalesforceLoginPopup(popup);
    setIsPendingConnection(true);
  };

  useEffect(() => {
    if (!salesforceLoginPopup) return;

    const timer = setInterval(() => {
      if (salesforceLoginPopup.closed) {
        clearInterval(timer);
        setIsPendingConnection(false);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [salesforceLoginPopup]);

  return {
    openSalesforceOAuthPopUp,
    isPendingConnection,
    setIsPendingConnection,
    salesforceLoginPopup,
  };
}
