import { AssignmentIcon } from 'packages/cosell/assets';
import FormSectionCard from '../components/FormSectionCard';
import { FormSectionConfig } from '../constants';
import useToggleState from 'hooks/useToggleState';
import { AddPartnerFormDrawer } from './AddPartnerForm';

export const PartnersFormSection = () => {
  const [drawerIsOpen, toggleDrawer] = useToggleState(false);

  return (
    <>
      <FormSectionCard
        title={FormSectionConfig.partners.title}
        subtitle={null}
        icon={<AssignmentIcon />}
        data-id={FormSectionConfig.partners.dataId}
      >
        <FormSectionCard.BlockMessage>
          You haven't added any partners yet.
        </FormSectionCard.BlockMessage>
        <FormSectionCard.AddButton onClick={toggleDrawer}>
          Add partner
        </FormSectionCard.AddButton>
      </FormSectionCard>
      <AddPartnerFormDrawer open={drawerIsOpen} onClose={toggleDrawer} />
    </>
  );
};

export default PartnersFormSection;
