import React from 'react';
import { Box, Typography } from 'vendor/material';
import useStyles from './RightRailSubSection.style';
import classNames from 'classnames';

interface RightRailSubSectionProps {
  title?: string;
  introText?: string | null;
  subTitle?: string | React.ReactNode | null;
  guidanceText?: string | React.ReactNode;
  icon?: React.ReactNode;
  subIcon?: React.ReactNode;
}

const RightRailSubSection: React.FC<RightRailSubSectionProps> = ({
  title,
  introText,
  subTitle,
  guidanceText,
  icon,
  subIcon,
}) => {
  const classes = useStyles();

  //* TODO: Enable Sync status when API team supports accurately*/
  // const iconName = isValidElement(icon)
  //   ? (icon.type as React.ComponentType).name
  //   : '';

  return (
    <Box className={classes.sectionBox}>
      {title && (
        <Box className={classes.titleBox}>
          <Typography variant="subtitle1" className={classes.titleText}>
            {title}
          </Typography>
          {/* {icon && (
            <Box
              mr={1}
              data-id={`${coSellDetailsPageDataId.SYNC_STATUS_ICON}-${iconName}`}
            >
              {icon}
            </Box>
          )} */}
        </Box>
      )}
      {introText && (
        <Typography
          variant="body2"
          className={classNames(classes.bodyText, classes.introText)}
        >
          {introText}
        </Typography>
      )}
      {subTitle && (
        <Box className={classes.subTitleBox}>
          {subIcon && (
            <Box className={classes.subIcon} mr={1}>
              {subIcon}
            </Box>
          )}
          <Typography variant="subtitle2" className={classes.subTitleText}>
            {subTitle}
          </Typography>
        </Box>
      )}
      {guidanceText && <div className={classes.bodyText}>{guidanceText}</div>}
    </Box>
  );
};

export default RightRailSubSection;
