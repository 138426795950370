import { Loader } from '@tackle-io/platform-ui';
import { PROSPECT_PERMISSIONS } from './utils/constants';
import { ProspectScoreCrmAccount } from 'generated/graphql';
import { useEffect, useState } from 'react';
import { useRbac } from 'utils/rbac';
import { useProspectScoreCrmAccountData } from './ProspectAccountDataProvider';
import ProspectCallToAction from './ProspectCallToAction';
import ProspectDetailsAndScoring from './ProspectDetailsAndScoring';
import { useScanLimitData } from './ScanLimitDataProvider';
import ScanLimitReachedBanner from './ScanLimitReachedBanner';
import WebsiteRequiredBanner from './WebsiteRequiredBanner';
import Explainability from './Explainability';
import NotEnoughPermissionsTabContent from '../src/components/NotEnoughPermissionsTabContent';

export enum ProspectComponentIdentifier {
  DETAIL_AND_SCORING = 'DETAIL_AND_SCORING',
  EXPLAINABILITY = 'EXPLAINABILITY',
}

export interface CurrentComponent {
  componentId: ProspectComponentIdentifier;
  componentProps?: any;
}

const NO_PERMISSIONS_GUIDANCE =
  'Your permissions are not configured to view or request scores. Contact your Salesforce admin to request changes.';

export const ProspectComponentSelector: React.FC = () => {
  const {
    loading: loadingAccount,
    error,
    crmAccountScore,
    website,
  } = useProspectScoreCrmAccountData();
  const [storedCrmAccountScore, setStoredCrmAccountScore] = useState<
    ProspectScoreCrmAccount | undefined
  >(undefined);
  const { hasPermission } = useRbac();

  useEffect(() => {
    if (crmAccountScore) {
      setStoredCrmAccountScore(crmAccountScore);
    }
  }, [crmAccountScore, setStoredCrmAccountScore]);
  const { loading: loadingScanLimit, limitUse } = useScanLimitData();
  const [limitUseState, setLimitUseState] = useState<boolean>(limitUse);
  const [currentComponent, setCurrentComponent] = useState<CurrentComponent>({
    componentId: ProspectComponentIdentifier.DETAIL_AND_SCORING,
  });

  useEffect(() => {
    setLimitUseState(limitUse);
  }, [limitUse]);

  if (
    !hasPermission(PROSPECT_PERMISSIONS.GET_SCORE) ||
    !hasPermission(PROSPECT_PERMISSIONS.LIST_SCORES) ||
    error?.message?.includes('403: Forbidden')
  ) {
    return <NotEnoughPermissionsTabContent blurb={NO_PERMISSIONS_GUIDANCE} />;
  }

  if (!website) {
    return <WebsiteRequiredBanner />;
  }

  if (loadingAccount || loadingScanLimit) return <Loader />;

  if (
    !storedCrmAccountScore ||
    storedCrmAccountScore.visibility !== 'visible'
  ) {
    if (limitUseState) {
      return <ScanLimitReachedBanner />;
    } else if (!hasPermission(PROSPECT_PERMISSIONS.CREATE_SCORE)) {
      return <NotEnoughPermissionsTabContent blurb={NO_PERMISSIONS_GUIDANCE} />;
    } else {
      return (
        <ProspectCallToAction
          setStoredCrmAccountScore={setStoredCrmAccountScore}
          setLimitUseState={setLimitUseState}
        />
      );
    }
  }
  switch (currentComponent.componentId) {
    case ProspectComponentIdentifier.DETAIL_AND_SCORING:
      return (
        <ProspectDetailsAndScoring
          crmAccountScore={storedCrmAccountScore}
          scoreDetailClick={setCurrentComponent}
        />
      );
    case ProspectComponentIdentifier.EXPLAINABILITY:
      return (
        <Explainability
          cloudMarketplace={currentComponent.componentProps.cloudMarketplace}
          score={currentComponent.componentProps.score}
          companyName={currentComponent.componentProps.companyName}
          navigateBack={setCurrentComponent}
        />
      );
    default:
      return (
        <ProspectDetailsAndScoring
          crmAccountScore={storedCrmAccountScore}
          scoreDetailClick={setCurrentComponent}
        />
      );
  }
};
