import { useQuery } from '@tanstack/react-query';
import { Banner } from '@tackle-io/platform-ui';
import { JobProgressBar } from '../components/JobProgressBar';
import { useEffect, useMemo } from 'react';
import StatusCountDashboard from '../components/StatusCountDashboard';
import { JobItemList } from './JobItemList';
import { makeStyles } from 'vendor/material';
import {
  getBulkCreateJob,
  getBulkCreateJobResults,
} from 'packages/cosell/api/requests/bulkCreate';
import {
  type CloudProvider,
  CloudProviderConfig,
  GetJobRequestError,
  JobFailedError,
  JobCompleted,
  JobIsPending,
} from '../consts';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gap: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
}));

const JobDetailView = ({
  cloudProvider,
  id,
}: {
  cloudProvider?: CloudProvider;
  id?: string;
}) => {
  const classes = useStyles();

  const bulkCreateJob = useQuery({
    queryKey: ['bulkCreateJob', id, cloudProvider],
    queryFn: () =>
      getBulkCreateJob({ cloudProvider: cloudProvider!, jobId: id! }),
    enabled: !!id && !!cloudProvider,
    refetchInterval(data, query) {
      if (query.state.status === 'error') return false;
      if (data?.status && ['completed', 'error'].includes(data.status))
        return false;
      return 3000; // 3 seconds
    },
    retry: false,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const totalJobCount = bulkCreateJob.isSuccess
    ? bulkCreateJob.data?.crmIDs?.length
    : 0;

  const totalCompleted = bulkCreateJob.data?.completed || 0;
  const totalPending = Math.max(0, totalJobCount - totalCompleted);
  const dataCloudProvider = bulkCreateJob.data?.cloudProvider;

  const jobResultsQuery = useQuery({
    queryKey: ['bulkCreateJobResults', id, cloudProvider],
    queryFn: () =>
      getBulkCreateJobResults({ cloudProvider: cloudProvider!, jobId: id! }),
    enabled: !!id && bulkCreateJob.isSuccess && totalCompleted > 0,
    select: (data) => {
      if (data?.results) {
        return {
          results: data.results.map((result) => ({
            crmId: result.crmId,
            tackleId: result.tackleId ?? null,
            status:
              result.result === 'successful'
                ? ('submitted' as const)
                : ('error' as const),
            error: result.result !== 'successful' ? result.result : null,
            title: result.title ?? null,
          })),
        };
      }
    },
  });

  useEffect(() => {
    if (bulkCreateJob.isSuccess && totalCompleted > 0) {
      jobResultsQuery.refetch();
    }
  }, [totalCompleted, bulkCreateJob.isSuccess, jobResultsQuery]);

  const jobIsPending =
    bulkCreateJob.isSuccess &&
    !['error', 'completed'].includes(bulkCreateJob.data?.status);

  const jobStatusCounts = useMemo(() => {
    const cloudLabel =
      dataCloudProvider && dataCloudProvider in CloudProviderConfig
        ? CloudProviderConfig[dataCloudProvider]?.formLabel
        : 'cloud';

    return {
      pending: {
        status: 'Pending',
        amount: totalPending,
        description: 'Waiting to submit',
      },
      submitted: {
        status: 'Submitted',
        amount: bulkCreateJob.data?.successful ?? 0,
        description: `Submitted to ${cloudLabel}`,
      },
      error: {
        status: 'Error',
        amount: bulkCreateJob.data?.failed ?? 0,
        description: 'Draft created with submission errors',
      },
    };
  }, [
    dataCloudProvider,
    totalPending,
    bulkCreateJob.data?.successful,
    bulkCreateJob.data?.failed,
  ]);

  const progressBarTheme = useMemo(() => {
    switch (bulkCreateJob.data?.status) {
      case 'completed':
        return 'success';
      case 'error':
        return 'danger';
      case 'running':
        return 'info';
      default:
        return;
    }
  }, [bulkCreateJob.data?.status]);

  return (
    <div className={classes.container}>
      {bulkCreateJob.isError && (
        <Banner borderPosition="top" type="danger" title={GetJobRequestError} />
      )}
      {bulkCreateJob.data?.status === 'error' && (
        <Banner borderPosition="top" type="danger" title={JobFailedError} />
      )}
      {bulkCreateJob.data?.status === 'completed' && (
        <Banner borderPosition="top" type="success" title={JobCompleted} />
      )}
      {jobIsPending && (
        <Banner borderPosition="top" type="info" title={JobIsPending} />
      )}

      {bulkCreateJob.isSuccess && (
        <JobProgressBar
          complete={totalJobCount - totalPending}
          showCount
          theme={progressBarTheme}
          total={totalJobCount}
        />
      )}

      <StatusCountDashboard
        isEmpty={!bulkCreateJob.isSuccess}
        statusCounts={jobStatusCounts}
      />
      <JobItemList
        cloud={dataCloudProvider}
        isInitialLoading={jobResultsQuery.isInitialLoading}
        items={jobResultsQuery.data?.results}
        isError={jobResultsQuery.isError}
      />
    </div>
  );
};

export default JobDetailView;
