import { Tab as MuiTab, Tabs as MuiTabs, makeStyles } from 'vendor/material';
import { useState } from 'react';
import { fontWeightBold, fontWeightMedium } from 'utils/fontWeightConstants';

type ReusableTabsProps = {
  tabs: { label: string; id: string }[];
  defaultActiveTab?: ReusableTabsProps['tabs'][number]['id'];
  renderPanel: (
    activeTab: ReusableTabsProps['tabs'][number]['id'],
  ) => React.ReactNode;
};

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    boxShadow: `0px -1px 0px 0px ${theme.palette.NEUTRAL050} inset`,
    marginInline: theme.spacing(2),
  },
  tab: {
    color: `${theme.palette.BLACK}`,
    opacity: 1,
    fontSize: theme.typography.pxToRem(14),
    padding: '8px 16px 8px 16px',
    fontWeight: fontWeightMedium,
    textTransform: 'none',
    minWidth: 'auto',
    boxSizing: 'border-box',
    borderBottom: '1px solid #747474',
    '&[aria-selected=true]': {
      boxShadow: `0px -2px 0px 0px #1589EE inset`,
      fontWeight: fontWeightBold,
    },
  },
  tabIndicator: {
    opacity: 0,
  },
}));

/**
 * Tabs with renderProps for rendering the selected tab content
 */
const Tabs = ({ defaultActiveTab, tabs, renderPanel }: ReusableTabsProps) => {
  const classes = useStyles();

  const [activeTabId, setActiveTabId] = useState<typeof defaultActiveTab>(
    defaultActiveTab || tabs[0].id,
  );

  const handleTabClick = (_e: unknown, tabId: typeof defaultActiveTab) => {
    setActiveTabId(tabId);
  };

  return (
    <>
      <MuiTabs
        className={classes.tabsContainer}
        onChange={handleTabClick}
        value={activeTabId}
        TabIndicatorProps={{
          className: classes.tabIndicator,
        }}
      >
        {tabs.map((tab) => (
          <MuiTab
            className={classes.tab}
            key={tab.id}
            value={tab.id}
            label={tab.label}
            data-testid={`tab-${tab.id}`}
          />
        ))}
      </MuiTabs>
      <div role="tabpanel" id={activeTabId}>
        {renderPanel(activeTabId)}
      </div>
    </>
  );
};

export default Tabs;
