import React from 'react';
import { Divider, Grid, useMediaQuery, useTheme } from 'vendor/material';
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding';
import InfoItem, {
  FULL_GRID_SIZE,
} from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
import { convertArrayToString } from '../../helper';
import { Marketing } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import { coSellAceOpportunityFormFieldsDataId } from 'packages/cosell/src/utilities/intercomEnums';
// import { DetailsSectionCard, InfoItem } from 'packages/cosell/src/components';

interface AceMarketingDetailsProps {
  marketing: Marketing;
}

const AceMarketingDetails: React.FC<AceMarketingDetailsProps> = ({
  marketing,
}) => {
  const theme = useTheme();
  const screenMobile = useMediaQuery(theme.breakpoints.down(960));

  return (
    <DetailsSectionCard title="Marketing details" icon={<OfficeBuilding />}>
      <Grid container spacing={2}>
        <InfoItem itemTitle="Opportunity source" value={marketing?.source} />
        {screenMobile && <Divider style={{ width: '100%' }} />}
        <InfoItem
          itemTitle="Marketing campaign"
          value={marketing?.campaignName}
          dataId={coSellAceOpportunityFormFieldsDataId.MARKETING_CAMPAIGN}
        />
        <Divider style={{ width: '100%' }} />

        <InfoItem
          itemTitle="Marketing activity use case"
          value={convertArrayToString(marketing?.useCases)}
          dataId={
            coSellAceOpportunityFormFieldsDataId.MARKETING_ACTIVITY_USE_CASE
          }
        />
        {screenMobile && <Divider style={{ width: '100%' }} />}
        <InfoItem
          itemTitle="Marketing activity channel"
          value={convertArrayToString(marketing?.channels)}
          dataId={
            coSellAceOpportunityFormFieldsDataId.MARKETING_ACTIVITY_CHANNEL
          }
        />
        <Divider style={{ width: '100%' }} />

        <InfoItem
          itemTitle="Marketing development funds"
          value={marketing?.awsFundingUsed}
          dataId={
            coSellAceOpportunityFormFieldsDataId.MARKETING_DEVELOPMENT_FUNDS
          }
          gridSize={FULL_GRID_SIZE}
        />
        <Divider style={{ width: '100%' }} />
      </Grid>
    </DetailsSectionCard>
  );
};

export default AceMarketingDetails;
