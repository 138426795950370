import { Loader } from '@tackle-io/platform-ui';
import { Box } from 'vendor/material';
import { toDateTime } from 'utils/dates';
// import { AwsCoSellSyncStatus } from 'packages/cosell/src/components/AwsCoSellSyncStatus/AwsCoSellSyncStatus';
import CollapsibleListItem from 'packages/cosell/src/components/CollapsibleListItem/CollapsibleListitem';
import { useTackleOpportunitiesForCrmId } from 'packages/salesforce-canvas/cosell/src/hooks/useTackleOpportunitiesForCrmId/useTackleOpportunitiesForCrmId';
import AwsOpportunitySummary from './AwsOpportunitySummary';
import useOpportunityListStyles from './AwsCoSells.styles';
import CoSellCallToAction from '../components/CoSellZeroStateView/CoSellZeroStateView';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import { WidgetContainer } from '../components/WidgetContainer';
import { AddCoSellButton } from '../components/AddCoSellButton';
import IncompleteAwsOpportunity from './IncompleteAwsOpportunity';
import { IncomingCoSellLoader } from '../components/IncomingDataLoader';
import { useHasCoSellRbacRights } from 'packages/cosell/src/hooks/useHasCoSellRbacRights/useHasCoSellRbacRights';
import CoSellLimitedPermissionBanner from 'packages/cosell/src/pages/UnifiedInvitationDetails/AceInvitationDetails/components/CoSellLimitedPermissionBanner';

/** not sure if this is going to be removed from the design */
// const PendingBanner = ({ message }: { message: string }) => {
//   return <Banner title={message} type="info" />;
// };

/** View for Salesforce Tackle widget AWS co-sells */
export default function AwsCoSells({ crmId }: { crmId: string }) {
  const rbacRights = useHasCoSellRbacRights();
  const classes = useOpportunityListStyles();
  const {
    data,
    isInitialLoading: isLoadingOpportunities,
    refetch: refetchOpportunitiesList,
    incomingCosell,
  } = useTackleOpportunitiesForCrmId({ crmId });
  const opportunities = data?.opportunities;
  const canCreateCoSell = rbacRights.createOpportunity;
  const canAccessAll = rbacRights.accessAll;

  if (isLoadingOpportunities) return <Loader />;

  const incomingCoSellContent = incomingCosell.creationIsPending ? (
    <IncomingCoSellLoader
      isPolling={incomingCosell.isPolling}
      onRefresh={refetchOpportunitiesList}
    />
  ) : null;

  if (!opportunities?.length) {
    return (
      <CoSellCallToAction
        canCreateCoSell={canCreateCoSell}
        headerContent={incomingCoSellContent}
        singleCloud={DisplayCloudType.AWS}
      />
    );
  }
  return (
    <WidgetContainer>
      <WidgetContainer.Content>
        <div className={classes.content}>
          {!canAccessAll && <CoSellLimitedPermissionBanner />}
          {incomingCoSellContent}

          {/* {opportunities.length > 1 && (
        <PendingBanner
          message={`${opportunities.length} Co-sells in progress`}
        />
      )} */}
          {/** this will enabled when it is connected to the correct data and actions*/}
          {/* <AwsCoSellSyncStatus
        // WIP: placeholder date - need to replace with actual last sync date
        lastSyncDate={'2024-12-10T17:51:00+00:00'}
        opportunityId={crmId}
      /> */}

          <Box className={classes.listItem}>
            {opportunities.map((opportunitySummary, i) => {
              const createdDate = opportunitySummary.created
                ? toDateTime(opportunitySummary.created).toFormat('MM/dd/yy')
                : '-';

              return (
                <CollapsibleListItem
                  key={opportunitySummary.id}
                  title={`Created ${createdDate}`}
                  defaultOpen={i === 0}
                  isCollapsible={!(i === 0 && opportunities.length === 1)}
                  className={
                    i === opportunities.length - 1
                      ? classes.lastListItem
                      : undefined
                  }
                >
                  {opportunitySummary.cloudProviderId ? (
                    <AwsOpportunitySummary
                      opportunityId={opportunitySummary.id}
                    />
                  ) : (
                    <IncompleteAwsOpportunity
                      opportunitySummary={opportunitySummary}
                    />
                  )}
                </CollapsibleListItem>
              );
            })}
          </Box>
        </div>
      </WidgetContainer.Content>
      {canCreateCoSell && (
        <WidgetContainer.Footer>
          <AddCoSellButton cloud={DisplayCloudType.AWS} />
        </WidgetContainer.Footer>
      )}
    </WidgetContainer>
  );
}
