import { makeStyles } from 'vendor/material';
import StartJobForm from './StartJobForm';
import { useMutation } from '@tanstack/react-query';
import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';
import invariant from 'tiny-invariant';
import { Banner } from '@tackle-io/platform-ui';
import JobDetailView from '../JobDetailView/JobDetailView';
import { startBulkCreateJob } from 'packages/cosell/api/requests/bulkCreate';
import { type CloudProvider, CreateJobRequestError } from '../consts';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gap: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
}));

const allowedErrorMessages = [
  'We could not verify your AWS account ID. Have you completed onboarding?',
];

const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) {
    return allowedErrorMessages.includes(error.message)
      ? error.message
      : CreateJobRequestError;
  }
  return null;
};

const StartJobView = ({
  defaultCloudProvider,
}: {
  defaultCloudProvider: CloudProvider;
}) => {
  const { payload } = useCanvasSession();
  const classes = useStyles();

  const crmIDs = payload?.records?.map((x) => x.Id);
  invariant(crmIDs, 'No crmIDs found in the canvas payload');

  const createJob = useMutation({
    mutationFn: startBulkCreateJob,
  });

  const handleStartJob = async ({
    cloudProvider,
  }: {
    cloudProvider: string;
  }) => {
    await createJob.mutateAsync({
      cloudProvider: cloudProvider as CloudProvider,
      crmIDs,
    });
  };

  return (
    <>
      <div className={classes.container}>
        {!createJob.isSuccess && (
          <StartJobForm
            defaultCloudProvider={defaultCloudProvider}
            coSellCount={crmIDs.length}
            onSubmit={handleStartJob}
          />
        )}
        {createJob.isError && (
          <Banner
            borderPosition="top"
            type="danger"
            title={getErrorMessage(createJob.error)}
          />
        )}
        <JobDetailView
          cloudProvider={createJob.data?.cloudProvider}
          id={createJob.data?.id}
        />
      </div>
    </>
  );
};

export default StartJobView;
