import { MappedInitialValuesMap } from 'packages/cosell/src/hooks/useAwsCoSellMappedFields';
import { MarketingSourceEnum } from 'packages/cosell/src/types/enums/MarketingSourceEnum';

/**
 * Remove mapped values that are not rendered in the UI
 * Temporary fix that should be later handled in fieldMapper
 */
export const getValidMappedValues = (
  initialValues: MappedInitialValuesMap['create'] = {},
) => {
  const validMappedValues = { ...initialValues };

  if (validMappedValues.marketingSource === MarketingSourceEnum.NONE) {
    delete validMappedValues.isMarketingDevelopmentFunded;
  }

  return validMappedValues;
};
