import { useCallback, useMemo } from 'react';
import { useCanUseCoSellCloud } from 'packages/cosell/src/hooks/useCanUseCoSellCloud';
import { Loader } from '@tackle-io/platform-ui';
import {
  CloudDisplayLabelLookup,
  DisplayCloudType,
} from 'packages/cosell/src/types/enums';
import Tabs from './components/Tabs';
import AwsCoSells from './AwsCoSells/AwsCoSells';
import MicrosoftView from './MicrosoftCoSells/MicrosoftCoSells';

export const tabOptions = {
  [DisplayCloudType.AWS]: {
    id: DisplayCloudType.AWS,
    label: CloudDisplayLabelLookup.aws,
    content: AwsCoSells,
  },
  [DisplayCloudType.MICROSOFT]: {
    id: DisplayCloudType.MICROSOFT,
    label: CloudDisplayLabelLookup.microsoft,
    content: MicrosoftView,
  },
};

export type TabOptions = typeof tabOptions[keyof typeof tabOptions];

/**
 * Co-sell Widget for Salesforce canvas
 */
export default function CoSellSfTackleWidget({ crmId }: { crmId: string }) {
  const canUseCoSellCloud = useCanUseCoSellCloud();
  const canUseMicrosoft = canUseCoSellCloud[DisplayCloudType.MICROSOFT];
  const availableTabs = useMemo(() => {
    const tabs: TabOptions[] = [tabOptions[DisplayCloudType.AWS]];
    if (canUseMicrosoft) {
      tabs.push(tabOptions[DisplayCloudType.MICROSOFT]);
    }
    return tabs;
  }, [canUseMicrosoft]);

  const renderPanel = useCallback(
    (selectedTabId: keyof typeof tabOptions) => {
      const Component = tabOptions[selectedTabId].content;
      return <Component crmId={crmId} />;
    },
    [crmId],
  );

  if (canUseCoSellCloud.loading) {
    return <Loader />;
  }

  if (!canUseMicrosoft) {
    return <AwsCoSells crmId={crmId} />;
  }

  return (
    <div
      style={{ height: '100%', display: 'grid', gridTemplateRows: 'auto 1fr' }}
    >
      <Tabs renderPanel={renderPanel} tabs={availableTabs} />
    </div>
  );
}
