import { Dispatch, SetStateAction } from 'react';
import { TablePagination } from 'vendor/material';
import useStyles from './CoSellPagination.styles';
import { useHistory } from 'react-router-dom';
import {
  PAGE_KEY,
  PAGE_LIMIT,
  PAGE_SIZE_KEY,
  FROM_KEY,
  getSearchParamNumberValue,
} from '../../../../helpers/urlParamsHelper';
import { coSellLandingPageDataId } from 'packages/cosell/src/utilities/intercomEnums';

interface PaginationPageProps {
  setIncomingPage: Dispatch<SetStateAction<number | null>>;
  total?: number;
  currentPageCount?: number;
  isNextPage?: boolean;
  nextIndex?: number;
}

// Special number to indicate loading "more than x total"
const UNKNOWN_COUNT_PLACEHOLDER = -1;

export const CoSellPagination = ({
  setIncomingPage,
  total,
  currentPageCount,
  isNextPage,
  nextIndex,
}: PaginationPageProps) => {
  const classes = useStyles();

  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const pageSize = getSearchParamNumberValue({
    searchParams,
    key: PAGE_SIZE_KEY,
  });
  const pageNumber = getSearchParamNumberValue({ searchParams, key: PAGE_KEY });
  const currentFromIndex = getSearchParamNumberValue({
    searchParams,
    key: FROM_KEY,
  });

  const handlePageChange = (incomingPage: number) => {
    if (incomingPage === 0) {
      searchParams.delete(FROM_KEY);
      searchParams.delete(PAGE_KEY);
    } else if (typeof nextIndex === 'number' && incomingPage > pageNumber) {
      searchParams.set(FROM_KEY, nextIndex.toString());
      searchParams.set(PAGE_KEY, incomingPage.toString());
    } else if (typeof currentPageCount === 'number') {
      // Previous page
      const previousIndex = currentFromIndex - currentPageCount;
      searchParams.set(FROM_KEY, previousIndex.toString());
      searchParams.set(PAGE_KEY, incomingPage.toString());
    }

    setIncomingPage(incomingPage);

    // Set URL params
    history.push({
      search: searchParams?.toString(),
    });
  };

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    searchParams.delete(PAGE_KEY);
    searchParams.delete(PAGE_SIZE_KEY);
    searchParams.delete(FROM_KEY);

    if (value !== PAGE_LIMIT.toString()) {
      searchParams.set(PAGE_SIZE_KEY, value);
    }

    history.push({
      search: searchParams?.toString(),
    });
    // Scroll to top of the page
    setIncomingPage(0);
  };

  return (
    <div
      className={classes.paginationDetails}
      data-id={coSellLandingPageDataId.PAGINATION}
    >
      <TablePagination
        component="div"
        count={total || UNKNOWN_COUNT_PLACEHOLDER}
        page={pageNumber}
        rowsPerPage={pageSize}
        onPageChange={(e, page) => handlePageChange(page)}
        onRowsPerPageChange={handlePageSizeChange}
        nextIconButtonProps={{
          disabled: !isNextPage,
        }}
      />
    </div>
  );
};
