import {
  InvalidCreateOfferPayloadError,
  LogicalValidationError,
  MarketplaceInvariantError,
  OfferSubmissionError,
  SchemaValidationError,
} from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import { Optional } from 'utils/optional/optional';
import { isArray } from 'lodash/fp';

export enum ErrorType {
  SchemaValidationError,
  LogicalValidationError,
  InvalidCreateOfferPayloadError,
  MarketplaceInvariantError,
  UnknownError,
}

const isSchemaValidationError = (error?: OfferSubmissionError) => {
  return Optional.ofNullable(error)
    .map((e) => {
      const errorProperties = new Set<string>(Object.keys(e));

      return (
        errorProperties.has('message') &&
        errorProperties.has('schema_validation_errors')
      );
    })
    .orElse(false);
};

const allValuesHaveCodeAndMessage = (v: any) => {
  const errorProperties = new Set<string>(Object.keys(v));
  return errorProperties.has('code') && errorProperties.has('message');
};

const isLogicalValidationError = (error?: OfferSubmissionError) => {
  return Optional.ofNullable(error)
    .map((e) => {
      const errorIsArray = isArray(e);
      return errorIsArray && (e as any[]).every(allValuesHaveCodeAndMessage);
    })
    .orElse(false);
};

const isInvalidCreateOfferPayloadError = (error?: OfferSubmissionError) => {
  return Optional.ofNullable(error)
    .map((e) => {
      const errorProperties = new Set<string>(Object.keys(e));
      return (
        errorProperties.has('code') &&
        errorProperties.has('description') &&
        errorProperties.has('error_codes')
      );
    })
    .orElse(false);
};

const isMarketplaceInvariantError = (error?: OfferSubmissionError) => {
  return Optional.ofNullable(error)
    .map((e) => {
      const errorProperties = new Set<string>(Object.keys(e));
      return errorProperties.has('message');
    })
    .orElse(false);
};

const schemaValidationErrorsProducer = (error?: SchemaValidationError): any[] =>
  error?.schema_validation_errors || (error && [error]);

const logicalValidationErrorsProducer = (
  error?: LogicalValidationError[],
): any[] => error?.map((e) => e.message);

const invalidCreateOfferPayloadErrorsProducer = (
  error?: InvalidCreateOfferPayloadError,
): any[] => error?.error_codes;

const marketplaceInvariantErrorsProducer = (
  error?: MarketplaceInvariantError,
): any[] => {
  const messages = error?.message?.split(',\n') || [];
  return messages.map((m) =>
    m.includes('IsISVFacing=true ') ? m.split('IsISVFacing=true ')[1] : m,
  );
};

const unknownErrorsProducer = (error?: any): any[] => {
  return error ? [JSON.stringify(error)] : [];
};

export const errorsProducerForErrorType = {
  [ErrorType.SchemaValidationError]: schemaValidationErrorsProducer,
  [ErrorType.LogicalValidationError]: logicalValidationErrorsProducer,
  [ErrorType.InvalidCreateOfferPayloadError]:
    invalidCreateOfferPayloadErrorsProducer,
  [ErrorType.MarketplaceInvariantError]: marketplaceInvariantErrorsProducer,
  [ErrorType.UnknownError]: unknownErrorsProducer,
};

export const determineErrorType = (error?: OfferSubmissionError): ErrorType =>
  isSchemaValidationError(error)
    ? ErrorType.SchemaValidationError
    : isLogicalValidationError(error)
    ? ErrorType.LogicalValidationError
    : isInvalidCreateOfferPayloadError(error)
    ? ErrorType.InvalidCreateOfferPayloadError
    : isMarketplaceInvariantError(error)
    ? ErrorType.MarketplaceInvariantError
    : ErrorType.UnknownError;
