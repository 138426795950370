import ky from 'ky';
import { get } from 'lodash';
import { reportError } from 'utils/monitor';
import { ErrorResponse } from '../src/types';
import {
  CreateAceOpportunityRequest,
  UpdateAceOpportunityRequest,
} from '../src/types/requests/AceOpportunityRequest';
import {
  AwsEnumListResponse,
  AwsEnumOption,
} from '../src/types/responses/AwsEnumResponse';
import {
  AceOpportunityCampaignNameEnum,
  AwsProductsEnum,
} from '../src/types/enums';
// To mock details page or edit page for testing
// import {
//   getOppLaunched,
//   getSrrpOppLaunched,
//   getOppDraft,
//   getOppActionRequired,
//   getOppApproved,
//   getOppNationalSecurityYes,
//   getOppCompetitiveTrackingOther,
//   getOppMarketingFundsNo,
//   getOppOpportunitySourceNone,
//   getSolutionList
// } from './mockHelpers';
// import mockInvitations from './mockInvitations.json';
import {
  AceOpportunityResponse,
  IncompleteAceOpportunityResponse,
} from '../src/types/responses/AceOpportunityResponse';
import {
  getInvitationsListUrl,
  getOpportunitiesListUrl,
  InvitationActionArg,
  TackleOperationId,
} from './utils';
import { AceSolutionListResponse } from '../src/types/responses/AceSolutionResponse';
import { AceOpportunitiesListResponse } from '../src/types/responses/AceOpportunitiesListResponse';
import { AceOpportunityEventsListResponse } from '../src/types/responses/AceOpportunityEventsListResponse';
import { AceCreateResponse } from '../src/types/responses/AceCreateResponse';
import { CoSellAwsVendorSettingsResponse } from '../src/types/responses/CoSellAwsVendorSettingsResponse';
import {
  AceInvitationEventsListResponse,
  AceInvitationResponse,
  AceInvitationsListResponse,
} from '../src/types/AceInvitation.type';
import { ActiveFilter } from '../src/components/FilterPanel/FilterPanel';

import {
  InvitationSortState,
  OpportunitySortState,
} from '../src/pages/CoSellLandingPage/helpers/urlParamsHelper';
import { CoSellCreateLinkRequest } from 'pages/Settings/Account/components/CoSellSettings/CoSellSettingsAwsLinking/requests/CoSellCreateLinkRequest';
import {
  CoSellLinkListResponse,
  CoSellLinkResponse,
} from 'pages/Settings/Account/components/CoSellSettings/CoSellSettingsAwsLinking/responses/CoSellLinkResponses';
import { coSellAwsApi } from 'packages/tackle-api';

// our calls to create/update ACE opportunities are rather expensive
// unfortunately, the speed at which ACE processes the opportunities we send it is out of our control
// our API service waits up to 20 seconds for ACE to process our opportunities, we need to provide enough time for that to complete
const COSELL_API_TIMEOUT = 25000;
const TIMEOUT_STATUS_CODE = 408;

const getErrorResponse = async (error: unknown) => {
  let errorResponse: ErrorResponse = {
    httpStatusCode: get(error, 'response.status', 500),
    message: get(error, 'message', 'An error was encountered'),
    detail: get(error, 'message', 'An error was encountered'),
  };
  if (error instanceof ky.HTTPError) {
    const errorJson = await error.response.json();
    errorResponse.detail = errorJson.detail;
  } else if (error instanceof ky.TimeoutError) {
    errorResponse.httpStatusCode = TIMEOUT_STATUS_CODE;
  }
  return errorResponse;
};

const coSellClient = {
  async createOpportunity(
    requestBody: CreateAceOpportunityRequest,
    tackleOperationId: TackleOperationId,
  ): Promise<AceCreateResponse> {
    try {
      const result = await coSellAwsApi.post(`opportunities`, {
        headers: {
          'tackle-operation-id': tackleOperationId,
        },
        body: JSON.stringify(requestBody),
        timeout: COSELL_API_TIMEOUT,
      });
      return result.json();
    } catch (error) {
      reportError(error);
      const err = await getErrorResponse(error);
      throw new Error(`An error was encountered: ${error?.message}`, {
        cause: err,
      });
    }
  },

  async updateOpportunity(
    body: UpdateAceOpportunityRequest,
    opportunityId: string,
    tackleOperationId: TackleOperationId,
  ): Promise<{ id: string } | undefined> {
    try {
      const result = await coSellAwsApi.post(`opportunities/${opportunityId}`, {
        headers: {
          'tackle-operation-id': tackleOperationId,
        },
        body: JSON.stringify(body),
        timeout: COSELL_API_TIMEOUT,
      });

      // 202 Accepted response indicates that the request is going to be queued and sent to ACE
      if (result.status === 202) {
        // Success case
        return {
          id: opportunityId,
        };
      }
    } catch (error) {
      reportError(error);
      const err = await getErrorResponse(error);
      throw new Error(`An error was encountered: ${error?.message}`, {
        cause: err,
      });
    }
  },

  /** only for mock service */
  async acceptOrRejectOpportunity({
    opportunityId,
    tackleOperationId,
  }: {
    opportunityId: string;
    tackleOperationId: string;
  }): Promise<{ success: true } | ErrorResponse> {
    try {
      await coSellAwsApi.post(`opportunities/${opportunityId}`, {
        headers: {
          'tackle-operation-id': tackleOperationId,
        },
        body: '{}',
        timeout: COSELL_API_TIMEOUT,
      });
      return { success: true };
    } catch (error) {
      reportError(error);
      const err = await getErrorResponse(error);
      throw new Error(`An error was encountered: ${error?.message}`, {
        cause: err,
      });
    }
  },

  async getAwsCoSellEnumOptions(): Promise<AwsEnumListResponse> {
    const result: { [key: string]: Array<AwsEnumOption> } = await coSellAwsApi
      .get(`settings/picklist-options`, {
        timeout: COSELL_API_TIMEOUT,
      })
      .json();

    // temporarily append hard-coded values to this response until the API returns them
    result['awsProducts'] = AwsProductsEnum;
    result['campaignName'] = AceOpportunityCampaignNameEnum;
    result['marketingActivityUseCase'] = [];

    return result as unknown as AwsEnumListResponse;
  },

  async getOpportunityByIdV3(
    opportunityId: string,
  ): Promise<
    AceOpportunityResponse | IncompleteAceOpportunityResponse | undefined
  > {
    try {
      const response = await coSellAwsApi.get(`opportunities/${opportunityId}`);
      const jsonResponse = await response.json();
      /** set a boolean based on parameters required to have been created in AWS cloud
       *  this will also provide correct inference of opportunity response type based on the presence of an id
       */
      jsonResponse['isCreatedInCloud'] = 'id' in jsonResponse;

      return jsonResponse;
    } catch (error) {
      reportError(error);
      const err = await getErrorResponse(error);
      throw err;
    }
  },

  async getVendorAceSolutions(): Promise<AceSolutionListResponse | undefined> {
    // Keep this here, you won't get any response from API if you point to a branch in co-sell-aws instead of main
    // return getSolutionList();

    try {
      const result = await coSellAwsApi.get(`solutions`).json();
      // @ts-expect-error - Type '{}' is missing the following properties
      // from type 'AceSolutionListResponse': solutions, total, currentPageCount
      return result;
    } catch (error) {
      if (error instanceof ky.HTTPError || error instanceof ky.TimeoutError) {
        const err = await getErrorResponse(error);
        throw new Error('An error was encountered', { cause: err });
      }
    }
  },

  async getOpportunitiesList({
    q,
    pageSize,
    from,
    activePanelFilters,
    sortState,
    startDate,
    endDate,
  }: {
    q?: string | null;
    pageSize?: string | null;
    from?: string | null;
    sortState: OpportunitySortState;
    activePanelFilters: ActiveFilter[];
    startDate?: string;
    endDate?: string;
  }): Promise<AceOpportunitiesListResponse> {
    try {
      const url = getOpportunitiesListUrl({
        q,
        pageSize,
        from,
        activePanelFilters,
        sortState,
        startDate,
        endDate,
      });
      const result = await coSellAwsApi.get(url);

      return result.json();
    } catch (error) {
      reportError(error);
      const err = await getErrorResponse(error);
      throw err;
    }
  },

  async getOpportunityEvents(
    opportunityId: string,
  ): Promise<AceOpportunityEventsListResponse> {
    try {
      const result = await coSellAwsApi.get(
        `opportunities/${opportunityId}/events`,
      );
      return result.json();
    } catch (error) {
      reportError(error);
      const err = await getErrorResponse(error);
      throw err;
    }
  },

  async getInvitationsList({
    q,
    pageSize,
    from,
    activePanelFilters,
    sortState,
    startDate,
    endDate,
  }: {
    q?: string | null;
    pageSize?: string | null;
    from?: string | null;
    activePanelFilters: ActiveFilter[];
    sortState: InvitationSortState;
    startDate?: string;
    endDate?: string;
  }): Promise<AceInvitationsListResponse> {
    try {
      // return mockInvitations;
      const url = getInvitationsListUrl({
        q,
        pageSize,
        from,
        activePanelFilters,
        sortState,
        startDate,
        endDate,
      });
      const result = await coSellAwsApi.get(url);

      return result.json();
    } catch (error) {
      reportError(error);
      const err = await getErrorResponse(error);
      throw err;
    }
  },

  async getInvitationById(
    invitationId: string,
  ): Promise<AceInvitationResponse> {
    try {
      const response = await coSellAwsApi.get(`invitations/${invitationId}`);
      const jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      reportError(error);
      const err = await getErrorResponse(error);
      throw err;
    }
  },
  async acceptOrRejectInvitation({
    invitationId,
    tackleOperationId,
    body,
  }: InvitationActionArg): Promise<{ status: number } | ErrorResponse> {
    try {
      const result = await coSellAwsApi.post(`invitations/${invitationId}`, {
        headers: {
          'tackle-operation-id': tackleOperationId,
        },
        body: JSON.stringify(body),
        timeout: COSELL_API_TIMEOUT,
      });
      // No response is given for accept/reject invitation
      // 202 Accepted response indicates that the request is going to be queued and sent to ACE

      return result;
      // }
    } catch (error) {
      reportError(error);
      const err = await getErrorResponse(error);
      throw new Error(`An error was encountered: ${error?.message}`, {
        cause: err,
      });
    }
  },

  async getInvitationEvents(
    invitationId: string,
  ): Promise<AceInvitationEventsListResponse> {
    try {
      const result = await coSellAwsApi.get(
        `invitations/${invitationId}/events`,
      );
      return result.json();
    } catch (error) {
      reportError(error);
      const err = await getErrorResponse(error);
      throw err;
    }
  },

  async getSettings(): Promise<CoSellAwsVendorSettingsResponse> {
    /** the endpoint used the auth token to get the vendor_id */
    try {
      const result = (await coSellAwsApi
        .get('settings')
        .json()) as CoSellAwsVendorSettingsResponse;
      return result;
    } catch (error) {
      reportError(error);
      const err = await getErrorResponse(error);
      throw err;
    }
  },

  async updateCustomWorkflows(requestBody: { id: string }): Promise<void> {
    try {
      await coSellAwsApi.post(`settings/custom-workflows`, {
        body: JSON.stringify(requestBody),
        timeout: COSELL_API_TIMEOUT,
      });
    } catch (error) {
      reportError(error);
      const err = await getErrorResponse(error);
      throw err;
    }
  },
  async deleteCustomWorkflows(requestBody: { id: string }): Promise<void> {
    const { id } = requestBody;
    try {
      await coSellAwsApi.delete(`settings/custom-workflows/${id}`);
    } catch (error) {
      reportError(error);
      const err = await getErrorResponse(error);
      throw err;
    }
  },

  async createOpportunityLinkingJob(
    requestBody: CoSellCreateLinkRequest,
  ): Promise<CoSellLinkResponse> {
    try {
      const result = await coSellAwsApi.post(
        `settings/opportunity-linking-jobs`,
        {
          body: JSON.stringify(requestBody),
          timeout: COSELL_API_TIMEOUT,
        },
      );
      return result.json();
    } catch (error) {
      reportError(error);
      const err = await getErrorResponse(error);
      throw new Error(`An error was encountered: ${error?.message}`, {
        cause: err,
      });
    }
  },

  async getOpportunityLinkingJob(jobId: string): Promise<CoSellLinkResponse> {
    try {
      const result = (await coSellAwsApi
        .get(`settings/opportunity-linking-jobs/${jobId}`)
        .json()) as CoSellLinkResponse;
      return result;
    } catch (error) {
      reportError(error);
      const err = await getErrorResponse(error);
      throw err;
    }
  },

  async getOpportunityLinkingJobCSVResult(jobId: string): Promise<Blob> {
    try {
      const result = await coSellAwsApi
        .get(`settings/opportunity-linking-jobs/${jobId}/results.csv`)
        .blob();
      return result;
    } catch (error) {
      reportError(error);
      const err = await getErrorResponse(error);
      throw err;
    }
  },

  async listOpportunityLinkingJobs(
    limit: number,
  ): Promise<CoSellLinkListResponse> {
    try {
      const result = (await coSellAwsApi
        .get(`settings/opportunity-linking-jobs/?limit=${limit}`)
        .json()) as CoSellLinkListResponse;
      return result;
    } catch (error) {
      reportError(error);
      const err = await getErrorResponse(error);
      throw err;
    }
  },
};

export default coSellClient;
