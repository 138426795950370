import React from 'react';
import { Spinner, Banner } from '@tackle-io/platform-ui';
import { Box, Typography, makeStyles } from 'vendor/material';
import { useRbac, AREA_PERMISSION_INTEGRATIONS } from 'utils/rbac';
import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';
import { useSalesforceTableStyles } from './ConnectionDetailsTable.style';
import TackleConnectionView from './TackleConnectionView';
import SalesforceConnectionView from './SalesforceConnectionView';
import { ConnectionData } from './ConnectionType';

interface SalesforceConnectionTableProps {
  data?: ConnectionData | null;
  isConnected?: boolean;
  isNewlyConnected?: boolean;
  isSalesforceDataLoading?: boolean;
  disconnected?: boolean;
  connectionStatus?: string;
  openSalesforceOAuthPopUp?: () => void;
  headerType?: 'tackle' | 'salesforce';
  onDisconnect?: (
    closeModal: () => void,
    instanceUrl?: string,
  ) => Promise<boolean>;
}

const useStyles = makeStyles(() => ({
  bannerText: {
    color: 'var(--Neutral-Neutral-100, #091E42)',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    marginTop: '8px',
  },
  bannerContainer: {
    marginBottom: '24px',
    width: '100%',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

const SalesforceConnectionTable: React.FC<SalesforceConnectionTableProps> = ({
  data,
  isConnected = false,
  isNewlyConnected = false,
  isSalesforceDataLoading = false,
  disconnected = false,
  connectionStatus = 'not connected',
  openSalesforceOAuthPopUp,
  onDisconnect,
  headerType = 'salesforce',
}) => {
  const classes = useSalesforceTableStyles();
  const tableStyles = useStyles();
  const { hasPermission } = useRbac();
  const { permissions: canvasPermissions } = useCanvasSession();

  const hasCanvasPermission = canvasPermissions?.includes(
    'integrations:DeleteSalesforceConnection',
  );
  const hasTacklePermission = hasPermission(AREA_PERMISSION_INTEGRATIONS);
  const hasDisconnectPermission = hasCanvasPermission || hasTacklePermission;

  if (isSalesforceDataLoading && !isNewlyConnected) {
    return (
      <Box className={classes.loaderContainer}>
        <Spinner type="ellipsis" />
      </Box>
    );
  }

  if (!data) {
    return (
      <Box className={classes.noDataMessage}>No connection data available.</Box>
    );
  }

  return (
    <>
      {!hasDisconnectPermission && !isNewlyConnected && (
        <Box className={tableStyles.bannerContainer}>
          <Banner
            type="warning"
            title="Permissions required"
            borderPosition="top"
            body={
              <Typography className={tableStyles.bannerText}>
                Due to an issue with permissions, "Disconnect from Tackle" is
                disabled. Ask your Tackle Admin to complete the process in
                Tackle.
              </Typography>
            }
          />
        </Box>
      )}
      <Box className={tableStyles.contentWrapper}>
        {headerType === 'tackle' ? (
          <TackleConnectionView
            data={data}
            isConnected={isConnected}
            onDisconnect={onDisconnect}
            hasDisconnectPermission={hasDisconnectPermission}
          />
        ) : (
          <SalesforceConnectionView
            data={data}
            isConnected={isConnected}
            isNewlyConnected={isNewlyConnected}
            disconnected={disconnected}
            connectionStatus={connectionStatus}
            isSalesforceDataLoading={isSalesforceDataLoading}
            openSalesforceOAuthPopUp={openSalesforceOAuthPopUp}
            onDisconnect={onDisconnect}
            hasDisconnectPermission={hasDisconnectPermission}
          />
        )}
      </Box>
    </>
  );
};

export default SalesforceConnectionTable;
