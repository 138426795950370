import Lottie, { LottieComponentProps } from 'lottie-react';
import animationData from './NoAccess.json';

const TackleAnimatedNoAccess = (
  props: Omit<LottieComponentProps, 'animationData'>,
) => {
  return <Lottie animationData={animationData} loop={false} {...props} />;
};

export default TackleAnimatedNoAccess;
