import { useCallback, useEffect } from 'react';
import useToast from '../../../../../hooks/useToast/useToast';
import { Link } from 'vendor/material';
import { useMessageHandlerStyles } from './MessageHandler.styles';
import { SalesforceCrmConnectorResponse } from '../CanvasTackleIntegrationStatus';

export function useSalesforceMessageHandler(
  handleSalesforceAuthComplete: (payload: any) => void,
  setIsPendingConnection: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const classes = useMessageHandlerStyles();
  const { toaster } = useToast();

  const handleMessage = useCallback(
    async (event: MessageEvent) => {
      if (typeof event.data !== 'string') {
        console.error('Received non-JSON message:', event.data);
        return;
      }

      const message = JSON.parse(event.data);

      if (message.type === 'SALESFORCE_AUTH_ERROR') {
        handleSalesforceAuthComplete({ error: message.payload.error });
        setIsPendingConnection(false);
        return;
      }

      if (message.type === 'SALESFORCE_AUTH_COMPLETE') {
        handleSalesforceAuthComplete(message.payload);
        setIsPendingConnection(false);

        const crmConnectorData: SalesforceCrmConnectorResponse | null =
          message?.payload?.salesforceRegistration ?? null;

        if (!crmConnectorData) {
          console.warn('No CRM connector data provided.');
          return;
        }

        const instanceUrl = `${crmConnectorData.instanceUrl}/lightning/n/tackle__Setup`;

        toaster({
          message: (
            <Link
              href={instanceUrl}
              target="_blank"
              className={classes.toastLink}
            >
              Connection successful!&nbsp;
              <span className={classes.toastLinkUnderline}>
                Refresh this page
              </span>
              &nbsp;to view the details.
            </Link>
          ),
          options: {
            type: 'success',
            className: classes.toastSuccess,
            autoClose: false,
            closeButton: true,
            onClick: () => {
              window.location.href = instanceUrl;
            },
          },
        });
      } else {
        console.log('Unexpected message type:', message.type);
      }
    },
    [handleSalesforceAuthComplete, setIsPendingConnection, classes, toaster],
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);
}
