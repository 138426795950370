import { Formik, useField } from 'formik';

import React, { useEffect, useMemo } from 'react';
import {
  FormControl,
  FormControlLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
  Box,
} from 'vendor/material';
import { Banner, Button, ProviderIcon } from '@tackle-io/platform-ui';
import { fontWeightMediumLarge } from 'utils/fontWeightConstants';
import { makeStyles } from 'vendor/material';
import { Label } from 'packages/cosell/src/components';
import * as Yup from 'yup';
import { useBulkCreateCloudContext } from 'packages/cosell/src/CoSellCanvasAppRouter/BulkCreateCoSellsPage/useBulkCreateCloudContext';
import {
  CloudProviderConfig,
  CloudProviderRequiredMessage,
  NoCrmIDsSelectedWarning,
} from '../consts';
import { useCanUseBulkCreate } from '../useCanUseBulkCreate';

const useStyles = makeStyles((theme) => ({
  selectPartner: {
    color: theme.palette.NEUTRAL600,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  label: {
    fontWeight: fontWeightMediumLarge,
    marginRight: theme.spacing(2),
    flexShrink: 0,
  },
  labelHelpText: {
    fontSize: theme.typography.pxToRem(12),
    position: 'absolute',
    bottom: '-1.5rem',
    left: 0,
    color: `${theme.palette.NEUTRAL200} !important`,
  },
  optionLabelLockup: {
    userSelect: 'none',
    display: 'inline-flex',
    gap: theme.spacing(0.75),
    alignItems: 'center',
  },
  optionLockup: {
    position: 'relative',
    display: 'grid',
  },
  optionLabel: {
    fontSize: theme.typography.pxToRem(14),
  },
  button: {
    fontWeight: fontWeightMediumLarge,
  },
  radioGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    gap: theme.spacing(0.5),
  },
  radioButton: {
    '& span': {
      lineHeight: theme.typography.pxToRem(22),
      color: theme.palette.NEUTRAL800,
      // To style the radio button
      '& div': { color: theme.palette.NEUTRAL100 },
      '& div[class*="checked"]': {
        color: theme.palette.BLUE400,
      },
    },
  },
  warningText: {
    color: theme.palette.RED500,
    fontSize: theme.typography.pxToRem(14),
    border: `1px solid ${theme.palette.RED500}`,
  },
}));

const cloudProviderMap = {
  [CloudProviderConfig.aws.id]: {
    label: CloudProviderConfig.aws.formLabel,
    value: CloudProviderConfig.aws.id,
    iconId: CloudProviderConfig.aws.iconId,
    disabled: false,
    helpText: null,
  },
  [CloudProviderConfig.msft.id]: {
    label: CloudProviderConfig.msft.formLabel,
    value: CloudProviderConfig.msft.id,
    iconId: CloudProviderConfig.msft.iconId,
    disabled: true,
    helpText: 'Coming soon',
  },
  [CloudProviderConfig.gcp.id]: {
    label: CloudProviderConfig.gcp.formLabel,
    value: CloudProviderConfig.gcp.id,
    iconId: CloudProviderConfig.gcp.iconId,
    disabled: false,
    helpText: 'Preview',
  },
};

type CloudProviderOption =
  typeof cloudProviderMap[keyof typeof cloudProviderMap];

interface RadioCheckboxGroupProps {
  disabled?: boolean;
  label: string;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: CloudProviderOption[];
}

const OptionLabel = ({
  helpText,
  label,
  value,
  iconId,
}: RadioCheckboxGroupProps['options'][number]) => {
  const classes = useStyles();
  return (
    <div className={classes.optionLockup}>
      <div className={classes.optionLabelLockup} key={value}>
        <ProviderIcon fontSize="small" provider={iconId} />
        <span className={classes.optionLabel}>{label}</span>
      </div>
      <span className={classes.labelHelpText}>{helpText}</span>
    </div>
  );
};

export const SelectCloudPartner = ({
  disabled = false,
  label,
  name,
  options = [],
}: RadioCheckboxGroupProps) => {
  const classes = useStyles();

  const [
    field,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _meta,
    update,
  ] = useField(name);
  const { handleSetCloudParam } = useBulkCreateCloudContext();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    field.onChange(event);
    handleSetCloudParam(value as typeof options[number]['value']);
  };

  useEffect(() => {
    if (!options.some((x) => x.value === field.value)) {
      update.setValue(options[0].value);
    }
  }, [field, handleSetCloudParam, options, update]);

  return (
    <>
      <Label className={classes.label} label={label} required />
      <FormControl id={field.name} data-id={field.name}>
        <MuiRadioGroup
          name={field.name}
          className={classes.radioGroup}
          onChange={handleChange}
          value={field.value}
          aria-labelledby={field.name}
        >
          {options.map((option) => {
            return (
              <Box key={option.value}>
                <FormControlLabel
                  disabled={option.disabled || disabled}
                  role={'presentation'}
                  data-testid={`${name}_${option.label}`}
                  id={`${name}_${option.label}`}
                  data-id={`${name}_${option.label}`}
                  name={name}
                  label={<OptionLabel {...option} />}
                  value={option.value}
                  className={classes.radioButton}
                  control={
                    <Radio
                      aria-label={option.label}
                      disabled={option.disabled}
                    />
                  }
                />
              </Box>
            );
          })}
        </MuiRadioGroup>
      </FormControl>
    </>
  );
};

const validationSchema = Yup.object().shape({
  cloudProvider: Yup.string().required(CloudProviderRequiredMessage),
});

const StartJobForm = ({
  defaultCloudProvider,
  coSellCount,
  onSubmit,
}: {
  defaultCloudProvider: CloudProviderOption['value'];
  coSellCount: number;
  onSubmit: (formVals: { cloudProvider: CloudProviderOption['value'] }) => void;
}) => {
  const canUse = useCanUseBulkCreate();
  const classes = useStyles();

  const cloudOptions = useMemo(() => {
    const options = new Set<CloudProviderOption>([
      cloudProviderMap[CloudProviderConfig.aws.id],
      cloudProviderMap[CloudProviderConfig.msft.id],
    ]);

    if (canUse.gcp) {
      options.add(cloudProviderMap[CloudProviderConfig.gcp.id]);
    }

    return Array.from(options);
  }, [canUse]);

  const noOpportunitiesSelected = coSellCount < 1;

  return (
    <>
      {noOpportunitiesSelected && (
        <Banner
          type="danger"
          borderPosition="top"
          title={NoCrmIDsSelectedWarning}
        />
      )}

      <Formik
        initialValues={{ cloudProvider: defaultCloudProvider }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <form className={classes.selectPartner} onSubmit={props.handleSubmit}>
            <SelectCloudPartner
              disabled={props.isSubmitting}
              label="Select a cloud partner"
              name="cloudProvider"
              options={cloudOptions}
            />
            <Button
              className={classes.button}
              disabled={props.isSubmitting || noOpportunitiesSelected}
              variant="contained"
              size="small"
              appearance="primary"
              type="submit"
              loading={props.isSubmitting}
            >
              Bulk create {coSellCount || ''} co-sells
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
};

export default StartJobForm;
