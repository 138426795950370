import { reportError } from 'utils/monitor';
import { coSellGcpApi, coSellAwsApi } from 'packages/tackle-api';

type CloudProvider = 'aws' | 'gcp' | 'msft';
type JobStatus = 'pending' | 'running' | 'completed' | 'error';

const REQUEST_TIMEOUT = 25000;

/** returns the api client with baseURL for co-sell v3 cloudProvider */
const getCloudApiClient = (cloudProvider: CloudProvider) => {
  switch (cloudProvider) {
    case 'aws':
      return coSellAwsApi;
    case 'gcp':
      return coSellGcpApi;
    default:
      throw new Error(`Unsupported cloud provider: ${cloudProvider}`);
  }
};

export interface BulkCreateJob<T> {
  id: string;
  crmIDs: string[];
  operationID: string;
  cloudProvider: T;
  successful: number;
  failed: number;
  completed: number;
  total: number;
  status: JobStatus;
  error?: {
    message: string;
    code: string;
    reasons: string[];
  };
  created: string;
  lastModified: string;
}

interface JobResults {
  results: {
    crmId: string;
    tackleId?: string;
    /** if value is not "successful", then it is an error message */
    result: 'successful' | string;
    /** if a title exists, it will be provided */
    title?: string;
  }[];
}

/** start a bulk create co-sell job for a cloud provider */
export const startBulkCreateJob = async ({
  cloudProvider,
  crmIDs,
}: {
  cloudProvider: CloudProvider;
  crmIDs: string[];
}) => {
  try {
    const result = (await getCloudApiClient(cloudProvider)
      .post('jobs/create-opportunities', {
        body: JSON.stringify({ crmIDs }),
        timeout: REQUEST_TIMEOUT,
      })
      .json()) as BulkCreateJob<typeof cloudProvider>;
    return result;
  } catch (error) {
    // handle scenarios where an error message is nested in the response
    if (error.response?.status === 400) {
      const errorResponse = await error.response.json();
      const errorMessage = errorResponse?.message;
      if (typeof errorMessage === 'string') {
        throw new Error(errorMessage);
      }
    }

    reportError(error);
    throw new Error(`An error was encountered: ${error?.message}`, {
      cause: error,
    });
  }
};

/** get the results of a bulk create co-sell job by cloud provider */
export const getBulkCreateJobResults = async ({
  cloudProvider,
  jobId,
}: {
  cloudProvider: CloudProvider;
  jobId: string;
}) => {
  try {
    const result = (await getCloudApiClient(cloudProvider)
      .get(`jobs/create-opportunities/${jobId}/results`, {
        timeout: REQUEST_TIMEOUT,
      })
      .json()) as JobResults;
    return result;
  } catch (error) {
    reportError(error);
    throw new Error(`An error was encountered: ${error?.message}`, {
      cause: error,
    });
  }
};

/** get details of a bulk create co-sell job by cloud provider */
export const getBulkCreateJob = async ({
  cloudProvider,
  jobId,
}: {
  cloudProvider: CloudProvider;
  jobId: string;
}) => {
  try {
    const result = (await getCloudApiClient(cloudProvider)
      .get(`jobs/create-opportunities/${jobId}`, {
        timeout: REQUEST_TIMEOUT,
      })
      .json()) as BulkCreateJob<typeof cloudProvider>;
    return result;
  } catch (error) {
    reportError(error);
    throw new Error(`An error was encountered: ${error?.message}`, {
      cause: error,
    });
  }
};

interface BulkCreateJobsListResponse<T> {
  jobs: BulkCreateJob<T>[];
  next: 'string';
}

/** get a list of bulk create co-sell jobs by cloud provider */
export const getBulkCreateJobsList = async ({
  cloudProvider,
}: {
  cloudProvider: CloudProvider;
}) => {
  try {
    const result = (await getCloudApiClient(cloudProvider)
      .get('jobs/create-opportunities', {
        timeout: REQUEST_TIMEOUT,
      })
      .json()) as BulkCreateJobsListResponse<typeof cloudProvider>;
    return result;
  } catch (error) {
    reportError(error);
    throw new Error(`An error was encountered: ${error?.message}`, {
      cause: error,
    });
  }
};
