import { User } from '../types/User';
import { UserJSON } from '../types/UserJSON';
import { Optional } from 'utils/optional/optional';

export const userJSONToUser = (user: UserJSON | null, vendorId: string): User =>
  Optional.ofNullable(user)
    .map((u) => ({
      id: u.id,
      email: u.email,
      vendorId,
    }))
    .orElse(null);
