import { Banner, Link } from '@tackle-io/platform-ui';
import { AssignmentIcon } from 'packages/cosell/assets';
import FormSectionCard from '../components/FormSectionCard';
import { FormSectionConfig } from '../constants';
import useToggleState from 'hooks/useToggleState';
import { AddSolutionsFormDrawer } from './AddSolutionsForm';

export const SolutionsFormSection = () => {
  const [drawerIsOpen, toggleDrawer] = useToggleState(false);
  return (
    <>
      <FormSectionCard
        title={FormSectionConfig.solutions.title}
        subtitle={null}
        icon={<AssignmentIcon />}
        data-id={FormSectionConfig.solutions.dataId}
      >
        <Banner
          isCollapsible
          title="At least one solution is required to create a referral."
          type="info"
          body={
            <>
              There are different types of solutions that can be added to a deal
              including solutions that are published by your company,
              Microsoft's solutions, or other third-party solutions or solution
              assessments.{' '}
              <b>
                Microsoft limits referrals to one IP-cosell eligible solution
                per referral.
              </b>{' '}
              <Link onClick={() => alert('handle learn more link')}>
                Learn more
              </Link>
            </>
          }
        />

        <FormSectionCard.BlockMessage>
          You haven't added any solutions yet.
        </FormSectionCard.BlockMessage>
        <FormSectionCard.AddButton onClick={toggleDrawer}>
          Add solution
        </FormSectionCard.AddButton>
      </FormSectionCard>

      <AddSolutionsFormDrawer open={drawerIsOpen} onClose={toggleDrawer} />
    </>
  );
};

export default SolutionsFormSection;
