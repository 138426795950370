import React, { createContext, useState } from 'react';
import { Month } from '../utils/constants';

export const FiscalYearContext = createContext({
  fiscalYearStartMonth: Month.January,
  setFiscalYearStartMonth: (value: string) => {},
  userHasSetFiscalYear: false,
  setUserHasSetFiscalYear: (value: boolean) => {},
});

export const FiscalYearProvider = ({ children }) => {
  const storedMonth = localStorage.getItem('fiscalYearStartMonth');

  const [fiscalYearStartMonth, setFiscalYearStartMonth] = useState(() => {
    return storedMonth && Object.values(Month).includes(storedMonth as Month)
      ? (storedMonth as Month)
      : Month.January;
  });

  const [userHasSetFiscalYear, setUserHasSetFiscalYear] = useState(
    !!storedMonth,
  );

  const setMonth = (value: Month) => {
    localStorage.setItem('fiscalYearStartMonth', value);
    setFiscalYearStartMonth(value);
    setUserHasSetFiscalYear(true);
  };

  return (
    <FiscalYearContext.Provider
      value={{
        fiscalYearStartMonth,
        setFiscalYearStartMonth: setMonth,
        userHasSetFiscalYear,
        setUserHasSetFiscalYear,
      }}
    >
      {children}
    </FiscalYearContext.Provider>
  );
};
