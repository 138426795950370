import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TimeRangePicker } from '../TimeRangePicker/TimeRangePicker';
import { useDashboardFilters } from 'shared/hooks/useDashboardFilters';
import { ALL_LABEL, formatOptionDate } from './DateFilter.helpers';
import type { TimeRangeOption, DateFilterValues } from './types';
import { FiscalYearContext } from 'pages/Overview/contexts/FiscalYearContext';
import { TIME_RANGE_FILTER_OPTIONS } from './DateFilter.helpers';
import { TimeRange } from './DateFilter.constants';
import { Popover } from 'vendor/material';
import { Calendar } from '@tackle-io/platform-ui';
import { DateTime } from 'luxon';
import useStyles from './CustomDatePicker.styles';
import {
  TimeSeriesGranularity,
  UserBillingDetailsQuery,
} from 'generated/graphql';
import { useHistory, useLocation } from 'react-router';
import { ampli } from 'utils/analytics/ampli';
import { DATE_TYPE_KEY } from 'packages/cosell/src/pages/CoSellLandingPage/helpers/urlParamsHelper';

type DateFilterProps = {
  filterOptions?: TimeRangeOption[];
  billingData?: UserBillingDetailsQuery | undefined;
  onChangeDate?: Function;
  dataId?: string;
};

function DateFilter({
  filterOptions = TIME_RANGE_FILTER_OPTIONS,
  billingData = undefined,
  onChangeDate = () => {},
  dataId,
}: DateFilterProps): JSX.Element {
  const defaultMenuOption = filterOptions[0];
  const { fiscalYearStartMonth } = useContext(FiscalYearContext);
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const dateType = searchParams.get(DATE_TYPE_KEY);
  const isAllTime = !dateType || dateType === TimeRange.ALL;
  // For now, this component is used on the dashboard and payments page
  // and at this time we are only tracking events on the payments page
  const isOnPaymentsPage = useMemo(() => {
    return location.pathname.startsWith('/payments');
  }, [location.pathname]);

  // For now, we do not want to persist startDate, endDate, dateType in the URL for co-sell
  const isOnCoSellV3Page = useMemo(() => {
    return location.pathname.startsWith('/co-sell');
  }, [location.pathname]);

  // hooks injected for custom date range.
  const [anchorElPicker, setAnchorElPicker] = useState(null);
  const [rangeContainerOpen, setRangeContainerOpen] = useState<boolean>(false);
  const [datesSelected, setDatesSelected] = useState({
    from: undefined,
    to: undefined,
  });
  const [menuCloseCallback, setMenuCloseCallback] = useState<Function>(
    () => {},
  );

  const { getFilterValue, onSetFilterValue } =
    useDashboardFilters<DateFilterValues>({
      startDate: defaultMenuOption.start?.toISODate(),
      endDate: defaultMenuOption.end?.toISODate(),
      dateType: defaultMenuOption?.key,
    });

  const [selectedDateString, setSelectedDateString] = React.useState<string>(
    () =>
      isOnCoSellV3Page && isAllTime
        ? ALL_LABEL
        : formatOptionDate(
            getFilterValue('dateType')[0],
            fiscalYearStartMonth,
            billingData,
          ),
  );

  const menuOptions = filterOptions.map((option) => {
    return {
      ...option,
      isSelected:
        isOnCoSellV3Page && selectedDateString === ALL_LABEL
          ? option.key === TimeRange.ALL
          : option.key === getFilterValue('dateType')[0] || false,
    };
  });

  const handleTimeRangeChange = useCallback(
    (value: TimeRangeOption, closeCallback: Function): void => {
      setMenuCloseCallback(() => closeCallback);
      setRangeContainerOpen(false);
      if (value.key === TimeRange.CUSTOM) {
        // we know we have a custom date, but now need to set it
        // and make the picker visible.
        setDatesSelected({ from: undefined, to: undefined });
        setRangeContainerOpen(true);

        // Track the change in Amplitude
        if (isOnPaymentsPage) {
          ampli.paymentsListRefined({
            date_range: TimeRange.CUSTOM,
          });
        }

        return;
      }
      if (value.start && value.end) {
        const newParams = new URLSearchParams(history.location.search);
        newParams.delete('page');
        newParams.delete('beforeCursor');
        newParams.delete('afterCursor');

        const correctedPath = `${location.pathname}?${newParams.toString()}`;
        history.push(correctedPath);
        onSetFilterValue('startDate', value.start?.toISODate());
        onSetFilterValue('endDate', value.end?.toISODate());
        onSetFilterValue('dateType', value.key);
        setSelectedDateString(
          formatOptionDate(value.key, fiscalYearStartMonth, billingData),
        );
        onChangeDate();
        closeCallback();

        if (isOnPaymentsPage) {
          ampli.paymentsListRefined({
            date_range: value.key,
          });
        }
      } else if (value.text === ALL_LABEL) {
        const newParams = new URLSearchParams(history.location.search);
        newParams.delete('page');
        newParams.delete('beforeCursor');
        newParams.delete('startDate');
        newParams.delete('endDate');
        newParams.delete('dateType');

        const correctedPath = `${location.pathname}?${newParams.toString()}`;
        history.push(correctedPath);
        onSetFilterValue('startDate', '');
        onSetFilterValue('endDate', '');
        onSetFilterValue('dateType', value.key);
        setSelectedDateString(ALL_LABEL);
        onChangeDate();
      }
    },
    [
      history,
      location.pathname,
      onSetFilterValue,
      fiscalYearStartMonth,
      billingData,
      onChangeDate,
      isOnPaymentsPage,
    ],
  );

  useEffect(() => {
    const currentDateTypeKey = getFilterValue('dateType')
      ? getFilterValue('dateType')[0]
      : defaultMenuOption.key;

    if (isOnCoSellV3Page) {
      // Prevent setting startDate,endDate, dateType from local storage
      return;
    } else if (
      !filterOptions.some((option) => {
        return option.key === currentDateTypeKey;
      })
    ) {
      // if the current page does not support the active filter
      // fall back to the first, default option.
      setSelectedDateString(
        formatOptionDate(
          defaultMenuOption?.key,
          fiscalYearStartMonth,
          billingData,
        ),
      );
      handleTimeRangeChange(defaultMenuOption, () => {});
      return;
    }
    setSelectedDateString(
      formatOptionDate(currentDateTypeKey, fiscalYearStartMonth, billingData),
    );
  }, [
    billingData,
    defaultMenuOption,
    filterOptions,
    fiscalYearStartMonth,
    getFilterValue,
    handleTimeRangeChange,
    isOnCoSellV3Page,
  ]);

  const handleCustomDateSelection = (value) => {
    // because selectRange
    // is set to true, we always get two dates

    const startDate = {
      year: value[0].getFullYear(),
      month: value[0].getMonth() + 1,
      day: value[0].getDate(),
    };
    const endDate = {
      year: value[1].getFullYear(),
      month: value[1].getMonth() + 1,
      day: value[1].getDate(),
    };
    setDatesSelected({
      from: DateTime.fromObject(startDate),

      to: DateTime.fromObject(endDate),
    });
    setRangeContainerOpen(false);
    const timeRange: TimeRangeOption = {
      key: TimeRange.CUSTOM,
      text: 'Custom',
      start: DateTime.fromObject(startDate),
      end: DateTime.fromObject(endDate),
      granularity: TimeSeriesGranularity.Day,
      timeRangeInput: {
        start: DateTime.fromObject(startDate),
        stop: DateTime.fromObject(endDate),
      },
    };

    onSetFilterValue('startDate', timeRange.start?.toISODate());
    onSetFilterValue('endDate', timeRange.end?.toISODate());
    onSetFilterValue('dateType', timeRange?.key);
    setSelectedDateString(
      formatOptionDate(timeRange.key, fiscalYearStartMonth, billingData),
    );
    onChangeDate();
    menuCloseCallback();
  };

  const handleCloseCustomDate = (event) => {
    setAnchorElPicker(null);
    setRangeContainerOpen(false);
  };

  // for now this is ripped straight from /src/components/DateListPicker.
  // we will probably want to tweak this at some point.
  type DateObjectRange = {
    from: DateTime;
    to: DateTime;
  };

  interface DatePopoverProps {
    id: string;
    open: boolean;
    onChangeDate: Function;
    datesSelected: DateObjectRange;
    anchorEl: HTMLElement;
    onClose: Function;
    pickerView: string;
    maxDate?: Date;
  }

  const DatePopover: React.FC<DatePopoverProps> = ({
    id,
    open,
    onChangeDate,
    datesSelected,
    anchorEl,
    onClose,
    pickerView,
  }) => {
    const handleClose = (): void => {
      onClose();
    };

    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.calendarContent}>
          <Calendar
            selectRange
            onChange={onChangeDate}
            maxDetail={pickerView}
            value={[datesSelected.from, datesSelected.to]}
          />
        </div>
      </Popover>
    );
  };

  return (
    <div onClick={(event) => setAnchorElPicker(event.currentTarget)}>
      <TimeRangePicker
        options={menuOptions}
        onSelectTimeFrame={handleTimeRangeChange}
        selectedValue={selectedDateString}
        dataId={dataId}
      />
      <DatePopover
        id={'picker-date'}
        open={rangeContainerOpen}
        onChangeDate={handleCustomDateSelection}
        datesSelected={datesSelected}
        anchorEl={anchorElPicker}
        onClose={handleCloseCustomDate}
        pickerView={'month'}
      />
    </div>
  );
}

export { DateFilter };
