import { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Collapse,
  makeStyles,
  Typography,
} from 'vendor/material';
import { fontWeightBold } from 'utils/fontWeightConstants';
import { WarningIconYellow } from 'packages/cosell/assets';

const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    width: '100%',
  },
  alertContainer: {
    color: '#091E42',
    backgroundColor: '#FFFAE6',
  },
  bannerTitle: {
    fontWeight: fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    marginBottom: theme.spacing(1),
  },
  bannerMessage: {
    fontSize: theme.typography.pxToRem(12),
  },
}));

const CoSellLimitedPermissionBanner: React.FC = () => {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  return (
    <Box
      className={classes.bannerContainer}
      data-testid="co-sell-limited-permission-banner"
    >
      <Collapse in={open}>
        <Alert
          className={classes.alertContainer}
          icon={<WarningIconYellow />}
          severity="warning"
          action={
            <Button
              style={{ color: '#0052CC' }}
              size="small"
              onClick={() => setOpen(false)}
            >
              Dismiss
            </Button>
          }
        >
          <Typography className={classes.bannerTitle}>
            Limited permissions
          </Typography>
          <Typography className={classes.bannerMessage}>
            Your Co-Sell permissions are limited. Contact your Salesforce admin
            to request changes.
          </Typography>
        </Alert>
      </Collapse>
    </Box>
  );
};

export default CoSellLimitedPermissionBanner;
