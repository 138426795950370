import { useCallback } from 'react';
import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';
import type { OffersCanvasAppContentPayload } from 'packages/salesforce-canvas/src/lib/salesforceCanvasSession';

const domain = 'offers';

const useOffersCanvasActions = () => {
  const { canvasClient, namespace, record } = useCanvasSession();

  const navigate = useCallback(
    (navAction: OffersCanvasAppContentPayload | { action: 'back' }) => {
      const navigateTo =
        navAction.action !== 'back'
          ? 'CanvasApp'
          : record.objectName === 'Opportunity'
          ? 'TackleWidget'
          : 'ListView';
      Sfdc.canvas.client.publish(canvasClient, {
        name: `${namespace}.${domain}`,
        payload: {
          navigate: navigateTo,
          objectName: record.objectName,
          domain: 'offers',
          record: record,
          ...navAction,
        },
      });
    },
    [canvasClient, namespace, record],
  );

  return {
    navigate,
  };
};

export default useOffersCanvasActions;
