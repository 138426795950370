import {
  ContextValueSetters,
  NewOfferOfferSubmissionErrorsKey,
  OfferApiFunctions,
  SchemaValidationError,
} from 'pages/PrivateOffers/pages/Next/generic/ApiContext/apiContext';
import { MsftPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/MsftPrivateOffer';
import { useCallback } from 'react';
import useOfferApi from 'pages/PrivateOffers/pages/Next/generic/hooks/useOfferApi';
import { msftPrivateOfferJSONToMsftPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/transformers/msftPrivateOfferJSONToMsftPrivateOffer';
import { OfferAPIKey } from '../ApiContext/offerAPIKey';
import { offersMsftApi } from '../api/offerAPIs';
import { msftPrivateOfferToMsftPrivateOfferJSON } from 'pages/PrivateOffers/pages/Next/generic/api/transformers/msftPrivateOfferToMsftPrivateOfferJSON';
import { PlanDetails } from 'pages/PrivateOffers/pages/Next/generic/api/types/PlanDetails';

type UseMsftPrivateOfferApi = OfferApiFunctions<MsftPrivateOffer> & {
  createOffer: (
    offer: Partial<MsftPrivateOffer>,
    createInMarketplace: boolean,
  ) => Promise<MsftPrivateOffer | null>;
  getPlans: (vendorId: string, productId: string) => Promise<any | null>;
  getPlan: (
    vendorId: string,
    productId: string,
    planId: string,
  ) => Promise<PlanDetails | null>;
};

const convertToMsftPrivateOffer = async (response: any) =>
  msftPrivateOfferJSONToMsftPrivateOffer(response);

const useMsftPrivateOfferApi = (
  accessTokenProvider: () => Promise<string>,
  contextValueSetters: ContextValueSetters,
): UseMsftPrivateOfferApi => {
  const { setOfferSubmissionErrors } = contextValueSetters;

  const { stateTrackingGet, stateTrackingSubmit } =
    useOfferApi(contextValueSetters);

  const handleOfferSubmissionError = useCallback(
    (poId: string) =>
      async (response): Promise<null> => {
        const body = await response.response.text();
        const offerSubmissionError = JSON.parse(body) as SchemaValidationError;

        setOfferSubmissionErrors((previousOfferSubmissionErrors) => ({
          ...previousOfferSubmissionErrors,
          [poId]: offerSubmissionError,
        }));

        return null;
      },
    [setOfferSubmissionErrors],
  );

  const getOffer = useCallback(
    async (poId: string): Promise<MsftPrivateOffer | null> => {
      return stateTrackingGet(
        OfferAPIKey.Offer,
        () => offersMsftApi(accessTokenProvider).get(`api/offer/${poId}`),
        convertToMsftPrivateOffer,
      );
    },
    [stateTrackingGet, accessTokenProvider],
  );

  const getOfferSilently = useCallback(
    async (poId: string) => {
      return stateTrackingGet(
        OfferAPIKey.Silent,
        () => offersMsftApi(accessTokenProvider).get(`api/offer/${poId}`),
        convertToMsftPrivateOffer,
      );
    },
    [stateTrackingGet, accessTokenProvider],
  );

  const createOffer = useCallback(
    async (
      offer: Partial<MsftPrivateOffer>,
      createInMarketplace: boolean,
    ): Promise<MsftPrivateOffer | null> => {
      const json = msftPrivateOfferToMsftPrivateOfferJSON(offer);

      return stateTrackingSubmit(
        OfferAPIKey.Offer,
        () =>
          offersMsftApi(accessTokenProvider).post(`api/offer/saas`, { json }),
        convertToMsftPrivateOffer,
        handleOfferSubmissionError(NewOfferOfferSubmissionErrorsKey),
      );
    },
    [stateTrackingSubmit, accessTokenProvider, handleOfferSubmissionError],
  );

  const updateOffer = useCallback(
    async (
      poId: string,
      updatedOffer: Partial<MsftPrivateOffer>,
      createInMarketplace: boolean,
    ) => {
      return null;
    },
    [],
  );

  const cancelOffer = useCallback(async (poId: string) => {
    return;
  }, []);

  const sendBuyerInstructions = useCallback(async (poId: string) => {
    return;
  }, []);

  const archiveOffer = useCallback(async (poId: string) => {
    return;
  }, []);

  const getPlans = useCallback(
    async (vendorId: string, productId: string) => {
      return stateTrackingGet(
        OfferAPIKey.Silent,
        () =>
          offersMsftApi(accessTokenProvider).get(
            `api/vendor/${vendorId}/getPlans/product/${productId}`,
          ),
        (v) => v,
      );
    },
    [stateTrackingGet, accessTokenProvider],
  );

  const getPlan = useCallback(
    async (
      vendorId: string,
      productId: string,
      planId: string,
    ): Promise<PlanDetails | null> => {
      return stateTrackingGet(OfferAPIKey.Silent, () =>
        offersMsftApi(accessTokenProvider).get(
          `api/vendor/${vendorId}/getPlan/product/${productId}/plan/${planId}`,
        ),
      );
    },
    [stateTrackingGet, accessTokenProvider],
  );

  return {
    getOffer,
    getOfferSilently,
    createOffer,
    updateOffer,
    cancelOffer,
    sendBuyerInstructions,
    archiveOffer,
    getPlans,
    getPlan,
  };
};

export default useMsftPrivateOfferApi;
