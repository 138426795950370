import { Banner, Button } from '@tackle-io/platform-ui';
import { Drawer, makeStyles } from 'vendor/material';
import {
  fontWeightBold,
  fontWeightMediumLarge,
} from 'utils/fontWeightConstants';
import { ChevronRight } from 'mdi-material-ui';
import { useState } from 'react';
import LinkCrmIdForm from './LinkCrmIdForm';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import { TackleOperationId } from 'packages/cosell/api/utils';
import { useToast } from 'hooks';
import { TOASTS } from 'packages/cosell/src/components';

export const CONTENT = {
  banner: {
    id: 'unlinked-crm-id-warning',
    title: 'Salesforce warning: co-sell is not linked to an opportunity.',
    message:
      'If you are connected to Salesforce, Tackle recommends adding a CRM ID to keep this co-sell in sync with AWS.',
    buttonLabel: 'Add CRM ID',
  },
};

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gap: theme.spacing(1),
    fontWeight: fontWeightMediumLarge,
    lineHeight: theme.typography.pxToRem(20),
    textWrap: 'pretty',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(12),
  },
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0',
    paddingInline: theme.spacing(1),
    fontWeight: fontWeightBold,
    fontSize: 'inherit',
    margin: 0,
    marginLeft: theme.spacing(0.25),
    color: theme.palette.BLUE400 + ' !important',
    minWidth: 'auto',
    lineHeight: theme.typography.pxToRem(20),
    '& span > span': {
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
  addSpan: {
    display: 'inline-flex',
    alignItems: 'center !important',
  },
}));

const UnlinkedCrmIdWarning = ({
  disabled,
  opportunityId,
}: {
  disabled: boolean;
  opportunityId: string;
}) => {
  const classes = useStyles();
  const [showDrawer, setShowDrawer] = useState(false);
  const { updateAceOpportunity } = useAceOpportunity({
    opportunityId,
  });
  const { toaster } = useToast();

  const handleSubmit = (formValues: { crmId: string }) => {
    return updateAceOpportunity.mutateAsync(
      {
        requestBody: formValues,
        tackleOperationId: TackleOperationId.UPDATE_OPPORTUNITY_CRMID,
      },
      {
        onError: () => {
          toaster(TOASTS.errorToast('Salesforce CRM ID submission failed'));
        },
        onSuccess: () => {
          toaster(TOASTS.crmIdSubmitted);
          setShowDrawer(false);
        },
      },
    );
  };

  return (
    <>
      <Banner
        data-id={CONTENT.banner.id}
        data-testid={CONTENT.banner.id}
        defaultOpen
        isCollapsible
        type="warning"
        title={CONTENT.banner.title}
        body={
          <div className={classes.wrapper}>
            {CONTENT.banner.message}
            {!disabled && (
              <Button
                className={classes.button}
                onClick={() => setShowDrawer(true)}
                type="button"
                variant="text"
              >
                {CONTENT.banner.buttonLabel}{' '}
                <ChevronRight style={{ fontSize: '1rem' }} />
              </Button>
            )}
          </div>
        }
      />

      <Drawer
        anchor="right"
        variant="temporary"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        children={
          <LinkCrmIdForm
            onClose={() => setShowDrawer(false)}
            onSubmit={handleSubmit}
          />
        }
      />
    </>
  );
};

export default UnlinkedCrmIdWarning;
