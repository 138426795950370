import { Box } from 'vendor/material';
import { Button, Tooltip } from '@tackle-io/platform-ui';
import useStyles from './CoSellHeaderFilters.styles';
import { Circle, Filter } from 'mdi-material-ui';
import { useState } from 'react';
import { coSellLandingPageDataId } from 'packages/cosell/src/utilities/intercomEnums';
import { FilterPanel } from 'packages/cosell/src/components/FilterPanel/FilterPanel';
import useOpportunitiesListQuery from 'packages/cosell/src/hooks/useOpportunitiesListQuery';
import {
  invitationsFilterSet,
  opportunitiesFilterSet,
} from 'packages/cosell/src/utilities/filters/constants';
import useInvitationsListQuery from 'packages/cosell/src/hooks/useInvitationsListQuery';
import { FROM_KEY, PAGE_KEY, TAB_KEY } from '../../helpers/urlParamsHelper';
import { useHistory, useLocation } from 'react-router-dom';
import { DateFilter } from 'shared/components/DateFilter/DateFilter';
import { COSELL_TIME_RANGE_FILTER_OPTIONS } from 'shared/components/DateFilter/DateFilter.helpers';

export const CoSellHeaderFilters = () => {
  const classes = useStyles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const history = useHistory();
  const activeTab = searchParams.get(TAB_KEY);
  const isInvitations = activeTab === 'invitations';

  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState<boolean>(false);
  const { activePanelFilters: opportunitiesActivePanelFilters } =
    useOpportunitiesListQuery();
  const { activePanelFilters: invitationsActivePanelFilters } =
    useInvitationsListQuery();

  const handleOpenFilterPanel = () => {
    setIsFilterPanelOpen(true);
  };

  const handleFilterPanelClose = () => {
    setIsFilterPanelOpen(false);
  };

  const forceCleanURL = () => {
    const newParams = new URLSearchParams(history.location.search);
    newParams.delete(PAGE_KEY);
    newParams.delete(FROM_KEY);
    const correctedPath = `${location.pathname}?${newParams.toString()}`;
    history.push(correctedPath);
  };

  const handleDateChange = () => {
    forceCleanURL();
  };
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'flex-start'}
      alignContent={'center'}
    >
      <FilterPanel
        isOpen={isFilterPanelOpen}
        filterSet={
          isInvitations ? invitationsFilterSet : opportunitiesFilterSet
        }
        onClose={handleFilterPanelClose}
      />
      <Tooltip content={<p>Filter</p>} position="bottom">
        <span className={classes.filterButton} onClick={handleOpenFilterPanel}>
          <Button
            variant="outlined"
            appearance="white"
            data-id={coSellLandingPageDataId.FILTER_PANEL_BUTTON}
            startIcon={<Filter fontSize="inherit" />}
          />
          {!isInvitations && opportunitiesActivePanelFilters.length ? (
            <Circle className={classes.activeFilterIndicator} />
          ) : null}
          {isInvitations && invitationsActivePanelFilters.length ? (
            <Circle className={classes.activeFilterIndicator} />
          ) : null}
        </span>
      </Tooltip>
      <div className={classes.dateFilterWrapper}>
        <DateFilter
          filterOptions={COSELL_TIME_RANGE_FILTER_OPTIONS}
          onChangeDate={handleDateChange}
          dataId={coSellLandingPageDataId.DATE_TIME_RANGE_FILTER}
        />
      </div>
    </Box>
  );
};
