import React, { useState } from 'react';
import useStyles from './RightRailButtonSection.style';
import { Box } from 'vendor/material';
import {
  AceOpportunityReviewStatusEnum,
  AceOpportunityStageEnum,
} from '../../types/enums';
import Button from '../Button/Button';
import { useHistory } from 'react-router-dom';
import RightRailEditModal from './RightRailEditModal';
import { TackleOperationId } from 'packages/cosell/api/utils';
import { useIsTestEnvironment } from '../../../../../hooks';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import { coSellDetailsPageDataId } from '../../utilities/intercomEnums';
import { useCoSellContext } from 'packages/cosell/src/CoSellContextProvider';
import { getAwsSubmission } from 'packages/cosell/src/utilities/typeConverters/getAwsSubmission';
import { AceOpportunityResponse } from '../../types/responses/AceOpportunityResponse';
import invariant from 'tiny-invariant';
import { PollingStatusButton } from './PollingStatusButton';
import { AceOpportunityEventOperation } from '../../types/responses/AceOpportunityEventResponse';
import { useHasCoSellRbacRights } from '../../hooks/useHasCoSellRbacRights/useHasCoSellRbacRights';

interface RightRailButtonSectionProps {
  status?: AceOpportunityReviewStatusEnum;
  stage?: AceOpportunityStageEnum;
  pendingOperation?: AceOpportunityEventOperation;
}

export enum EditModalType {
  EDIT_LAUNCHED = 'Edit launched',
  LAUNCH = 'Launch',
  CLOSE_LOST = 'Close lost',
}

const RightRailButtonSection: React.FC<RightRailButtonSectionProps> = ({
  status,
  stage,
  pendingOperation,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const rbacRights = useHasCoSellRbacRights();
  const { opportunityId } = useCoSellContext();
  const isTestEnvironment = useIsTestEnvironment();
  const {
    aceOpportunityQuery,
    updateAceOpportunity,
    testEnvironmentAcceptOrRejectOpportunity,
  } = useAceOpportunity({
    opportunityId,
  });
  const isDraft = status === AceOpportunityReviewStatusEnum.PENDING_SUBMISSION;
  const hasPendingRequest = !!pendingOperation;
  const opportunity = aceOpportunityQuery?.data;
  const hasRequestErrors = opportunity?.metadata?.hasCloudErrors;

  const variantByStatus =
    status === AceOpportunityReviewStatusEnum.ACTION_REQUIRED
      ? 'contained'
      : 'outlined';
  const [editModalOpen, setEditModalOpen] = useState<EditModalType | null>(
    null,
  );
  const showTestEnvAcceptRejectButtons =
    isTestEnvironment && status === AceOpportunityReviewStatusEnum.SUBMITTED;

  const handleUpdateAcceptRejectOpportunity = async (
    operationID: TackleOperationId,
  ) => {
    /** TODO: update component so we don't have to check for opportunityId here */
    invariant(opportunityId, 'Opportunity ID must be defined to accept/reject');

    testEnvironmentAcceptOrRejectOpportunity.mutate({
      opportunityId,
      tackleOperationId: operationID,
    });
  };

  // after the opportunity is approved,
  // show the launch and close lost buttons in the early stages
  const isEarlyStage = [
    AceOpportunityStageEnum.PROSPECT,
    AceOpportunityStageEnum.QUALIFIED,
    AceOpportunityStageEnum.TECHNICAL_VALIDATION,
    AceOpportunityStageEnum.BUSINESS_VALIDATION,
    AceOpportunityStageEnum.COMMITTED,
  ].includes(stage as AceOpportunityStageEnum);

  const handleRedirectToEditPage = () => {
    const currentUrl = history.location.pathname;
    const redirectUrl = `${currentUrl}/edit`;
    history.push(redirectUrl);
  };

  const updateOpportunityFromDraftToSubmitted = async () => {
    // TODO: update this with a modal
    const awsSubmission = getAwsSubmission(
      /** The component is only rendered if this is a cloud created opportunity */
      (aceOpportunityQuery.data as AceOpportunityResponse)
        .primaryNeedsFromAws ?? null,
    );
    updateAceOpportunity.mutateAsync({
      requestBody: { awsSubmission },
      tackleOperationId: TackleOperationId.START_ENGAGEMENT,
    });
  };

  const userHasRightToEdit = isDraft
    ? rbacRights?.updateDraftOpportunity || rbacRights?.updateOpportunity
    : rbacRights?.updateOpportunity;

  const showEditButton =
    userHasRightToEdit && !showTestEnvAcceptRejectButtons && !hasPendingRequest;

  const showSubmitToCloudButton =
    !showTestEnvAcceptRejectButtons &&
    !hasPendingRequest &&
    isDraft &&
    !hasRequestErrors &&
    rbacRights?.startEngagement;

  const showLaunchCloseLostButtons =
    status === AceOpportunityReviewStatusEnum.APPROVED &&
    isEarlyStage &&
    !hasPendingRequest &&
    rbacRights?.updateOpportunity;
  return (
    <Box className={classes.root}>
      {hasPendingRequest && (
        <PollingStatusButton pendingOperation={pendingOperation} />
      )}
      {showEditButton && (
        <Button
          variant={variantByStatus}
          data-id={coSellDetailsPageDataId.EDIT_OPPORTUNITY}
          data-testid={coSellDetailsPageDataId.EDIT_OPPORTUNITY}
          appearance="primary"
          onClick={() => {
            if (stage === AceOpportunityStageEnum.LAUNCHED) {
              setEditModalOpen(EditModalType.EDIT_LAUNCHED);
            } else {
              handleRedirectToEditPage();
            }
          }}
        >
          Edit co-sell opportunity
        </Button>
      )}
      {showSubmitToCloudButton && (
        <Button
          variant="contained"
          appearance="primary"
          data-id={coSellDetailsPageDataId.SUBMIT_TO_CLOUD}
          data-testid={coSellDetailsPageDataId.SUBMIT_TO_CLOUD}
          onClick={updateOpportunityFromDraftToSubmitted}
          loading={updateAceOpportunity.isLoading}
        >
          Submit to cloud
        </Button>
      )}
      {showLaunchCloseLostButtons && (
        <Box className={classes.actionButton}>
          <Button
            variant="contained"
            data-id={coSellDetailsPageDataId.LAUNCH_OPPORTUNITY}
            data-testid={coSellDetailsPageDataId.LAUNCH_OPPORTUNITY}
            appearance="primary"
            onClick={() => {
              setEditModalOpen(EditModalType.LAUNCH);
            }}
          >
            Launch
          </Button>
          <Button
            variant="contained"
            data-id={coSellDetailsPageDataId.CLOSE_LOST}
            data-testid={coSellDetailsPageDataId.CLOSE_LOST}
            appearance="destructive"
            onClick={() => {
              setEditModalOpen(EditModalType.CLOSE_LOST);
            }}
          >
            Close lost
          </Button>
        </Box>
      )}
      {showTestEnvAcceptRejectButtons &&
        !hasPendingRequest &&
        status === AceOpportunityReviewStatusEnum.SUBMITTED && (
          <Box className={classes.actionButton}>
            <Button
              variant="contained"
              data-id={coSellDetailsPageDataId.ACCEPT_OPPORTUNITY}
              appearance="primary"
              loading={testEnvironmentAcceptOrRejectOpportunity.isLoading}
              onClick={() =>
                handleUpdateAcceptRejectOpportunity(
                  TackleOperationId.ACCEPT_OPPORTUNITY,
                )
              }
            >
              Accept
            </Button>
            <Button
              variant="contained"
              data-id={coSellDetailsPageDataId.REJECT_OPPORTUNITY}
              appearance="destructive"
              loading={testEnvironmentAcceptOrRejectOpportunity.isLoading}
              onClick={() =>
                handleUpdateAcceptRejectOpportunity(
                  TackleOperationId.REJECT_OPPORTUNITY,
                )
              }
            >
              Reject
            </Button>
          </Box>
        )}
      {editModalOpen && (
        <RightRailEditModal
          editModalType={editModalOpen}
          setEditModalOpen={setEditModalOpen}
        />
      )}
    </Box>
  );
};

export default RightRailButtonSection;
