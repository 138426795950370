import { Button } from '@tackle-io/platform-ui';
import { ComponentProps, FC } from 'react';
import { Drawer } from 'vendor/material';
import FormDrawer from '../components/FormDrawer';

export const AddPartnerForm = ({ onClose }: { onClose: () => void }) => {
  const footerActions = (
    <>
      <Button appearance="secondary" variant="outlined" onClick={onClose}>
        Cancel
      </Button>
      <Button
        appearance="primary"
        variant="contained"
        onClick={() => alert('Add Partner')}
      >
        Add Partner
      </Button>
    </>
  );
  return (
    <FormDrawer
      footerActions={footerActions}
      title="Add Partner"
      onClose={onClose}
    >
      content
    </FormDrawer>
  );
};

/** AddPartnerForm wrapped in a Drawer */
export const AddPartnerFormDrawer: FC<
  ComponentProps<typeof Drawer> & { onClose: () => void }
> = ({ open, onClose, ...props }) => {
  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      onClose={onClose}
      {...props}
      children={<AddPartnerForm onClose={onClose} />}
    />
  );
};
