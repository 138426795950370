export enum TimeRange {
  TACKLE_CONTRACT_PERIOD = 'TACKLE_CONTRACT_PERIOD',
  LAST_12_MONTHS = 'LAST_12_MONTHS',
  PREVIOUS_8_QUARTERS = 'PREVIOUS_8_QUARTERS',
  PREVIOUS_3_YEARS = 'PREVIOUS_3_YEARS',
  THIS_YEAR = 'THIS_YEAR',
  LAST_YEAR = 'LAST_YEAR',
  CUSTOM = 'CUSTOM',
  ALL = 'ALL', // Only used for co-sell
}
