import React, { useEffect, useMemo, useState } from 'react';
import { Box, Card, Divider } from 'vendor/material';
import useStyles from './RightRailCard.style';
import RightRailSubSection from 'packages/cosell/src/components/RightRailSubSection/RightRailSubSection';
import { AceInvitationCloudStatusEnum } from 'packages/cosell/src/types/enums';
import RightRailContactInfo from 'packages/cosell/src/components/RightRailSubSection/RightRailContactInfoSection';
import {
  CLOUD_CONTACTS_TITLE,
  getRightRailContentConfig,
  NO_CLOUD_CONTACT_GUIDANCE_TEXT,
  RightRailContentConfig,
  shouldRenderCloudContact,
} from './helper';
import RightRailButtonSection from './RightRailButtonSection';
import {
  AceInvitationEventResponse,
  AceInvitationResponse,
} from 'packages/cosell/src/types/AceInvitation.type';
import { useToast } from 'hooks';
import { getPendingRequestOperationId } from 'packages/cosell/src/hooks/useAceInvitationEventsQuery/getPendingRequestOperationId';
import { Contact } from 'packages/cosell/src/types/responses/AceOpportunityResponse';

interface RightRailCardProps {
  invitation: Pick<
    AceInvitationResponse,
    | 'status'
    | 'opportunityInvitationPayload'
    | 'rejectionReason'
    | 'invitationId'
    | 'expirationDate'
  >;
  invitationEvents: AceInvitationEventResponse[];
}

const RightRailCard: React.FC<RightRailCardProps> = ({
  invitation,
  invitationEvents,
}) => {
  const {
    status,
    rejectionReason,
    opportunityInvitationPayload,
    expirationDate,
  } = invitation;

  const customerCompanyName =
    opportunityInvitationPayload?.customer?.companyName;
  const senderContacts =
    invitation?.opportunityInvitationPayload?.senderContacts;

  const classes = useStyles();

  const { toaster } = useToast();
  // There is a pending operation if this value is not null
  const [pendingOperationId, setPendingOperationId] = useState<
    'acceptInvitation' | 'rejectInvitation' | null
  >(null);
  const [invitationEventsCount, setInvitationEventsCount] = useState<number>(
    invitationEvents?.length || 0,
  );
  useEffect(() => {
    //
    if (
      invitationEventsCount > 0 &&
      invitationEventsCount !== invitationEvents?.length &&
      invitation?.status
    ) {
      const updatePendingOperationId = getPendingRequestOperationId({
        events: invitationEvents,
        status: invitation.status,
      });

      setPendingOperationId(updatePendingOperationId);
      setInvitationEventsCount(invitationEvents.length);
    }
  }, [
    invitation?.status,
    invitationEvents,
    toaster,
    invitationEventsCount,
    pendingOperationId,
  ]);

  const renderCloudContact = shouldRenderCloudContact(status);

  const getCloudContactConfig = (
    senderContacts?: Contact[],
  ): RightRailContentConfig => {
    if (!senderContacts || senderContacts.length === 0 || !renderCloudContact) {
      return {
        title: CLOUD_CONTACTS_TITLE,
        guidanceText: NO_CLOUD_CONTACT_GUIDANCE_TEXT,
      };
    }
    const contactInfo = senderContacts.map((teamMember, i) => (
      <RightRailContactInfo
        businessTitle={teamMember.businessTitle}
        name={
          teamMember.firstName &&
          teamMember.lastName &&
          `${teamMember.firstName} ${teamMember.lastName}`
        }
        email={teamMember.email}
        key={`${teamMember.firstName}_${i}`}
      />
    ));
    return {
      title: CLOUD_CONTACTS_TITLE,
      guidanceText: contactInfo,
    };
  };

  const rightRailContents = useMemo(
    () =>
      getRightRailContentConfig({
        status,
        rejectionReason:
          status === AceInvitationCloudStatusEnum.REJECTED
            ? rejectionReason
            : null,
        pendingOperationId,
        expirationDate,
      }),
    [pendingOperationId, status, rejectionReason, expirationDate],
  );
  const cloudContactConfig = getCloudContactConfig(senderContacts);

  return (
    <Card variant="outlined" className={classes.rightRailCard}>
      {rightRailContents ? (
        <RightRailSubSection
          title={rightRailContents?.title}
          subTitle={rightRailContents?.subTitle}
          guidanceText={rightRailContents?.guidanceText}
          icon={rightRailContents?.icon}
          subIcon={rightRailContents?.subIcon}
          introText={rightRailContents?.introText}
        />
      ) : null}
      {status !== AceInvitationCloudStatusEnum.REJECTED &&
        status !== AceInvitationCloudStatusEnum.EXPIRED && (
          <Box mt={2}>
            <RightRailButtonSection
              status={status}
              customerCompanyName={customerCompanyName}
              hasPendingRequest={!!pendingOperationId}
              setPendingOperationId={setPendingOperationId}
              invitationId={invitation.invitationId}
            />
          </Box>
        )}

      <Box mt={2} mb={1}>
        <Divider />
      </Box>

      <RightRailSubSection
        title={cloudContactConfig.title}
        guidanceText={cloudContactConfig.guidanceText}
      />
    </Card>
  );
};

export default RightRailCard;
