export const FEATURE_VENDOR_GCP_CO_SELL_ENABLED =
  'feature-vendor-gcp-co-sell-enabled';

export const FEATURE_CO_SELL_V3_UI_ENABLED = 'feature-co-sell-v3-ui-enabled';
const CO_SELL_V2_VENDORS_NOT_MIGRATED = 'co-sell-v2-vendors-not-migrated';
export const FEATURE_FLAGS_CO_SELL_V3_UI = {
  TOGGLE_CORE_ENABLED: FEATURE_CO_SELL_V3_UI_ENABLED,
  CO_SELL_V2_VENDORS_NOT_MIGRATED,
} as const;

/* This is a UI Feature flag used for shipping quickly.
 This feature flag can be removed when feature flag 'co-sell-v2-vendors-not-migrated' 
 is populated with all V2 production vendors.
 DO NOT MERGE WITH VALUE true
*/
export const isV3OnboardingUIFeatureFlag = false;
