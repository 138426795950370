import {
  Box,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from 'vendor/material/index';
import { Banner } from '@tackle-io/platform-ui';

const useStyles = makeStyles((theme) => ({
  errorList: {
    listStyleType: 'disc',
    paddingLeft: theme.spacing(3),
  },
  errorListItem: {
    padding: '0 16px 0 0',
    display: 'list-item',
  },
}));

const MarketplaceErrorsBanner = ({
  marketplaceErrors,
}: {
  marketplaceErrors: string[];
}) => {
  const classes = useStyles();

  return (
    <Banner
      type="danger"
      borderPosition="top"
      title="Marketplace error"
      body={
        <Box>
          <Box mb={2}>
            <Typography>The following items need to be corrected:</Typography>
          </Box>
          <List className={classes.errorList} disablePadding>
            {marketplaceErrors.map((error: string, i: number) => (
              <ListItem
                key={`marketplace-error-${i}`}
                className={classes.errorListItem}
              >
                <ListItemText primary={error} />
              </ListItem>
            ))}
          </List>
        </Box>
      }
      isCollapsible
      defaultOpen
    />
  );
};

export default MarketplaceErrorsBanner;
