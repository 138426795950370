import * as React from 'react';
import { CoSellVersionToggle } from './CoSellVersionToggle';
import { useAmpliFeatureFlags } from '@tackle-io/tackle-auth-tools';
import { useRouteMatch, useHistory } from 'react-router';
import { CoSellV3Root } from 'packages/cosell/src/CoSellV3Root';
import { CoSellV2Root } from 'pages/CoSell/CoSellV2Root';
import { FEATURE_FLAGS_CO_SELL_V3_UI } from 'packages/cosell/src/utilities/constants/CoSellFeatureFlags';
import { FeatureFlagState } from 'packages/cosell/src/types/enums';
import { CoSellTestEnvironmentBanner } from '../../../packages/cosell/src/components/CoSellTestEnvironmentBanner/CoSellTestEnvironmentBanner';

const STORAGE_KEY_PREFIX = 'tackle:enable-cosell-v3:';

/**
 *   V3 Only Pathnames
 * - Will not render correctly if we use CoSellV2Root
 */
const COSELL_V3_ONLY_PATHNAMES = ['/co-sell/invitation', '/co-sell/microsoft'];
/**
 *   CoSell Root route component
 * - Allows users with feature flag enabled to switch between CoSell v2 and v3
 * - Renders CoSell v2 by default iffeature flag is not enabled
 *
 *  NOTE: when v3 is the production version, this component can be removed
 */
export const CoSellRoot = ({ vendorId }: { vendorId?: string }) => {
  const { flags: ampliFlags, error: errorLoadingFF } = useAmpliFeatureFlags();
  const history = useHistory();
  const currentPathname = history.location.pathname;
  const isV3OnlyRoot = COSELL_V3_ONLY_PATHNAMES.some((v3pathname) =>
    currentPathname.startsWith(v3pathname),
  );

  if (
    !!vendorId &&
    !errorLoadingFF &&
    ampliFlags?.[FEATURE_FLAGS_CO_SELL_V3_UI.TOGGLE_CORE_ENABLED] ===
      FeatureFlagState.ON
  ) {
    return <CoSellVersionSwitcher vendorId={vendorId} />;
  } else if (isV3OnlyRoot) {
    return (
      <>
        <CoSellTestEnvironmentBanner isV3={true} />
        <CoSellV3Root />
      </>
    );
  }
  return <CoSellV2Root />;
};

/**
 * Component used to allow users to swtich between v2 and v3
 * - maintains user preferance in local storage if they have the feature flag enabled
 *
 * NOTE: when V3 is the production version, this component can be removed
 *
 */
const CoSellVersionSwitcher = ({
  vendorId,
}: {
  /** ensures localStorage version toggle is specific to vendorId */
  vendorId: string;
}) => {
  const storageKey = vendorId ? `${STORAGE_KEY_PREFIX}${vendorId}` : null;
  const [isV3EnabledByUser, setIsV3EnabledByUser] = React.useState<boolean>(
    () => {
      if (!storageKey) {
        return false;
      }
      return localStorage?.getItem(storageKey) === 'true';
    },
  );
  const { path, isExact: isCoSellRootRoute } = useRouteMatch();
  const { push } = useHistory();

  const toggleVersion = () => {
    if (!storageKey) return;

    setIsV3EnabledByUser((prev) => {
      if (!prev) {
        localStorage.setItem(storageKey, 'true');
      } else {
        localStorage.removeItem(storageKey);
      }
      return !prev;
    });

    /** if not on root route when switching version, redirect user to base route  */
    if (!isCoSellRootRoute) {
      return push(path);
    }
  };

  return (
    <>
      <CoSellTestEnvironmentBanner isV3={isV3EnabledByUser} />
      <CoSellVersionToggle
        enabled={isV3EnabledByUser}
        onChange={toggleVersion}
      />
      {isV3EnabledByUser ? <CoSellV3Root /> : <CoSellV2Root />}
    </>
  );
};
