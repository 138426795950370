import {
  flow,
  flatMap,
  curryRight,
  curry,
  isEqual,
  size,
  difference,
  getOr,
} from 'lodash/fp';

import {
  Cloud,
  Domain,
} from 'pages/Settings/Account/components/FieldMapper/utils/constants';

// /insights
export const FEATURE_DOWNSTREAM_INSIGHTS_V1 = 'feature-downstream-insights-v1';

// /contracts/:contract_id
export const FEATURE_DOWNSTREAM_CONTRACTS_V1 =
  'feature-downstream-contracts-v1';

// /disbursements
export const FEATURE_DOWNSTREAM_DISBURSEMENTS_V1 =
  'feature-downstream-disbursements-v1';

// Downstream /datafeeds
export const FEATURE_DATA_FEEDS_V1 = 'feature-data-feeds-v1';

// Downstream /private-offers
export const FEATURE_PRIVATE_OFFER_ACCELERATION_V1 =
  'feature-private-offer-acceleration-v1';

// Downstream /metering
export const FEATURE_METERING_ABSTRACTION_V1 =
  'feature-metering-abstraction-v1';

// Integrations
export const FEATURE_CONNECTORS_SLACK_V1 = 'feature-connectors-slack-v1';
export const FEATURE_CONNECTORS_ZUORA_V1 = 'feature-connectors-zuora-v1';
export const FEATURE_CONNECTORS_SFDC_V1 = 'feature-connectors-sfdc-v1';
export const FEATURE_SALESFORCE_APPEXCHANGE_V1 =
  'feature-salesforce-appexchange-v1';

// Buyer's Portal - license Management
export const FEATURE_LICENSE_MANAGEMENT_V1 = 'feature-license-management-v1';

// +1 listing, same marketplace
export const FEATURE_ADDITIONAL_LISTING_V1 = 'feature-additional-listing-v1';

// +1 marketplace for existing listing
export const FEATURE_ADDITIONAL_MARKETPLACE_V1 =
  'feature-additional-marketplace-v1';

// Multiple products
export const FEATURE_MUTLI_LISTING_V1 = 'feature-multi-listing-v1';

// Multiple marketplaces, Future work required when SSO supports multi-cloud
export const FEATURE_MUTLI_MARKETPLACE_V1 = 'feature-multi-marketplace-v1';

// E-learning
export const FEATURE_ENABLEMENT_MODULES_V1 = 'feature-enablement-modules-v1';

// E-learning w/Customer Support
export const FEATURE_ENABLEMENT_MODULES_WITH_SUPPORT_V1 =
  'feature-enablement-modules-with-support-v1';

// Features which only one product needs to have for access
const ISV_FEATURES = [
  FEATURE_CONNECTORS_SFDC_V1,
  FEATURE_CONNECTORS_ZUORA_V1,
  FEATURE_CONNECTORS_SLACK_V1,
  FEATURE_ENABLEMENT_MODULES_V1,
  FEATURE_ENABLEMENT_MODULES_WITH_SUPPORT_V1,
];

// Addons
export const ADDON_METERING_V1 = 'addon-metering-v1';
export const ADDON_PRIVATE_OFFER_ACCELERATION_V1 =
  'addon-private-offer-acceleration-v1';
export const ADDON_ADDITIONAL_LISTING_V1 = 'addon-additional-listing-v1';
export const ADDON_ADDITIONAL_MARKETPLACE_V1 =
  'addon-additional-marketplace-v1';
export const ADDON_ENABLEMENT_MODULES_V1 = 'addon-enablement-modules-v1';
export const ADDON_CONNECTORS_SLACK_V1 = 'addon-connector-slack-v1';
export const ADDON_CONNECTORS_ZUORA_V1 = 'addon-connector-zuora-v1';
export const ADDON_CONNECTORS_SFDC_V1 = 'addon-connector-sfdc-v1';
export const ADDON_CONNECTORS_ALL_V1 = 'addon-connector-all-v1';
export const ADDON_LICENSE_MANAGEMENT_V1 = 'addon-license-management-v1';
export const ADDON_REPORTING_ONLY_WITH_SMART_ONBOARDING_V1 =
  'addon-reporting-only-with-smart-onboarding-v1';
export const ADDON_FULL_REPORTING_V1 = 'addon-full-reporting-v1';

const ADDONS = {};
ADDONS[ADDON_METERING_V1] = [FEATURE_METERING_ABSTRACTION_V1];
ADDONS[ADDON_PRIVATE_OFFER_ACCELERATION_V1] = [
  FEATURE_PRIVATE_OFFER_ACCELERATION_V1,
];
ADDONS[ADDON_ADDITIONAL_LISTING_V1] = [FEATURE_ADDITIONAL_LISTING_V1];
ADDONS[ADDON_ADDITIONAL_MARKETPLACE_V1] = [FEATURE_ADDITIONAL_MARKETPLACE_V1];
ADDONS[ADDON_ENABLEMENT_MODULES_V1] = [FEATURE_ENABLEMENT_MODULES_V1];
ADDONS[ADDON_LICENSE_MANAGEMENT_V1] = [FEATURE_LICENSE_MANAGEMENT_V1];
ADDONS[ADDON_REPORTING_ONLY_WITH_SMART_ONBOARDING_V1] = [FEATURE_DATA_FEEDS_V1];
ADDONS[ADDON_CONNECTORS_ALL_V1] = [
  FEATURE_CONNECTORS_SLACK_V1,
  FEATURE_CONNECTORS_ZUORA_V1,
  FEATURE_CONNECTORS_SFDC_V1,
];
ADDONS[ADDON_CONNECTORS_SLACK_V1] = [FEATURE_CONNECTORS_SLACK_V1];
ADDONS[ADDON_CONNECTORS_ZUORA_V1] = [FEATURE_CONNECTORS_ZUORA_V1];
ADDONS[ADDON_CONNECTORS_SFDC_V1] = [FEATURE_CONNECTORS_SFDC_V1];
ADDONS[ADDON_FULL_REPORTING_V1] = [
  FEATURE_DOWNSTREAM_CONTRACTS_V1,
  FEATURE_DOWNSTREAM_DISBURSEMENTS_V1,
];

// Plans
export const PLAN_TCMP_BASIC_V1 = 'plan-tcmp-basic-v1';
export const PLAN_TCMP_ENHANCED_V1 = 'plan-tcmp-enhanced-v1';
export const PLAN_TCMP_PRO_V1 = 'plan-tcmp-pro-v1';
export const PLAN_TCMP_ENTERPRISE_V1 = 'plan-tcmp-enterprise-v1';

const PLANS = {};
PLANS[PLAN_TCMP_BASIC_V1] = [FEATURE_LICENSE_MANAGEMENT_V1];

PLANS[PLAN_TCMP_ENHANCED_V1] = [
  ...PLANS[PLAN_TCMP_BASIC_V1],
  FEATURE_PRIVATE_OFFER_ACCELERATION_V1,
  FEATURE_METERING_ABSTRACTION_V1,
];

PLANS[PLAN_TCMP_PRO_V1] = [
  ...PLANS[PLAN_TCMP_ENHANCED_V1],
  ...ADDONS[ADDON_CONNECTORS_ALL_V1],
  ...ADDONS[ADDON_FULL_REPORTING_V1],
  FEATURE_LICENSE_MANAGEMENT_V1,
  FEATURE_MUTLI_LISTING_V1,
  FEATURE_ENABLEMENT_MODULES_V1,
];

PLANS[PLAN_TCMP_ENTERPRISE_V1] = [
  ...PLANS[PLAN_TCMP_PRO_V1],
  FEATURE_ENABLEMENT_MODULES_WITH_SUPPORT_V1,
  FEATURE_MUTLI_MARKETPLACE_V1,
];

const getFeatures = (key: string, dict): string => getOr(key, key, dict);
const plansToFeatures = curryRight(getFeatures)(PLANS);
const addonsToFeatures = curryRight(getFeatures)(ADDONS);
const toFeatures = flatMap(flow(plansToFeatures, addonsToFeatures));

function hasFeatures(
  existingFeatures: string[],
  possibleFeatures: string[],
): boolean {
  const compareFeatures = curryRight(difference)(possibleFeatures);
  const hasAllPossibleFeatures = flow(size, curry(isEqual)(0));

  return flow(
    toFeatures,
    compareFeatures,
    hasAllPossibleFeatures,
  )(existingFeatures);
}

export enum CoSellFeatureFlags {
  BETA_AI_COSELL_DESCRIPTION = 'beta-ai-cosell-description',
}

export enum ContractsFeatureFlags {
  UI_CONTRACTS = 'ui-contracts',
}

export enum ProspectFeatureFlags {
  PROSPECT_AUTO_SCORING_UI_ENABLED = 'prospect-auto-scoring-ui-enabled',
}

export const SALESFORCE_V2_ENABLED = 'v2_salesforce_enabled';

export const SALESFORCE_V2_FIELD_MAPPER_FEATURES = {
  [Cloud.AWS]: {
    [Domain.CO_SELL]: true,
    [Domain.OFFER]: true,
  },
  [Cloud.MSFT]: {
    [Domain.CO_SELL]: false,
    [Domain.OFFER]: false,
  },
  [Cloud.GCP]: {
    [Domain.CO_SELL]: false,
    [Domain.OFFER]: false,
  },
};

export default hasFeatures;
export { PLANS, ADDONS, ISV_FEATURES, toFeatures };
