export enum Cloud {
  AWS = 'aws',
  AZURE = 'azure',
  GCP = 'gcp',
}

export enum Month {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
}

export const ALL_CLOUDS = [Cloud.AWS, Cloud.AZURE, Cloud.GCP];
