import React from 'react';
import { Box, Grid } from 'vendor/material';
// import { calculateDaysBetweenDateAndToday } from 'utils/dates';

import {
  DisplayCloudType,
  AceOpportunityReviewStatusEnum,
  TagColorEnum,
  AceOpportunityStageEnum,
  SourceEnum,
} from 'packages/cosell/src/types/enums';
import Tag from 'packages/cosell/src/components/Tag/Tag';
import ToolTipCopy from 'packages/cosell/src/components/ToolTipCopy/ToolTipCopy';
import {
  AceResponseMetadata,
  LifeCycle,
} from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import { SOURCE_TAG } from 'packages/cosell/src/utilities/constants';
import { SharedHeader } from 'packages/cosell/src/components';

const DRAFT = 'Draft';
interface AceOpportunityDetailsHeaderProps {
  source?: SourceEnum;
  title?: string;
  subtitle?: string;
  lifeCycle?: LifeCycle;
  crmId?: AceResponseMetadata['crmId'];
}

export const getStatusTagColor = (status: AceOpportunityReviewStatusEnum) => {
  if (status === AceOpportunityReviewStatusEnum.ACTION_REQUIRED) {
    return TagColorEnum.YELLOW;
  } else if (status === AceOpportunityReviewStatusEnum.REJECTED) {
    return TagColorEnum.RED;
  } else {
    return TagColorEnum.STANDARD;
  }
};

export const getStageTagColor = (stage: AceOpportunityStageEnum) => {
  if (stage === AceOpportunityStageEnum.LAUNCHED) {
    return TagColorEnum.GREEN;
  } else if (stage === AceOpportunityStageEnum.CLOSED_LOST) {
    return TagColorEnum.RED;
  } else {
    return TagColorEnum.STANDARD;
  }
};

const AceOpportunityDetailsHeader: React.FC<
  AceOpportunityDetailsHeaderProps
> = ({ crmId, source, title, subtitle, lifeCycle }) => {
  const renderStatus = (status: AceOpportunityReviewStatusEnum) => {
    if (status === AceOpportunityReviewStatusEnum.PENDING_SUBMISSION) {
      return DRAFT; //TODO: check to see if we have internal review status
    } else {
      return status;
    }
  };

  const renderSourceTag = (source: SourceEnum) => {
    if (source === SourceEnum.OUTBOUND) {
      return SOURCE_TAG.OUTBOUND;
    } else if (source === SourceEnum.INBOUND) {
      return SOURCE_TAG.INBOUND;
    }
    return '';
  };

  return (
    <>
      <Box mb={1}>
        <SharedHeader
          title={title}
          cloud={DisplayCloudType.AWS}
          subtitle={subtitle}
        />
      </Box>

      <Grid container spacing={1}>
        {!!source && (
          <Grid item>
            <Tag>{renderSourceTag(source)}</Tag>
          </Grid>
        )}
        {lifeCycle?.reviewStatus && (
          <Grid item>
            <Tag color={getStatusTagColor(lifeCycle?.reviewStatus)}>
              {renderStatus(lifeCycle?.reviewStatus)}
            </Tag>
          </Grid>
        )}
        {lifeCycle?.stage && (
          <Grid item>
            <Tag color={getStageTagColor(lifeCycle?.stage)}>
              {lifeCycle?.stage}
            </Tag>
          </Grid>
        )}

        {!!crmId && (
          <Grid item>
            <Tag color={TagColorEnum.STANDARD}>
              <ToolTipCopy
                content={crmId}
                name="CRM ID:"
                hideNameInTooltip={true}
              />
            </Tag>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AceOpportunityDetailsHeader;
