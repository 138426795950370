import { Button } from '@tackle-io/platform-ui';
import { ComponentProps, FC } from 'react';
import { Drawer } from 'vendor/material';
import FormDrawer from '../components/FormDrawer';

export const AddSolutionsForm = ({ onClose }: { onClose: () => void }) => {
  const footerActions = (
    <>
      <Button appearance="primary" variant="outlined" onClick={onClose}>
        Cancel
      </Button>
      <Button
        disabled
        appearance="primary"
        variant="contained"
        onClick={() => alert('review solutions')}
      >
        Review solutions
      </Button>
    </>
  );
  return (
    <FormDrawer
      footerActions={footerActions}
      title="Add Solution"
      onClose={onClose}
    >
      content
    </FormDrawer>
  );
};

/** AddSolutionsForm wrapped in a Drawer */
export const AddSolutionsFormDrawer: FC<
  ComponentProps<typeof Drawer> & { onClose: () => void }
> = ({ open, onClose, ...props }) => {
  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      onClose={onClose}
      {...props}
      children={<AddSolutionsForm onClose={onClose} />}
    />
  );
};
