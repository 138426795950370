export const CloudProviderConfig = {
  aws: {
    formLabel: 'AWS',
    fullName: 'Amazon',
    iconId: 'aws',
    id: 'aws',
  },
  gcp: {
    formLabel: 'Google',
    fullName: 'Google',
    iconId: 'gcp',
    id: 'gcp',
  },
  msft: {
    formLabel: 'Microsoft',
    fullName: 'Microsoft',
    iconId: 'microsoft',
    id: 'msft',
  },
} as const;

export type CloudProvider = keyof typeof CloudProviderConfig;

export const DefaultCloud: CloudProvider = 'aws';

/** Banner messages */
export const CreateJobRequestError =
  'Bulk submit job for co-sell was not started due to an error. Try restarting the job. If the error continues, contact your Salesforce admin.';
export const GetJobRequestError =
  'Bulk submit job for co-sell was started, but there is an error getting the status. Try refreshing the page. If the error continues, contact your Salesforce admin.';
export const JobFailedError =
  'Bulk submit job for co-sell stopped due to a connection error. Try restarting the job. If the error continues, contact your Salesforce admin.';
export const JobCompleted = 'Bulk submit job for co-sell is complete!';
export const JobIsPending =
  'Bulk submit job for co-sell is in progress. This job will continue to run if you move away from this tab or close the Salesforce tab.';
export const NoCrmIDsSelectedWarning =
  'You must select at least 1 opportunity to start a job.';
export const CloudProviderRequiredMessage = 'Please select a cloud partner';
