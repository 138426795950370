import { ActiveFilterChips } from 'packages/cosell/src/components/ActiveFilterChips/ActiveFilterChips';
import useInvitationsListQuery from 'packages/cosell/src/hooks/useInvitationsListQuery';
import useOpportunitiesListQuery from 'packages/cosell/src/hooks/useOpportunitiesListQuery';
import { coSellLandingPageDataId } from 'packages/cosell/src/utilities/intercomEnums';
import { useHistory, useLocation } from 'react-router';

const CoSellFilterTags = ({
  filterType = 'opportunities',
}: {
  filterType: 'opportunities' | 'invitations';
}) => {
  const location = useLocation();
  const history = useHistory();
  const { activePanelFilters: opportunitiesActivePanelFilters } =
    useOpportunitiesListQuery();
  const { activePanelFilters: invitationsActivePanelFilters } =
    useInvitationsListQuery();
  const forceCleanURL = () => {
    const newParams = new URLSearchParams(history.location.search);
    newParams.delete('page');
    newParams.delete('from');
    const correctedPath = `${location.pathname}?${newParams.toString()}`;
    history.push(correctedPath);
  };

  const handleFilterChange = () => {
    forceCleanURL();
  };

  return (
    <ActiveFilterChips
      activeFilters={
        filterType === 'invitations'
          ? invitationsActivePanelFilters
          : opportunitiesActivePanelFilters
      }
      dataId={coSellLandingPageDataId.FILTER_TAGS}
      onChange={handleFilterChange}
    />
  );
};

export { CoSellFilterTags };
