import { useMutation, useQuery } from '@tanstack/react-query';
import coSellClient from '../coSellClient';
import { TackleOperationId } from '../utils';

export const POLLING_MS = 3000;

export const useAceInvitation = ({
  invitationId,
  enablePolling,
}: {
  invitationId?: string;
  enablePolling?: boolean;
}) => {
  const aceInvitationQuery = useQuery({
    queryKey: ['co-sell-aws-invitation', invitationId],
    queryFn: () => coSellClient.getInvitationById(invitationId!),
    enabled: !!invitationId,
    refetchInterval: enablePolling ? POLLING_MS : 0,
  });

  const acceptInvitation = useMutation({
    mutationFn: async ({
      crmId,
      invitationId,
    }: {
      /** add crmId to link to the created co-sell to a salesforce opportunity */
      crmId?: string;
      invitationId: string;
    }) =>
      await coSellClient.acceptOrRejectInvitation({
        invitationId,
        tackleOperationId: TackleOperationId.ACCEPT_INVITATION,
        body: { crmId },
      }),
  });
  const rejectInvitation = useMutation({
    mutationFn: async ({
      rejectionReason,
      invitationId,
    }: {
      rejectionReason: string;
      invitationId: string;
    }) =>
      await coSellClient.acceptOrRejectInvitation({
        invitationId,
        tackleOperationId: TackleOperationId.REJECT_INVITATION,
        body: { rejectionReason },
      }),
  });

  return {
    aceInvitationQuery,
    acceptInvitation,
    rejectInvitation,
  };
};
