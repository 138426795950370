import { Box, useTheme } from 'vendor/material';

import { EngagementScoreEnum } from 'packages/cosell/src/types/enums';
import { CircleIcon } from 'packages/cosell/assets';

const EngagementScore = ({
  engagementScore,
}: {
  engagementScore: EngagementScoreEnum;
}) => {
  const theme = useTheme();

  const fillColor = {
    [EngagementScoreEnum.HIGH]: theme.palette.GREEN300,
    [EngagementScoreEnum.MEDIUM]: theme.palette.YELLOW200,
    [EngagementScoreEnum.LOW]: theme.palette.NEUTRAL050,
  }[engagementScore];

  return (
    <>
      {engagementScore}
      <Box ml={2} display="flex" alignItems="center" justifyContent="center">
        <CircleIcon fillColor={fillColor} />
      </Box>
    </>
  );
};

export default EngagementScore;
