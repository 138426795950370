import { useMemo } from 'react';
import theme from 'Theme';
import { fontWeightMedium } from 'utils/fontWeightConstants';
import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  statusBadge: {
    fontSize: theme.typography.pxToRem(12),
    borderRadius: theme.spacing(0.5),
    fontWeight: fontWeightMedium,
    lineHeight: theme.typography.pxToRem(12),
    letterSpacing: '0.3px',
    padding: '7px 8px',
    width: 'max-content',
  },
}));

const colorMap = {
  success: {
    backgroundColor: theme.palette.GREEN050,
    color: theme.palette.GREEN500,
  },
  danger: {
    backgroundColor: theme.palette.RED050,
    color: theme.palette.RED500,
  },
  info: {
    backgroundColor: theme.palette.BLUE050,
    color: theme.palette.BLUE400,
  },
  neutral: {
    backgroundColor: theme.palette.NEUTRAL020,
    color: theme.palette.NEUTRAL300,
  },
} as const;

type Status =
  | 'submitted'
  | 'error'
  | 'in-progress'
  | 'pending'
  | 'running'
  | 'completed';

/** displays the status in a color matching the status */
const StatusBadge = ({ status }: { status: Status }) => {
  const classes = useStyles();

  const colorStyle = useMemo(() => {
    switch (status) {
      case 'completed':
      case 'submitted':
        return colorMap.success;
      case 'error':
        return colorMap.danger;
      case 'running':
      case 'in-progress':
        return colorMap.info;
      case 'pending':
        return colorMap.neutral;
      default:
        return colorMap.neutral;
    }
  }, [status]);

  return (
    <span className={classes.statusBadge} style={colorStyle}>
      {status.charAt(0).toUpperCase() + status.slice(1)?.replaceAll('-', ' ')}
    </span>
  );
};

export default StatusBadge;
