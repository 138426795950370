import { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { type CloudProvider } from './consts';
import { useCanUseBulkCreate } from './useCanUseBulkCreate';

const DEFAULT_CLOUD = 'aws';
/** Manages the current selected cloudProvider state via route params */
export const useBulkCreateCloudContext = () => {
  const history = useHistory();
  const params = useParams<{ cloud: CloudProvider }>();
  const canUse = useCanUseBulkCreate();

  const handleSetCloudParam = useCallback(
    (cloud: CloudProvider) => {
      history.replace(history.location.pathname.replace(params.cloud, cloud));
    },
    [params.cloud, history],
  );

  useEffect(() => {
    if (!canUse[params.cloud]) {
      handleSetCloudParam(DEFAULT_CLOUD);
    }
  }, [canUse, handleSetCloudParam, params.cloud]);

  return {
    /** the current selected cloud Provider */
    cloudProvider: params.cloud,
    /** update route param with selected cloud */
    handleSetCloudParam,
  };
};
