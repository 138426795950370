import { useEffect, useState } from 'react';
import { AmpliFeatureFlagsProvider } from '@tackle-io/tackle-auth-tools';
import { getOrRenewAccessToken } from 'packages/salesforce-canvas/api/oneTimeKeyAuth';
import analyticsService from 'utils/analytics/amplitude';
import { isEnabled as ampliEnabled } from 'utils/analytics/amplitude';
import { ampli } from 'utils/analytics/ampli';
import { GroupsEnrichmentPlugin } from 'utils/analytics/plugins';
import { useCanvasSession } from '../useCanvasSession';

/** Provides access to the Amplitude feature flags using the Salesforce OTK access_token */
export const FeatureFlagsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { sessionIsLoaded } = useCanvasSession();
  const [token, setToken] = useState<string>(null);
  useEffect(() => {
    const fetchToken = async () => {
      const accessToken = await getOrRenewAccessToken();
      setToken(accessToken);
    };

    if (!token && sessionIsLoaded) {
      fetchToken();
    }
    fetchToken();
  }, [sessionIsLoaded, token]);

  return (
    <AmpliFeatureFlagsProvider
      accessToken={token}
      authServiceUrl={process.env.REACT_APP_TKL_AUTH_SERVICE_URL}
      ampliKey={process.env.REACT_APP_AMPLITUDE_DEPLOYMENT_KEY}
      serviceName="downstream-app"
      proxyServerUrl={process.env.REACT_APP_AMPLITUDE_SERVER_URL}
    >
      {children}
    </AmpliFeatureFlagsProvider>
  );
};

/** initialize Amplitude to allow feature flag polling */
export const useInitializeAmplitude = ({
  organizationId,
  vendorId,
}: {
  organizationId: string;
  vendorId: string;
}) => {
  const isAmplitudeEnabled = ampliEnabled();

  useEffect(() => {
    if (isAmplitudeEnabled) {
      analyticsService.init({
        apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY,
      });
      ampli.client.add(GroupsEnrichmentPlugin({ vendorId, organizationId }));
    }
  }, [isAmplitudeEnabled, vendorId, organizationId]);

  return null;
};
