import { Banner } from '@tackle-io/platform-ui';
import { AssignmentIcon } from 'packages/cosell/assets';
import FormSectionCard from '../components/FormSectionCard';
import { FormSectionConfig } from '../constants';
import useToggleState from 'hooks/useToggleState';
import { AddTeamMemberFormDrawer } from './AddTeamMemberForm';

export const TeamMembersFormSection = () => {
  const [drawerIsOpen, toggleDrawer] = useToggleState(false);
  return (
    <>
      <FormSectionCard
        title={FormSectionConfig.teamMembers.title}
        subtitle={FormSectionConfig.teamMembers.subtitle}
        icon={<AssignmentIcon />}
        data-id={FormSectionConfig.teamMembers.dataId}
      >
        <Banner
          isCollapsible
          title="At least one team member is required to create a referral."
          type="info"
        />

        <FormSectionCard.BlockMessage>
          You haven't added a team member yet.
        </FormSectionCard.BlockMessage>
        <FormSectionCard.AddButton onClick={toggleDrawer}>
          Add team member
        </FormSectionCard.AddButton>
      </FormSectionCard>
      <AddTeamMemberFormDrawer open={drawerIsOpen} onClose={toggleDrawer} />
    </>
  );
};

export default TeamMembersFormSection;
