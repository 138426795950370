const WarningIconYellow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect x="2" y="2" width="12" height="12" rx="6" fill="black" />
    <path
      d="M3.33333 2H12.6667C13.0203 2 13.3594 2.14048 13.6095 2.39052C13.8595 2.64057 14 2.97971 14 3.33333V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2ZM8.66667 8.66667V4.66667H7.33333V8.66667H8.66667ZM8.66667 11.3333V10H7.33333V11.3333H8.66667Z"
      fill="#FFAB00"
    />
  </svg>
);
export default WarningIconYellow;
