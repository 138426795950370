import { EditModalType } from '../src/components';
import { ActiveFilter } from '../src/components/FilterPanel/FilterPanel';
import {
  DEFAULT_OPPORTUNITIES_SORT_DIRECTION,
  DEFAULT_OPPORTUNITIES_SORT_KEY,
  OpportunitySortState,
  InvitationSortState,
  DEFAULT_INVITATIONS_SORT_KEY,
  DEFAULT_INVITATIONS_SORT_DIRECTION,
  HAS_ERRORS_KEY,
  HAS_CRM_ID_KEY,
  START_DATE_API_KEY,
  END_DATE_API_KEY,
} from '../src/pages/CoSellLandingPage/helpers/urlParamsHelper';
import { AceOpportunityReviewStatusEnum } from '../src/types/enums';
import { CrmIdEnum } from '../src/types/enums/CrmIdEnum';
import { ErrorsEnum } from '../src/types/enums/ErrorsEnum';

export enum TackleOperationId {
  CREATE_DRAFT_OPPORTUNITY = 'createDraftOpportunity',
  CREATE_OPPORTUNITY = 'createOpportunity',
  UPDATE_OPPORTUNITY = 'updateOpportunity',
  UPDATE_OPPORTUNITY_CRMID = 'updateOpportunityCRMID',
  /** Begin engagement on the current draft opportunity with AWS. */
  START_ENGAGEMENT = 'startEngagement',
  /** Update the existing draft opportunity and initiate engagement. */
  UPDATE_START_ENGAGEMENT = 'updateStartEngagementOpportunity',
  CLOSE_LOST_OPPORTUNITY = 'closeLostOpportunity',
  LAUNCH_OPPORTUNITY = 'launchOpportunity',
  /** Accepting or rejecting opportunities is for the mock service
   * to simulate AWS's approval or rejection process. */
  ACCEPT_OPPORTUNITY = 'acceptOpportunity',
  REJECT_OPPORTUNITY = 'rejectOpportunity',
  ACCEPT_INVITATION = 'acceptInvitation',
  REJECT_INVITATION = 'rejectInvitation',
}

export type InvitationActionArg =
  | {
      invitationId: string;
      tackleOperationId: TackleOperationId.ACCEPT_INVITATION;
      body: {};
    }
  | {
      invitationId: string;
      tackleOperationId: TackleOperationId.REJECT_INVITATION;
      body: { rejectionReason: string };
    };

export const getEditModalTackleOperationId = (
  editModalType: EditModalType,
): TackleOperationId => {
  switch (editModalType) {
    case EditModalType.EDIT_LAUNCHED:
      return TackleOperationId.UPDATE_OPPORTUNITY;
    case EditModalType.LAUNCH:
      return TackleOperationId.LAUNCH_OPPORTUNITY;
    case EditModalType.CLOSE_LOST:
      return TackleOperationId.CLOSE_LOST_OPPORTUNITY;
  }
};

export const getEditPageSubmitTackleOperationId = (
  currentStatus: AceOpportunityReviewStatusEnum,
): TackleOperationId => {
  // the START_ENGAGEMENT operation will only submit the existing draft opportunity to ACE,
  // if we want the user to be able to submit the draft with updates, the operation should be updateStartEngagementOpportunity
  if (currentStatus === AceOpportunityReviewStatusEnum.PENDING_SUBMISSION) {
    return TackleOperationId.UPDATE_START_ENGAGEMENT;
  }
  return TackleOperationId.UPDATE_OPPORTUNITY;
};

const getFilterQueryString = (filter: ActiveFilter) => {
  const hasCloudErrorsKey = 'hasCloudErrors';
  const hasSalesforceErrorsKey = 'hasSalesforceErrors';

  if (filter.key === HAS_ERRORS_KEY) {
    switch (filter.value) {
      case ErrorsEnum.AWS_ERRORS:
        return `&${hasCloudErrorsKey}=true`;
      case ErrorsEnum.SALESFORCE_ERRORS:
        return `&${hasSalesforceErrorsKey}=true`;
      case ErrorsEnum.NO_ERRORS:
        return `&${hasCloudErrorsKey}=false&${hasSalesforceErrorsKey}=false`;
      default:
        return '';
    }
  }

  if (filter.key === HAS_CRM_ID_KEY) {
    return `&${filter.key}=${filter.value === CrmIdEnum.YES}`;
  }

  return `&${filter.key}=${filter.value}`;
};

export const getOpportunitiesListUrl = ({
  q,
  pageSize,
  from,
  activePanelFilters,
  sortState,
  startDate,
  endDate,
}: {
  q?: string | null;
  pageSize?: string | null;
  from?: string | null;
  activePanelFilters: ActiveFilter[];
  sortState: OpportunitySortState;
  startDate?: string;
  endDate?: string;
}) => {
  const { sortKey, sortDirection } = sortState;
  let url = 'opportunities';
  let hasUrlParams = false;

  if (startDate && endDate) {
    url += `?${START_DATE_API_KEY}=${startDate}&${END_DATE_API_KEY}=${endDate}`;
    hasUrlParams = true;
  }

  if (sortKey === null || sortDirection === null) {
    url += `${
      hasUrlParams ? '&' : '?'
    }sortField=${DEFAULT_OPPORTUNITIES_SORT_KEY}&sortOrder=${DEFAULT_OPPORTUNITIES_SORT_DIRECTION}`;
  } else {
    url += `${
      hasUrlParams ? '&' : '?'
    }sortField=${sortKey}&sortOrder=${sortDirection}`;
  }

  if (q) {
    url += `&q=${q}`;
  }
  if (pageSize) {
    url += `&pageSize=${pageSize}`;
  }
  if (from) {
    url += `&from=${from}`;
  }
  activePanelFilters.forEach((filter) => {
    const filterQueryString = getFilterQueryString(filter);
    url += filterQueryString;
  });

  return url;
};

export const getInvitationsListUrl = ({
  q,
  pageSize,
  from,
  activePanelFilters,
  sortState,
  startDate,
  endDate,
}: {
  q?: string | null;
  pageSize?: string | null;
  from?: string | null;
  activePanelFilters: ActiveFilter[];
  sortState: InvitationSortState;
  startDate?: string;
  endDate?: string;
}) => {
  const { sortKey, sortDirection } = sortState;
  let url = 'invitations?origin=aws';

  if (startDate && endDate) {
    url += `&${START_DATE_API_KEY}=${startDate}&${END_DATE_API_KEY}=${endDate}`;
  }

  if (sortKey === null || sortDirection === null) {
    url += `&sortField=${DEFAULT_INVITATIONS_SORT_KEY}&sortOrder=${DEFAULT_INVITATIONS_SORT_DIRECTION}`;
  } else {
    url += `&sortField=${sortKey}&sortOrder=${sortDirection}`;
  }

  if (q) {
    url += `&q=${q}`;
  }
  if (pageSize) {
    url += `&pageSize=${pageSize}`;
  }
  if (from) {
    url += `&from=${from}`;
  }
  activePanelFilters.forEach((filter) => {
    url += `&${filter.key}=${filter.value}`;
  });

  return url;
};

export const getRadioButtonDataId = ({
  dataId,
  fieldValue,
}: {
  dataId: string;
  fieldValue: string;
}) => `${dataId}_${fieldValue}`;
