import { makeStyles } from 'vendor/material';
import { ThemeTackle } from 'Theme';

import { getToolbarHeight } from 'utils/styleHelpers';

const useStyles = makeStyles((theme: ThemeTackle) => {
  const toolbarHeight = getToolbarHeight(theme);

  return {
    root: {
      display: 'flex',
    },
    content: {
      marginTop: 0,
      position: 'relative',
      flexGrow: 1,
      maxWidth: '100vw',
      width: `calc(100% - ${theme.spacing(7) + 1}px)`,
      minHeight: '100vh',
      backgroundColor: theme.palette.NEUTRAL020,
      paddingTop: toolbarHeight,
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      backgroundColor: theme.palette.NEUTRAL000,
      minHeight: toolbarHeight,
      boxShadow: 'none',
      borderBottom: `1px solid ${theme.palette.NEUTRAL050}`,
    },
    headerBar: {
      justifyContent: 'end',
    },
    contentWithAppShell: {
      marginTop: 0,
      height: '100vh',
      overflowY: 'auto',
      minHeight: `calc(100vh - ${toolbarHeight})`,
    },
    contentWithoutToolbar: {
      paddingTop: 0,
    },
    hidden: {
      display: 'none',
    },
    pageBanner: {
      position: 'sticky',
      top: 0,
      zIndex: theme.zIndex.appBar,
    },
  };
});

export default useStyles;
