import { makeStyles } from 'vendor/material';
import { AnimationSpinner } from 'packages/cosell/src/components/AnimationSpinner/AnimationSpinner';
import { fontWeightMediumLarge } from 'utils/fontWeightConstants';

const useStyles = makeStyles((theme) => ({
  childContent: {
    paddingInline: '4px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    justifyItems: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.BLUE050,
    paddingInline: theme.spacing(1),
    paddingBlock: theme.spacing(0.5),
    gap: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    fontWeight: fontWeightMediumLarge,
    fontSize: theme.typography.pxToRem(12),
    textWrap: 'pretty',
    '& svg': {
      color: 'currentColor',
    },
  },
  linkButton: {
    cursor: 'pointer',
    color: theme.palette.BLUE400,
    padding: 0,
    margin: 0,
    display: 'inline',
    border: 'none',
    outline: 'none',
    background: 'none',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
  },
}));

export const IncomingDataLoader = ({
  children,
  title,
}: {
  children?: React.ReactNode;
  title: string;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span>{title}</span>
        <AnimationSpinner />
      </div>
      {children}
    </div>
  );
};

export const IncomingCoSellLoader = ({
  isPolling,
  onRefresh,
}: {
  isPolling: boolean;
  onRefresh?: () => void;
}) => {
  const classes = useStyles();

  return (
    <IncomingDataLoader title="Co-sell incoming!">
      <div className={classes.childContent}>
        An invitation was accepted and we are fetching the co-sell.{' '}
        {!isPolling && (
          <>
            Refresh the widget if you don't see it within a few minutes.{' '}
            {typeof onRefresh === 'function' ? (
              <button className={classes.linkButton} onClick={onRefresh}>
                Refresh
              </button>
            ) : null}
          </>
        )}
      </div>
    </IncomingDataLoader>
  );
};
