import React, { useState } from 'react';
import classNames from 'classnames';
import { ClickAwayListener, Button } from 'vendor/material';
import { Menu } from '@tackle-io/platform-ui';
import { CalendarMonth as CalendarIcon } from 'mdi-material-ui';
import useStyles from './TimeRangePicker.styles';
import { TimeRangePickerProps } from '../DateFilter/types';

function TimeRangePicker({
  options,
  onSelectTimeFrame,
  selectedValue,
  dataId,
}: TimeRangePickerProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const cssDropdown = classNames(classes.dropdown, {
    selected: anchorEl,
    fullWidth: true,
  });

  const handleClick = (event: React.MouseEvent): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleSelect = (data) => {
    onSelectTimeFrame(data, handleClose);
  };

  return (
    <div data-id={dataId}>
      <ClickAwayListener onClickAway={handleClose}>
        <Button
          aria-controls="dropdown"
          aria-haspopup="true"
          disableFocusRipple
          disableRipple
          onClick={handleClick}
          startIcon={<CalendarIcon />}
          classes={{ root: cssDropdown }}
        >
          {selectedValue}
        </Button>
      </ClickAwayListener>
      <Menu
        id="dashboard-time-range-filter-list"
        anchorEl={anchorEl}
        open={open}
        onSelect={handleSelect}
        onClose={handleClose}
        items={options}
      />
    </div>
  );
}

export { TimeRangePicker };
