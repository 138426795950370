import { makeStyles } from 'vendor/material';
import { fontWeightMediumLarge } from 'utils/fontWeightConstants';
import { LifeCycle } from '../../types/responses/AceOpportunityResponse';

const useStyles = makeStyles((theme) => ({
  bodyTextContent: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  bodyTextSubTitle: {
    fontWeight: fontWeightMediumLarge,
    marginBottom: theme.spacing(0.5),
  },
}));

const ActionRequiredGuidance = ({ lifeCycle }: { lifeCycle: LifeCycle }) => {
  const { nextSteps, reviewComments } = lifeCycle;
  const classes = useStyles();
  return (
    <>
      <p className={classes.bodyTextContent}>
        This co-sell opportunity has been reviewed by the cloud and requires an
        action on your part to proceed. If provided, please read the reviewer
        comments, address any issues, and resubmit to the cloud.
      </p>
      {!!reviewComments && (
        <>
          <p className={classes.bodyTextSubTitle}>Reviewer comments</p>
          <span>{reviewComments}</span>
        </>
      )}
      {!!nextSteps && (
        <>
          <p className={classes.bodyTextSubTitle}>Recommended actions</p>
          <span>{nextSteps}</span>
        </>
      )}
    </>
  );
};

export default ActionRequiredGuidance;
