import React, { SetStateAction, Dispatch, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { convertOpportunityResponseToAceFormValues } from '../../utilities/typeConverters/convertOpportunityResponseToAceFormValues';
import { UpdateAceOpportunityFormValues } from '../UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import {
  getAwsMarketplaceOffers,
  getSoftwareRevenue,
} from '../../utilities/typeConverters/convertAceFormValuesToUpdateRequest';
import { ampli } from 'utils/analytics/ampli';
import { getEditModalTackleOperationId } from 'packages/cosell/api/utils';
import { AceOpportunityResponse } from '../../types/responses/AceOpportunityResponse';
import { EditModalType } from './RightRailButtonSection';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import { useCoSellContext } from 'packages/cosell/src/CoSellContextProvider';
import {
  CloseLostOpportunityRequest,
  EditLaunchOpportunityRequest,
  LaunchAceOpportunityRequest,
} from '../../types/requests/AceOpportunityRequest';
import { removeUndefinedOrEmptyObjectProperties } from '../../utilities/typeConverters/utils';
import { useAwsCoSellMappedFields } from 'packages/cosell/src/hooks/useAwsCoSellMappedFields';
import { Loader } from '@tackle-io/platform-ui';
import useOpportunityEventsQuery from '../../hooks/useOpportunityEventsQuery/useOpportunityEventsQuery';
import {
  getInitialTouched,
  mapErrors,
} from '../UnifiedOpportunityForm/utils/mapErrors';
import { useAceVendorConfig } from 'packages/cosell/api/hooks/useAceVendorConfig';
import {
  closeLostFormSchema,
  editLaunchFormSchema,
  editLaunchFormWithSaasDocRequiredSchema,
  launchFormSchema,
  launchFormWithSaasDocRequiredSchema,
} from '../UnifiedOpportunityForm/AceOpportunityForm/schemas/modalFormValidationSchema';
import useToast from 'hooks/useToast/useToast';
import { TOASTS } from 'packages/cosell/src/components';
import { RightRailEditModalContent } from './RightRailEditModalContent';

const getFormValidationSchema = (
  editModalType: EditModalType,
  enableSaasDocumentationFields: Boolean,
) => {
  switch (editModalType) {
    case EditModalType.LAUNCH:
      return enableSaasDocumentationFields
        ? launchFormWithSaasDocRequiredSchema
        : launchFormSchema;
    case EditModalType.EDIT_LAUNCHED:
      return enableSaasDocumentationFields
        ? editLaunchFormWithSaasDocRequiredSchema
        : editLaunchFormSchema;
    case EditModalType.CLOSE_LOST:
      return closeLostFormSchema;
    default:
      return {}; // No schema validation by default
  }
};

interface RightRailEditModalProps {
  editModalType: EditModalType;
  setEditModalOpen: Dispatch<SetStateAction<EditModalType | null>>;
}

const RightRailEditModal: React.FC<RightRailEditModalProps> = ({
  editModalType,
  setEditModalOpen,
}: {
  editModalType: EditModalType;
  setEditModalOpen: Dispatch<SetStateAction<EditModalType | null>>;
}) => {
  const { toaster } = useToast();
  const { opportunityId, renderEnv } = useCoSellContext();
  const { aceOpportunityQuery, updateAceOpportunity } = useAceOpportunity({
    opportunityId,
  });
  const { aceOpportunityErrors } = useOpportunityEventsQuery(opportunityId);
  const { isSaasDocumentationRequiredToLaunch } = useAceVendorConfig();
  const crmId = aceOpportunityQuery.data?.metadata?.crmId;

  const formTypeMap = {
    [EditModalType.LAUNCH]: 'launch',
    [EditModalType.CLOSE_LOST]: 'closedLost',
  };
  const shouldUseMappedFields = editModalType in formTypeMap;
  const mappedFields = useAwsCoSellMappedFields({
    crmId: crmId,
    enabled: !!crmId && shouldUseMappedFields && renderEnv === 'sf_canvas',
    formType: formTypeMap[editModalType],
  });

  const mappedFieldsLoadingError = mappedFields.isError;

  useEffect(() => {
    if (mappedFieldsLoadingError) {
      toaster(TOASTS.errorToast('Failed to load mapped fields'));
    }
  }, [mappedFieldsLoadingError, toaster]);

  /** this component is only rendered if it is a complete AceOpportunity */
  const opportunity = aceOpportunityQuery.data as AceOpportunityResponse;

  const lastAceRequestHasErrors = opportunity?.metadata?.hasCloudErrors;

  const enableSaasDocumentationFields =
    isSaasDocumentationRequiredToLaunch({
      deliveryModels: opportunity.project?.deliveryModels || [],
    }) ?? false;
  const formValidationSchema = getFormValidationSchema(
    editModalType,
    enableSaasDocumentationFields,
  );

  const handleEditOpportunity = async (
    values: UpdateAceOpportunityFormValues,
  ): Promise<void> => {
    const tackleOperationId = getEditModalTackleOperationId(editModalType);
    /** Close lost uses a different payload than Launch
     *  and needs to be handled separately
     */
    if (editModalType === EditModalType.CLOSE_LOST) {
      const closeLostRequestBody: CloseLostOpportunityRequest = {
        lifeCycle: {
          closedLostReason: values.closedLostReason,
        },
      };

      if (!!values.additionalComments) {
        closeLostRequestBody.project = {
          additionalComments: values.additionalComments,
        };
      }

      await updateAceOpportunity.mutateAsync({
        requestBody: closeLostRequestBody,
        tackleOperationId,
      });
    } else if (editModalType === EditModalType.LAUNCH) {
      const launchRequest: LaunchAceOpportunityRequest = {
        lifeCycle: {
          targetCloseDate: values.targetCloseDate,
        },
        softwareRevenue: getSoftwareRevenue(values),
        solutions: values.solutions,
        awsMarketplaceOffers: getAwsMarketplaceOffers(values),
      };

      const trimmedLaunchRequest =
        removeUndefinedOrEmptyObjectProperties(launchRequest);

      await updateAceOpportunity.mutateAsync({
        requestBody: trimmedLaunchRequest,
        tackleOperationId,
      });
    } else {
      const editLaunchedOppRequest: EditLaunchOpportunityRequest = {
        project: {
          additionalComments: values.additionalComments,
          deliveryModels: values.deliveryModels,
        },
        softwareRevenue: getSoftwareRevenue(values),
      };
      await updateAceOpportunity.mutateAsync({
        requestBody: editLaunchedOppRequest,
        tackleOperationId,
      });
    }
  };

  const handleSubmitAceOpportunityToCloud = async (
    values: UpdateAceOpportunityFormValues,
  ): Promise<void> => {
    try {
      await handleEditOpportunity(values);
      ampli.outboundSubmitted({ cloud: 'aws' });
      setEditModalOpen(null);
    } catch (error) {
      throw error;
    }
  };

  const initialValues = {
    ...convertOpportunityResponseToAceFormValues(opportunity),
    ...mappedFields.mappedData?.initialValues,
  };

  if (mappedFields.isInitialLoading) {
    return <Loader />;
  }
  const mappedErrors = lastAceRequestHasErrors
    ? mapErrors(aceOpportunityErrors)
    : {};
  const initialTouched = getInitialTouched(mappedErrors);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={formValidationSchema}
      initialErrors={mappedErrors}
      initialTouched={initialTouched}
      onSubmit={() => {
        // noOp because we use the button outside of the form to submit
        // noop
      }}
    >
      <Form>
        <RightRailEditModalContent
          editModalType={editModalType}
          setEditModalOpen={setEditModalOpen}
          opportunity={opportunity}
          handleSubmitAceOpportunityToCloud={handleSubmitAceOpportunityToCloud}
        />
      </Form>
    </Formik>
  );
};

export default RightRailEditModal;
