import React from 'react';

interface CircleIconProps {
  fillColor?: string;
}

const CircleIcon: React.FC<CircleIconProps> = ({ fillColor = '#36B37E' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g id="Circle" clipPath="url(#clip0_14766_5)">
      <path
        id="Vector"
        d="M7.99967 1.33301C4.31967 1.33301 1.33301 4.31967 1.33301 7.99967C1.33301 11.6797 4.31967 14.6663 7.99967 14.6663C11.6797 14.6663 14.6663 11.6797 14.6663 7.99967C14.6663 4.31967 11.6797 1.33301 7.99967 1.33301Z"
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_14766_5">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CircleIcon;
