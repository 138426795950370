import { fontWeightMediumLarge } from 'utils/fontWeightConstants';
import { makeStyles } from 'vendor/material';
import { Button, Link } from '@tackle-io/platform-ui';

const useStyles = makeStyles((theme) => ({
  actionStyle: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0',
    paddingInline: theme.spacing(1),
    fontWeight: fontWeightMediumLarge,
    fontSize: 'inherit',
    margin: 0,
    marginLeft: theme.spacing(0.25),
    color: theme.palette.BLUE400 + ' !important',
    minWidth: 'auto',
    textDecoration: 'none',
    lineHeight: theme.typography.pxToRem(24),
    '& span > span': {
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
}));

/** Provides an consistent style for an action item (button or link)
 * that can be used in a list item.
 *
 * if href is provided, it will be rendered as a Link
 *  otherwise it will be rendered as a button.
 */
export const ActionButton = ({
  children,
  href,
  onClick,
}: {
  children: React.ReactNode;
  href?: string;
  onClick?: () => void;
} & React.ComponentPropsWithRef<'button' | 'a'>) => {
  const classes = useStyles();

  if (href) {
    return (
      <Link className={classes.actionStyle} to={href}>
        {children}
      </Link>
    );
  }

  return (
    <Button
      className={classes.actionStyle}
      onClick={onClick}
      type="button"
      variant="text"
    >
      {children}
    </Button>
  );
};

export default ActionButton;
