import React from 'react';
import {
  FormControl,
  FormControlLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
  Typography,
  Box,
} from 'vendor/material';

import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    display: 'grid',
    gap: theme.spacing(3),
    marginRight: theme.spacing(4),
  },
  radioButton: {
    '& span': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(22),
      color: theme.palette.NEUTRAL800,
      // To style the radio button
      '& div': { color: theme.palette.NEUTRAL100 },
      '& div[class*="checked"]': {
        color: theme.palette.BLUE400,
      },
    },
  },
  radioContent: {
    marginTop: -theme.spacing(0.5),
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(0.5),
    display: 'grid',
    gap: theme.spacing(2),
  },
  helperText: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.NEUTRAL300,
    lineHeight: theme.typography.pxToRem(22),
  },
  tooltip: {
    marginLeft: theme.spacing(0.5),
  },
}));

interface RadioCheckboxGroupProps {
  label: string;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options?: Array<{
    /** optionally pass in a child element to render right below the radio option */
    children?: React.ReactNode;
    display: string;
    value: string;
    helperText?: string;
  }>;
  value: RadioCheckboxGroupProps['options'][number]['value'];
}

export const RadioCheckboxGroupFormField = ({
  name,
  onChange,
  options = [],
  value,
}: RadioCheckboxGroupProps) => {
  const classes = useStyles();

  return (
    <FormControl id={name} data-id={name}>
      <MuiRadioGroup
        name={name}
        className={classes.radioGroup}
        onChange={onChange}
        value={value}
      >
        {options.map((option) => {
          return (
            <Box key={option.value}>
              <FormControlLabel
                role={'presentation'}
                data-testid={`${name}_${option.display}`}
                id={`${name}_${option.display}`}
                data-id={`${name}_${option.display}`}
                name={name}
                label={option.display}
                value={option.value}
                className={classes.radioButton}
                control={<Radio />}
              />
              <Box className={classes.radioContent}>
                <Typography className={classes.helperText}>
                  {option.helperText}
                </Typography>
                <div>{option.children}</div>
              </Box>
            </Box>
          );
        })}
      </MuiRadioGroup>
    </FormControl>
  );
};
