import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  dropdown: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.NEUTRAL050,
    backgroundColor: theme.palette.NEUTRAL000,
    color: theme.palette.NEUTRAL800,
    height: theme.typography.pxToRem(42),
    padding: theme.spacing(0.625, 2),
    marginTop: theme.typography.pxToRem(4),
    minWidth: theme.typography.pxToRem(272),
    textTransform: 'none',
    display: 'flex',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&.selected': {
      borderColor: theme.palette.BLUE500,
      color: theme.palette.BLUE500,
    },
    '&.withIcon': {
      justifyContent: 'start',
    },
    '&:focus': {
      outline: `1px solid ${theme.palette.BLUE500}`,
    },
    '&:hover': {
      borderColor: theme.palette.NEUTRAL100,
    },
    '&[disabled]': {
      borderColor: theme.palette.NEUTRAL090,
      color: theme.palette.NEUTRAL090,
    },
  },
}));

export default useStyles;
