import React from 'react';
import { SharedHeader } from './SharedHeader';
import { Box, makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  page: {
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr',
    minHeight: '100svh',
    overflowX: 'clip',
  },
}));

const CloudPage = ({
  children,
  titleProps,
}: {
  children: React.ReactNode;
  titleProps: React.ComponentProps<typeof SharedHeader>;
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.page}>
      <Box padding={4}>
        <SharedHeader {...titleProps} />
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default CloudPage;
