import { useQuery } from '@tanstack/react-query';
import { getTackleOpportunityListByCrmId } from 'packages/salesforce-canvas/api/requests/getTackleOpportunityListByCrmId';
import { useState } from 'react';
import {
  clearIncomingCoSellId,
  getStorageKey,
  getIncomingCoSellId,
} from 'packages/salesforce-canvas/cosell/src/utils/incomingCoSellSessionStorage';

const MAX_POLLING_COUNT = 10;
const POLLING_FREQUENCY = 3000;

/** Returns a summary for all Tackle co-sell opportunities,
 * linked to the provided crmId (Salesforce Opportunity ID)
 */
export const useTackleOpportunitiesForCrmId = ({
  crmId,
}: {
  crmId?: string;
}) => {
  const [creationIsPending, setCreationIsPending] = useState(false);
  const [pollingQueryCountExceeded, setPollingQueryCountExceeded] =
    useState(false);

  const incomingCoSellSessionStorageKey = crmId
    ? getStorageKey({
        crmId: crmId,
        pendingItemType: 'incomingCoSell',
        pendingItemName: 'cloudProviderId',
      })
    : null;

  const { data, isInitialLoading, refetch, isSuccess } = useQuery({
    queryFn: () => getTackleOpportunityListByCrmId({ crmId: crmId! }),
    queryKey: [crmId],
    enabled: !!crmId,
    refetchInterval(data, query) {
      if (!data) return false;

      if (query.state.dataUpdateCount >= MAX_POLLING_COUNT) {
        setPollingQueryCountExceeded(true);
      }

      const pendingItem = incomingCoSellSessionStorageKey
        ? getIncomingCoSellId(incomingCoSellSessionStorageKey)
        : null;

      if (!pendingItem) {
        return false;
      }

      const pendingDataHasLoaded = data.opportunities?.some(
        (opportunity) => opportunity.cloudProviderId === pendingItem,
      );
      const shouldPoll =
        !pendingDataHasLoaded &&
        query.state?.dataUpdateCount <= MAX_POLLING_COUNT;

      if (pendingDataHasLoaded) {
        clearIncomingCoSellId(incomingCoSellSessionStorageKey);
      }

      /** If the incoming co-sell is not loaded, and we stop polling,
       * we need to inform the user that they can refresh the data
       */
      setCreationIsPending(!pendingDataHasLoaded);

      return shouldPoll ? POLLING_FREQUENCY : false;
    },
  });

  return {
    data,
    incomingCosell: {
      isPolling: !pollingQueryCountExceeded && creationIsPending,
      creationIsPending,
    },
    isInitialLoading,
    refetch,
    isSuccess,
  };
};
