import { AceInvitation } from '../pages/CoSellLandingPage/components/CoSellInvitationsTable/types';
import { ExpectedCustomerSpend } from './AceOpportunity.type';
import { AceInvitationCloudStatusEnum, SourceEnum } from './enums';
import type { CoSellActivityDetails } from './responses/AceOpportunityEventResponse';
import { Contact } from './responses/AceOpportunityResponse';

export type AceInvitationEventOperation =
  | 'acceptInvitation'
  | 'rejectInvitation'
  | 'cloudUpdateEvent';

export enum AceInvitationStatusEnum {
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
}

export interface AceInvitationsListResponse {
  invitations: AceInvitation[];
  currentPageCount: number;
  total: number;
  isNextPage: boolean;
  nextIndex: number;
}

interface AWSInvitationEventCloudRawResponse {
  RejectionReason?: string;
  Status?: AceInvitationStatusEnum;
}

export interface AceInvitationEventResponse {
  operation: AceInvitationEventOperation;
  eventID: string;
  /** ISO 8601 format */
  timestamp: string;
  cloudRaw: AWSInvitationEventCloudRawResponse;
  activityDetails: CoSellActivityDetails;
}

export interface AceInvitationEventsListResponse {
  events: AceInvitationEventResponse[];
}

export interface InvitationProject {
  title: string;
  businessProblem: string;
  targetCompletionDate: string;
  expectedCustomerSpend: ExpectedCustomerSpend;
}

export interface OpportunityInvitationPayloadInterface {
  receiverResponsibilities: string[];
  customer: {
    companyName: string;
    websiteUrl: string;
    countryCode: string;
    industry: string;
  };
  project: InvitationProject;
  senderContacts?: Contact[];
}

export interface AceInvitationResponse {
  invitationId: string;
  engagementTitle: string;
  source: SourceEnum.INBOUND;
  status: AceInvitationCloudStatusEnum;
  opportunityInvitationPayload: OpportunityInvitationPayloadInterface;
  rejectionReason?: string;
  expirationDate?: string;
  invitationDate?: string;
  /** the cloud opportunityId, which should exist if invitation has been accepted
   *  and the opportunity has been created in the cloud
   */
  opportunityId?: string;
  metadata?: {
    /** salesforce opportunityId that was linked when accepting the invitation */
    crmId?: string;
    /** tackle opportunityId created when invitation accepted */
    tackleOpportunityId?: string;
  };
}
