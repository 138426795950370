import {
  fontWeightMediumLarge,
  fontWeightRegular,
} from 'utils/fontWeightConstants';
import ComingSoonAnimation from './ComingSoonAnimation/ComingSoonAnimation';
import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.NEUTRAL600,
    padding: theme.spacing(2),
    backgroundColor: '#F3F5F5',
    gap: theme.spacing(3),
  },
  header: {
    '& h1': {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: fontWeightRegular,
      lineHeight: theme.typography.pxToRem(48),
      margin: 0,
    },
    '& span': {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: fontWeightMediumLarge,
    },
  },
  message: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    margin: 0,
    marginTop: theme.spacing(4),
  },
  figure: {
    flex: 1,
    height: '100%',
    maxHeight: '600px',
    ratio: '1',
    alignSelf: 'center',
  },
}));

const ComingSoonView = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <h1>Coming soon!</h1>
        <span>Bulk submit co-sells</span>
        <p className={classes.message}>
          Start a new bulk submit job for co-sells, and track the status of all
          jobs started by Tackle in Salesforce.
        </p>
      </header>

      <div className={classes.figure}>
        <ComingSoonAnimation />
      </div>
    </div>
  );
};

export default ComingSoonView;
