import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getAndStoreTackleSessionFromOtk } from './lib/salesforceCanvasSession';
import { getUserData } from '../api/requests/getUserData';
import {
  getRefreshToken,
  isSessionExpired,
} from '../api/salesforceSessionStorage';
// Load the Salesforce Canvas SDK so it's available globally.
import './lib/salesforceCanvasSdk';

import { rotateTokens, getOrRenewAccessToken } from '../api/oneTimeKeyAuth';

const ONE_TIME_KEY_PARAM = 'otk';
const COMPONENT_ID_PARAM = 'componentId';

// The interval to renew the refresh token before it expires.
// If the salesforce user is inactive, this will ensure that
// the refresh token is renewed before it expires.
// It has to be shorter than the refresh token expiration time set in TAS.
const REFRESH_TOKEN_RENEW_INTERVAL = 20 * 60 * 1000; // 20 minutes in ms

/** Manages Salesforce Canvas session data */
export function useCanvasSession<Payload = Record<string, any>>() {
  const urlParams = new URLSearchParams(window.location.search);
  const otk = urlParams.get(ONE_TIME_KEY_PARAM);
  const initialComponentId = urlParams.get(COMPONENT_ID_PARAM);
  const isAuthorized = !!otk;

  const [startTokenRotation, setStartTokenRotation] = useState(false);

  const {
    data: canvasSession,
    isLoading: isQueryLoading,
    isError: isCanvasSessionQueryError,
  } = useQuery({
    queryKey: [otk],
    queryFn: () => getAndStoreTackleSessionFromOtk<Payload>(otk),
    enabled: isAuthorized,
    staleTime: Infinity,
    retry: false,
  });

  // If user does not have OTK, they are not authorized session, so there is no loading state
  const isLoading = isAuthorized ? isQueryLoading : false;
  const accessToken = canvasSession?.access_token;

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartTokenRotation(true);
    }, REFRESH_TOKEN_RENEW_INTERVAL);
    return () => clearTimeout(timer);
  }, []);

  const { isError: isCanvasRenewSessionQueryError } = useQuery({
    queryKey: [otk, 'refresh'],
    queryFn: () => {
      return rotateTokens(getRefreshToken());
    },
    enabled: startTokenRotation,
    staleTime: Infinity,
    retry: false,
    refetchInterval: REFRESH_TOKEN_RENEW_INTERVAL,
  });

  const {
    data: userData,
    isError: isUserDataQueryError,
    isInitialLoading: isLoadingUserData,
    isSuccess: sessionIsLoaded,
  } = useQuery({
    queryKey: ['get-user-data', accessToken],
    queryFn: () => getUserData(accessToken),
    enabled: !!accessToken,
    select: (data) => {
      return {
        permissions: data?.context?.access?.permissions ?? null,
        vendorId: data?.context?.vendorid ?? null,
        vendorType: data?.context?.vendor_type ?? null,
        organizationId: data?.context?.organization_id ?? null,
      };
    },
    staleTime: Infinity,
  });

  const parameters = canvasSession?.context?.parameters;
  const user = canvasSession?.context?.user;

  return {
    isAuthorized,
    getOrRenewAccessToken,
    context: canvasSession?.context,
    isError:
      isCanvasSessionQueryError ||
      isUserDataQueryError ||
      isCanvasRenewSessionQueryError,
    isLoadingCanvasSession: isLoading || isLoadingUserData,
    namespace: parameters?.namespace,
    /** Salesforce object record: if not provided from SF, try to get it from payload */
    record: parameters?.record ?? parameters?.payload?.record,
    records: parameters?.records ?? parameters?.payload?.records,
    organizationId: userData?.organizationId,
    vendorId: userData?.vendorId,
    vendorType: userData?.vendorType,
    canvasClient: canvasSession?.context?.client,
    /** context passed into canvas from other canvas component event */
    user,
    payload: parameters?.payload,
    /** RBAC permissions from tackle auth service */
    permissions: userData?.permissions ?? null,
    /** the SF canvas component you currently rendered */
    currentComponentId: parameters?.componentId,
    /** the SF canvas component that was initially rendered */
    initialComponentId,
    /** the canvas session is loaded and can use token */
    sessionIsLoaded,
    /** The canvas sessions is expired and a new session should be created.
     * This occurs if we get a 401 from calling /api/v1/auth/one-time-keys/renew
     */
    isExpired: isSessionExpired(),
  };
}
