import {
  BuyerDetailsJSON,
  BuyerJSON,
  ContractDurationJSON,
  DimensionJSON,
  DimensionQuantityJSON,
  EULAJSON,
  MsftPrivateOfferJSON,
  OfferStateJSON,
  POActivityJSON,
  PricingJSON,
  SalesforceJSON,
  ScheduleDetailJSON,
  UserToNotifyJSON,
} from '../types/MsftPrivateOfferJSON';
import { Optional } from 'utils/optional/optional';
import {
  Buyer,
  BuyerDetails,
  ContractDuration,
  Dimension,
  DimensionQuantity,
  EULA,
  MarketplaceAcceptanceLink,
  MsftPrivateOffer,
  OfferState,
  POActivity,
  Pricing,
  Salesforce,
  ScheduleDetail,
  UserToNotify,
} from '../types/MsftPrivateOffer';

const toSalesforceJSON = (
  salesforce: Salesforce | null,
): SalesforceJSON | null =>
  Optional.ofNullable(salesforce)
    .map((sf) => ({
      custom_object_id: sf.customObjectId,
      opportunity_id: sf.opportunityId,
    }))
    .orElse(null);

const toActivityJSONs = (activities: POActivity[]): POActivityJSON[] =>
  activities.map((a) => ({
    activity_type: a.activityType,
    slug: a.slug,
    user_id: a.userId,
    created_at: a.createdAt,
    metadata: a.metadata,
  }));

const toBuyerJSONs = (buyers: Buyer[]): BuyerJSON[] =>
  buyers.map((b) => ({
    full_name: b.fullName,
    email_address: b.emailAddress,
    title: b.title,
  }));

const toBuyerDetailsJSON = (
  buyerDetails: BuyerDetails | null,
): BuyerDetailsJSON | null =>
  Optional.ofNullable(buyerDetails)
    .map((bd) => ({
      company_name: bd.companyName,
      billing_account_id: bd.billingAccountId,
      buyers: toBuyerJSONs(bd.buyers || []),
    }))
    .orElse(null);

const toEulaJSON = (eula: EULA | null): EULAJSON | null =>
  Optional.ofNullable(eula)
    .map((e) => ({
      type: e.type,
      files: e.files || [],
    }))
    .orElse(null);

const toUsersToNotifyJSONs = (
  usersToNotify: UserToNotify[],
): UserToNotifyJSON[] =>
  usersToNotify.map((u) => ({
    contact_type: u.contactType,
    value: u.value,
    first_name: u.firstName,
    last_name: u.lastName,
    email: u.email,
  }));

const toContractDurationJSON = (
  cd: ContractDuration,
): ContractDurationJSON => ({
  type: cd.type,
  frequency: cd.frequency,
});

const toScheduleDetailJSONs = (
  schedules: ScheduleDetail[],
): ScheduleDetailJSON[] =>
  schedules.map((s) => ({
    charge_date: s.chargeDate,
    price_per_payment_in_usd: s.pricePerPaymentInUsd,
    note: s.note,
  }));

const toDimensionQuantityJSONs = (
  dimensionQuantities: DimensionQuantity[],
): DimensionQuantityJSON[] =>
  dimensionQuantities.map((dq) => ({
    dimension_term: dq.dimensionTerm,
    unlimited_quantity: dq.unlimitedQuantity,
    quantity: dq.quantity,
  }));

const toDimensionJSON = (dimension: Dimension): DimensionJSON => {
  const included_quantities = toDimensionQuantityJSONs(
    dimension.includedQuantities || [],
  );

  return {
    enabled: dimension.enabled,
    id: dimension.id,
    unit_of_measure: dimension.unitOfMeasure,
    price_per_unit_in_usd: dimension.pricePerUnitInUsd,
    included_quantities,
  };
};

const toDimensionsJsonMap = (dimensions: {
  [k: string]: Dimension;
}): {
  [k: string]: DimensionJSON;
} =>
  Object.entries(dimensions).reduce(
    (acc: { [k: string]: DimensionJSON }, entry: [string, Dimension]) => {
      const key = entry[0];
      const dimension = entry[1];

      acc[key] = toDimensionJSON(dimension);

      return acc;
    },
    {},
  );

const pricingToPricingJSON = (pricing: Pricing): PricingJSON => {
  const contract_duration = Optional.ofNullable(pricing.contractDuration)
    .map(toContractDurationJSON)
    .orElse(null);

  const payment_installments = Optional.ofNullable(pricing.paymentInstallments)
    .map((pi) => ({
      price_per_payment_in_usd: pi.pricePerPaymentInUsd,
    }))
    .orElse(null);

  const payment_schedule = toScheduleDetailJSONs(pricing.paymentSchedule || []);
  const dimensions = toDimensionsJsonMap(pricing.dimensions || {});

  return {
    plan_id: pricing.planId,
    plan_name: pricing.planName,
    description: pricing.description,
    contract_duration,
    payment_model: pricing.paymentModel,
    payment_installments,
    payment_schedule,
    dimensions,
  };
};

const toPricingJSON = (pricing: Pricing | null): PricingJSON | null =>
  Optional.ofNullable(pricing).map(pricingToPricingJSON).orElse(null);

const toOfferStateJSON = (offerState: OfferState): OfferStateJSON => {
  return Optional.ofNullable(offerState)
    .map((os) => {
      const draft = Optional.ofNullable(os.draft)
        .map((d) => ({ raw_message: d.rawMessage }))
        .orElse(null);

      const submitted = Optional.ofNullable(os.submitted)
        .map((s) => ({ raw_message: s.rawMessage }))
        .orElse(null);

      const accepted = Optional.ofNullable(os.accepted)
        .map((a) => ({ raw_message: a.rawMessage }))
        .orElse(null);

      const current_cloud = Optional.ofNullable(os.currentCloud)
        .map((cc) => ({ raw_message: cc.rawMessage }))
        .orElse(null);

      return {
        draft,
        submitted,
        accepted,
        current_cloud,
        last_modified: os.lastModified,
      };
    })
    .orElse(null);
};

export const msftPrivateOfferToMsftPrivateOfferJSON = (
  offer: Partial<MsftPrivateOffer>,
): MsftPrivateOfferJSON => {
  const salesforce = toSalesforceJSON(offer.salesforce);
  const activities = toActivityJSONs(offer.activities || []);
  const buyer_details = toBuyerDetailsJSON(offer.buyerDetails);
  const eula = toEulaJSON(offer.eula);
  const pricing = toPricingJSON(offer.pricing);
  const offer_state = toOfferStateJSON(offer.offerState);
  const users_to_notify = toUsersToNotifyJSONs(offer.usersToNotify || []);

  const marketplace_acceptance_link =
    offer.marketplaceAcceptanceLink as MarketplaceAcceptanceLink;

  return {
    po_id: offer.poId,
    accepted_at: offer.acceptedAt,
    accepted_source: offer.acceptedSource,
    archived_at: offer.archivedAt,
    created_at: offer.createdAt,
    cancelled_at: offer.cancelledAt,
    last_modified_at: offer.lastModifiedAt,
    last_update_source: offer.lastUpdateSource,
    viewed_offer_at: offer.viewedOfferAt,
    sent_at: offer.sentAt,
    submitted_at: offer.submittedAt,
    opened_instructions_at: offer.openedInstructionsAt,
    marketplace_acceptance_link,
    salesforce,
    vendor_id: offer.vendorId,
    vendor_name: offer.vendorName,
    product_contact_email: offer.productContactEmail,
    state: offer.state,
    status: offer.status,
    offer_type: offer.offerType,
    quote_number: offer.quoteNumber,
    marketplace_fee: offer.marketplaceFee,
    activities,
    archived: offer.archived,
    version: offer.version,
    product_ref: offer.productRef,
    product_name: offer.productName,
    source: offer.source,
    salesforce_opportunity_id: offer.salesforceOpportunityId,
    submitted_to_cloud: offer.submittedToCloud,
    marketplace_created_at: offer.marketplaceCreatedAt,
    name: offer.name,
    description: offer.description,
    buyer_details,
    renewal: offer.renewal,
    eula,
    users_to_notify,
    pricing,
    preparer_email: offer.preparerEmail,
    start_on: offer.startOn,
    start_date: offer.startDate,
    end_date: offer.endDate,
    accept_by_date: offer.acceptByDate,
    offer_ref: offer.offerRef,
    offer_state,
    metadata: offer.metadata,
    pre_registration_details: offer.preRegistrationDetails,
    total_contract_value_gross_amount: offer.totalContractValueGrossAmount,
    total_contract_value_net_amount: offer.totalContractValueNetAmount,
  };
};
