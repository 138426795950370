import { useQuery } from '@tanstack/react-query';
import { crmConnectorSalesforceApi } from 'packages/tackle-api';
import { ConnectionDetailsResponse } from '../../../api/requests/getTackleConnectionDetails';
import { useCanvasSession } from '../../useCanvasSession';

export function useSalesforceConnectionData() {
  const canvasSessionHook = useCanvasSession();

  const fetchSalesforceData = async (): Promise<ConnectionDetailsResponse> => {
    const accessToken = await canvasSessionHook.getOrRenewAccessToken();
    if (!accessToken) {
      throw new Error('Access token is required to fetch Salesforce data.');
    }

    const response = await crmConnectorSalesforceApi
      .get('connection-details')
      .json<ConnectionDetailsResponse>();
    return response;
  };

  const {
    data: salesforceData,
    isLoading: dataLoading,
    error: dataError,
  } = useQuery<ConnectionDetailsResponse, Error>({
    queryKey: ['salesforceData', canvasSessionHook.isAuthorized],
    queryFn: fetchSalesforceData,
    enabled: canvasSessionHook.isAuthorized,
    retry: 1,
    staleTime: Infinity,
  });

  return {
    canvasSessionHook,
    salesforceData,
    dataLoading,
    dataError,
  };
}
