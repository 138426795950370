import { useRbac, Permission } from 'utils/rbac';

const requiredFormDataRights = [
  'cosell:GetPicklistOptions',
  'cosell:ListSolutions',
] as const;

const baseUpdateOpportunityRights = [
  'cosell:GetOpportunity',
  ...requiredFormDataRights,
] as const;

/** Mapping of cosell rights based derived from one or more
 *  RBAC rights.
 */
export const rightsMap = {
  acceptInvitation: ['cosell:AcceptInvitation'],
  createOpportunity: [
    'cosell:CreateOpportunity',
    'cosell:ApplyMapping',
    ...requiredFormDataRights,
  ],
  rejectInvitation: ['cosell:RejectInvitation'],
  startEngagement: ['cosell:StartEngagement', 'cosell:UpdateOpportunity'],
  updateDraftOpportunity: [
    ...baseUpdateOpportunityRights,
    'cosell:UpdateDraftOpportunity',
  ],
  updateOpportunity: [
    ...baseUpdateOpportunityRights,
    'cosell:UpdateOpportunity',
  ],
  viewInvitations: [
    'cosell:GetInvitation',
    'cosell:ListInvitations',
    'cosell:ListInvitationEvents',
  ],
  viewOpportunities: [
    'cosell:GetOpportunity',
    'cosell:GetSettings',
    'cosell:ListOpportunities',
    'cosell:ListOpportunityEvents',
  ],
};

/** Hook to return formatted cosell rights derived from compound RBAC rights */
export const useHasCoSellRbacRights = () => {
  const { hasAllPermissions } = useRbac();
  const granularRights = Object.entries(rightsMap).reduce(
    (acc, [cosellPermission, rights]) => {
      acc[cosellPermission as keyof typeof rightsMap] = hasAllPermissions(
        rights as Permission[],
      );
      return acc;
    },
    {} as Record<keyof typeof rightsMap, boolean>,
  );

  const { updateDraftOpportunity, ...accessAllEntries } = granularRights;

  return {
    accessAll: Object.values(accessAllEntries).every(Boolean),
    ...granularRights,
  };
};
