import { AwsPrivateOffer } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { Box, Grid } from 'vendor/material';
import { Divider } from 'vendor/material/index';
import { Button } from '@tackle-io/platform-ui';
import { useState } from 'react';
import { useRbac } from 'utils/rbac';
import StartMSFTOffer from 'packages/salesforce-canvas/offers/StartMSFTOffer';

const AZUREOffersForOpportunity = ({
  azureOffers,
}: {
  azureOffers: AwsPrivateOffer[];
}) => {
  const { hasPermission } = useRbac();
  const [startingNewMSFTOffer, setStartingNewMSFTOffer] = useState(false);

  if (startingNewMSFTOffer) {
    return <StartMSFTOffer onCancel={() => setStartingNewMSFTOffer(false)} />;
  }

  return (
    <Box height="100%">
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        {hasPermission('offers:CreateDraftOffer') && (
          <>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <Button
                fullWidth
                variant="outlined"
                appearance="primary"
                onClick={() => setStartingNewMSFTOffer(true)}
              >
                Add Azure offer
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default AZUREOffersForOpportunity;
