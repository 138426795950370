import { Switch, Route, useRouteMatch } from 'react-router';
import Error from 'pages/Error/Error';
import CreateReferralPage from './CreateReferralPage';
import EditReferralPage from './EditReferralPage';
import ViewReferral from './ViewReferralPage';
import { routeMap } from './constants';

const MicrosoftRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        component={CreateReferralPage}
        path={`${path}/${routeMap.createReferral}`}
        exact
      />
      <Route
        component={ViewReferral}
        path={`${path}/${routeMap.viewReferral}`}
        exact
      />
      <Route
        component={EditReferralPage}
        path={`${path}/${routeMap.editReferral}`}
        exact
      />
      <Route component={Error} />
    </Switch>
  );
};

export default MicrosoftRouter;
