import { ErrorBoundary, Page } from 'components';
import useStyles from './CoSellLandingPage.styles';
import { useDocumentTitle } from 'hooks';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import { CoSellHeader, CoSellCloudMenu, CoSellTabs } from './components';
import { DisplayCloudType } from '../../types/enums';
import CoSellDateFilterWarning from './components/CoSellDateFilterWarning/CoSellDateFilterWarning';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { START_DATE_URL_KEY, TAB_KEY } from './helpers/urlParamsHelper';

const CoSellLandingPage = () => {
  const classes = useStyles();

  useDocumentTitle('Co-Sell opportunities');
  const location = useLocation();

  const hasOpportunityDateFilter = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const startDate = searchParams.get(START_DATE_URL_KEY);
    const tab = searchParams.get(TAB_KEY);
    return !!startDate && tab !== 'invitations';
  }, [location]);

  return (
    <CoSellLandingPageContainer>
      <Page className={classes.container}>
        <CoSellHeader />
        <CoSellCloudMenu />
        {hasOpportunityDateFilter && <CoSellDateFilterWarning />}
        <CoSellTabs />
      </Page>
    </CoSellLandingPageContainer>
  );
};

interface CoSellLandingPageContainerProps {
  vendorIdFromCanvas?: string;
  tokenFromCanvas?: string;
  opportunityId?: string;
  cloud?: DisplayCloudType;
  children?: React.ReactElement;
}

export const CoSellLandingPageContainer = ({
  children,
}: CoSellLandingPageContainerProps) => {
  return (
    <ErrorBoundary
      renderError={() => <ErrorPage title="Co-sell opportunities" />}
    >
      {children}
    </ErrorBoundary>
  );
};

export default CoSellLandingPage;
