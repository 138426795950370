import {
  getUtcDateTimeFromSerializedIsoDateTime,
  startOfTodayAsUtcDatetime,
} from 'pages/PrivateOffers/pages/Next/generic/utils/date/dateUtils';
import { PaymentModel } from 'pages/PrivateOffers/pages/Next/generic/api/types/AwsPrivateOffer';
import { TackleOffer } from '../../types/TackleOffer';

const expirationDateIsBeforeToday = (offerExpirationAt: string): boolean => {
  const today = startOfTodayAsUtcDatetime().startOf('day');
  const expiresAt =
    getUtcDateTimeFromSerializedIsoDateTime(offerExpirationAt).startOf('day');

  return expiresAt < today;
};

export const isOfferExpired = (offer: TackleOffer): boolean =>
  !offer.acceptedAt &&
  offer.offerExpirationAt &&
  expirationDateIsBeforeToday(offer.offerExpirationAt);

export const paymentModelResourceText: { [pm: string]: string } = {
  [PaymentModel.PayGo]: 'Pay as you go',
  [PaymentModel.PaymentSchedule]: 'Payment schedule',
  [PaymentModel.PerProduct]: 'Upfront pricing',
};

export const byAcceptanceDateDescending = (
  o1: TackleOffer,
  o2: TackleOffer,
) => {
  const o1AcceptanceDate = getUtcDateTimeFromSerializedIsoDateTime(
    o1.acceptedAt,
  );
  const o2AcceptanceDate = getUtcDateTimeFromSerializedIsoDateTime(
    o2.acceptedAt,
  );

  return o1AcceptanceDate && o2AcceptanceDate
    ? o1AcceptanceDate > o2AcceptanceDate
      ? -1
      : 1
    : o1AcceptanceDate
    ? -1
    : 1;
};
