import { WidgetContainer } from '../components/WidgetContainer';
import { useHasCoSellRbacRights } from 'packages/cosell/src/hooks/useHasCoSellRbacRights/useHasCoSellRbacRights';
import CoSellCallToAction from '../components/CoSellZeroStateView/CoSellZeroStateView';
import CoSellLimitedPermissionBanner from 'packages/cosell/src/pages/UnifiedInvitationDetails/AceInvitationDetails/components/CoSellLimitedPermissionBanner';
import {
  CloudDisplayLabelLookup,
  DisplayCloudType,
} from 'packages/cosell/src/types/enums';

export default function MicrosoftCoSells({ crmId }: { crmId: string }) {
  const rbacRights = useHasCoSellRbacRights();
  const canCreateCoSell = rbacRights.createOpportunity;

  /** TODO: derive value from get referrals request data */
  const hasCoSells = false;

  if (!hasCoSells) {
    return (
      <CoSellCallToAction
        canCreateCoSell={canCreateCoSell}
        singleCloud={DisplayCloudType.MICROSOFT}
      />
    );
  }

  return (
    <WidgetContainer>
      <WidgetContainer.Content>
        {rbacRights.accessAll && <CoSellLimitedPermissionBanner />}
        <div
          style={{
            display: 'grid',
            gap: '0',
            textAlign: 'center',
            placeContent: 'center',
          }}
        >
          <h1>{CloudDisplayLabelLookup[DisplayCloudType.MICROSOFT]} Co-sell</h1>
          <p>coming soon!</p>
        </div>
      </WidgetContainer.Content>
    </WidgetContainer>
  );
}
