import Lottie, { LottieComponentProps } from 'lottie-react';
import animationData from './tackle-coming-soon-animation.json';

const ComingSoonAnimation = (
  props: Omit<LottieComponentProps, 'animationData'>,
) => (
  <Lottie
    animationData={animationData}
    loop={false}
    style={{ width: '100%' }}
    {...props}
  />
);

export default ComingSoonAnimation;
