export enum coSellDetailsPageDataId {
  SYNC_STATUS_ICON = 'co_sell_sync_status',
  CANCEL_EDIT_MODAL = 'co_sell_cancel_edit_modal',
  SUBMIT_EDIT_MODAL = 'co_sell_submit_edit_modal',
  EDIT_OPPORTUNITY = 'co_sell_edit_opportunity',
  SUBMIT_TO_CLOUD = 'co_sell_submit_to_cloud',
  LAUNCH_OPPORTUNITY = 'co_sell_launch_opportunity',
  CLOSE_LOST = 'co_sell_close_lost',
  ACCEPT_OPPORTUNITY = 'co_sell_accept_opportunity',
  REJECT_OPPORTUNITY = 'co_sell_reject_opportunity',
  REFRESH_OPPORTUNITY = 'co_sell_refresh_opportunity',
  CONTACT_CLOUD_PARTNER = 'co_sell_contact_cloud_partner',
  NEXT_STEPS_SECTION_HEADER = 'co_sell_next_steps_section_header',
  RECEIVER_RESPONSIBILITIES = 'co_sell_receiver_responsibilities',
  ACCEPT_INVITATION = 'co_sell_accept_invitation',
  ACCEPT_INVITATION_SF_DISABLED = 'co_sell_accept_invitation_salesforce_disabled',
  REJECT_INVITATION = 'co_sell_reject_invitation',
  VIEW_OPPORTUNITY = 'co_sell_view_opportunity',
  VIEW_SALESFORCE_OPPORTUNITY = 'co_sell_invitation_view_salesforce_opportunity',
  CANCEL_ACCEPT_INVITATION_MODAL = 'co_sell_cancel_accept_invitation_modal',
  SUBMIT_ACCEPT_INVITATION_MODAL = 'co_sell_submit_accept_invitation_modal',
}

export enum coSellLandingPageDataId {
  CREATE_BUTTON = 'co_sell_create_button',
  OPPORTUNITIES_TABLE_HEADER_ROW = 'co_sell_opportunities_table_header_row',
  OPPORTUNITIES_SEARCH = 'co_sell_opportunities_search',
  INVITATIONS_TABLE_HEADER_ROW = 'co_sell_invitations_table_header_row',
  INVITATIONS_SEARCH = 'co_sell_invitations_search',
  TABS = 'co_sell_tabs',
  OPPORTUNITIES_TAB = 'co_sell_opportunities_tab',
  INVITATIONS_TAB = 'co_sell_invitations_tab',
  INVITATIONS_PROJECT_NAME_HEADER = 'co_sell_invitations_project_name_header',
  INVITATIONS_CUSTOMER_HEADER = 'co_sell_invitations_customer_header',
  INVITATIONS_STATUS_HEADER = 'co_sell_invitations_status_header',
  INVITATIONS_DATE_RECEIVED_HEADER = 'co_sell_invitations_date_received_header',
  INVITATIONS_EXPIRATION_DATE_HEADER = 'co_sell_invitations_expiration_date_header',
  INVITATIONS_SYNC_STATUS_HEADER = 'co_sell_invitations_sync_status_header',
  OPPORTUNITIES_PROJECT_NAME_HEADER = 'co_sell_opportunities_project_name_header',
  OPPORTUNITIES_CUSTOMER_HEADER = 'co_sell_opportunities_customer_header',
  OPPORTUNITIES_CO_SELL_STATUS_HEADER = 'co_sell_iopportunities_co_sell_status_header',
  OPPORTUNITIES_CO_SELL_STAGE_HEADER = 'co_sell_iopportunities_co_sell_stage_header',
  OPPORTUNITIES_DATE_CREATED_HEADER = 'co_sell_iopportunities_date_created_header',
  OPPORTUNITIES_SYNC_STATUS_HEADER = 'co_sell_iopportunities_sync_status_header',
  FILTER_PANEL_BUTTON = 'co_sell_filter_panel_button',
  FILTER_TAGS = 'co_sell_filter_tags',
  PAGINATION = 'co_sell_pagination',
  DATE_TIME_RANGE_FILTER = 'co_sell_date_time_range_filter',
}

export enum coSellCreateEditPageDataId {
  SAVE_DRAFT = 'co_sell_save_draft',
  SUBMIT_TO_CLOUD = 'co_sell_submit_to_cloud',
  CANCEL = 'co_sell_cancel',
  UPDATE_SUBMIT_TO_CLOUD = 'co_sell_update_submit_to_cloud',
  CO_SELL_STAGE_SECTION_HEADER = 'co_sell_stage_section_header',
  CUSTOMER_DETAILS_SECTION_HEADER = 'co_sell_customer_details_section_header',
  PROJECT_DETAILS_SECTION_HEADER = 'co_sell_project_details_section_header',
  MARKETING_DETAILS_SECTION_HEADER = 'co_sell_marketing_details_section_header',
  ADDITIONAL_DETAILS_SECTION_HEADER = 'co_sell_additional_details_section_header',
  CUSTOMER_CONTACT_SECTION_HEADER = 'co_sell_customer_contact_section_header',
  PARTNER_CONTACT_SECTION_HEADER = 'co_sell_partner_contact_section_header',
}

// These data-ids are used for the form fields in the Create/Edit page and Details page
export enum coSellAceOpportunityFormFieldsDataId {
  EXPECTED_CUSTOMER_SPEND = 'co_sell_expected_customer_spend',
  TARGET_CLOSE_DATE = 'co_sell_target_close_date',
  SOLUTIONS_OFFERED = 'co_sell_solutions_offered',
  SOLUTIONS_OFFERED_TOGGLE = 'co_sell_solutions_offered_toggle',
  ADDITIONAL_COMMENTS = 'co_sell_additional_comments',
  MARKETPLACE_OFFER_ID = 'co_sell_marketplace_offer_id',
  REASON_FOR_CLOSING = 'co_sell_reason_for_closing',
  PROCUREMENT_TYPE = 'co_sell_procurement_type',
  CUSTOMER_SOFTWARE_VALUE = 'co_sell_customer_software_value',
  CURRENCY_CODE = 'co_sell_currency_code',
  CONTRACT_START_DATE = 'co_sell_contract_start_date',
  CONTRACT_END_DATE = 'co_sell_contract_end_date',
  CUSTOMER_DUNS_NUMBER = 'co_sell_customer_duns_number',
  CUSTOMER_COMPANY_NAME = 'co_sell_customer_company_name',
  INDUSTRY = 'co_sell_industry',
  INDUSTRY_OTHER = 'co_sell_industry_other',
  CUSTOMER_WEBSITE = 'co_sell_customer_website',
  POSTAL_CODE = 'co_sell_postal_code',
  CITY = 'co_sell_city',
  ADDRESS = 'co_sell_address',
  PRIMARY_NEED_FROM_AWS = 'co_sell_primary_need_from_aws',
  PRIMARY_NEEDS_FROM_AWS = 'co_sell_primary_needs_from_aws',
  PARENT_CO_SELL_ID = 'co_sell_parent_co_sell_id',
  PROJECT_TITLE = 'co_sell_project_title',
  SALES_ACTIVITIES = 'co_sell_sales_activities',
  CUSTOMER_BUSINESS_PROBLEM = 'co_sell_customer_business_problem',
  OTHER_SOLUTION_OFFERED = 'co_sell_other_solution_offered',
  NEXT_STEP = 'co_sell_next_step',
  DELIVERY_MODEL = 'co_sell_delivery_model',
  APN_PROGRAMS = 'co_sell_apn_programs',
  EXPECTED_CUSTOMER_SPEND_AMOUNT = 'co_sell_expected_customer_spend_amount',
  EXPECTED_CUSTOMER_SPEND_CURRENCY_CODE = 'co_sell_expected_customer_spend_currency_code',
  EXPECTED_CUSTOMER_SPEND_FREQUENCY = 'co_sell_expected_customer_spend_frequency',
  EXPECTED_CUSTOMER_SPEND_TARGET_COMPANY = 'co_sell_expected_customer_spend_target_company',
  MARKETING_CAMPAIGN = 'co_sell_marketing_campaign',
  MARKETING_ACTIVITY_USE_CASE = 'co_sell_marketing_activity_use_case',
  MARKETING_ACTIVITY_CHANNEL = 'co_sell_marketing_activity_channel',
  OTHER_COMPETITORS = 'co_sell_other_competitors',
  AWS_ACCOUNT_ID = 'co_sell_aws_account_id',
  CUSTOMER_FIRST_NAME = 'co_sell_customer_first_name',
  CUSTOMER_LAST_NAME = 'co_sell_customer_last_name',
  CUSTOMER_TITLE = 'co_sell_customer_title',
  CUSTOMER_EMAIL = 'co_sell_customer_email',
  CUSTOMER_PHONE = 'co_sell_customer_phone',
  PRIMARY_CONTACT_FIRST_NAME = 'co_sell_primary_contact_first_name',
  PRIMARY_CONTACT_LAST_NAME = 'co_sell_primary_contact_last_name',
  PRIMARY_CONTACT_TITLE = 'co_sell_primary_contact_title',
  PRIMARY_CONTACT_EMAIL = 'co_sell_primary_contact_email',
  PRIMARY_CONTACT_PHONE = 'co_sell_primary_contact_phone',
  OPPORTUNITY_SOURCE = 'cosell_is_opportunity_from_marketing_activity',
  CO_SELL_STAGE = 'co_sell_co_sell_stage',
  COUNTRY_CODE = 'co_sell_country_code',
  STATE_PROVINCE = 'co_sell_state_province',
  CUSTOMER_USE_CASE = 'co_sell_customer_use_case',
  COMPETITIVE_TRACKING = 'co_sell_competitive_tracking',
  AWS_PRODUCTS = 'co_sell_aws_products',
  MARKETING_DEVELOPMENT_FUNDS = 'co_sell_marketing_development_funds',
  NATIONAL_SECURITY = 'co_sell_national_security',
  PARTNER_NEED_TYPE = 'co_sell_partner_need_type',
  INVOLVEMENT_TYPE = 'co_sell_involvement_type',
  VISIBILITY = 'co_sell_visibility',
  OPPORTUNITY_TYPE = 'co_sell_opportunity_type',
  PARTNER_CRM_UNIQUE_IDENTIFIER = 'co_sell_partner_crm_unique_identifier',
  REJECTION_REASON = 'co_sell_rejection_reason',
  AWS_ENGAGEMENT_SCORE = 'co_sell_engagement_score',
}

// These data-ids are used in Invitation details page
export enum coSellAceInvitationDetailsDataId {
  TARGET_COMPLETION_DATE = 'co_sell_target_completion_date', // Used only in invitation details
}

// These data-ids are used when it may not be limited to one page
export enum coSellCommonDataId {
  CONFIRM_DELETE_OPPORTUNITY = 'cosell_delete_modal_confirm_button',
  CANCEL_DELETE_OPPORTUNITY = 'cosell_delete_modal_cancel_button',
  EXPAND_FORM_NEXT_STEPS_BUTTON = 'cosell_form_expand_next_steps_button',
}
