import { useParams } from 'react-router';
import ReferralForm from './ReferralForm/ReferralForm';

const CreateReferralPage = () => {
  const { crmId } = useParams<{ crmId: string }>();

  console.info('create referral with crmId', crmId);
  return <ReferralForm />;
};

export default CreateReferralPage;
