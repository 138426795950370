import React, { SetStateAction, Dispatch } from 'react';
import { Box } from 'vendor/material';
import { EditModal, SharedHeader } from 'packages/cosell/src/components';
import AceOpportunityFormFields from '../UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormFields';
import { setNestedObjectValues, useFormikContext } from 'formik';
import { UpdateAceOpportunityFormValues } from '../UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import { AceOpportunityResponse } from '../../types/responses/AceOpportunityResponse';
import { EditModalType } from './RightRailButtonSection';
import AceOpportunityDetailsHeader from '../../pages/UnifiedOpportunityDetails/AceOpportunityDetails/detailSections/AceOpportunityDetailsHeader/AceOpportunityDetailsHeader';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import { UnifiedOpportunityFormValues } from '../UnifiedOpportunityForm';

export const RightRailEditModalContent: React.FC<{
  editModalType: EditModalType;
  setEditModalOpen: Dispatch<SetStateAction<EditModalType | null>>;
  opportunity: AceOpportunityResponse;
  handleSubmitAceOpportunityToCloud: (
    values: UpdateAceOpportunityFormValues,
  ) => Promise<void>;
}> = ({
  editModalType,
  setEditModalOpen,
  opportunity,
  handleSubmitAceOpportunityToCloud,
}) => {
  const { setTouched, values, validateForm } =
    useFormikContext<UnifiedOpportunityFormValues>();

  const validateBeforeSubmit = async () => {
    const formErrors = await validateForm();
    if (Object.keys(formErrors).length > 0) {
      setTouched(setNestedObjectValues(formErrors, true));
    } else {
      handleSubmitAceOpportunityToCloud(values);
    }
  };

  const reviewStatus = opportunity.lifeCycle?.reviewStatus ?? null;

  const getModalTitle = () => {
    switch (editModalType) {
      case EditModalType.EDIT_LAUNCHED:
        return 'Edit launched co-sell opportunity';
      case EditModalType.LAUNCH:
        return (
          <SharedHeader
            title="Launch co-sell"
            cloud={DisplayCloudType.AWS}
            subtitle="Confirm the following information before you launch."
          />
        );
      case EditModalType.CLOSE_LOST:
        return (
          <SharedHeader
            title="Close lost co-sell"
            cloud={DisplayCloudType.AWS}
          />
        );
      default:
        return 'Edit opportunity';
    }
  };
  const getSubmitLabel = () => {
    switch (editModalType) {
      case EditModalType.LAUNCH:
        return 'Launch';
      case EditModalType.CLOSE_LOST:
        return 'Close lost';
      default:
        return 'Submit';
    }
  };

  return (
    <EditModal
      open={true}
      onClose={() => setEditModalOpen(null)}
      onSubmit={validateBeforeSubmit}
      title={getModalTitle()}
      submitLabel={getSubmitLabel()}
      submitAppearance={
        editModalType === EditModalType.CLOSE_LOST ? 'destructive' : 'primary'
      }
    >
      <div>
        {editModalType === EditModalType.EDIT_LAUNCHED && (
          <Box mt={2} mb={3}>
            <AceOpportunityDetailsHeader
              title={opportunity.customer?.account?.companyName}
              subtitle={opportunity.project?.title}
              source={opportunity.source}
              lifeCycle={opportunity.lifeCycle}
              crmId={opportunity.metadata?.crmId}
            />
          </Box>
        )}
        <AceOpportunityFormFields
          editModalType={editModalType}
          reviewStatus={reviewStatus}
        />
      </div>
    </EditModal>
  );
};
