import React, { MouseEvent, ReactNode } from 'react';
import { Modal, Button } from '@tackle-io/platform-ui';
import { useStyles } from './EditModal.styles';
import { useFormikContext } from 'formik';
import { coSellDetailsPageDataId } from '../../utilities/intercomEnums';
import { UpdateAceOpportunityFormValues } from '../UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import { UnifiedOpportunityFormValues } from '../UnifiedOpportunityForm';
import { Typography } from 'vendor/material';
interface EditModalProps {
  children: JSX.Element;
  onClose?: (event: MouseEvent) => void;
  onSubmit: (values: UnifiedOpportunityFormValues) => Promise<void>;
  open?: boolean;
  title: ReactNode;
  cancelLabel?: string;
  submitLabel?: string;
  submitAppearance?:
    | 'primary'
    | 'white'
    | 'disabled'
    | 'secondary'
    | 'destructive'
    | 'success';
  submitButtonLoading?: boolean;
  submitButtonDisabled?: boolean;
}

export const EditModal: React.FC<EditModalProps> = ({
  children,
  onClose,
  onSubmit,
  cancelLabel = 'Cancel',
  submitLabel = 'Submit',
  open = false,
  title,
  submitButtonLoading,
  submitButtonDisabled,
  submitAppearance = 'primary',
}) => {
  const classes = useStyles();
  const { values } = useFormikContext<UpdateAceOpportunityFormValues>();

  /** If the prop title is a React component, no need to add editModalTitle style */
  const titleWithStyle = React.isValidElement(title) ? (
    title
  ) : (
    <Typography className={classes.editModalTitle}>{title}</Typography>
  );

  return (
    <Modal
      data-testid={'cosell_confirm_modal'}
      title={titleWithStyle}
      width="medium"
      paperClasses={classes.modal}
      open={open}
      onClose={onClose}
      footerActions={
        <>
          <Button
            id={coSellDetailsPageDataId.CANCEL_EDIT_MODAL}
            appearance="secondary"
            variant="outlined"
            onClick={onClose}
            disabled={submitButtonLoading}
            className={classes.cancelButton}
          >
            {cancelLabel}
          </Button>
          <Button
            id={coSellDetailsPageDataId.SUBMIT_EDIT_MODAL}
            appearance={submitAppearance}
            className={classes.submitButton}
            onClick={() => onSubmit(values)}
            loading={submitButtonLoading}
            disabled={submitButtonLoading || submitButtonDisabled}
          >
            {submitLabel}
          </Button>
        </>
      }
    >
      {children}
    </Modal>
  );
};
