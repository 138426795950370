import React from 'react';
import { Tag } from '@tackle-io/platform-ui';
import { Button, Box, Grid, Typography, makeStyles } from 'vendor/material';
import { useSalesforceTableStyles } from './ConnectionDetailsTable.style';
import ConnectionTableHeader from './ConnectionTableHeader';
import ConnectionStatus from './ConnectionStatus';
import { ConnectionData } from './ConnectionType';
import SalesforceDisconnectButton from 'components/TackleForSalesforce/DisconnectButton';
import openInNew from '../Assets/open_in_new.svg';

const useStyles = makeStyles(() => ({
  disconnectButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: '24px',
  },
}));

interface SalesforceConnectionViewProps {
  connectionStatus: string;
  data: ConnectionData;
  isConnected: boolean;
  disconnected?: boolean;
  isNewlyConnected: boolean;
  openSalesforceOAuthPopUp?: () => void;
  onDisconnect: (
    closeModal: () => void,
    instanceUrl: string,
  ) => Promise<boolean>;
  isSalesforceDataLoading?: boolean;
  hasDisconnectPermission: boolean;
}

const SalesforceConnectionView: React.FC<SalesforceConnectionViewProps> = ({
  connectionStatus,
  data,
  disconnected,
  isConnected,
  isNewlyConnected,
  isSalesforceDataLoading,
  openSalesforceOAuthPopUp,
  onDisconnect,
  hasDisconnectPermission,
}) => {
  const tableClasses = useSalesforceTableStyles();
  const classes = useStyles();

  return (
    <>
      <Box className={tableClasses.tableContainer}>
        <ConnectionTableHeader headerType="salesforce" />
        <Grid container>
          <Grid
            item
            xs={3}
            className={`${tableClasses.cell} ${tableClasses.alignUnderHeader}`}
          >
            <a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              className={tableClasses.link}
            >
              <Box display="flex" alignItems="center">
                {data.organization.name}
                <img
                  src={openInNew}
                  alt="Open in new tab"
                  className={tableClasses.externalIcon}
                />
              </Box>
            </a>
          </Grid>
          <Grid
            item
            xs={4}
            className={`${tableClasses.cell} ${tableClasses.user}`}
          >
            <Typography variant="body2">{data.user.email}</Typography>
            <Typography variant="caption" className={tableClasses.timestamp}>
              {new Date(data.createdAt).toLocaleString()}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            className={`${tableClasses.cell} ${tableClasses.statusCell}`}
          >
            <ConnectionStatus
              status={
                isNewlyConnected
                  ? connectionStatus
                  : isConnected && !disconnected
                  ? data.status
                  : 'not connected'
              }
            />
            <Typography variant="caption" className={tableClasses.timestamp}>
              {new Date(data.updatedAt).toLocaleString()}
            </Typography>
          </Grid>
          <Grid item xs={3} className={tableClasses.cell}>
            <Tag className={tableClasses.environmentTag}>
              {data.organization.isSandbox ? 'Sandbox' : 'Production'}
            </Tag>
          </Grid>

          {!isNewlyConnected &&
            (!isConnected || disconnected) &&
            !isSalesforceDataLoading &&
            openSalesforceOAuthPopUp && (
              <Grid item xs={12}>
                <Box className={tableClasses.cell} textAlign="center">
                  <Button
                    variant="contained"
                    className={tableClasses.connectButton}
                    onClick={openSalesforceOAuthPopUp}
                  >
                    Connect to Tackle
                  </Button>
                </Box>
              </Grid>
            )}
        </Grid>
      </Box>

      {isConnected && !disconnected && (
        <Box className={classes.disconnectButtonContainer}>
          <SalesforceDisconnectButton
            disableWithPermissionCheck={!hasDisconnectPermission}
            onDisconnect={async (closeModal) =>
              onDisconnect(closeModal, data.instanceUrl)
            }
          />
        </Box>
      )}
    </>
  );
};

export default SalesforceConnectionView;
