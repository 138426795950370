import {
  DateFormField,
  MultiSelectFormField,
  TextFormField,
} from 'packages/cosell/src/components';
import { isFieldEditable } from './helpers';
import { coSellAceOpportunityFormFieldsDataId } from 'packages/cosell/src/utilities/intercomEnums';
import { useAceVendorConfig } from 'packages/cosell/api/hooks/useAceVendorConfig';
import { AwsEnumListResponse } from 'packages/cosell/src/types/responses/AwsEnumResponse';

type StatusType = Parameters<typeof isFieldEditable>[0]['status'];

// The following fields are shared with the Edit Modal
export const TargetCloseDate = ({ status }: { status: StatusType }) => (
  <DateFormField
    name="targetCloseDate"
    label="Target close date"
    data-id={coSellAceOpportunityFormFieldsDataId.TARGET_CLOSE_DATE}
    required
    disabled={
      !isFieldEditable({
        name: 'targetCloseDate',
        status,
      })
    }
  />
);

export const Solutions = ({ status }: { status: StatusType }) => {
  const { solutionsList } = useAceVendorConfig();

  return (
    <MultiSelectFormField
      fieldId="solutions"
      label="Solutions"
      dataId={coSellAceOpportunityFormFieldsDataId.SOLUTIONS_OFFERED}
      options={solutionsList || []}
      disabled={
        !isFieldEditable({
          name: 'solutions',
          status,
        })
      }
      required
      helperText="Choose up to 10 partner solutions that you're offering to this customer."
      filterSelectedOptions={true}
    />
  );
};

/** In api, this is project.deliveryModels */
export const DeliveryModels = ({
  awsEnumListMap,
  disabled,
}: {
  awsEnumListMap: AwsEnumListResponse;
  disabled?: boolean;
}) => {
  return (
    <MultiSelectFormField
      fieldId="deliveryModels"
      filterSelectedOptions={true}
      label="Delivery model"
      dataId={coSellAceOpportunityFormFieldsDataId.DELIVERY_MODEL}
      options={awsEnumListMap?.['project.deliveryModels[]'] || []}
      helperText="Indicate one or more of the most applicable deployment or consumption model for your solution or service."
      required
      disabled={disabled}
    />
  );
};
export const AdditionalComments = ({ status }: { status: StatusType }) => (
  <TextFormField
    name="additionalComments"
    label="Additional comments"
    dataId={coSellAceOpportunityFormFieldsDataId.ADDITIONAL_COMMENTS}
    multiline
    rows={4}
    helperText="Enter project description, opportunity details, customer pain points, customer needs, etc."
    maxLength={255}
    disabled={
      !isFieldEditable({
        name: 'additionalComments',
        status,
      })
    }
  />
);
