import { Box, Grid, Typography, makeStyles, Link } from 'vendor/material';
import { Donut } from '@tackle-io/platform-ui';
import { fontWeightMediumLarge } from 'utils/fontWeightConstants';

const useStyles = makeStyles((theme) => ({
  nav: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.NEUTRAL500,
    fontWeight: fontWeightMediumLarge,
    lineHeight: theme.typography.pxToRem(12),
    letterSpacing: theme.typography.pxToRem(0.3),
    marginBottom: theme.spacing(2),
  },
  link: {
    color: 'currentColor',
    transition: theme.transitions.create(['color', 'text-decoration']),
    '&:focus': {
      outline: `2px solid currentColor`,
      outlineOffset: '2px',
      borderRadius: '2px',
    },
    '&:focus:not(:focus-visible)': {
      outline: 'none',
    },
  },
  navItem: {
    color: theme.palette.BLUE400,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

const QuickLinks = ({
  sections,
}: {
  sections: { label: string; id: string; progress: number }[];
}) => {
  const classes = useStyles();

  return (
    <Box paddingX={2} paddingY={4}>
      <Typography component="h2" className={classes.title}>
        Quick links
      </Typography>
      <Grid
        component="nav"
        aria-label="Quick navigation links"
        role="navigation"
        container
        spacing={2}
      >
        {sections.map((section) => (
          <Grid
            item
            xs={12}
            className={classes.navItem}
            key={section.id}
            aria-valuenow={section.progress}
            aria-valuemin={0}
            aria-valuemax={100}
            aria-label={`${section.label} section completion progress`}
            role="progressbar"
          >
            <Donut
              size={16}
              percentage={section.progress}
              activeColor="currentColor"
              backgroundColor="#DFE1E6"
            />
            <Link
              className={classes.link}
              href={`#${section.id}`}
              aria-current={
                window.location.hash === `#${section.id}`
                  ? 'location'
                  : undefined
              }
              role="link"
              aria-describedby={`progress-${section.id}`}
            >
              {section.label}
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default QuickLinks;
