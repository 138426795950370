import React from 'react';
import { Box, Grid } from 'vendor/material';
import { Page } from 'components';
import NextStepsCard from './detailSections/NextStepsCard/NextStepsCard';
import AceCustomerDetails from './detailSections/AceCustomerDetails/AceCustomerDetails';
import AceProjectDetails from './detailSections/AceProjectDetails/AceProjectDetails';
import AceMarketingDetails from './detailSections/AceMarketingDetails/AceMarketingDetails';
import AceAdditionalDetails from './detailSections/AceAdditionalDetails/AceAdditionalDetails';
import AceContactDetails from './detailSections/AceContactDetails/AceContactDetails';
import AcePartnerDetails from './detailSections/AcePartnerDetails/AcePartnerDetails';
import { AceOpportunityResponse } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import RightRailCard from './rightRail/RightRailCard';
import useStyles from './AceOpportunityDetails.style';
import { AceOpportunityStageEnum } from 'packages/cosell/src/types/enums';
import { Divider } from '../../../../../../vendor/material';
import { CoSellActivityFeed } from '../../../components/CoSellActivityFeed/CoSellActivityFeed';
import { AceCoSellErrorMessagesBanner } from '../../AceCoSellErrorMessagesBanner';
import AceOpportunityDetailsHeader from './detailSections/AceOpportunityDetailsHeader/AceOpportunityDetailsHeader';
import useOpportunityEventsQuery from 'packages/cosell/src/hooks/useOpportunityEventsQuery/useOpportunityEventsQuery';
import { isEmpty } from 'lodash-es';
import UnlinkedCrmIdWarning from './detailSections/UnlinkedCrmIdWarning/UnlinkedCrmIdWarning';
import { useCoSellContext } from 'packages/cosell/src/CoSellContextProvider';

interface AceSingleOpportunityProps {
  opportunity: AceOpportunityResponse;
  opportunityId: string;
  requiresSaasDocumentationToLaunch: boolean;
}

const AceOpportunityDetails: React.FC<AceSingleOpportunityProps> = ({
  opportunity,
  opportunityId,
  requiresSaasDocumentationToLaunch,
}) => {
  const classes = useStyles();
  const { vendorIsConnectedToSalesforce } = useCoSellContext();
  const { pendingOperation, opportunityEvents, opportunityEventsError } =
    useOpportunityEventsQuery(opportunityId);

  const hasPendingRequest = !!pendingOperation;
  const hasRequestError =
    !hasPendingRequest && !!opportunity.metadata?.hasCloudErrors;

  return (
    <Page className={classes.detailsPage}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Box mt={2} data-testid="details-header">
            <AceOpportunityDetailsHeader
              title={opportunity.customer?.account?.companyName}
              subtitle={opportunity.project?.title}
              source={opportunity.source}
              lifeCycle={opportunity.lifeCycle}
              crmId={opportunity.metadata?.crmId}
            />
          </Box>
        </Grid>

        {opportunity.metadata?.hasCloudErrors && !hasPendingRequest && (
          <Grid item xs={12}>
            <AceCoSellErrorMessagesBanner opportunityId={opportunityId} />
          </Grid>
        )}
        {vendorIsConnectedToSalesforce &&
          !!opportunity.metadata &&
          !opportunity.metadata.crmId && (
            <Grid item xs={12}>
              <UnlinkedCrmIdWarning
                opportunityId={opportunityId}
                disabled={hasPendingRequest}
              />
            </Grid>
          )}

        <Grid item xs={12} md={9}>
          <NextStepsCard
            nextSteps={opportunity.lifeCycle?.nextSteps}
            nextStepsHistory={opportunity.lifeCycle?.nextStepsHistory || []}
          />

          <Box mt={4}>
            {!!opportunity.customer?.account && (
              <AceCustomerDetails
                account={opportunity.customer?.account}
                insights={opportunity.awsOpportunitySummary?.insights}
              />
            )}
          </Box>
          <Box mt={4}>
            {!!opportunity.project ? (
              <AceProjectDetails
                opportunityType={opportunity.opportunityType}
                project={opportunity.project}
                targetCloseDate={opportunity.lifeCycle?.targetCloseDate || ''}
                primaryNeedsFromAws={opportunity.primaryNeedsFromAws}
                awsProducts={opportunity.awsProducts || []}
                solutions={opportunity.solutions || []}
              />
            ) : null}
          </Box>
          <Box mt={4}>
            {!!opportunity.marketing && (
              <AceMarketingDetails marketing={opportunity.marketing} />
            )}
          </Box>

          <Box mt={4}>
            <AceAdditionalDetails
              isSRRPAndLaunched={
                requiresSaasDocumentationToLaunch &&
                opportunity.lifeCycle?.stage ===
                  AceOpportunityStageEnum.LAUNCHED
              }
              awsAccountId={opportunity.customer?.account?.awsAccountId}
              additionalComments={opportunity.project?.additionalComments}
              partnerOpportunityIdentifier={
                opportunity.partnerOpportunityIdentifier
              }
              competitiveTracking={opportunity.project?.competitorName}
              softwareRevenue={opportunity.softwareRevenue}
              awsMarketplaceOffers={opportunity.awsMarketplaceOffers}
            />
          </Box>

          <Box mt={4}>
            {!!opportunity.customer?.contacts &&
              opportunity.customer.contacts.length > 0 && (
                <AceContactDetails contact={opportunity.customer.contacts[0]} />
              )}
          </Box>

          {!!opportunity.awsOpportunitySummary?.opportunityTeam?.length &&
            opportunity.awsOpportunitySummary.opportunityTeam.map(
              (teamMember, i) => (
                <Box mt={4} key={i}>
                  <AcePartnerDetails partnerContact={teamMember} />
                </Box>
              ),
            )}
        </Grid>
        <Grid item xs={12} md={3}>
          {!isEmpty(opportunityEventsError) ? (
            <div>Error loading opportunity events</div>
          ) : (
            <>
              {opportunity.isCreatedInCloud &&
              !!opportunity.lifeCycle &&
              !!opportunity.lifeCycle.stage &&
              !!opportunity.lifeCycle.reviewStatus ? (
                <RightRailCard
                  cloudId={opportunity.id}
                  pendingOperation={pendingOperation}
                  hasRequestError={hasRequestError}
                  origin={opportunity.origin || ''}
                  opportunityTeam={
                    opportunity.awsOpportunitySummary?.opportunityTeam || []
                  }
                  lifeCycle={opportunity.lifeCycle}
                />
              ) : null}

              <Box mt={4} mb={4}>
                <Divider />
              </Box>

              <CoSellActivityFeed
                activities={opportunityEvents?.events || []}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Page>
  );
};

export default AceOpportunityDetails;
