import { useState } from 'react';
import { Box } from 'vendor/material';
import { Banner } from '@tackle-io/platform-ui';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from '@tackle-io/platform-ui';
import { useCanvasTackleIntegrationStyles } from './CanvasTackleIntegration.styles';
import { SetupCard } from './SetupCard';
import SalesforceConnectionTable from 'components/TackleForSalesforce/ConnectionDetailsTable/SalesforceConnectionTable';
import { useCanvasSession } from '../useCanvasSession';
import { useDisconnectSalesforce } from './salesforceIntegration/DisconnectSalesforce';
import { useOAuthPopup } from './salesforceIntegration/OAuthPopup';
import { useSalesforceMessageHandler } from './salesforceIntegration/MessageHandler';
import { useSalesforceConnectionData } from './salesforceIntegration/SalesforceConnectionData';

const PACKAGE_NAMESPACE_PARAM = 'namespace';
const IS_SANDBOX_PARAM = 'isSandbox';
export const SALESFORCE_AUTH_COMPLETE = 'SALESFORCE_AUTH_COMPLETE';

export type Registration = {
  organization: {
    id: string;
    name: string;
    instanceName: string;
    isSandbox: boolean;
    organizationType: string;
    namespacePrefix: string;
  };
  user: {
    userId: string;
    organizationId: string;
    email: string;
    givenName: string;
    familyName: string;
    userType: string;
  };
  instanceUrl: string;
  vendorId: string;
  tackleOrgId: string;
  errorCode: string;
  errorMessage: string;
  status: string;
  createdAt: string;
  updatedAt: string;
};

export interface SalesforceCrmConnectorResponse extends Registration {
  orgId: string;
  namespace: string;
}

export const getIsSandbox = (urlParams: URLSearchParams): boolean => {
  const isSandboxString = urlParams.get(IS_SANDBOX_PARAM);
  return isSandboxString === 'true';
};

export const getPackageVersion = (urlParams: URLSearchParams): string => {
  const packageVersion = urlParams.get('packageVersion');
  return packageVersion ? unescape(packageVersion) : '';
};

export default function CanvasTackleIntegrationStatus() {
  const canvasSessionHook = useCanvasSession();
  const classes = useCanvasTackleIntegrationStyles();
  const [isConnecting, setIsConnecting] = useState(false);
  const [disconnectError, setDisconnectError] = useState<string | null>(null);
  const [registrationError, setRegistrationError] = useState<string | null>(
    null,
  );
  const [, setPopupMessageResponse] = useState<any>(null);
  const [disconnected, setDisconnected] = useState(
    !canvasSessionHook.context?.parameters?.isConnected,
  );
  const [connectionData, setConnectionData] =
    useState<SalesforceCrmConnectorResponse | null>(null);
  const {
    salesforceData,
    dataError,
    dataLoading: isSalesforceDataLoading,
  } = useSalesforceConnectionData();

  const urlParams = new URLSearchParams(window.location.search);
  const packageNamespace = urlParams.get(PACKAGE_NAMESPACE_PARAM) ?? '';
  const packageVersion = getPackageVersion(urlParams);
  const isSandbox = getIsSandbox(urlParams);
  const getOAuthLink = document.referrer + '/services/oauth2/authorize';

  const { disconnectSalesforce } = useDisconnectSalesforce();
  const {
    openSalesforceOAuthPopUp,
    isPendingConnection,
    setIsPendingConnection,
  } = useOAuthPopup(getOAuthLink, packageNamespace, isSandbox, packageVersion);
  const isConnected = !!canvasSessionHook.context?.parameters?.isConnected;

  const shouldShowConnectionSetup =
    (isConnected && salesforceData) || (!isConnected && connectionData);

  useSalesforceMessageHandler((payload) => {
    if (payload.error) {
      setRegistrationError(payload.error);
    } else {
      setConnectionData(payload?.salesforceRegistration ?? null);
      setPopupMessageResponse(payload);
    }
    setIsPendingConnection(false);
  }, setIsConnecting);

  if (canvasSessionHook.isLoadingCanvasSession) {
    return <Spinner type="ellipsis" />;
  }

  if (canvasSessionHook.isError) {
    return <>Error loading CanvasSession</>;
  }

  if (isConnected && isSalesforceDataLoading) {
    return (
      <Box className={classes.loaderContainer}>
        <Spinner type="ellipsis" />
      </Box>
    );
  }

  if (!shouldShowConnectionSetup) {
    const setupCard = (
      <div className={classes.integrationWindow}>
        <SetupCard
          isPendingConnection={isPendingConnection}
          isConnecting={isConnecting}
          onConnect={() => {
            setRegistrationError(null);
            openSalesforceOAuthPopUp();
          }}
        />
      </div>
    );

    if (registrationError) {
      return (
        <div className={classes.integrationWindow}>
          <Box mb={4}>
            <Banner
              type="danger"
              title="Registration Error"
              borderPosition="top"
              body={registrationError}
            />
          </Box>
          <SetupCard
            isPendingConnection={isPendingConnection}
            isConnecting={isConnecting}
            onConnect={() => {
              setRegistrationError(null);
              openSalesforceOAuthPopUp();
            }}
          />
        </div>
      );
    }

    return setupCard;
  }

  if (dataError) {
    return <>Error loading Salesforce data: {dataError.message}</>;
  }

  const handleDisconnect = async (
    closeModal: () => void,
    instanceUrl: string,
  ): Promise<boolean> => {
    try {
      const success = await disconnectSalesforce(closeModal, instanceUrl);
      if (success) {
        setDisconnected(true);
        setConnectionData((prevData) =>
          prevData ? { ...prevData, status: 'not connected' } : null,
        );
        return true;
      }
      return false;
    } catch (error) {
      if (error instanceof Error) {
        setDisconnectError(error.message);
      }
      return false;
    }
  };

  return (
    <div className={classes.integrationWindow}>
      {(disconnectError || registrationError) && (
        <Box mb={4}>
          <Banner
            type="danger"
            title={disconnectError ? 'Disconnect Error' : 'Registration Error'}
            borderPosition="top"
            body={disconnectError || registrationError}
          />
        </Box>
      )}
      <SalesforceConnectionTable
        data={connectionData ?? salesforceData}
        isConnected={isConnected}
        openSalesforceOAuthPopUp={openSalesforceOAuthPopUp}
        isNewlyConnected={!!connectionData}
        onDisconnect={handleDisconnect}
        disconnected={disconnected}
        isSalesforceDataLoading={isSalesforceDataLoading}
        connectionStatus={
          connectionData?.status ?? salesforceData?.status ?? 'not connected'
        }
      />
    </div>
  );
}
