import { Banner } from '@tackle-io/platform-ui';
import { AssignmentIcon } from 'packages/cosell/assets';
import FormSectionCard from '../components/FormSectionCard';
import { FormSectionConfig } from '../constants';

export const TypeOfHelpFormSection = () => {
  return (
    <FormSectionCard
      title={FormSectionConfig.typeOfHelp.title}
      subtitle={null}
      icon={<AssignmentIcon />}
      data-id={FormSectionConfig.typeOfHelp.dataId}
    >
      <Banner
        isCollapsible
        title="Notes are AI generated by Microsoft based on customer and solution(s) added. Please make sure they are accurate and edit it as appropriate before sharing."
        type="info"
      />
    </FormSectionCard>
  );
};

export default TypeOfHelpFormSection;
