import { awsCoSellToFormFieldEnumMap } from './awsCoSellToFormFieldEnumMap';

export const awsCoSellToFormErrorFieldEnumMap: Record<
  string,
  { label: string; name: string }
> = {
  ...awsCoSellToFormFieldEnumMap,
  /** mapping aws field to form field */

  'customer.account.duns': {
    label: 'Customer data universal number system (DUNS)',
    name: 'duns',
  },
  opportunityType: {
    label: 'Opportunity type',
    name: 'opportunityType',
  },
  'relatedEntityIdentifiers.solutions': {
    label: 'Solutions',
    name: 'solutions',
  },
  'project.expectedCustomerSpend.1.member.currencyCode': {
    label: 'Currency',
    name: 'expectedCustomerSpendCurrencyCode',
  },
  'CreateOpportunityInput.Project.ExpectedCustomerSpend[0].CurrencyCode': {
    label: 'Currency',
    name: 'expectedCustomerSpendCurrencyCode',
  },
  'UpdateOpportunityInput.Project.ExpectedCustomerSpend[0].CurrencyCode': {
    label: 'Currency',
    name: 'expectedCustomerSpendCurrencyCode',
  },
  // Note: aws in very inconsistent with their field names
  'project.expectedCustomerSpend.amount': {
    label: 'Amount',
    name: 'expectedCustomerSpendAmount',
  },
  'UpdateOpportunityInput.Project.ExpectedCustomerSpend[0].Amount': {
    label: 'Amount',
    name: 'expectedCustomerSpendAmount',
  },
  'CreateOpportunityInput.Project.ExpectedCustomerSpend[0].Amount': {
    label: 'Amount',
    name: 'expectedCustomerSpendAmount',
  },

  'customer.contacts.1.member.firstName': {
    label: 'Customer first name',
    name: 'customerFirstName',
  },
  'customer.contacts.1.member.lastName': {
    label: 'Customer last name',
    name: 'customerLastName',
  },
  'customer.contacts.1.member.businessTitle': {
    label: 'Customer title',
    name: 'customerTitle',
  },
  'customer.contacts.1.member.email': {
    label: 'Customer email',
    name: 'customerEmail',
  },
  'customer.contacts.1.member.phone': {
    label: 'Customer phone',
    name: 'customerPhone',
  },

  'opportunityTeam.1.member.email': {
    label: 'Primary contact email',
    name: 'primaryContactEmail',
  },

  // TODO: update this mapping once AWS updates the api field name
  'partnerOpportunityTeam.0.member.businessTitle': {
    label: 'Primary contact title',
    name: 'primaryContactTitle',
  },

  'softwareRevenue.value.amount': {
    label: 'Customer software value',
    name: 'customerSoftwareValue',
  },
  'softwareRevenue.value.currencyCode': {
    label: 'Currency',
    name: 'currencyCode',
  },
  'softwareRevenue.deliveryModel': {
    label: 'Procurement type',
    name: 'deliveryModel',
  },
  'softwareRevenue.expirationDate': {
    label: 'Contract end date',
    name: 'expirationDate',
  },
  'softwareRevenue.effectiveDate': {
    label: 'Contract start date',
    name: 'effectiveDate',
  },
};
