import React from 'react';
import { Tag } from '@tackle-io/platform-ui';
import { makeStyles } from 'vendor/material';

const useStyles = makeStyles(() => ({
  notConnectedTag: {
    backgroundColor: '#DFE1E6',
    color: '#172B4D',
    fontWeight: 500,
  },
}));

interface ConnectionStatusProps {
  status: string;
}

const ConnectionStatus: React.FC<ConnectionStatusProps> = ({ status }) => {
  const classes = useStyles();

  const isConnected = status === 'connected';
  const tagClass = isConnected ? '' : classes.notConnectedTag;

  return (
    <Tag color={isConnected ? 'green' : 'grey'} className={tagClass}>
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </Tag>
  );
};

export default ConnectionStatus;
