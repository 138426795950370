import React from 'react';
import { Box, Card, Divider } from 'vendor/material';
import useStyles from './RightRailCard.style';
import RightRailSubSection from 'packages/cosell/src/components/RightRailSubSection/RightRailSubSection';
import {
  AceOpportunityReviewStatusEnum,
  AceOpportunityOriginEnum,
} from 'packages/cosell/src/types/enums';
import RightRailButtonSection from 'packages/cosell/src/components/RightRailSubSection/RightRailButtonSection';
import RightRailContactInfo from 'packages/cosell/src/components/RightRailSubSection/RightRailContactInfoSection';
import { Link } from '@tackle-io/platform-ui';
import {
  Contact,
  LifeCycle,
} from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import {
  CLOUD_CONTACTS_TITLE,
  getRightRailContentConfig,
  NO_CLOUD_CONTACT_GUIDANCE_TEXT,
  RightRailContentConfig,
  shouldRenderActionButtonSection,
  shouldRenderCloudContact,
} from './helper';
import Button from 'packages/cosell/src/components/Button/Button';
import { useIsTestEnvironment } from '../../../../../../../hooks';
import { coSellDetailsPageDataId } from 'packages/cosell/src/utilities/intercomEnums';
import { AceOpportunityEventOperation } from 'packages/cosell/src/types/responses/AceOpportunityEventResponse';

const PARTNER_CENTER_BASE_URL =
  'https://partnercentral.awspartner.com/partnercentral2/s/editopportunity';

interface RightRailCardProps {
  origin: string;
  opportunityTeam: Contact[];
  pendingOperation?: AceOpportunityEventOperation;
  hasRequestError: boolean;
  cloudId: string;
  lifeCycle: LifeCycle;
}

const RightRailCard: React.FC<RightRailCardProps> = ({
  cloudId,
  origin,
  opportunityTeam,
  pendingOperation,
  hasRequestError,
  lifeCycle,
}) => {
  const classes = useStyles();
  const isTestEnvironment = useIsTestEnvironment();
  const hasPendingRequest = !!pendingOperation;
  const { reviewStatus: status, stage } = lifeCycle;
  const renderActionButtonSection = shouldRenderActionButtonSection(
    status,
    stage,
    isTestEnvironment,
  );
  const renderCloudContact = shouldRenderCloudContact(status);

  const getCloudContactConfig = (
    opportunityTeam: Contact[],
  ): RightRailContentConfig => {
    if (
      !opportunityTeam ||
      opportunityTeam.length === 0 ||
      !renderCloudContact
    ) {
      return {
        title: CLOUD_CONTACTS_TITLE,
        guidanceText: NO_CLOUD_CONTACT_GUIDANCE_TEXT,
      };
    }
    const contactInfo = opportunityTeam.map((teamMember, i) => (
      <RightRailContactInfo
        businessTitle={teamMember.businessTitle}
        name={
          teamMember.firstName &&
          teamMember.lastName &&
          `${teamMember.firstName} ${teamMember.lastName}`
        }
        email={teamMember.email}
        key={`${teamMember.firstName}_${i}`}
      />
    ));
    return {
      title: CLOUD_CONTACTS_TITLE,
      guidanceText: contactInfo,
    };
  };

  const rightRailContents = getRightRailContentConfig(
    lifeCycle,
    isTestEnvironment,
    hasPendingRequest,
    hasRequestError,
  );
  const cloudContactConfig = getCloudContactConfig(opportunityTeam);

  return (
    <Card variant="outlined" className={classes.rightRailCard}>
      {rightRailContents ? (
        <RightRailSubSection
          title={rightRailContents?.title}
          subTitle={rightRailContents?.subTitle}
          guidanceText={rightRailContents?.guidanceText}
          icon={rightRailContents?.icon}
          subIcon={rightRailContents?.subIcon}
        />
      ) : null}

      {(renderActionButtonSection || hasPendingRequest) && (
        <Box mt={2}>
          <RightRailButtonSection
            status={status}
            stage={stage}
            pendingOperation={pendingOperation}
          />
        </Box>
      )}

      {status !== AceOpportunityReviewStatusEnum.PENDING_SUBMISSION &&
        !hasPendingRequest && (
          <>
            <Box mt={2} mb={1}>
              <Divider />
            </Box>
            <RightRailSubSection
              title="View on cloud portal"
              guidanceText={
                <Link to={`${PARTNER_CENTER_BASE_URL}?id=${cloudId}`} external>
                  {/* TODO: They opp cloud id should be dynamic, but currently we don't have 006 cloud id in the api */}
                  {`${PARTNER_CENTER_BASE_URL}?id=${cloudId}`}
                </Link>
              }
            />
          </>
        )}

      {origin === AceOpportunityOriginEnum.AWS_REFERRAL && (
        <div>
          <Box mt={2} mb={1}>
            <Divider />
          </Box>

          {/*TODO: adding api invitation link will be in a follow up PR*/}
          {/*this link only render when the approved opportunity originated from an AWS invitation*/}
          {/*Question: what does it mean? It means for only for partner originated opportunity NOT for invitations?*/}
          <RightRailSubSection
            title="Related invitation"
            guidanceText={
              <Link
                to="https://example.com/invitation-ww5skj2oejvbi"
                external
                showExternalIcon
              >
                invitation-ww5skj2oejvbi
              </Link>
            }
          />
        </div>
      )}

      <Box mt={2} mb={1}>
        <Divider />
      </Box>

      {/*TODO: this related offer link will be in a follow up PR  */}
      {/* <RightRailSubSection
        title="Related offer"
        guidanceText={
          <Link
            to="https://example.com/invitation-ww5skj2oejvbi"
            external
            showExternalIcon
          >
            invitation-ww5skj2oejvbi
          </Link>
        }
      />
      <Box mt={2} mb={2}>
        <Divider />
      </Box> */}

      <RightRailSubSection
        title={cloudContactConfig.title}
        guidanceText={cloudContactConfig.guidanceText}
      />

      {renderCloudContact && opportunityTeam?.length > 0 && (
        <Box mt={2}>
          <Button
            data-id={coSellDetailsPageDataId.CONTACT_CLOUD_PARTNER}
            variant="outlined"
            appearance="primary"
            fullWidth
          >
            Contact cloud partner
          </Button>
        </Box>
      )}
    </Card>
  );
};

export default RightRailCard;
