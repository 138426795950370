import { Banner, Button } from '@tackle-io/platform-ui';
import { ComponentProps, FC } from 'react';
import { Box, Drawer } from 'vendor/material';
import FormDrawer from '../components/FormDrawer';

export const AddTeamMemberForm = ({ onClose }: { onClose: () => void }) => {
  const footerActions = (
    <>
      <Button appearance="primary" variant="outlined" onClick={onClose}>
        Cancel
      </Button>
      <Button
        disabled
        appearance="primary"
        variant="contained"
        onClick={() => alert('add team member')}
      >
        Add
      </Button>
    </>
  );
  return (
    <FormDrawer
      footerActions={footerActions}
      title="Add Team member"
      onClose={onClose}
    >
      <Box sx={{ my: 2 }}>
        <Banner
          title="Microsoft requires all fields below to add a team member."
          type="info"
        />
      </Box>
      content
    </FormDrawer>
  );
};

/** AddTeamMemberForm wrapped in a Drawer */
export const AddTeamMemberFormDrawer: FC<
  ComponentProps<typeof Drawer> & { onClose: () => void }
> = ({ open, onClose, ...props }) => {
  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      onClose={onClose}
      {...props}
      children={<AddTeamMemberForm onClose={onClose} />}
    />
  );
};
