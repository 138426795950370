export const FormSectionConfig = {
  customerDetails: {
    id: 'customerDetails',
    title: 'Customer details',
    dataId: 'msft-customer-details-section',
  },
  dealDetails: {
    id: 'dealDetails',
    title: 'Deal details',
    dataId: 'msft-deal-details-section',
  },
  solutions: {
    id: 'solutions',
    title: 'Solutions',
    dataId: 'msft-solutions-section',
  },
  typeOfHelp: {
    id: 'typeOfHelp',
    title: 'Type of help',
    dataId: 'msft-type-of-help-section',
  },
  partners: {
    id: 'partners',
    title: 'Partners',
    dataId: 'msft-partners-section',
  },
  teamMembers: {
    id: 'teamMembers',
    subtitle:
      'Add up to five team members. They will receive referral notifications from Microsoft and Tackle. Tackle recommends adding an email alias to centralize notifications to your co-sell operations.',
    title: 'Team members',
    dataId: 'msft-team-members-section',
  },
} as const;

export type FormSection = keyof typeof FormSectionConfig;
