import { MouseEvent, useEffect } from 'react';
import { Modal, Button, ProviderIcon } from '@tackle-io/platform-ui';
import { Box, Typography } from 'vendor/material';
import {
  fontWeightMediumLarge,
  fontWeightBold,
} from 'utils/fontWeightConstants';
import { makeStyles } from 'vendor/material';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import { coSellDetailsPageDataId } from 'packages/cosell/src/utilities/intercomEnums';
import { TextField } from '@tackle-io/platform-ui';
import { Field, Formik, useFormikContext } from 'formik';
import { RadioCheckboxGroupFormField } from './RadioCheckboxField';
import { Label } from 'packages/cosell/src/components';
import * as Yup from 'yup';

type FormContext = {
  crmId?: string;
  linked: 'yes' | 'no';
};

const validationSchema = Yup.object().shape({
  crmId: Yup.string().when('linked', {
    is: 'yes',
    then: Yup.string().required('Opportunity ID is required'),
  }),
});

export const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: fontWeightMediumLarge,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(30),
    color: theme.palette.NEUTRAL700,
    marginLeft: theme.spacing(1),
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    color: `${theme.palette.BLUE400} !important`,
    fontWeight: fontWeightBold,
    borderColor: `${theme.palette.NEUTRAL040} !important`,
  },
  submitButton: {
    fontWeight: fontWeightBold,
  },
  header: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    '& p': {
      color: theme.palette.NEUTRAL300,
      marginTop: theme.spacing(0.5),
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: fontWeightMediumLarge,
    color: theme.palette.NEUTRAL300,
  },
}));

const CrmIdField = ({ name }: { name: string }) => {
  const classes = useStyles();
  const { errors, values, setFieldValue, touched } =
    useFormikContext<FormContext>();
  const linkingEnabled = values.linked === 'yes';

  useEffect(() => {
    if (!linkingEnabled && !!values.crmId) {
      setFieldValue(name, '');
    }
  }, [linkingEnabled, name, setFieldValue, values.crmId]);

  return (
    <Field disabled={!linkingEnabled} name={name}>
      {({ field }) => (
        <>
          <Label
            className={classes.label}
            label="Opportunity ID"
            required={linkingEnabled}
          />
          <TextField
            {...field}
            disabled={!linkingEnabled}
            placeholder="Enter the target Salesforce opportunity ID"
            required={linkingEnabled}
            error={touched[name] && errors[name]}
          />
        </>
      )}
    </Field>
  );
};

interface AcceptInvitationModalProps {
  cloud: DisplayCloudType;
  onClose?: (event: MouseEvent) => void;
}

const TITLE = 'Accept invitation';

export const AcceptInvitationModal = ({
  onClose,
}: AcceptInvitationModalProps) => {
  const classes = useStyles();

  const { isSubmitting, handleSubmit } = useFormikContext<FormContext>();

  const crmIdField = <CrmIdField name="crmId" />;

  return (
    <Modal
      data-testid={'cosell_accept_invitation_modal'}
      title={
        <Box sx={{ display: 'flex', alignItems: 'center' }} mb={1} mt={2}>
          <ProviderIcon fontSize={'medium'} provider={DisplayCloudType.AWS} />
          <Typography variant="h3" className={classes.title}>
            {TITLE}
          </Typography>
        </Box>
      }
      width="medium"
      onClose={onClose}
      open
      footerActions={
        <>
          <Button
            id={coSellDetailsPageDataId.CANCEL_ACCEPT_INVITATION_MODAL}
            appearance="secondary"
            variant="outlined"
            onClick={onClose}
            disabled={isSubmitting}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            id={coSellDetailsPageDataId.SUBMIT_ACCEPT_INVITATION_MODAL}
            appearance="primary"
            className={classes.submitButton}
            onClick={() => handleSubmit()}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Accept
          </Button>
        </>
      }
    >
      <Box mt={-2} mb={4}>
        <Field name="linked">
          {({ field }) => (
            <RadioCheckboxGroupFormField
              {...field}
              options={[
                {
                  display: 'Accept and associate to an existing opportunity',
                  helperText:
                    'Tackle will create a new co-sell in Salesforce and attach it to the selected opportunity.',
                  value: 'yes',
                  children: crmIdField,
                },
                {
                  display: 'Accept without linking',
                  helperText:
                    'Tackle will create a new co-sell in Salesforce. It will not be attached to an opportunity. To view the co-sell after accepting, open the view of Tackle AWS Co-sell Opportunities in Salesforce.',
                  value: 'no',
                },
              ]}
            />
          )}
        </Field>
      </Box>
    </Modal>
  );
};

export const AcceptInvitationModalForm = ({
  cloud,
  onClose,
  onSubmit,
}: AcceptInvitationModalProps & {
  onSubmit: (values: { crmId?: string }) => Promise<void>;
}) => {
  /** only send the `crmId` */
  const handleSubmit = async (values: FormContext) => {
    return onSubmit({ crmId: values.crmId });
  };
  return (
    <Formik
      initialValues={{ crmId: '', linked: 'yes' }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <AcceptInvitationModal cloud={cloud} onClose={onClose} />
    </Formik>
  );
};
