import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import { OfferType } from '../types/TackleOffer';

export const labelsByOfferType = {
  [OfferType.Direct]: {
    title: 'Direct',
    subtitle: 'Custom offer you send directly to your buyer',
  },
  [OfferType.PartnerResale]: {
    title: 'Partner',
    subtitle: 'Custom offer for your reseller to send to the buyer',
  },
};

export const labelsByOfferCreationMode = {
  [OfferPageMode.New]: {
    title: 'Create new offer',
    subtitle: 'Build a custom offer for a specific marketplace.',
  },
  [OfferPageMode.Associate]: {
    title: 'Import to associate to an existing offer',
    subtitle:
      'Import an existing offer from the marketplace and associate it to Tackle to track and receive bookable artifacts.',
  },
};
