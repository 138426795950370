import React, { isValidElement } from 'react';
import { Box, Grid, Typography, GridSize } from 'vendor/material';
import useStyles from './InfoItem.styles';
import { isNull, isUndefined } from 'lodash';

export const HALF_GRID_SIZE = 6;
export const FULL_GRID_SIZE = 12;

const InfoItem: React.FC<{
  className?: string;
  itemTitle: string;
  value: React.ReactNode;
  gridSize?: GridSize;
  flipContent?: boolean;
  dataId?: string;
}> = ({
  className,
  itemTitle,
  value,
  gridSize = HALF_GRID_SIZE,
  flipContent,
  dataId,
}) => {
  const classes = useStyles();

  const getDisplayValue = (value: unknown) => {
    if (isNull(value) || isUndefined(value) || value === '') {
      return '-';
    }
    return value;
  };

  const renderValue = (value: React.ReactNode) => {
    if (isValidElement(value)) {
      return <div className={classes.infoValue}>{value}</div>;
    }
    return (
      <Typography component="p" className={classes.infoValue}>
        {getDisplayValue(value)}
      </Typography>
    );
  };

  return (
    <Grid
      className={className}
      item
      xs={12}
      md={gridSize}
      style={{ padding: '16px 0 0 0' }}
    >
      {flipContent ? (
        <Box mb={2}>
          {renderValue(value)}
          <Typography
            component="h5"
            className={classes.infoTitle}
            data-id={dataId}
          >
            {itemTitle}
          </Typography>
        </Box>
      ) : (
        <Box mb={2}>
          <Typography
            component="h5"
            className={classes.infoTitle}
            data-id={dataId}
          >
            {itemTitle}
          </Typography>

          {renderValue(value)}
        </Box>
      )}
    </Grid>
  );
};

export default InfoItem;
