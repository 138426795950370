import { Button } from '@tackle-io/platform-ui';
import { coSellDetailsPageDataId } from 'packages/cosell/src/utilities/intercomEnums';
import useCoSellSfCanvasActions from 'packages/salesforce-canvas/cosell/src/hooks/useCoSellSfCanvasActions';
import { useCallback } from 'react';
import { setIncomingCoSellId } from 'packages/salesforce-canvas/cosell/src/utils/incomingCoSellSessionStorage';

/** can poll on the invitation for the
 * tackle invitation id / opportunity id / cloud id
 */

/** can only be rendered in renderEnv = sf_canvas since it uses canvas actions */
export const ViewSfOpportunityButton = ({
  crmId,
  cloudOpportunityId,
}: {
  /** native salesforce opportunity id */
  crmId: string;
  cloudOpportunityId: string;
}) => {
  const { handleCoSellAction } = useCoSellSfCanvasActions();

  const handleClick = useCallback(() => {
    /** set to sessionStorage, so we can poll in the Tackle Widget if it has
     *  propagated to open search yet.
     *  This will be cleared in the Tackle Widget when it has been found.
     *  If it already exists in the opportunity list, it will be cleared immediately.
     */
    setIncomingCoSellId({
      crmId,
      pendingItem: {
        name: 'cloudProviderId',
        id: cloudOpportunityId,
      },
    });
    handleCoSellAction({
      id: 'go-to-salesforce-object-view',
      record: {
        Id: crmId,
        objectName: 'Opportunity',
      },
    });
  }, [cloudOpportunityId, crmId, handleCoSellAction]);

  return (
    <Button
      variant="contained"
      data-id={coSellDetailsPageDataId.VIEW_SALESFORCE_OPPORTUNITY}
      appearance="primary"
      onClick={handleClick}
    >
      View opportunity
    </Button>
  );
};

export default ViewSfOpportunityButton;
