import { createContext, useContext } from 'react';
import { useHasCoSellRbacRights } from './hooks/useHasCoSellRbacRights/useHasCoSellRbacRights';
import { useConnectionDetailsQuery } from 'components/TackleForSalesforce/useConnectionDetails';

export interface CoSellContextType {
  /** the tackle co-sell opportunity id */
  opportunityId?: string;
  /** the tackle co-sell invitation id */
  invitationId?: string;
  /** explicitly set the render environment the component is in
   *  defaults to downstream
   */
  renderEnv?: 'sf_canvas' | 'downstream';
  /** authenticated vendorId from the Salesforce OTK session */
  vendorId?: string;
  rbacRights?: ReturnType<typeof useHasCoSellRbacRights>;
  /** whether the vendor is connected to a Salesforce instance */
  vendorIsConnectedToSalesforce: boolean;
}
/** vendorId is required by the context provider, so should be set on init */
export const CoSellContext = createContext<CoSellContextType>({
  vendorId: '',
  vendorIsConnectedToSalesforce: false,
});

export type CoSellContextProviderProps = Pick<
  CoSellContextType,
  'opportunityId' | 'invitationId' | 'renderEnv' | 'vendorId'
> & {
  children: React.ReactNode;
};

/**
 * context to keep track of the vendor id
 */
export const CoSellContextProvider = ({
  children,
  opportunityId,
  invitationId,
  renderEnv = 'downstream',
  vendorId,
}: CoSellContextProviderProps) => {
  const rbacRights = useHasCoSellRbacRights();
  const { data: connectionData } = useConnectionDetailsQuery({
    enabled: renderEnv === 'downstream',
  });

  const vendorIsConnectedToSalesforce =
    renderEnv === 'sf_canvas' || connectionData?.status === 'connected';

  return (
    <CoSellContext.Provider
      value={{
        opportunityId,
        invitationId,
        renderEnv,
        vendorId,
        rbacRights,
        vendorIsConnectedToSalesforce,
      }}
    >
      {children}
    </CoSellContext.Provider>
  );
};

export function useCoSellContext() {
  const context = useContext(CoSellContext);
  if (context === undefined) {
    throw new Error(
      'useCoSellContext must be used within a CoSellContextProvider',
    );
  }
  return context;
}
