import {
  fontWeightMediumLarge,
  fontWeightRegular,
} from 'utils/fontWeightConstants';
import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  infoBox: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  infoTitle: {
    color: theme.palette.NEUTRAL300,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: fontWeightMediumLarge,
    lineHeight: theme.typography.pxToRem(20),
  },
  infoValue: {
    color: theme.palette.NEUTRAL500,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: fontWeightRegular,
    lineHeight: theme.typography.pxToRem(24),
    display: 'flex',
  },
}));

export default useStyles;
