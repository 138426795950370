import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
  },
  calendarContent: {
    marginBottom: theme.spacing(1.5),
  },
}));

export default useStyles;
