import { useAmpliFeatureFlags } from '@tackle-io/tackle-auth-tools';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import { FeatureFlagState } from 'packages/cosell/src/types/enums/FeatureFlags';

const MSFT_UI_ENABLED = 'co-sell-msft-v3-ui-enabled';

/**
 * checks amplitude feature flags to determine if the user
 * can use the co-sell cloud UI
 *
 */
export function useCanUseCoSellCloud() {
  const { loading, error, flags } = useAmpliFeatureFlags();

  return {
    [DisplayCloudType.MICROSOFT]:
      flags[MSFT_UI_ENABLED] === FeatureFlagState.ON,
    error,
    loading,
  };
}
