import { useSalesforceCoSellWidgetButtonGroupStyles } from './SalesforceCoSellWidgetButtonGroup.styles';
import { Button } from 'vendor/material';
import useCoSellSfCanvasActions, {
  COSELL_ACTION_MAP,
} from 'packages/salesforce-canvas/cosell/src/hooks/useCoSellSfCanvasActions';
import {
  AceOpportunityReviewStatusEnum,
  DisplayCloudType,
} from 'packages/cosell/src/types/enums';
import { useHasCoSellRbacRights } from 'packages/cosell/src/hooks/useHasCoSellRbacRights/useHasCoSellRbacRights';

/** Submit to cloud is not ready to be enabled for SF yet */
const isSubmitToCloudEnabled = false;

/** WIP
 *
 * TODO:
 *  - style buttons to match design (color, spacing, layout)
 *  - implement button actions
 *  - add memoized func to generate button actions (will be driven by many factors)
 *  - cleanup code
 */
export const SalesforceCoSellWidgetButtonGroup = ({
  isEditable,
  tackleCoSellId,
  disableViewButton,
  status,
}: {
  isEditable: boolean;
  tackleCoSellId?: string;
  disableViewButton?: boolean;
  status?: AceOpportunityReviewStatusEnum;
}) => {
  const classes = useSalesforceCoSellWidgetButtonGroupStyles();
  const { handleCoSellAction } = useCoSellSfCanvasActions();
  const rbacRights = useHasCoSellRbacRights();
  const isDraft = status === AceOpportunityReviewStatusEnum.PENDING_SUBMISSION;
  const userHasRightToEdit = isDraft
    ? rbacRights?.updateDraftOpportunity || rbacRights?.updateOpportunity
    : rbacRights?.updateOpportunity;

  const cloud = DisplayCloudType.AWS;

  return (
    <div className={classes.editLockup}>
      {tackleCoSellId && (
        <Button
          className={classes.buttonElement}
          disabled={disableViewButton}
          variant="outlined"
          color="primary"
          onClick={() =>
            handleCoSellAction({
              id: 'view-cosell',
              cloud,
              tackleId: tackleCoSellId,
            })
          }
        >
          {COSELL_ACTION_MAP['view-cosell'].label}
        </Button>
      )}

      {userHasRightToEdit && isEditable && tackleCoSellId && (
        <Button
          className={classes.dashboardButtonElement}
          onClick={() =>
            handleCoSellAction({
              id: 'edit-cosell',
              cloud,
              tackleId: tackleCoSellId,
            })
          }
        >
          {COSELL_ACTION_MAP['edit-cosell'].label}
        </Button>
      )}

      {isSubmitToCloudEnabled && (
        <Button
          className={classes.dashboardButtonElement}
          onClick={() =>
            handleCoSellAction({
              id: 'submit-cosell-to-cloud',
              cloud,
              tackleId: tackleCoSellId,
            })
          }
        >
          {COSELL_ACTION_MAP['submit-cosell-to-cloud'].label}
        </Button>
      )}
    </div>
  );
};
