import React from 'react';
import { Grid, Typography, Box } from 'vendor/material';
import integration from '../Assets/Integrations.svg';
import { useSalesforceTableStyles } from './ConnectionDetailsTable.style';

interface TableHeaderProps {
  headerType: 'tackle' | 'salesforce';
}

const ConnectionTableHeader: React.FC<TableHeaderProps> = ({ headerType }) => {
  const classes = useSalesforceTableStyles();
  const firstHeaderTitle =
    headerType === 'tackle' ? 'Salesforce Org' : 'Tackle Org';

  return (
    <Box className={classes.headerContainer}>
      {headerType === 'salesforce' && (
        <>
          <Box display="flex" alignItems="center" justifyContent="center">
            <img
              src={integration}
              alt="Integration icon"
              className={classes.integrationIcon}
            />
            <Typography className={classes.headerText}>
              Tackle Integration
            </Typography>
          </Box>
          <Typography className={classes.headerSubText}>
            Manage your Salesforce connection to Tackle.
          </Typography>
        </>
      )}
      <Box className={classes.tableHeaderBox}>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant="subtitle1" className={classes.headerColumn}>
              {firstHeaderTitle}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" className={classes.headerColumn}>
              Connecting Salesforce User
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              variant="subtitle1"
              className={`${classes.headerColumn} ${classes.statusColumn}`}
            >
              Status
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="subtitle1"
              className={`${classes.headerColumn} ${classes.environmentColumn}`}
            >
              Environment
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ConnectionTableHeader;
