import { useParams } from 'react-router';
import ReferralForm from './ReferralForm/ReferralForm';

const EditReferralPage = () => {
  const { tackleId } = useParams<{ tackleId: string }>();
  console.info('edit referral with tackleId', tackleId);
  return <ReferralForm isEdit={true} />;
};

export default EditReferralPage;
