import { Loader } from '@tackle-io/platform-ui';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import coSellClient from 'packages/cosell/api/coSellClient';
import { useCallback, useEffect, useState } from 'react';
import { ACE_OPPORTUNITY_QUERY_KEY } from 'packages/cosell/api/hooks/useAceOpportunity';
import { useCoSellContext } from 'packages/cosell/src/CoSellContextProvider';
import { useHistory } from 'react-router';
import { COSELL_PATH } from 'packages/cosell/src/utilities/constants/CoSellConstants';
import { AceOpportunityEventOperationEnum } from 'packages/cosell/src/types/responses/AceOpportunityEventResponse';

type Events = Awaited<
  ReturnType<typeof coSellClient.getOpportunityEvents>
>['events'];

/** return status with event for operation */
export const operationIsCompleteCheck = ({
  events,
  operationId,
}: {
  events?: Events;
  /** pending request id */
  operationId?: string;
}) => {
  if (!events || !operationId)
    return {
      event: null,
      isComplete: false,
      latestCloudResponseTimestamp: null,
    };

  const event =
    events?.find(
      (x) =>
        x.operation === AceOpportunityEventOperationEnum.CREATE_OPPORTUNITY &&
        operationId === x.eventID,
    ) ?? null;

  /**
   * each cloudRequest should have a corresponding cloudResponse,
   * if not, the cloudRequest has not received a response yet and
   * would be considered pending.
   *  */
  const eventRequestResponseCountMatches =
    event?.cloudResponses?.length > 0 &&
    event?.cloudResponses?.length === event?.cloudRequests?.length;

  if (!eventRequestResponseCountMatches) {
    return {
      isComplete: false,
      event,
      latestCloudResponseTimestamp: null,
    };
  }

  const cloudResponse = event?.cloudResponses?.[0];
  return {
    isComplete: eventRequestResponseCountMatches,
    latestCloudResponseTimestamp: cloudResponse.timestamp,
    event,
  };
};

/** Handles the loading state for an Ace co-sell opportunity that is pending in the creation process.
 *  upon successful creation:
 *   - the opportunity is preloaded into the ACE_OPPORTUNITY_QUERY_KEY query
 *   - the onSuccess callback is called with the opportunity id
 *
 *  upon error:
 *  - if rendered by the creation form on initial create mutation,
 *    the user is redirected to the edit page
 *  - if the location is the edit page, onError callback is fired
 *
 *  TODO: update this to accept a pendingRequestId returned from a mutation event,
 *  to avoid having to fetch the opportunity data to get the pendingRequestIds,
 *  which can be delayed
 */
export function CreationPending<T extends string>({
  onError,
  onSuccess,
  pendingAceTackleId,
}: {
  pendingAceTackleId: T;
  onSuccess: (id: T) => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();
  const { renderEnv } = useCoSellContext();
  const history = useHistory();

  const [initialPendingRequestId, setInitialPendingRequestId] = useState<
    string | null
  >(null);

  /** events are loaded with a create opportunity operation.
   *  we confirm this operation is complete before requesting the opportunity data,
   *  as the events can take a bit to populate after the tackle id is created.
   */
  const { data: operationEvent } = useQuery({
    queryKey: [
      'ACE_OPPORTUNITY_EVENTS_QUERY_KEY',
      pendingAceTackleId,
      initialPendingRequestId,
    ],
    queryFn: () => coSellClient.getOpportunityEvents(pendingAceTackleId),
    select: (data) =>
      operationIsCompleteCheck({
        events: data?.events,
        operationId: initialPendingRequestId,
      }),
    refetchInterval(data) {
      /** stop polling when operation is complete */
      return !data?.isComplete ? 1000 : false;
    },
    enabled: !!initialPendingRequestId,
  });

  /** use the opportunity data to get the pending request
   *  and poll the opportunity data to check if the opportunity is created in the cloud
   *  or if it has errors
   */
  const { data: opportunity, remove: removeOpportunityQuery } = useQuery({
    queryKey: [
      pendingAceTackleId,
      'creation-pending-ace-cosell-opportunity',
      operationEvent?.isComplete,
    ],
    queryFn: () => coSellClient.getOpportunityByIdV3(pendingAceTackleId!),
    refetchInterval: (data) => {
      /**
       * poll every 1 second under the following conditions:
       *  state does not have a initialPendingRequestId yet
       *  or
       *  operationEvent is complete,
       *  so we can be sure this has been updated and is available
       *  for the details page
       */
      return !initialPendingRequestId || !!operationEvent?.isComplete
        ? 1000
        : false;
    },
    enabled: !initialPendingRequestId || !!operationEvent?.isComplete,
  });

  const handleCreationError = useCallback(() => {
    // if origin of creation is edit page, and a new error exists
    if (history.location.pathname.endsWith('/edit')) {
      onError?.();
      return;
    }

    const domainPath = renderEnv === 'sf_canvas' ? '/cosell' : COSELL_PATH;
    history.push(`${domainPath}/opportunity/aws/${pendingAceTackleId}/edit`);
  }, [onError, history, pendingAceTackleId, renderEnv]);

  const handleCreationSuccess = useCallback(() => {
    /** if cloud id is created, set the opportunity data to the opportunity query */
    queryClient.setQueryData(
      [ACE_OPPORTUNITY_QUERY_KEY, pendingAceTackleId],
      opportunity,
    );
    onSuccess(pendingAceTackleId);
  }, [onSuccess, queryClient, pendingAceTackleId, opportunity]);

  /**  Check if the opportunity data is up-to-date by comparing timestamps
   *   TODO: look into a better comparison for timestamps, bc metadata lastModifiedDate,
   *   could be related to non creation events.
   */
  const opportunityDataIsUpToDateWithEvents =
    operationEvent?.isComplete &&
    operationEvent?.latestCloudResponseTimestamp &&
    opportunity?.metadata?.lastModifiedDate
      ? new Date(opportunity?.metadata?.lastModifiedDate) >=
        new Date(operationEvent?.latestCloudResponseTimestamp)
      : false;

  /** set the initial pending request id
   *  the first pendingRequestId should be the create request
   *  TODO: see if we can get this from the original mutation request instead of from the opportunity query
   *    - is the createOpportunity operation always going to be the first pendingRequestId returned?
   */
  useEffect(() => {
    const pendingRequestIds = opportunity?.metadata?.pendingRequestIds;
    if (!pendingRequestIds?.length || !!initialPendingRequestId) return;
    setInitialPendingRequestId(pendingRequestIds[0]);

    return () => {
      // remove opportunity query, so it fetches new each time
      removeOpportunityQuery();
    };
  }, [
    initialPendingRequestId,
    opportunity?.metadata?.pendingRequestIds,
    removeOpportunityQuery,
  ]);

  useEffect(() => {
    /** If created in the cloud, immediately call success handler
     */
    if (opportunity?.isCreatedInCloud) {
      handleCreationSuccess();
      return;
    }

    /** wait until:
     * - opportunityDataIsUpToDateWithEvents
     * - there are no pendingRequestIds, as we want to be sure we have the latest
     * */
    if (
      !opportunityDataIsUpToDateWithEvents ||
      !!opportunity?.metadata?.pendingRequestIds?.length
    )
      return;

    /** When opportunity data is up to date with operationEvent lastModifedDate,
     *  check if the opportunity has been created in the cloud.
     *  don't use the event to check if it was created in the cloud,
     *  bc there is a delay updating the opportunity data.
     */
    if (
      !opportunity?.isCreatedInCloud &&
      !!opportunity?.metadata?.hasCloudErrors
    ) {
      handleCreationError();
      return;
    }

    handleCreationSuccess();
  }, [
    opportunityDataIsUpToDateWithEvents,
    handleCreationError,
    handleCreationSuccess,
    opportunity?.isCreatedInCloud,
    opportunity?.metadata?.hasCloudErrors,
    opportunity?.metadata?.pendingRequestIds,
  ]);

  return <Loader />;
}
