import { Tab, Tabs, makeStyles } from 'vendor/material';
import { useState } from 'react';
import { fontWeightMedium } from 'utils/fontWeightConstants';

type TabsContentProps = {
  tabs: { label: string; id: string; content: React.ReactNode }[];
  defaultActiveTab?: TabsContentProps['tabs'][number]['id'];
};

const useStyles = makeStyles((theme) => ({
  tab: {
    fontSize: theme.typography.pxToRem(14),
    padding: '12px 16px 10px 16px',
    fontWeight: fontWeightMedium,
    textTransform: 'none',
    minWidth: 'auto',
    borderBottom: `2px solid transparent`,
    '&[aria-selected=true]': {
      color: theme.palette.BLUE400,
      borderColor: `${theme.palette.BLUE400}`,
    },
  },
  tabIndicator: {
    opacity: 0,
  },
}));

const TabsContent = ({ defaultActiveTab, tabs }: TabsContentProps) => {
  const classes = useStyles();

  const [activeTabId, setActiveTabId] = useState<typeof defaultActiveTab>(
    defaultActiveTab || tabs[0].id,
  );

  const tabMap = new Map(tabs.map((tab) => [tab.id, tab]));

  const handleTabClick = (_e: unknown, tabId: typeof defaultActiveTab) => {
    setActiveTabId(tabId);
  };

  return (
    <div>
      <Tabs
        key={activeTabId}
        onChange={handleTabClick}
        value={activeTabId}
        TabIndicatorProps={{
          className: classes.tabIndicator,
        }}
      >
        {tabs.map((tab) => (
          <Tab
            className={classes.tab}
            key={tab.id}
            value={tab.id}
            label={tab.label}
            data-testid={`tab-${tab.id}`}
          />
        ))}
      </Tabs>
      <div role="tabpanel">{tabMap.get(activeTabId)?.content}</div>
    </div>
  );
};

export default TabsContent;
