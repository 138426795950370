import React, { useState } from 'react';
import useStyles from './RightRailButtonSection.styles';
import { Box } from 'vendor/material';
import { Button } from '@tackle-io/platform-ui';
import { coSellDetailsPageDataId } from 'packages/cosell/src/utilities/intercomEnums';
import {
  AceInvitationCloudStatusEnum,
  DisplayCloudType,
} from 'packages/cosell/src/types/enums';
import RightRailRejectModal from './RightRailRejectModal';
import { useAceInvitation } from 'packages/cosell/api/hooks/useAceInvitation';
import useToast from 'hooks/useToast/useToast';
import {
  TOASTS,
  INVITATION_ACCEPTANCE_SUBMITTED,
  INVITATION_REJECTION_SUBMITTED,
} from 'packages/cosell/src/components';
import { useCoSellContext } from 'packages/cosell/src/CoSellContextProvider';
import { COSELL_PATH } from 'packages/cosell/src/utilities/constants';
import { useHistory } from 'react-router-dom';
import { PollingStatusButton } from 'packages/cosell/src/components/RightRailSubSection/PollingStatusButton';
import { AcceptInvitationModalForm } from '../AcceptInvitationModal/AcceptInvitationModal';
import { ViewSfOpportunityButton } from './ViewSfOpportunityButton';
import { useHasCoSellRbacRights } from 'packages/cosell/src/hooks/useHasCoSellRbacRights/useHasCoSellRbacRights';

interface RightRailButtonSectionProps {
  status: AceInvitationCloudStatusEnum;
  customerCompanyName: string;
  hasPendingRequest: boolean;
  invitationId: string;
  setPendingOperationId: React.Dispatch<
    'acceptInvitation' | 'rejectInvitation' | null
  >;
}

const RightRailButtonSection: React.FC<RightRailButtonSectionProps> = ({
  status,
  customerCompanyName,
  hasPendingRequest,
  setPendingOperationId,
  invitationId,
}) => {
  const classes = useStyles();
  const rbacRights = useHasCoSellRbacRights();
  const variantByStatus = 'contained';
  const [acceptModalOpen, setAcceptModalOpen] = useState<boolean>(false);
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
  const { renderEnv } = useCoSellContext();
  const [isProcessingOperation, setIsProcessingOperation] =
    useState<boolean>(hasPendingRequest);
  const { acceptInvitation, aceInvitationQuery } = useAceInvitation({
    invitationId,
    enablePolling: isProcessingOperation,
  });

  const { toaster } = useToast();
  const history = useHistory();
  const isDownstream = renderEnv === 'downstream';
  const isPendingStatus = status === AceInvitationCloudStatusEnum.PENDING;
  const encodedCustomerCompanyName = encodeURIComponent(customerCompanyName);
  const cloudOpportunityId = aceInvitationQuery.data?.opportunityId;
  const sfOpportunityId = aceInvitationQuery.data?.metadata?.crmId;
  const tackleOpportunityId =
    aceInvitationQuery.data?.metadata?.tackleOpportunityId;
  const canAcceptOrRejectInvitation =
    rbacRights.acceptInvitation && rbacRights.rejectInvitation;

  const showAcceptAndRejectButtons =
    canAcceptOrRejectInvitation && isPendingStatus && !isProcessingOperation;
  const onAcceptInvitation = async (formValues?: { crmId?: string }) => {
    if (invitationId) {
      await acceptInvitation.mutateAsync(
        {
          invitationId,
          ...(formValues?.crmId ? { crmId: formValues.crmId } : null),
        },
        {
          onSuccess: () => {
            if (acceptModalOpen) {
              setAcceptModalOpen(false);
            }
            toaster(TOASTS[INVITATION_ACCEPTANCE_SUBMITTED]);
            setPendingOperationId('acceptInvitation');
            setIsProcessingOperation(true);
          },
        },
      );
    }
  };

  const handleAcceptInvitation = () => {
    if (renderEnv !== 'sf_canvas') {
      onAcceptInvitation();
      return;
    }
    setAcceptModalOpen(true);
  };

  const handleRejectInvitation = () => {
    toaster(TOASTS[INVITATION_REJECTION_SUBMITTED]);
    setRejectModalOpen(false);

    setPendingOperationId('rejectInvitation');

    setIsProcessingOperation(true);
  };

  return (
    <>
      <Box className={classes.root}>
        {isProcessingOperation && isPendingStatus && <PollingStatusButton />}

        {status === AceInvitationCloudStatusEnum.ACCEPTED &&
          sfOpportunityId &&
          cloudOpportunityId &&
          renderEnv === 'sf_canvas' && (
            <ViewSfOpportunityButton
              cloudOpportunityId={cloudOpportunityId}
              crmId={sfOpportunityId}
            />
          )}

        {status === AceInvitationCloudStatusEnum.ACCEPTED && isDownstream && (
          <Button
            variant={variantByStatus}
            data-id={coSellDetailsPageDataId.VIEW_OPPORTUNITY}
            appearance="primary"
            onClick={() => {
              const redirectPath = !!tackleOpportunityId
                ? `/opportunity/aws/${tackleOpportunityId}`
                : `?source=Inbound&q=${encodedCustomerCompanyName}`;
              history.push(`${COSELL_PATH}${redirectPath}`);
            }}
          >
            View co-sell
          </Button>
        )}

        {showAcceptAndRejectButtons && (
          <Box className={classes.actionButton}>
            <Button
              variant="contained"
              data-id={coSellDetailsPageDataId.ACCEPT_INVITATION}
              appearance="primary"
              onClick={handleAcceptInvitation}
            >
              Accept
            </Button>

            <Button
              variant="contained"
              data-id={coSellDetailsPageDataId.REJECT_INVITATION}
              appearance="destructive"
              onClick={() => setRejectModalOpen(true)}
            >
              Reject
            </Button>
          </Box>
        )}
      </Box>
      {rejectModalOpen && (
        <RightRailRejectModal
          handleRejectInvitation={handleRejectInvitation}
          invitationId={invitationId}
          setRejectModalOpen={setRejectModalOpen}
        />
      )}
      {acceptModalOpen && (
        <AcceptInvitationModalForm
          cloud={DisplayCloudType.AWS}
          onClose={() => setAcceptModalOpen(false)}
          onSubmit={onAcceptInvitation}
        />
      )}
    </>
  );
};

export default RightRailButtonSection;
