import { DateTime } from 'luxon';

export const formatDateYMD = (date?: DateTime): string =>
  date?.toFormat('yyyy-LL-dd') ?? '--';

const toStringDateUsingFormat = (
  format: string,
  date?: DateTime,
): string | null => date?.toFormat(format) ?? null;

export const dateTimeToString = (date?: DateTime): string | null => {
  const format = "yyyy-LL-dd'T'TT'Z'";
  return toStringDateUsingFormat(format, date);
};

export const dateTimeNoTimestampToString = (date?: DateTime): string | null => {
  const format = 'yyyy-LL-dd';
  return toStringDateUsingFormat(format, date);
};
