import { makeStyles } from 'vendor/material';

export const useSalesforceTableStyles = makeStyles((theme) => ({
  tableContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    boxShadow: theme.shadows[1],
    padding: '16px',
    marginBottom: theme.spacing(2),
    alignSelf: 'stretch',
    width: '100%',
  },
  cell: {
    padding: theme.spacing(1.5),
    textAlign: 'left',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  link: {
    color: '#0052CC',
    textDecoration: 'none',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    overflow: 'hidden',
    maxWidth: '100%',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  instanceUrl: {
    color: theme.palette.grey[500],
    marginTop: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    textOverflow: 'ellipsis',
  },
  timestamp: {
    color: '#6B778C',
    marginTop: theme.spacing(0.5),
    display: 'block',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  statusTimestamp: {
    color: '#6B778C',
    marginTop: theme.spacing(0.5),
    display: 'block',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  statusCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingLeft: theme.spacing(2),
  },
  noDataMessage: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  alignUnderHeader: {
    paddingLeft: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  headerContainer: {
    display: 'flex',
    padding: '16px 24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    borderBottom: '1px solid #F4F5F7',
    background: '#FFF',
  },
  headerText: {
    fontSize: '24px',
    fontWeight: 600,
    color: '#253858',
  },
  headerSubText: {
    width: '341px',
    color: '#5E6C84',
    fontFamily: '"Open Sans", sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    marginTop: '8px',
  },
  tableHeaderBox: {
    backgroundColor: '#EBECF0',
    padding: theme.spacing(1.5),
    borderRadius: 4,
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  integrationIcon: {
    height: '32px',
    width: '32px',
    marginRight: '8px',
  },
  environmentColumn: {
    textAlign: 'left',
    paddingLeft: theme.spacing(2),
  },
  statusColumn: {
    textAlign: 'left',
    paddingLeft: theme.spacing(3),
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  orgNameCell: {
    paddingLeft: theme.spacing(3),
  },
  user: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  externalIcon: {
    marginLeft: '8px',
    width: '16px',
    height: '16px',
    verticalAlign: 'middle',
  },
  connectButton: {
    backgroundColor: '#0052CC',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#003D99',
    },
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  tableRow: {
    padding: '8px 0',
    borderBottom: '1px solid #DFE1E6',
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  headerColumn: {
    fontWeight: 600,
    color: '#172B4D',
  },
  disconnectButtonContainer: {
    marginTop: '16px',
    textAlign: 'left',
  },
  environmentTag: {
    background: '#DFE1E6',
    fontWeight: 500,
    padding: '4px 8px',
    borderRadius: '6px',
    color: '#505F79',
    display: 'inline-flex',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  tableValue: {
    fontSize: '14px',
    fontWeight: 500,
  },
}));
