import React from 'react';
import { Divider, Grid, useMediaQuery, useTheme } from 'vendor/material';
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding';
import InfoItem, {
  FULL_GRID_SIZE,
} from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
import { convertArrayToString } from '../../helper';
import { OpportunityProject } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import { ExpectedCustomerSpendDetails } from './ExpectedCustomerSpendDetails';
import { coSellAceOpportunityFormFieldsDataId } from 'packages/cosell/src/utilities/intercomEnums';
import { AcePartnerNeedType } from 'packages/cosell/src/types/enums';

interface AceProjectDetailsProps {
  opportunityType: string;
  primaryNeedsFromAws?: string[];
  project: OpportunityProject;
  targetCloseDate: string;
  awsProducts: string[];
  solutions: string[];
}

const AceProjectDetails: React.FC<AceProjectDetailsProps> = ({
  opportunityType,
  primaryNeedsFromAws,
  project,
  targetCloseDate,
  awsProducts,
  solutions,
}) => {
  const theme = useTheme();
  const screenMobile = useMediaQuery(theme.breakpoints.down(960));

  return (
    <DetailsSectionCard title="Project details" icon={<OfficeBuilding />}>
      <Grid container spacing={2}>
        <InfoItem
          itemTitle="Partner primary need from AWS"
          value={
            primaryNeedsFromAws?.length
              ? AcePartnerNeedType.CO_SELL_WITH_AWS
              : AcePartnerNeedType.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP
          }
          dataId={coSellAceOpportunityFormFieldsDataId.PRIMARY_NEED_FROM_AWS}
        />
        {screenMobile && <Divider style={{ width: '100%' }} />}
        <InfoItem
          itemTitle="Partner specific need(s) from AWS for co-sell"
          value={primaryNeedsFromAws?.join(', ')}
          dataId={coSellAceOpportunityFormFieldsDataId.PRIMARY_NEEDS_FROM_AWS}
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          itemTitle="Opportunity type"
          value={opportunityType}
          dataId={coSellAceOpportunityFormFieldsDataId.OPPORTUNITY_TYPE}
        />
        {screenMobile && <Divider style={{ width: '100%' }} />}
        <InfoItem
          itemTitle="Sales activities"
          value={project.salesActivities?.join(', ')}
          dataId={coSellAceOpportunityFormFieldsDataId.SALES_ACTIVITIES}
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          itemTitle="Partner project title"
          value={project?.title}
          gridSize={FULL_GRID_SIZE}
          dataId={coSellAceOpportunityFormFieldsDataId.PROJECT_TITLE}
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          gridSize={FULL_GRID_SIZE}
          itemTitle="Customer business problem"
          value={project?.customerBusinessProblem}
          dataId={
            coSellAceOpportunityFormFieldsDataId.CUSTOMER_BUSINESS_PROBLEM
          }
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          itemTitle="Solution offered"
          value={convertArrayToString(solutions)}
          dataId={coSellAceOpportunityFormFieldsDataId.SOLUTIONS_OFFERED}
        />

        {screenMobile && <Divider style={{ width: '100%' }} />}
        <InfoItem
          itemTitle="AWS products"
          value={convertArrayToString(awsProducts)}
          dataId={coSellAceOpportunityFormFieldsDataId.AWS_PRODUCTS}
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          itemTitle="Use case"
          value={project?.customerUseCase}
          dataId={coSellAceOpportunityFormFieldsDataId.CUSTOMER_USE_CASE}
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          gridSize={FULL_GRID_SIZE} // TODO: Remove when we add AWS products
          itemTitle="Delivery model"
          value={convertArrayToString(project?.deliveryModels)}
          dataId={coSellAceOpportunityFormFieldsDataId.DELIVERY_MODEL}
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          itemTitle="APN programs"
          value={convertArrayToString(project?.apnPrograms)}
          dataId={coSellAceOpportunityFormFieldsDataId.APN_PROGRAMS}
          gridSize={FULL_GRID_SIZE}
        />
        <Divider style={{ width: '100%' }} />
        <InfoItem
          gridSize={FULL_GRID_SIZE}
          itemTitle="Target close date"
          value={targetCloseDate}
          dataId={coSellAceOpportunityFormFieldsDataId.TARGET_CLOSE_DATE}
        />
        <Divider style={{ width: '100%' }} />
        {!!project?.expectedCustomerSpend && (
          <>
            <ExpectedCustomerSpendDetails
              expectedCustomerSpend={project.expectedCustomerSpend}
            />
          </>
        )}
      </Grid>
    </DetailsSectionCard>
  );
};

export default AceProjectDetails;
