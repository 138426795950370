import { makeStyles } from 'vendor/material';
import {
  TableError,
  TableLoadingRows,
} from 'packages/cosell/src/pages/CoSellLandingPage/components';
import { fontWeightMediumLarge } from 'utils/fontWeightConstants';
import { ListHeader } from '../components/ListHeader';
import StatusBadge from '../components/StatusBadge';
import { CloudProvider } from '../consts';
import ListItemCard from '../components/ListItemCard';
import ActionButton from '../components/ActionButton';

export type Column = {
  id: 'name' | 'status' | 'action';
  label: string | null;
  dataId: string;
};

type DataItem = {
  crmId: string;
  // Todo: add other statuses when all list items are added
  // status: 'submitted' | 'error' | 'in-progress' | 'pending';
  status: 'submitted' | 'error';
  tackleId: string | null;
  title: string | null;
  error: string | null;
};

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    display: 'grid',
    gap: theme.spacing(1),
    color: theme.palette.NEUTRAL900,
  },
  listItem: {
    color: theme.palette.NEUTRAL900,
    boxShadow: theme.depth.d100,
    minHeight: theme.spacing(7.5),
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.NEUTRAL000,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.NEUTRAL020,
    },
    '@media (max-width: 1028px)': {
      overflow: 'scroll',
    },
    margin: `${theme.spacing(1)}px 0`,
    borderRadius: '4px',
    width: '100%',
  },
  listItemNameGroup: {
    display: 'grid',
  },
  listItemNameLabel: {
    lineHeight: theme.typography.pxToRem(24),
    minHeight: theme.typography.pxToRem(24),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&:empty': {
      display: 'none',
    },
  },
  listItemIdLabel: {
    color: theme.palette.NEUTRAL300,
    fontWeight: fontWeightMediumLarge,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    alignSelf: 'center',
  },
  emptyTableContent: {
    padding: theme.spacing(2),
    fontWeight: fontWeightMediumLarge,
    display: 'grid',
    placeContent: 'center',
  },
  actionGroup: {
    textAlign: 'right',
    fontSize: theme.typography.pxToRem(14),
  },
}));

const EmptyTableContent = () => {
  const classes = useStyles();
  return <div className={classes.emptyTableContent}>No data!</div>;
};

const defaultColumns: Column[] = [
  {
    id: 'name',
    label: 'Opportunity',
    dataId: 'salesforce-opportunity-id',
  },
  {
    id: 'status',
    label: 'Status',
    dataId: 'bulk-create-list-item-status',
  },
  {
    id: 'action',
    label: null,
    dataId: 'bulk-create-list-item-action',
  },
];

const rowGrid = '3fr 1fr 1fr';

export const JobItemList = ({
  cloud,
  items,
  isInitialLoading,
  isError,
}: {
  cloud?: CloudProvider;
  isInitialLoading: boolean;
  isError: boolean;
  items?: DataItem[];
}) => {
  const classes = useStyles();

  if (isError) return <TableError />;

  return (
    <>
      <div className={classes.tableContainer} role="grid">
        <ListHeader columns={defaultColumns} rowGrid={rowGrid} />
        {isInitialLoading ? (
          <TableLoadingRows pageLimit={10} />
        ) : items && cloud ? (
          <List cloud={cloud} items={items} />
        ) : null}
      </div>
    </>
  );
};

const List = ({
  cloud,
  items,
}: {
  cloud: CloudProvider;
  items: DataItem[];
}) => {
  const classes = useStyles();
  if (!items.length) return <EmptyTableContent />;

  return (
    <>
      {items.map((item) => (
        <ListItemCard
          data-testid={`opportunity-${item.crmId}`}
          key={item.crmId}
        >
          <div
            style={{
              display: 'grid',
              gap: '32px',
              gridTemplateColumns: rowGrid,
            }}
          >
            <div className={classes.listItemNameGroup}>
              <span className={classes.listItemNameLabel}>{item.title}</span>
              <span className={classes.listItemIdLabel}>
                CRM ID {item.crmId}
              </span>
            </div>
            <div>
              <StatusBadge status={item.status} />
            </div>
            <div className={classes.actionGroup}>
              {cloud === 'aws' &&
              item.tackleId &&
              item.status === 'submitted' ? (
                <ActionButton
                  href={`/co-sell/opportunity/${cloud}/${item.tackleId}`}
                >
                  View co-sell
                </ActionButton>
              ) : null}
              {cloud === 'aws' && item.tackleId && item.status === 'error' ? (
                <ActionButton
                  href={`/co-sell/opportunity/${cloud}/${item.tackleId}/edit`}
                >
                  Edit co-sell
                </ActionButton>
              ) : null}
            </div>
          </div>
        </ListItemCard>
      ))}
    </>
  );
};
