import { useCallback, useState } from 'react';

/** simple hook to manage boolean toggle state */
const useToggleState = (initialState: boolean): [boolean, () => void] => {
  const [isOpen, setIsOpen] = useState(initialState);

  const toggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, [setIsOpen]);

  return [isOpen, toggle];
};

export default useToggleState;
