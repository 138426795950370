import { Box } from 'vendor/material';
import { Card, Button } from '@tackle-io/platform-ui';
import { TransitConnectionVariant } from 'mdi-material-ui';
import integrationIconUrl from './../assets/integration_icon.png';
import { useCanvasTackleIntegrationStyles } from './CanvasTackleIntegration.styles';

interface SetupCardProps {
  isPendingConnection: boolean;
  isConnecting: boolean;
  onConnect: () => void;
}

export function SetupCard({
  isPendingConnection,
  isConnecting,
  onConnect,
}: SetupCardProps) {
  const classes = useCanvasTackleIntegrationStyles();

  return (
    <Card
      icon={<TransitConnectionVariant />}
      title="Setup your integration"
      subtitle="Set up the Tackle app and sync your Marketplace, Co-Sell, and Prospect data."
    >
      <Box className={classes.connectContainer}>
        <img
          src={integrationIconUrl}
          alt="Integration icon"
          className={classes.integrationIcon}
        />
        <Button
          className={classes.connectButton}
          variant="contained"
          color="secondary"
          disabled={isPendingConnection}
          onClick={onConnect}
        >
          {isConnecting ? 'Connecting...' : 'Connect to Tackle'}
        </Button>
      </Box>
    </Card>
  );
}
