import { Button } from '@tackle-io/platform-ui';
import TackleCoSellAnimatedHandshake from 'components/AnimatedSvgs/TackleCoSellAnimatedHandshake';
import { Box, Grid, makeStyles, Typography } from 'vendor/material';
import { LightbulbOnOutline } from 'mdi-material-ui';
import useCoSellSfCanvasActions from 'packages/salesforce-canvas/cosell/src/hooks/useCoSellSfCanvasActions';
import {
  CloudDisplayLabelLookup,
  DisplayCloudType,
} from 'packages/cosell/src/types/enums';
import React from 'react';
import { fontWeightMediumLarge } from 'utils/fontWeightConstants';
import NoCoSellYet from './NoCoSellYet';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    background: 'linear-gradient(to bottom, #FFFFFF99, #DEF0F399)',
    boxSizing: 'border-box',
    textWrap: 'pretty',
  },
  expertTip: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    marginBottom: theme.spacing(1.5),
    '& svg': {
      fill: theme.palette.YELLOW500,
      fontSize: '1.7em',
      verticalAlign: 'text-bottom',
    },
  },
  expertTipBody: {
    fontSize: theme.typography.pxToRem(12),
    color: '#253858',
  },
  button: {
    backgroundColor: theme.palette.common.white,
    whiteSpace: 'nowrap',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
  },
  headerContent: {
    '&:empty': {
      display: 'none',
    },
  },
  mainContent: {
    padding: theme.spacing(2),
    flex: 1,
    display: 'grid',
    gap: '16px',
  },
  title: {
    color: theme.palette.NEUTRAL900,
    textWrap: 'pretty',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: fontWeightMediumLarge,
    lineHeight: theme.typography.pxToRem(30),
  },
  footer: {
    padding: theme.spacing(2),
  },
}));

const cloudOptions = [
  DisplayCloudType.AWS,
  DisplayCloudType.MICROSOFT,
] as const;
type AllowedCloud = typeof cloudOptions[number];

const CoSellCallToAction = ({
  singleCloud,
  headerContent,
}: {
  /** provide cloud if you only want to show 1 cloud,
   *  otherwise it wil show all cloud options
   */
  singleCloud?: AllowedCloud;
  headerContent?: React.ReactNode;
}) => {
  const { handleCoSellAction } = useCoSellSfCanvasActions();
  const classes = useStyles();

  const clouds: Array<AllowedCloud> = singleCloud
    ? [singleCloud]
    : [...cloudOptions];

  const title = `Co-sell with ${
    singleCloud ? CloudDisplayLabelLookup[singleCloud] : 'your cloud partners'
  } to unlock revenue`;

  return (
    <Grid container direction="column" className={classes.container}>
      <Box className={classes.mainContent}>
        <Box className={classes.headerContent}>{headerContent}</Box>
        <Grid item container>
          <Grid
            item
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item>
              <TackleCoSellAnimatedHandshake />
            </Grid>
            <Grid item>
              <Typography className={classes.title} variant="h6" align="center">
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle2"
                align="center"
                className={classes.expertTip}
              >
                <LightbulbOnOutline fontSize="inherit" /> Tackle expert tip
              </Typography>
              <Typography
                className={classes.expertTipBody}
                variant="body2"
                align="center"
              >
                Co-sell increases win rates by 27% and deal size by 80%.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid className={classes.footer} item container spacing={1}>
        {clouds.map((cloud) => (
          <Grid item key={cloud} xs>
            <Button
              fullWidth
              variant="outlined"
              appearance="primary"
              className={classes.button}
              onClick={() =>
                handleCoSellAction({
                  id: 'create-cosell',
                  cloud,
                })
              }
            >
              Co-sell with {CloudDisplayLabelLookup[cloud]}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const CoSellZeroStateView = ({
  canCreateCoSell,
  ...callToActionProps
}: { canCreateCoSell: boolean } & React.ComponentProps<
  typeof CoSellCallToAction
>) => {
  return canCreateCoSell ? (
    <CoSellCallToAction {...callToActionProps} />
  ) : (
    <NoCoSellYet />
  );
};

export default CoSellZeroStateView;
