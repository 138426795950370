import { makeStyles } from 'vendor/material';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  card: {
    color: theme.palette.NEUTRAL900,
    boxShadow: theme.depth.d100,
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.NEUTRAL000,
    borderRadius: '4px',
    width: '100%',
  },
}));

const ListItemCard = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const classes = useStyles();
  return (
    <div className={classNames([classes.card, className])}>{children}</div>
  );
};

export default ListItemCard;
