import { ActivitySlug, Marketplace, OfferType } from '../../types/TackleOffer';

export interface AwsPrivateOffer {
  acceptedAt?: string;
  activities?: Activity[];
  archivedAt?: string;
  awsRenewalMovingToMarketplace?: boolean;
  buyerBillingAccountRef?: string;
  buyerCompanyName?: string;
  buyers?: Buyer[];
  cancelledAt?: string;
  cloudContractId?: string;
  createdAt?: string;
  createdInMarketplaceAt?: string;
  enableZeroDollarPrices?: boolean;
  eula?: EULA;
  extraData?: ExtraData;
  lastModifiedAt?: string;
  marketplace?: Marketplace;
  offerDescription?: string;
  offerEndAt?: string;
  offerExpirationAt?: string;
  offerMetadata?: { [key: string]: any };
  offerName?: string;
  offerPointOfContact?: string;
  offerPointOfContactName?: string;
  offerStartAt?: string;
  offerType?: OfferType;
  offerId?: string;
  openedInstructionsAt?: string;
  partnerOffer?: PartnerOffer;
  poId?: string;
  preRegistrationDetails?: { [key: string]: any };
  pricing?: Pricing;
  productId?: string;
  renewal?: boolean;
  salesforce?: Salesforce;
  salesforceId?: string;
  sentAt?: string;
  sourceOfferId?: string;
  stage?: string;
  status?: string;
  vendorId?: string;
  viewedOfferAt?: string;
  principalType?: PrincipalType;
}

export interface Activity {
  activityType?: string;
  createdAt?: string;
  metadata?: { [key: string]: any };
  slug?: ActivitySlug;
  userId?: string;
}

export interface Buyer {
  emailAddress: string;
  fullName?: string;
  title?: string;
}

export interface EULA {
  documentUrns?: string[];
  files?: string[];
  type: EULAType;
}

export enum EULAType {
  Public = 'public',
  AwsStandard = 'aws-standard',
  Custom = 'custom',
  UseExisting = 'use-existing',
}

export interface ExtraData {
  users?: UserToNotify[];
}

export interface UserToNotify {
  email?: string;
}

export interface PartnerOffer {
  partnerName?: string;
  partnerOfferRef?: string;
  partnerRef?: string;
  resellerAgreement?: ResellerAgreement;
}

export interface ResellerAgreement {
  documentUrns?: string[];
  files?: string[];
  type?: ResellerAgreementType;
}

export enum ResellerAgreementType {
  NoneSelected = 'none-selected',
  Custom = 'custom',
  Rcmp20211201 = 'rcmp-2021-12-01',
}

export interface Pricing {
  allowAutoRenew?: boolean;
  billingTerm?: BillingTerm;
  currencyCode?: string;
  dimensions?: Dimension[];
  duration?: string;
  durationType?: DurationType;
  durationValue?: number;
  marketplaceFee?: string;
  monthlyContractValue?: string;
  netContractValue?: string;
  paymentModel?: PaymentModel;
  schedule?: Schedule[];
  serviceEndAt?: string;
  serviceStartAt?: string;
  showOnEmail?: boolean;
  totalContractValue?: string;
  units?: Unit[];
  usageDimensions?: UsageDimension[];
  version?: PricingVersion;
}

export enum PricingVersion {
  SimplePricingV1 = 'simple-pricing-v1-aws',
}

export enum BillingTerm {
  FutureDated = 'future_dated',
  Custom = 'custom',
  OneMonth = '1-month',
  OneYear = '1-year',
  TwoYears = '2-years',
  ThreeYears = '3-years',
}

export interface Dimension {
  absolutePrices?: AbsolutePrice[];
  apiName?: string;
  description?: string;
  dimensionValue?: string;
  discountPercentage?: number;
  name?: string;
  price?: string;
  quantity?: string;
}

export interface AbsolutePrice {
  billingTerm?: AbsolutePriceBillingTerm;
  paymentOption?: AbsolutePricePaymentOption;
  pricePerPaymentInUsd?: number;
}

export interface AbsolutePriceBillingTerm {
  type?: string;
  value?: number;
}

export interface AbsolutePricePaymentOption {
  type?: string;
  value?: number;
}

export enum DurationType {
  Months = 'months',
  Quarterly = 'quarterly',
  Annually = 'annually',
}

export enum PaymentModel {
  PayGo = 'saas_subscription',
  PaymentSchedule = 'payment_schedule',
  PerProduct = 'per_product',
  OneTime = 'one_time',
  PerMonth = 'per_month',
  PerYear = 'per_year',
}

export interface Schedule {
  invoiceAmount?: string;
  invoiceDate?: string;
  invoiceDateType?: string;
}

export interface Unit {
  durationPrice?: string;
  hourlyPrice?: string;
  unit?: string;
}

export interface UsageDimension {
  description?: string;
  price?: string;
  sku?: string;
}

export interface Salesforce {
  customObjectId?: string;
  opportunityId?: string;
}

export enum PrincipalType {
  Machine = 'machine',
  MachineOBO = 'machineobo',
  User = 'user',
  Staff = 'staff',
  OTKUser = 'otkuser',
}
