import { Banner } from '@tackle-io/platform-ui';
import { OfficeBuilding } from 'mdi-material-ui';
import { FormSectionConfig } from '../constants';
import FormSectionCard from '../components/FormSectionCard';
import useToggleState from 'hooks/useToggleState';
import { AddCustomerFormDrawer } from './AddCustomerForm';

export const CustomerDetailsFormSection = () => {
  const [drawerIsOpen, toggleDrawer] = useToggleState(false);

  return (
    <>
      <FormSectionCard
        title={FormSectionConfig.customerDetails.title}
        subtitle={null}
        icon={<OfficeBuilding style={{ fill: '#253858' }} />}
        data-id={FormSectionConfig.customerDetails.dataId}
        elevation={2}
      >
        <Banner
          title="Customer is required to create a referral."
          type="info"
        />
        <FormSectionCard.BlockMessage>
          You haven't added a customer yet.
        </FormSectionCard.BlockMessage>
        <FormSectionCard.AddButton onClick={toggleDrawer}>
          Add customer
        </FormSectionCard.AddButton>
      </FormSectionCard>
      <AddCustomerFormDrawer open={drawerIsOpen} onClose={toggleDrawer} />
    </>
  );
};

export default CustomerDetailsFormSection;
