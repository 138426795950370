import { useState, useEffect } from 'react';
import { Loader } from '@tackle-io/platform-ui';
import { CheckCircle } from 'mdi-material-ui';
import { useConnectedAppCallbackStyles } from './ConnectedAppCallback.styles';
import { Icon } from 'vendor/material';
import { ReactQueryClientProvider } from 'providers/ReactQueryClientProvider';
import { unescape } from 'lodash';
import { SALESFORCE_AUTH_COMPLETE } from './CanvasTackleIntegrationStatus';
import { AccountConnectionModal } from './AccountConnectionModal';
import { gql } from '@apollo/client';
import { isEmpty } from 'lodash';
import {
  useSalesforceConnectionDetailsQuery,
  useCreateSalesforceRegistrationMutation,
} from 'generated/graphql';
const CODE_PARAM = 'code';
const STATE_PARAM = 'state';

export const ACCOUNT_SWITCHING_GET_VENDORS_QUERY = gql`
  query SalesforceConnectionDetails {
    salesforceConnectionDetails {
      vendorId
      vendorName
      vendorType
      registration {
        organization {
          id
          name
          instanceName
          isSandbox
          organizationType
          namespacePrefix
        }
        user {
          userId
          organizationId
          email
          givenName
          familyName
          userType
        }
        instanceUrl
        vendorId
        tackleOrgId
        errorCode
        errorMessage
        status
        createdAt
        updatedAt
        packageVersion
      }
    }
  }
`;

export const SALESFORCE_REGISTRATION_MUTATION = gql`
  mutation CreateSalesforceRegistration(
    $authorizationCode: String!
    $loginURL: String!
    $namespace: String!
    $packageVersion: String!
    $vendorId: String!
    $redirectURL: String
  ) {
    salesforceRegistration(
      authorizationCode: $authorizationCode
      loginURL: $loginURL
      namespace: $namespace
      packageVersion: $packageVersion
      vendorId: $vendorId
      redirectURL: $redirectURL
    ) {
      organization {
        id
        name
        instanceName
        isSandbox
        organizationType
        namespacePrefix
      }
      user {
        userId
        organizationId
        email
        givenName
        familyName
        userType
      }
      instanceUrl
      vendorId
      tackleOrgId
      errorCode
      errorMessage
      status
      createdAt
      updatedAt
      orgId
      namespace
      packageVersion
    }
  }
`;

const ConnectedAppCallback = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get(CODE_PARAM)?.toString();
  const state = JSON.parse(urlParams.get(STATE_PARAM)?.toString());
  const classes = useConnectedAppCallbackStyles();
  const salesforceInstanceUrl = state.loginUrl;
  const isSandbox = state.isSandbox;
  const packageVersion = state.packageVersion;
  const {
    data: sfRegistrationInformation,
    loading: sfRegistrationLoading,
    error: sfRegistrationError,
  } = useSalesforceConnectionDetailsQuery();
  const [vendorId, setVendorId] = useState<string | null>(null);

  const [
    registerSalesforce,
    { data: createRegistrationData, loading, error: createRegistrationError },
  ] = useCreateSalesforceRegistrationMutation();

  const handleRegistration = async (selectedVendorId: string) => {
    setVendorId(selectedVendorId);
  };

  useEffect(() => {
    if (vendorId && code) {
      const register = async () => {
        try {
          await registerSalesforce({
            variables: {
              authorizationCode: unescape(code),
              loginURL: salesforceInstanceUrl,
              namespace: state.packageNamespace,
              packageVersion: packageVersion,
              vendorId: vendorId,
              redirectURL:
                process.env
                  .REACT_APP_CRM_CONNECTOR_SALESFORCE_REGISTER_REDIRECT_URL,
            },
          });
        } catch (e: any) {
          console.error(
            '📡 GraphQL Error Details:',
            e.networkError?.result || e.message,
          );
        }
      };

      register();
    }
  }, [
    vendorId,
    code,
    packageVersion,
    registerSalesforce,
    salesforceInstanceUrl,
    state.packageNamespace,
  ]);

  if (sfRegistrationLoading || loading) {
    return <Loader />;
  }

  if (sfRegistrationError) {
    return (
      <p>Error loading vendor information: {sfRegistrationError?.message}</p>
    );
  }

  if (!sfRegistrationInformation) {
    return <p>No vendor information available</p>;
  }

  if (!vendorId) {
    return (
      <AccountConnectionModal
        sfRegistrationInformation={sfRegistrationInformation}
        onRegistration={handleRegistration}
        isSfOrgSandbox={isSandbox}
      />
    );
  }

  if (createRegistrationData && !isEmpty(createRegistrationData)) {
    return (
      <div className={classes.successContainer}>
        <Icon fontSize="large">
          <CheckCircle />
        </Icon>
        <h2>Successfully connected to Salesforce</h2>
        <button
          onClick={() => {
            const successData = JSON.stringify({
              type: SALESFORCE_AUTH_COMPLETE,
              payload: createRegistrationData,
            });
            window.opener?.postMessage(successData, window.location.origin);

            window.close();
          }}
        >
          Close Window
        </button>
      </div>
    );
  }

  if (createRegistrationError) {
    return (
      <div className={classes.errorContainer}>
        <h2>There was an issue connecting to Salesforce</h2>
        <p>Please try again</p>
        <button
          onClick={() => {
            JSON.stringify({
              type: 'ERROR',
              status: 'error',
              error: 'Failed to connect to Salesforce.',
              details:
                createRegistrationError.message || createRegistrationError,
            });

            setTimeout(() => window.close(), 500);
          }}
        >
          Close Window
        </button>
      </div>
    );
  }

  return null;
};

export const WrappedConnectedAppCallback = () => {
  return (
    <ReactQueryClientProvider>
      <ConnectedAppCallback />
    </ReactQueryClientProvider>
  );
};

export default ConnectedAppCallback;
