import { Page } from 'components';
import { makeStyles } from 'vendor/material';
import Header from './components/Header';
import TabsContent from './TabsContent';
import StartJobView from './StartJobView/StartJobView';
import AllJobsView from './AllJobsView';
import ComingSoonView from './ComingSoonView/ComingSoonView';
import { DefaultCloud } from './consts';
import { useBulkCreateCloudContext } from './useBulkCreateCloudContext';
import { Switch, Redirect, Route, useRouteMatch } from 'react-router';
import { useCanUseBulkCreate } from './useCanUseBulkCreate';
import { Loader } from '@tackle-io/platform-ui';

/** Using Router to manage selected cloud.
 *  On initial load, or if cloud is not selected,
 *  redirect sets the default cloud
 */
const BulkCreatePageRouter = () => {
  const routeMatch = useRouteMatch();
  const { path } = routeMatch;
  return (
    <Switch>
      <Route exact path={`${path}/:cloud`} component={BulkCreateView} />
      <Redirect to={`${path}/${DefaultCloud}`} />
    </Switch>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.NEUTRAL600,
    padding: theme.spacing(4),
    backgroundColor: '#F3F5F5',
    display: 'grid',
    gridGap: theme.spacing(3),
  },
}));

/**
 * TODO: look into how to not render content that is not selected in the tabs
 *  */

const BulkCreateView = () => {
  const { cloudProvider } = useBulkCreateCloudContext();

  const tabs = [
    {
      id: 'startJob',
      label: 'Start a job',
      content: <StartJobView defaultCloudProvider={cloudProvider} />,
    },
    {
      id: 'allJobs',
      label: 'All jobs',
      content: <AllJobsView cloudProvider={cloudProvider} />,
    },
  ];
  return (
    <>
      <Header
        description="Share Co-sell opportunities, in bulk, with the your cloud partner."
        title="Create Co-sell opportunities in bulk"
      />
      <TabsContent tabs={tabs} />
    </>
  );
};

const BulkCreateCoSellsPage = () => {
  const classes = useStyles();
  const canUse = useCanUseBulkCreate();

  if (canUse.loading) return <Loader />;

  return (
    <Page className={classes.container}>
      {canUse.bulkCreate ? <BulkCreatePageRouter /> : <ComingSoonView />}
    </Page>
  );
};

export default BulkCreateCoSellsPage;
