import { fontWeightMediumLarge } from 'utils/fontWeightConstants';
import { Card, Grid, makeStyles } from 'vendor/material';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: {},
  card: {
    paddingBlock: theme.spacing(2),
    paddingInline: theme.spacing(3),
    backgroundColor: 'white',
    borderRadius: theme.spacing(1),
    height: '100%',
  },
  status: {
    color: theme.palette.NEUTRAL300,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: fontWeightMediumLarge,
    lineHeight: theme.typography.pxToRem(20),
  },
  statusLight: {
    color: '#97A0AF',
  },
  amount: {
    color: theme.palette.NEUTRAL900,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    minHeight: theme.typography.pxToRem(24),
    '&:empty': {
      visibility: 'hidden',
    },
  },
  description: {
    color: '#97A0AF',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: fontWeightMediumLarge,
    lineHeight: theme.typography.pxToRem(20),
    minHeight: theme.typography.pxToRem(20),
    '&:empty': {
      visibility: 'hidden',
    },
  },
  emptyDetails: {
    visibility: 'hidden',
  },
}));

const StatusCountDashboard = ({
  isEmpty,
  statusCounts,
}: {
  isEmpty: boolean;
  statusCounts:
    | Record<string, { status: String; amount: number; description: string }>
    | { status: String; amount: number; description: string }[];
}) => {
  const classes = useStyles();

  const statuses = Array.isArray(statusCounts)
    ? statusCounts
    : Object.values(statusCounts);
  return (
    <Grid
      data-testid="job-status-count-dash"
      direction="row"
      container
      spacing={2}
    >
      {statuses.map(({ status, amount, description }) => (
        <Grid
          xs={6}
          md={statuses.length === 4 ? 3 : 4}
          item
          key={`${status}-${amount}`}
        >
          <Card
            className={classes.card}
            elevation={isEmpty ? 0 : 1}
            data-testid={`status-figure-${status
              ?.replace(' ', '-')
              .toLowerCase()}`}
          >
            <div
              className={classnames(
                classes.status,
                isEmpty ? classes.statusLight : '',
              )}
            >
              {status}
            </div>
            <div
              className={classnames(
                classes.amount,
                isEmpty && classes.emptyDetails,
              )}
            >
              {amount ?? '-'}
            </div>
            <div
              className={classnames(
                classes.description,
                isEmpty && classes.emptyDetails,
              )}
            >
              {description}
            </div>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default StatusCountDashboard;
