import { makeStyles } from 'vendor/material';

export const useCanvasTackleIntegrationStyles = makeStyles((theme) => ({
  integrationWindow: {
    padding: '24px',
  },
  integrationBannerContainer: {
    marginBottom: '12px',
  },
  integrationIcon: {
    marginTop: '24px',
    maxWidth: '222px',
    display: 'block',
    margin: '0px auto',
  },
  connectContainer: {
    textAlign: 'center',
    padding: '32px 0',
  },
  connectButton: {
    '&&': {
      borderRadius: '4px',
      background: '#FFAB00',
      color: '#000000',
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
      padding: '12px 24px',
      textTransform: 'none',
      display: 'block',
      margin: '20px auto 0 auto',
      minWidth: '340px',
    },
    '&:hover': {
      background: '#FFA000',
    },
  },
  outerContainer: {
    borderRadius: '4px',
    background: '#F4F5F7',
    maxWidth: '1410px',
    width: '100%',
    height: 'auto',
    margin: '0 auto',
  },
  mainContainer: {
    maxWidth: '1410px',
    width: '100%',
    height: 'auto',
    borderRadius: '4px',
    background: '#F4F5F7',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    alignItems: 'flex-start',
    gap: '24px',
    width: '100%',
    background: '#FFF',
  },
  disconnectButton: {
    color: 'red',
    border: 'none',
    padding: '8px',
    marginTop: '8px',
    textAlign: 'left',
    display: 'block',
  },
  popupContainer: {
    width: '100%',
    height: '100%',
    padding: '24px',
    background: '#FFF',
    boxShadow: 'none',
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  popupText: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#253858',
  },
  popupImage: {
    width: '180px',
    height: '60.84px',
    marginBottom: '16px',
    marginTop: '8px',
    background: 'url(../assets/tackle-standard-logo.png) 50% / cover no-repeat',
  },
  popupActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  popupTitle: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#253858',
    marginBottom: '16px',
    textAlign: 'center',
  },
  popupDescription: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#5E6C84',
    marginBottom: '24px',
    textAlign: 'center',
  },
  confirmButton: {
    borderRadius: '4px',
    background: '#DE350B',
    display: 'flex',
    padding: '8px 16px',
    alignItems: 'center',
    gap: '8px',
    color: '#FFF',
    fontFamily: '"Open Sans", sans-serif',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    '&:hover': {
      background: '#DE350B',
    },
  },
  cancelButton: {
    color: '#0052CC',
    fontFamily: '"Open Sans", sans-serif',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    background: 'transparent',
    padding: '8px 16px',
    borderRadius: '4px',
    alignSelf: 'flex-start',
  },
  toastContainer: {
    display: 'flex',
    width: '596px',
    padding: '16px 24px',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '4px',
    background: '#00875A',
    boxShadow:
      '0px 0px 1px rgba(9, 30, 66, 0.30), 0px 12px 24px rgba(9, 30, 66, 0.25)',
  },
  toastIcon: {
    width: '24px',
    height: '24px',
    flexShrink: 0,
  },
  toastMessage: {
    flex: 1,
    fontFamily: '"Open Sans", sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#FFFFFF',
  },
  toastCloseIcon: {
    width: '24px',
    height: '24px',
    color: '#FFF',
    cursor: 'pointer',
  },
  noData: {
    textAlign: 'left',
    padding: theme.spacing(2),
    color: theme.palette.NEUTRAL400,
  },
  spacer: {
    height: theme.spacing(0),
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
  },
}));
