import React from 'react';
import { Box, Grid } from 'vendor/material';

import {
  DisplayCloudType,
  TagColorEnum,
  AceOpportunityStageEnum,
  AceInvitationCloudStatusEnum,
} from 'packages/cosell/src/types/enums';
import Tag from 'packages/cosell/src/components/Tag/Tag';
import { SOURCE_TAG } from 'packages/cosell/src/utilities/constants';
import { AwsInvitationStatusTag } from '../../../CoSellLandingPage/components/CoSellInvitationsTable/components';
import { SharedHeader } from 'packages/cosell/src/components';

interface InvitationDetailsHeaderProps {
  title?: string;
  subtitle?: string;
  status?: AceInvitationCloudStatusEnum;
}

const InvitationDetailsHeader: React.FC<InvitationDetailsHeaderProps> = ({
  title,
  subtitle,
  status,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Box mb={1}>
          <SharedHeader
            title={title}
            cloud={DisplayCloudType.AWS}
            subtitle={subtitle}
          />
        </Box>
        <Grid container spacing={1}>
          <Grid item>
            <Tag>{SOURCE_TAG.INBOUND}</Tag>
          </Grid>
          <Grid item>
            {status && <AwsInvitationStatusTag status={status} />}
          </Grid>
          <Grid item>
            <Tag color={TagColorEnum.STANDARD}>
              {AceOpportunityStageEnum.PROSPECT}
            </Tag>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InvitationDetailsHeader;
