import React from 'react';
import { Box, Typography } from 'vendor/material';
import useStyles from './CoSellHeader.styles';
import { CoSellHeaderFilters } from '../CoSellHeaderFilters/CoSellHeaderFilters';
import {
  CreateOpportunityButton,
  Entitlement,
} from 'packages/cosell/src/components/CreateOpportunityButton';
import { useHasCoSellRbacRights } from 'packages/cosell/src/hooks/useHasCoSellRbacRights/useHasCoSellRbacRights';

export const CoSellHeader: React.FC = () => {
  const classes = useStyles();
  const entitlements: Entitlement[] = ['AWS'];
  const rbacRights = useHasCoSellRbacRights();
  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'flex-start'}
      className={classes.headerContainer}
      paddingTop={4}
    >
      <Box display={'flex'} flexDirection={'column'}>
        <Typography
          className={classes.pageTitle}
          component={'h1'}
          variant={'h3'}
        >
          Co-Sell
        </Typography>
        <Typography className={classes.pageSubtitle} variant={'subtitle1'}>
          Co-Sell opportunities with your cloud partners.
        </Typography>
      </Box>
      <Box display={'flex'}>
        <CoSellHeaderFilters />
        {rbacRights.createOpportunity && (
          <CreateOpportunityButton entitlements={entitlements} />
        )}
      </Box>
    </Box>
  );
};
