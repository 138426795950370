import { UnifiedOpportunityFormShell } from '../UnifiedOpportunityFormShell';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import AceOpportunityFormFields from './AceOpportunityFormFields';
import { convertAceFormValuesToCreateRequest } from 'packages/cosell/src/utilities/typeConverters/convertAceFormValuesToCreateRequest';
import { aceOpportunityFormValidationSchema } from './schemas/aceOpportunityFormValidationSchema';
import { CreateAceOpportunityFormValues } from './AceOpportunityFormValues';
import { TackleOperationId } from 'packages/cosell/api/utils';
// import { getMockInitialValues } from 'packages/cosell/api/mockHelpers';
import { Loader } from '@tackle-io/platform-ui';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import { COSELL_CREATE_DEFAULT_VALUES } from 'packages/cosell/src/utilities/constants/CoSellConstants';
import { useAceVendorConfig } from 'packages/cosell/api/hooks/useAceVendorConfig';
import { useAcePickListQuery } from 'packages/cosell/api/hooks/useAcePickList';
import { CreationPending } from './CreationPending/CreationPending';

interface CreateAceOpportunityFormProps {
  /** banners to be passed through to top of form */
  banners?: React.ReactNode;
  /** salesforce opportunity id
   * only added if creating an opportunity from an existing salesforce opportunity
   * TODO: update type so crmId is required if renderEnv is 'sf_canvas'
   */
  crmId?: string;
  onCancel: () => void;
  onCreated: (opportunityId: string) => void;
  initialValues?: Partial<CreateAceOpportunityFormValues>;
}

/**
 *
 * TODO:
 * - if creation failed, the tackle-id is still created and should be "updated" (edit) with instead of creating a new one.
 *   The edit form can then be prefilled with the original payload values
 */
const CreateAceOpportunityForm = ({
  banners: externalBanners,
  crmId,
  onCancel,
  onCreated,
  initialValues,
}: CreateAceOpportunityFormProps) => {
  const acePickListQuery = useAcePickListQuery();
  const { isError: isVendorConfigError, isLoading: isVendorConfigLoading } =
    useAceVendorConfig();
  const { createAceOpportunity } = useAceOpportunity({});

  // const mockInitialValues = getMockInitialValues();
  const handleSubmitToCloud = async (
    values: CreateAceOpportunityFormValues,
  ) => {
    const createAndSubmitReqBody = convertAceFormValuesToCreateRequest(values);
    if (crmId) {
      createAndSubmitReqBody['crmId'] = crmId;
    }

    /** add data-mapper claimCheck to the request body if it exists */
    if (values.tklMetadata) {
      createAndSubmitReqBody['dataMapper'] = {
        '$tkl-metadata': values.tklMetadata,
      };
    }

    return await createAceOpportunity.mutateAsync({
      requestBody: createAndSubmitReqBody,
      tackleOperationId: TackleOperationId.CREATE_OPPORTUNITY,
    });
  };

  const handleSaveDraft = async (values: CreateAceOpportunityFormValues) => {
    const requestBody = convertAceFormValuesToCreateRequest(values);

    if (crmId) {
      requestBody['crmId'] = crmId;
    }

    /** add data-mapper claimCheck to the request body if it exists */
    if (values.tklMetadata) {
      requestBody['dataMapper'] = {
        '$tkl-metadata': values.tklMetadata,
      };
    }

    return await createAceOpportunity.mutateAsync({
      requestBody,
      tackleOperationId: TackleOperationId.CREATE_DRAFT_OPPORTUNITY,
    });
  };

  if (isVendorConfigLoading || acePickListQuery.isLoading) {
    return <Loader />;
  }

  if (isVendorConfigError || acePickListQuery.isError) {
    return <div>Required data failed to load, please refresh</div>;
  }

  if (createAceOpportunity.data?.id) {
    return (
      <CreationPending
        onSuccess={onCreated}
        pendingAceTackleId={createAceOpportunity.data.id}
      />
    );
  }

  const banners = <>{externalBanners}</>;

  return (
    <>
      <UnifiedOpportunityFormShell
        banners={banners}
        onCancel={onCancel}
        onSubmit={handleSubmitToCloud}
        onSaveAsDraft={handleSaveDraft}
        initialValues={{
          // ...mockInitialValues,
          ...COSELL_CREATE_DEFAULT_VALUES,
          ...(initialValues ?? null),
        }}
        provider={DisplayCloudType.AWS}
        validationSchema={aceOpportunityFormValidationSchema}
      >
        <AceOpportunityFormFields reviewStatus={null} />
      </UnifiedOpportunityFormShell>
    </>
  );
};

export default CreateAceOpportunityForm;
