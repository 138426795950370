import React from 'react';
import classNames from 'classnames';
import { FormControl, FormHelperText, InputLabel } from 'vendor/material';
import Alert from 'mdi-material-ui/Alert';
import NumberFormat from 'react-number-format';
import { TextInputBase, useTextFieldStyles } from './TextField.styles';

const TextField = ({
  error = undefined,
  helperText = undefined,
  type = undefined,
  label = undefined,
  labelHidden = undefined,
  id: idProp = undefined,
  disabled = false,
  readOnly = false,
  name,
  iconLeft = undefined,
  iconRight = undefined,
  append = undefined,
  onFocus = (e = null) => {},
  onBlur = (e = null) => {},
  onChange = undefined,
  placeholder = undefined,
  inputProps = undefined,
  mode = '',
  decimalScale = 2,
  numberPrefix = '$ ',
  size = 'medium',
  ...props
}) => {
  const id = idProp || name;
  const { value } = props;

  const handleRef = (el) => {
    if (props.inputRef) {
      if (typeof props.inputRef === 'function') {
        props.inputRef(el);
      } else {
        // eslint-disable-next-line no-param-reassign
        props.inputRef.current = el;
      }
    }
  };

  const hasError = !!error;

  const handleChange = (event) => {
    if (event.nativeEvent) {
      event.persist();
    }

    if (onChange) {
      onChange(event);
    }
  };

  const handleFocus = (e) => {
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  const classes = useTextFieldStyles();
  const cssInputBase = classNames(classes.inputBase, {
    [classes.inputDisabled]: disabled || readOnly,
    [classes.withAppend]: append,
    [classes.withIconLeft]: iconLeft,
    [classes.withIconRight]: iconRight || type === 'password',
    [classes.inputError]: hasError,
    [classes.smallSize]: size === 'small',
    [classes.checkbox]: type === 'checkbox',
  });

  const cssInputContainer = classNames(classes.inputContainer, {
    [classes.inputContainerError]: hasError,
    'input-type-number': type === 'number',
  });

  const cssHelperTextContainer = classNames(classes.helperTextContainer, {
    [classes.helperTextDisabled]: disabled,
  });

  return (
    <FormControl fullWidth disabled={disabled} error={hasError}>
      {!labelHidden && (
        <InputLabel classes={{ root: classes.inputLabel }} shrink htmlFor={id}>
          <span data-id={`${props['data-id']}_label` || name || label || id}>
            {label}
          </span>
        </InputLabel>
      )}
      <div className={cssInputContainer}>
        <div className={classes.iconLeft}>{iconLeft}</div>
        {!mode && (
          <TextInputBase
            {...props}
            id={id}
            name={name}
            type={type}
            className={cssInputBase}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeholder}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChange}
            inputProps={inputProps}
            value={value}
            inputRef={handleRef}
          />
        )}
        {mode === 'numberformat' && (
          <>
            <NumberFormat
              {...props}
              id={id}
              name={name}
              readOnly={readOnly}
              disabled={disabled}
              value={value}
              decimalScale={decimalScale}
              thousandSeparator
              isNumericString
              prefix={numberPrefix}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onValueChange={(v) => {
                handleChange({ target: { id, name, value: v.floatValue } });
              }}
            />
          </>
        )}

        {append}
      </div>
      <div className={classes.footerContainer}>
        <div className={cssHelperTextContainer}>
          {!hasError && helperText && (
            <FormHelperText>{helperText}</FormHelperText>
          )}
          {hasError && (
            <div className={classes.errorContainer}>
              <Alert fontSize="inherit" />
              <FormHelperText>{error}</FormHelperText>
            </div>
          )}
        </div>
      </div>
    </FormControl>
  );
};

export default TextField;
