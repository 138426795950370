import { useQuery } from '@tanstack/react-query';
import StatusCountDashboard from './components/StatusCountDashboard';
import { useCallback, useMemo, useState } from 'react';
import { ProviderIcon } from '@tackle-io/platform-ui';
import { JobProgressBar } from './components/JobProgressBar';
import { Button } from '@tackle-io/platform-ui';
import StatusBadge from './components/StatusBadge';
import { ListHeader } from './components/ListHeader';
import JobDetailView from './JobDetailView/JobDetailView';
import { getBulkCreateJobsList } from 'packages/cosell/api/requests/bulkCreate';
import { type CloudProvider, CloudProviderConfig } from './consts';
import NoResultsFound from './components/NoResultsFound';
import ListItemCard from './components/ListItemCard';
import ActionButton from './components/ActionButton';

const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  if (!date) return 'Invalid date';

  const dateFormatted = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date);
  return dateFormatted;
};

const CloudProviderContent = ({ provider }: { provider: CloudProvider }) => (
  <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
    <ProviderIcon
      provider={CloudProviderConfig[provider].iconId}
      fontSize="small"
    />
    {CloudProviderConfig[provider].fullName}
  </div>
);

type JobStatus = Awaited<
  ReturnType<typeof getBulkCreateJobsList>
>['jobs'][0]['status'];

const ProgressElement = ({
  status,
  completed,
  total,
}: {
  completed: number;
  total: number;
  status: JobStatus;
}) => {
  const themeMap = {
    pending: 'neutral',
    running: 'info',
    completed: 'success',
    error: 'danger',
  } as const;

  return (
    <div
      style={{
        display: 'inline-flex',
        gap: '4px',
        flexDirection: 'column',
        minWidth: '100px',
        width: '100%',
      }}
    >
      <StatusBadge status={status} />
      {['pending', 'running'].includes(status) && (
        <JobProgressBar
          complete={completed}
          total={total}
          theme={themeMap[status]}
        />
      )}
    </div>
  );
};

const JobsListView = ({
  cloudProvider,
  onViewJob,
}: {
  cloudProvider: CloudProvider;
  onViewJob: (id: string) => void;
}) => {
  const { data, isSuccess } = useQuery({
    queryKey: ['bulk-create-jobs-list', cloudProvider],
    queryFn: () => getBulkCreateJobsList({ cloudProvider }),
    select: (data) => {
      return {
        ...data,
        /** TODO: add following items to response
         *  - total counts: since we won't be able to calculate all items when we use pagination
         *  - job.type: needed in the UI
         *  */
        counts: {
          pending: data?.jobs.filter((job) => job.status === 'pending').length,
          running: data?.jobs.filter((job) => job.status === 'running').length,
          completed: data?.jobs.filter((job) => job.status === 'completed')
            .length,
          error: data.jobs.filter((job) => job.status === 'error').length,
        },
        jobs:
          data?.jobs?.map((job) => ({
            ...job,
            type: 'Co-sell',
          })) ?? [],
      };
    },
    refetchInterval(data) {
      if (
        data?.counts &&
        (data.counts?.pending > 0 || data.counts.running > 0)
      ) {
        return 3000; // 3 seconds
      }

      return false;
    },
  });

  const jobStatusCounts = useMemo(
    () => [
      {
        status: 'Pending',
        amount: data?.counts.pending ?? 0,
        description: 'Waiting to start',
      },
      {
        status: 'In progress',
        amount: data?.counts.running ?? 0,
        description: 'Started and processing items',
      },
      {
        status: 'Completed',
        amount: data?.counts.completed ?? 0,
        description: 'Finished processing all items',
      },
      {
        status: 'Error',
        amount: data?.counts.error ?? 0,
        description: 'Finished with errors',
      },
    ],
    [data?.counts],
  );

  const columns = [
    { id: 'cloud', label: 'Cloud' },
    { id: 'dateStarted', label: 'Date started' },
    { id: 'jobType', label: 'Job type' },
    { id: 'totalItems', label: 'Total items' },
    { id: 'status', label: 'Status' },
    { id: 'actions', label: '' },
  ];
  const gridTemplateColumns = `repeat(${columns.length}, 1fr)`;

  return (
    <div style={{ paddingTop: '32px' }}>
      <StatusCountDashboard isEmpty={!data} statusCounts={jobStatusCounts} />
      <div
        data-testid="all-jobs-list"
        style={{
          display: 'grid',
          gap: '8px',
          marginTop: '32px',
        }}
      >
        <ListHeader columns={columns} rowGrid={gridTemplateColumns} />

        {isSuccess && data?.jobs?.length === 0 && (
          <NoResultsFound title="No jobs found" />
        )}

        {data?.jobs.map((job) => (
          <ListItemCard data-testid={`job-${job.id}`} key={job.id}>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: gridTemplateColumns,
                gap: '24px',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                alignItems: 'start',
              }}
            >
              <CloudProviderContent provider={job.cloudProvider} />
              <div>{formatDate(job.created)}</div>
              <div>{job.type}</div>
              <div>{job.total}</div>
              <div>
                <ProgressElement
                  status={job.status}
                  completed={job.completed}
                  total={job.total}
                />
              </div>
              <div style={{ textAlign: 'right' }}>
                <ActionButton onClick={() => onViewJob(job.id)}>
                  View job
                </ActionButton>
              </div>
            </div>
          </ListItemCard>
        ))}
      </div>
    </div>
  );
};

const AllJobsView = ({ cloudProvider }: { cloudProvider: CloudProvider }) => {
  const [selectedJobId, setSelectedJobId] = useState<string | null>(null);
  const handleViewJob = useCallback((jobId: string) => {
    setSelectedJobId(jobId);
  }, []);

  if (selectedJobId) {
    return (
      <>
        <Button
          onClick={() => setSelectedJobId(null)}
          size="small"
          style={{ marginTop: '16px' }}
          variant="text"
        >
          {`< back to jobs`}
        </Button>
        <JobDetailView cloudProvider={cloudProvider} id={selectedJobId} />
      </>
    );
  }

  return (
    <JobsListView cloudProvider={cloudProvider} onViewJob={handleViewJob} />
  );
};

export default AllJobsView;
