import { dataMapperPublicApi } from 'packages/tackle-api';
import { reportError } from 'utils/monitor';
import { Mapping } from '../../utils/types';
import { Cloud, Domain, Operation } from '../../utils/constants';

export interface ListMappingsResponse {
  mappings: Mapping[];
}

/**
 * Fetch a list of mappings for a vendor from data-mapper-api with filter parameters such as:
 * - cloud
 * - domain
 * - operation
 * - isDefault
 * - isPublished
 */
export const listMappings = async ({
  cloud,
  domain,
  operation,
  isPublished,
  isDefault,
  limit,
  next,
}: {
  cloud: Cloud;
  domain: Domain;
  operation: Operation;
  isDefault?: boolean;
  isPublished?: boolean;
  limit?: number;
  next?: string;
}) => {
  const limitString = !!limit ? String(limit) : '25';
  const queryParams = new URLSearchParams({
    cloud,
    domain,
    operation,
    ...(isPublished !== undefined && {
      isPublished: isPublished ? 'true' : 'false',
    }),
    ...(isDefault !== undefined && { isDefault: isDefault ? 'true' : 'false' }),
    limit: limitString,
    ...(!!next && { next }),
  });

  try {
    const result = (await dataMapperPublicApi
      .get(`mappings?${queryParams?.toString()}`, {})
      .json()) as ListMappingsResponse;

    return result;
  } catch (error) {
    reportError(error);

    throw error;
  }
};
