import React from 'react';
import { Box, Grid, Typography, makeStyles } from 'vendor/material';
import { Tag } from '@tackle-io/platform-ui';
import SalesforceDisconnectButton from 'components/TackleForSalesforce/DisconnectButton';
import ConnectionTableHeader from './ConnectionTableHeader';
import ConnectionStatus from './ConnectionStatus';
import { ConnectionData } from './ConnectionType';
import { useSalesforceTableStyles } from './ConnectionDetailsTable.style';
import openInNew from '../Assets/open_in_new.svg';

interface TackleConnectionViewProps {
  data: ConnectionData;
  isConnected: boolean;
  onDisconnect?: (
    closeModal: () => void,
    instanceUrl?: string,
  ) => Promise<boolean>;
  hasDisconnectPermission: boolean;
}

const useStyles = makeStyles(() => ({
  disconnectButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: '24px',
  },
}));

const TackleConnectionView: React.FC<TackleConnectionViewProps> = ({
  data,
  isConnected,
  onDisconnect,
  hasDisconnectPermission,
}) => {
  const tableClasses = useSalesforceTableStyles();
  const classes = useStyles();

  return (
    <>
      <Box className={tableClasses.tableContainer}>
        <ConnectionTableHeader headerType="tackle" />
        <Grid container>
          <Grid
            item
            xs={3}
            className={`${tableClasses.cell} ${tableClasses.alignUnderHeader}`}
          >
            <a
              href={data.instanceUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={tableClasses.link}
            >
              <Box display="flex" alignItems="center">
                <Typography variant="body1">
                  {data.organization.name}
                </Typography>
                <img
                  src={openInNew}
                  alt="Open in new tab"
                  className={tableClasses.externalIcon}
                />
              </Box>
              <Typography
                variant="caption"
                className={tableClasses.instanceUrl}
                data-full-url={data.instanceUrl}
              >
                {data.instanceUrl}
              </Typography>
            </a>
          </Grid>

          <Grid
            item
            xs={4}
            className={`${tableClasses.cell} ${tableClasses.user}`}
          >
            <Typography variant="body1">{data.user.email}</Typography>
            <Typography variant="caption" className={tableClasses.timestamp}>
              {new Date(data.createdAt).toLocaleString()}
            </Typography>
          </Grid>

          <Grid
            item
            xs={2}
            className={`${tableClasses.cell} ${tableClasses.statusCell}`}
          >
            <ConnectionStatus
              status={isConnected ? data.status : 'not connected'}
            />
            <Typography variant="caption" className={tableClasses.timestamp}>
              {new Date(data.updatedAt).toLocaleString()}
            </Typography>
          </Grid>

          <Grid item xs={3} className={tableClasses.cell}>
            <Tag className={tableClasses.environmentTag}>
              {data.organization.isSandbox ? 'Sandbox' : 'Production'}
            </Tag>
          </Grid>
        </Grid>
      </Box>
      {isConnected && (
        <Box className={classes.disconnectButtonContainer}>
          <SalesforceDisconnectButton
            disableWithPermissionCheck={!hasDisconnectPermission}
            onDisconnect={async (closeModal) =>
              onDisconnect?.(closeModal, data.instanceUrl) ?? false
            }
          />
        </Box>
      )}
    </>
  );
};

export default TackleConnectionView;
