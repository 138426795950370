import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';
import CoSellSfTackleWidget from 'packages/cosell/src/components/CoSellSfTackleWidget/CoSellSfTackleWidget';
import { CoSellContextProvider } from 'packages/cosell/src/CoSellContextProvider';
import { useHasCoSellRbacRights } from 'packages/cosell/src/hooks/useHasCoSellRbacRights/useHasCoSellRbacRights';
import NotEnoughPermissionsTabContent from 'packages/salesforce-canvas/src/components/NotEnoughPermissionsTabContent';

export const NEED_PERMISSIONS_GUIDANCE =
  'Your permissions are not configured to view or create co-sells. Contact your Salesforce admin to request changes.';
/** Co-sell view for TackleWidget Salesforce canvas app*/
export default function TackleWidgetCoSellView() {
  const { vendorId, payload, record } = useCanvasSession();
  const cosellRbacRights = useHasCoSellRbacRights();
  if (!record?.Id) {
    return (
      <p>
        Unable to get Salesforce Opportunity ID. Please verify with your admin
        this component is on the Salesforce Opportunity page.
      </p>
    );
  }

  if (!cosellRbacRights?.viewOpportunities) {
    return <NotEnoughPermissionsTabContent blurb={NEED_PERMISSIONS_GUIDANCE} />;
  }

  return (
    <CoSellContextProvider vendorId={vendorId} renderEnv="sf_canvas">
      <CoSellSfTackleWidget crmId={record.Id} {...payload} />
    </CoSellContextProvider>
  );
}
