import { useAmpliFeatureFlags } from '@tackle-io/tackle-auth-tools';
import { Cloud } from 'pages/Settings/Account/components/FieldMapper/utils/constants';
import { FeatureFlagState } from '../../types/enums/FeatureFlags';
const BULK_CREATE_ENABLED = 'sfdc-bulk-create-co-sell-ui-enabled';
const BULK_CREATE_GCP_ENABLED = 'sfdc-bulk-create-gcp-co-sell-ui-enabled';

export function useCanUseBulkCreate() {
  const { loading, error, flags } = useAmpliFeatureFlags();

  return {
    bulkCreate: flags[BULK_CREATE_ENABLED] === FeatureFlagState.ON,
    /** AWS is enabled by default */
    [Cloud.AWS]: flags[BULK_CREATE_ENABLED] === FeatureFlagState.ON,
    [Cloud.GCP]: flags[BULK_CREATE_GCP_ENABLED] === FeatureFlagState.ON,
    [Cloud.MSFT]: false,
    error,
    loading,
  };
}
