import { useParams } from 'react-router';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import CloudPage from 'packages/cosell/src/components/CloudPage';

const ViewReferralPage = () => {
  const { tackleId } = useParams<{ tackleId: string }>();

  return (
    <CloudPage
      titleProps={{
        cloud: DisplayCloudType.MICROSOFT,
        title: 'Co-sell name here',
      }}
    >
      <p>
        View referral with tackleId: <b>{tackleId}</b>
      </p>
    </CloudPage>
  );
};

export default ViewReferralPage;
