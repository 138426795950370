import { useCallback } from 'react';
import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';
import { DisplayCloudType } from 'pages/CoSell/types/enums';

import type {
  CoSellCanvasAppContentPayload,
  ParameterPayload,
  TackleWidgetContentPayload,
  TackleDomain,
  RecordType,
} from 'packages/salesforce-canvas/src/lib/salesforceCanvasSession';

/** @deprecated will be removing this */
export const COSELL_ACTION_MAP = {
  'go-back-to-opportunity': {
    label: 'cancel',
  },
  'edit-cosell': {
    label: 'Edit co-sell',
  },
  'view-cosell': {
    label: 'View co-sell',
  },
  'submit-cosell-to-cloud': {
    label: 'Submit to cloud',
  },
  'create-cosell-aws': {
    label: 'Add AWS co-sell',
  },
  'create-cosell-microsoft': {
    label: 'Add Microsoft co-sell',
  },
} as const;

/** available co-sell actions and their payloads
 */
type CoSellAction =
  | {
      id: 'go-to-salesforce-object-view';
      record: {
        objectName: Extract<RecordType['objectName'], 'Opportunity'>;
        Id: string;
      };
    }
  | {
      id: 'back';
      cloud?: DisplayCloudType;
    }
  | {
      id: 'go-to-salesforce-list';
    }
  | {
      id: 'go-back-to-opportunity-root';
      cloud?: DisplayCloudType;
    }
  | {
      id: 'create-cosell';
      cloud: DisplayCloudType;
    }
  | {
      id: 'view-cosell';
      cloud: DisplayCloudType;
      tackleId: string;
    }
  | {
      id: 'edit-cosell';
      cloud: DisplayCloudType;
      tackleId: string;
    }
  | {
      id: 'submit-cosell-to-cloud';
      cloud: DisplayCloudType;
      tackleId: string;
    }
  | {
      id: 'view-invitation';
      cloud: DisplayCloudType;
      tackleId: string;
    };

/** Allows specific set of payloads per component */
type MessagePayload =
  | ({
      navigate: 'TackleWidget';
    } & ParameterPayload<TackleWidgetContentPayload>)
  | ({
      navigate: 'CanvasApp';
    } & ParameterPayload<CoSellCanvasAppContentPayload>)
  | {
      navigate: 'back';
    }
  | {
      navigate: 'Setup';
    }
  | {
      navigate: 'ListView';
      objectName: Exclude<RecordType['objectName'], 'Opportunity'>;
    };

const domain: TackleDomain = 'cosell';

/** All actions and their requirements
 *  TODO: clean up types to be more dynamic
 */
const useCoSellSfCanvasActions = () => {
  const {
    canvasClient,
    namespace,
    record,
    records,
    currentComponentId,
    payload,
  } = useCanvasSession<MessagePayload>();

  /** just pass a payload to publish a message */
  const publishCanvasMessage = useCallback(
    (data: MessagePayload) => {
      /** only add previous if navigating from 'TackleWidget */
      const previous =
        currentComponentId === 'TackleWidget' ? currentComponentId : null;

      const payload = {
        ...data,
        ...(previous && { previous }),
      };
      const message = {
        name: `${namespace}.${domain}`,
        payload,
      };
      Sfdc.canvas.client.publish(canvasClient, message);
    },
    [canvasClient, namespace, currentComponentId],
  );

  /**
   * Handle all actions that require communication with the Salesforce aura controller
   */
  const handleCoSellAction = (action: CoSellAction) => {
    const recordObjectName =
      record?.objectName ?? records?.[0]?.objectName ?? 'Opportunity';

    switch (action.id) {
      case 'back':
        if (!('previous' in payload) && recordObjectName !== 'Opportunity') {
          publishCanvasMessage({
            navigate: 'ListView',
            objectName: recordObjectName,
            record,
            domain,
          });
          break;
        }

        if ('previous' in payload && payload.previous === 'TackleWidget') {
          publishCanvasMessage({
            navigate: 'TackleWidget',
            cloud: action.cloud,
            record,
            domain,
          });
          break;
        }
        // use "back" as fallback - has unexpected behavior
        publishCanvasMessage({
          navigate: 'back',
          cloud: action.cloud,
          record,
          domain,
        });
        break;
      case 'go-to-salesforce-list':
        if (record.objectName !== 'Opportunity') {
          publishCanvasMessage({
            navigate: 'ListView',
            objectName: record.objectName,
            record,
            domain,
          });
        }
        break;
      case 'go-to-salesforce-object-view': {
        if (action.record.objectName === 'Opportunity') {
          publishCanvasMessage({
            navigate: 'TackleWidget',
            object: 'opportunity',
            record: action.record,
            domain,
            hasPendingUpdates: true,
          });
          break;
        }

        console.error(`Cannot navigate to ${record.objectName} directly yet.`);
        break;
      }
      case 'go-back-to-opportunity-root':
        publishCanvasMessage({
          navigate: 'TackleWidget',
          cloud: action.cloud,
          record,
          domain,
        });
        break;
      case 'create-cosell':
        publishCanvasMessage({
          navigate: 'CanvasApp',
          object: 'opportunity',
          cloud: action.cloud,
          action: 'create',
          record,
          domain,
          crmId: record.Id,
        });
        break;
      case 'view-cosell':
        publishCanvasMessage({
          navigate: 'CanvasApp',
          object: 'opportunity',
          cloud: action.cloud,
          action: 'view',
          tackleId: action.tackleId,
          record,
          domain,
        });
        break;
      case 'edit-cosell':
        publishCanvasMessage({
          navigate: 'CanvasApp',
          object: 'opportunity',
          cloud: action.cloud,
          action: 'edit',
          tackleId: action.tackleId,
          record,
          domain,
        });
        break;
      case 'view-invitation':
        publishCanvasMessage({
          navigate: 'CanvasApp',
          object: 'invitation',
          cloud: action.cloud,
          action: 'view',
          tackleId: action.tackleId,
          domain,
          record,
        });
        break;
      default:
        alert(`TODO: handle action: ${action.id}`);
        break;
    }
  };

  return {
    handleCoSellAction,
  };
};

export default useCoSellSfCanvasActions;
