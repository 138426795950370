import React, { useState } from 'react';
import { Typography, Box } from 'vendor/material';
import { Button, Modal } from '@tackle-io/platform-ui';
import tackleStandardLogo from 'components/TackleForSalesforce/Assets/tackle-standard-logo.svg';
import { useDisconnectButtonStyles } from './DisconnectButton.styles';

interface DisconnectButtonProps {
  onDisconnect: (
    closeModal: () => void,
    permissionDenied?: boolean,
  ) => Promise<boolean>;
  disableWithPermissionCheck?: boolean;
}

export default function SalesforceDisconnectButton({
  onDisconnect,
  disableWithPermissionCheck,
}: DisconnectButtonProps) {
  const [openDisconnectPopup, setOpenDisconnectPopup] = useState(false);
  const disconnectClasses = useDisconnectButtonStyles();
  return (
    <>
      <Button
        variant="text"
        className={disconnectClasses.disconnectButton}
        onClick={() => setOpenDisconnectPopup(true)}
        disabled={disableWithPermissionCheck}
      >
        Disconnect from Tackle
      </Button>

      <Modal
        width="small"
        open={openDisconnectPopup}
        onClose={() => setOpenDisconnectPopup(false)}
      >
        <Box className={disconnectClasses.popupContainer}>
          <img
            src={tackleStandardLogo}
            alt="Tackle Logo"
            className={disconnectClasses.popupImage}
          />
          <Typography className={disconnectClasses.popupTitle}>
            Disconnect from Tackle
          </Typography>
          <Typography className={disconnectClasses.popupDescription}>
            This action will stop all syncing with Tackle.
          </Typography>
          <Box className={disconnectClasses.popupActions}>
            <Button
              variant="outlined"
              color="primary"
              className={disconnectClasses.cancelButton}
              onClick={() => setOpenDisconnectPopup(false)}
            >
              Cancel
            </Button>

            <Button
              appearance="destructive"
              className={disconnectClasses.confirmButton}
              onClick={async () => {
                await onDisconnect(
                  () => setOpenDisconnectPopup(false),
                  disableWithPermissionCheck,
                );
                setOpenDisconnectPopup(false);
              }}
            >
              Disconnect from Tackle
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
