import { Box, Typography, makeStyles } from 'vendor/material';
import { GeneralEmptyStateIcon } from 'packages/cosell/assets/GeneralEmptyStateIcon';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.NEUTRAL050,
    filter: 'grayscale(1)',
  },
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    padding: theme.spacing(4),
  },
  header: {
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 300,
    lineHeight: 1.25,
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.palette.NEUTRAL700,
  },
}));

/** Component to display when no list items are returned */
const NoResultsFound = ({
  hasSearch = false,
  title = 'No results found',
}: {
  hasSearch?: boolean;
  title?: string;
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <GeneralEmptyStateIcon className={classes.icon} />
      <Typography className={classes.header}>{title}</Typography>
      {hasSearch && (
        <Typography component={'p'} className={classes.description}>
          Try changing your search criteria to find what you're looking for.
        </Typography>
      )}
    </Box>
  );
};

export default NoResultsFound;
