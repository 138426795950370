import {
  CloseLostOpportunityRequest,
  CreateAceOpportunityRequest,
  LaunchAceOpportunityRequest,
  StartEngagementRequest,
  UpdateAceOpportunityBaseRequest,
} from '../requests/AceOpportunityRequest';

export interface CoSellActivityDetails {
  displayText: string;
  givenName?: string;
  familyName?: string;
  pictureUrl?: string;
  email?: string;
}

export type AceOpportunityEventOperation =
  | 'internalUpdateEvent'
  | 'createOpportunity'
  | 'cloudUpdateEvent'
  | 'updateOpportunity'
  | 'updateSubmitOpportunity'
  | 'closeLostOpportunity'
  | 'launchOpportunity'
  | 'startEngagement';

export enum AceOpportunityEventOperationEnum {
  INTERNAL_UPDATE_EVENT = 'internalUpdateEvent',
  CREATE_OPPORTUNITY = 'createOpportunity',
  CLOUD_UPDATE_EVENT = 'cloudUpdateEvent',
  UPDATE_OPPORTUNITY = 'updateOpportunity',
  UPDATE_SUBMIT_OPPORTUNITY = 'updateSubmitOpportunity',
  CLOSE_LOST_OPPORTUNITY = 'closeLostOpportunity',
  LAUNCH_OPPORTUNITY = 'launchOpportunity',
  START_ENGAGEMENT = 'startEngagement',
}

/** Note:
 * createOpportunity operation -> CreateAceOpportunityRequest
 * updateOpportunity operation -> UpdateAceOpportunityBaseRequest
 * launchOpportunity operation -> LaunchAceOpportunityRequest
 * startEngagement operation -> StartEngagementRequest
 * closeLostOpportunity operation -> CloseLostOpportunityRequest
 */
export type RawRequest =
  | CreateAceOpportunityRequest
  | UpdateAceOpportunityBaseRequest
  | LaunchAceOpportunityRequest
  | StartEngagementRequest
  | CloseLostOpportunityRequest;

// Note this is from AWS and is in PascalCase
export type AwsCloudRaw = {
  LifeCycle: {
    ReviewStatus: string;
    Stage: string;
  };
};

// Note this is from AWS and is in PascalCase
export type AwsCloudRequest = {
  LifeCycle: {
    ReviewStatus: string;
    Stage: string;
  };
};

type CloudRequest = {
  requestID: string;
  operation: AceOpportunityEventOperation;
  request?: AwsCloudRequest;
  /** ISO 8601 format */
  timestamp: string;
};

export interface AceOpportunityEventResponse {
  operation: AceOpportunityEventOperation;
  eventID: string;
  /** ISO 8601 format */
  timestamp: string;
  cloudResponses?: AWSOpportunityEventCloudResponse[];
  cloudRaw?: AwsCloudRaw;
  rawRequest?: RawRequest;
  cloudRequests?: CloudRequest[];
  activityDetails: CoSellActivityDetails;
}

export type AceCloudResponseErrorType =
  | 'AccessDeniedException'
  | 'ConflictException'
  | 'ValidationException'
  | 'InvalidParameterError'
  | 'InternalServerException'
  | 'ResourceNotFoundException'
  | 'ThrottlingException'
  | 'UnknownError';

export type AceCloudErrorResponse = {
  fieldName?: string;
  errorMessage?: string;
};

export type FailedCloudResponse = {
  requestID: string;
  success: false;
  /** ISO 8601 format */
  timestamp: string;
  errorType: AceCloudResponseErrorType;
  /** might or might not return an array */
  errorResponses?: AceCloudErrorResponse[];
  /** can return a single error message */
  errorResponse?: string;
};

type SuccessfulCloudResponse = {
  requestID: string;
  success: true;
  /** ISO 8601 format */
  timestamp: string;
  response?: {
    /** cloud id */
    Id?: string;
    /** ISO 8601 format */
    LastModifiedDate?: string;
    PartnerOpportunityIdentifier?: string;
    ResultMetadata?: {};
    TaskStatus?: string;
  };
};

export type AWSOpportunityEventCloudResponse =
  | FailedCloudResponse
  | SuccessfulCloudResponse;
