export enum DisplayCloudType {
  AWS = 'aws',
  MICROSOFT = 'microsoft',
  GCP = 'gcp',
}

export enum DisplayLabelCloudType {
  AWS = 'AWS',
  MICROSOFT = 'Microsoft',
  GCP = 'Google cloud',
}

export const CloudDisplayLabelLookup = {
  [DisplayCloudType.AWS]: DisplayLabelCloudType.AWS,
  [DisplayCloudType.MICROSOFT]: DisplayLabelCloudType.MICROSOFT,
  [DisplayCloudType.GCP]: DisplayLabelCloudType.GCP,
} as const;
