import { fontWeightMediumLarge } from 'utils/fontWeightConstants';
import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.NEUTRAL030,
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    gap: '24px',
  },
  headerColumnWrapper: {
    '&:not(:last-child)': {
      paddingRight: theme.spacing(1.5),
    },
    alignItems: 'center',
    display: 'flex',
  },
  label: {
    display: 'inline-flex',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: fontWeightMediumLarge,
    color: theme.palette.NEUTRAL900,
  },
}));

export const ListHeader = ({
  columns,
  rowGrid,
}: {
  columns: { id: string; label: string | null; dataId?: string }[];
  /** pass in the rowGrid context so the header matches the content row */
  rowGrid: string;
}) => {
  const classes = useStyles();
  return (
    <div
      className={classes.container}
      role="row"
      style={{ gridTemplateColumns: `${rowGrid}` }}
    >
      {columns.map((column) => {
        return (
          <div
            key={column.id}
            className={classes.headerColumnWrapper}
            id={column.id}
            role={'columnheader'}
            tabIndex={0}
          >
            <label className={classes.label}>{column.label}</label>
          </div>
        );
      })}
    </div>
  );
};
