import { useLocalStorage } from 'react-use';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';

type FilterValue = string | string[];
interface Filters {
  [key: string]: FilterValue;
}

function useDashboardFilters<T extends Filters>(defaultValues: T) {
  const history = useHistory();
  const queryString = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search],
  );

  const location = useLocation();

  const isOnCoSellV3Page = useMemo(() => {
    return location.pathname.startsWith('/co-sell');
  }, [location.pathname]);
  const [filtersFromStorage, setFiltersFromStorage] = useLocalStorage(
    'dashboardFilters',
    {},
  );

  function getFilterValues(
    queryString: URLSearchParams,
    filtersFromStorage: Record<string, FilterValue>,
    defaultValues: T,
  ): T {
    const filterNames = Object.keys(defaultValues);

    return filterNames.reduce((acc, filterName: keyof T & string) => {
      const query = queryString.getAll(filterName);
      if (query.length) {
        return {
          ...acc,
          [filterName]: query,
        };
      } else if (filtersFromStorage[filterName]?.length) {
        return {
          ...acc,
          [filterName]: Array.isArray(filtersFromStorage[filterName])
            ? filtersFromStorage[filterName]
            : [filtersFromStorage[filterName]],
        };
      }

      return acc;
    }, defaultValues);
  }

  const setQueryString = useCallback(
    (filterName: keyof T & string, values: FilterValue) => {
      queryString.delete(filterName);
      if (Array.isArray(values)) {
        values.forEach((value) => queryString.append(filterName, value));
      } else {
        queryString.append(filterName, values);
      }
      history.replace({ search: queryString.toString() });
    },
    [history, queryString],
  );

  const setStorageFilters = useCallback(
    (filterName: keyof T, filterValues: FilterValue) => {
      // Do not persist Cosell filter selections to local storage
      if (!isOnCoSellV3Page) {
        const currentFilters = JSON.parse(
          localStorage.getItem('dashboardFilters') || '{}',
        );
        setFiltersFromStorage({
          ...currentFilters,
          [filterName]: filterValues,
        });
      }
    },
    [setFiltersFromStorage, isOnCoSellV3Page],
  );

  const values = getFilterValues(
    queryString,
    filtersFromStorage,
    defaultValues,
  );

  function getFilterValue(filterName: keyof T) {
    return values[filterName] || null;
  }

  useEffect(() => {
    for (const filterName in defaultValues) {
      // Do not set query strings for CoSell V3 page from dashboardFilters
      if (
        !isOnCoSellV3Page &&
        !isEqual(values[filterName], queryString.getAll(filterName))
      ) {
        setQueryString(filterName, values[filterName]);
      }
    }
  }, [
    defaultValues,
    queryString,
    values,
    history,
    setQueryString,
    isOnCoSellV3Page,
  ]);

  return {
    getFilterValue: <TfilterValue extends keyof T & string>(
      filterName: TfilterValue,
    ) => getFilterValue(filterName),
    onSetFilterValue: <TFilterName extends keyof T & string>(
      filterName: TFilterName,
      filterValues: T[TFilterName],
    ) => {
      setQueryString(filterName, filterValues);
      setStorageFilters(filterName, filterValues);
    },
  };
}

export { useDashboardFilters };
