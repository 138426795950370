import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';

interface OrganizationDetails {
  id: string;
  name: string;
  instanceName: string;
  isSandbox: boolean;
  organizationType: string;
  namespacePrefix: string | null;
}

interface UserDetails {
  userId: string;
  organizationId: string;
  email: string;
  givenName: string;
  familyName: string;
  userType: string;
}

interface ConnectionDetailsResponse {
  organization: OrganizationDetails;
  user: UserDetails;
  instanceUrl: string;
  vendorId: string;
  errorCode?: string;
  errorMessage?: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

async function fetchConnectionDetails(
  getAccessTokenSilently: () => Promise<string>,
): Promise<ConnectionDetailsResponse | null> {
  const token = await getAccessTokenSilently();
  const response = await fetch(
    `${process.env.REACT_APP_CRM_CONNECTOR_API_URL}/connection-details`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (response.status === 404) {
    console.warn('Connection details not found (404)');
    return null;
  }

  if (!response.ok) {
    throw new Error('Failed to fetch connection details');
  }

  return response.json();
}

export function useConnectionDetailsQuery(options?: { enabled?: boolean }) {
  const { enabled = true } = options || {};
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: ['connectionDetails', getAccessTokenSilently],
    queryFn: () => fetchConnectionDetails(getAccessTokenSilently),
    staleTime: 5 * 60 * 1000,
    retry: 2,
    enabled,
  });
}
