import { lazy, Suspense } from 'react';
import {
  generatePath,
  useParams,
  useLocation,
  Switch,
  Route,
} from 'react-router-dom';
import { OfferPageMode } from 'pages/PrivateOffers/pages/Next/generic/OfferPageContext/offerPageMode';
import { OfferType } from 'pages/PrivateOffers/pages/Next/generic/types/TackleOffer';
import { Loader } from '@tackle-io/platform-ui';

type Cloud = 'aws' | 'azure' | 'gcp'; // TODO move this somewhere generic

/**
 * A `ROUTE` is a `PATH` with it's variables interpolated. For example:
 * PO_EDIT_PATH = /private-offers/:cloud/:poId/edit
 * PO_EDIT_ROUTE = /private-offers/aws/12345/edit
 *
 * You insert a `PATH` into the `path` prop like `<Route path={PATH}><Component /></Route>`
 * you insert a `ROUTE` into the `to` prop like `<Link to={ROUTE}>Go to route</Link>`
 */

// paths (keep this together so you can easily see what all routes are possible)
export const PO_BASE_PATH = '/private-offers';
export const PO_NEW_PATH = '/private-offers/:cloud/new'; // ? offerType, mode, [opportunityId], [mappingId]
export const PO_VIEW_PATH = '/private-offers/:cloud/:poId';
export const PO_EDIT_PATH = '/private-offers/:cloud/:poId/edit';
export const PO_CLONE_PATH = '/private-offers/:cloud/:poId/clone';
export const PO_AMEND_PATH = '/private-offers/:cloud/amend/:offerId'; // ? [poId], [opportunityId], [mappingId]

// base
export const poBaseRoute = PO_BASE_PATH;

// new
export const usePoNewParams = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params = useParams();
  return {
    ...params,
    offerType: (searchParams.get('offerType') || OfferType.Direct) as OfferType,
    mode: (searchParams.get('mode') || OfferPageMode.New) as OfferPageMode,
    opportunityId: searchParams.get('opportunityId'),
    opportunityName: searchParams.get('opportunityName'),
    mappingId: searchParams.get('mappingId'),
  };
};
export const poNewRoute = (
  cloud: Cloud,
  mode: OfferPageMode,
  offerType: OfferType,
  opportunityId: string | null,
  opportunityName: string | null,
  mappingId?: string, // maybe someday we sill start passing this
) => {
  let search = `?offerType=${offerType}&mode=${mode}`;
  if (opportunityId) search += `&opportunityId=${opportunityId}`;
  if (opportunityName) search += `&opportunityName=${opportunityName}`;
  if (mappingId) search += `&mappingId=${mappingId}`;
  return {
    pathname: generatePath(PO_NEW_PATH, { cloud }),
    search,
  };
};

// view
export const usePoViewParams = () => useParams<{ poId: string }>();
export const poViewRoute = (cloud: Cloud, poId: string) =>
  generatePath(PO_VIEW_PATH, { cloud, poId });

// edit
export const usePoEditParams = () => useParams<{ poId: string }>();
export const poEditRoute = (cloud: Cloud, poId: string) =>
  generatePath(PO_EDIT_PATH, { cloud, poId });

// clone
export const usePoCloneParams = () => useParams<{ poId: string }>();
export const poCloneRoute = (cloud: Cloud, poId: string) =>
  generatePath(PO_CLONE_PATH, { cloud, poId });

// amend
export const usePoAmendParams = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params = useParams<{ poId: string; offerId: string }>();
  return {
    ...params,
    poId: searchParams.get('poId'),
    opportunityId: searchParams.get('opportunityId'),
    opportunityName: searchParams.get('opportunityName'),
    mappingId: searchParams.get('mappingId'),
  };
};
export const poAmendRoute = (
  cloud: Cloud,
  offerId: string,
  poId: string | null,
  opportunityId: string | null,
  opportunityName: string | null,
  mappingId?: string, // maybe someday we sill start passing this
) => {
  if (!poId && !opportunityId && !mappingId) {
    return generatePath(PO_AMEND_PATH, { cloud, offerId });
  }
  let search = '?';
  if (poId) search += `poId=${poId}`;
  if (opportunityId) search += `&opportunityId=${opportunityId}`;
  if (opportunityName) search += `&opportunityName=${opportunityName}`;
  if (mappingId) search += `&mappingId=${mappingId}`;
  return {
    pathname: generatePath(PO_AMEND_PATH, { cloud, offerId }),
    search,
  };
};

const PrivateOffersList = lazy(() => import('./pages/List/List'));
const NewAwsPrivateOffer = lazy(
  () => import('./pages/Next/aws/edit/NewAwsPrivateOfferPage'),
);
const ViewAwsPrivateOffer = lazy(
  () => import('./pages/Next/aws/view/ViewAwsPrivateOfferPage'),
);
const EditAwsPrivateOffer = lazy(
  () => import('./pages/Next/aws/edit/EditAwsPrivateOfferPage'),
);
const CloneAwsPrivateOffer = lazy(
  () => import('./pages/Next/aws/edit/CloneAwsPrivateOfferPage'),
);
const AmendAwsPrivateOffer = lazy(
  () => import('./pages/Next/aws/edit/AmendAwsPrivateOfferPage'),
);
const NewMsftPrivateOffer = lazy(
  () => import('./pages/Next/msft/edit/NewMsftPrivateOfferPage'),
);
const ViewMsftPrivateOffer = lazy(
  () => import('./pages/Next/msft/view/ViewMsftPrivateOfferPage'),
);
const EditMsftPrivateOffer = lazy(
  () => import('./pages/Next/msft/edit/EditMsftPrivateOfferPage'),
);
const CloneMsftPrivateOffer = lazy(
  () => import('./pages/Next/msft/edit/CloneMsftPrivateOfferPage'),
);
const OLDPrivateOffersNew = lazy(() => import('./pages/New/New'));
const OLDPrivateOffersExisting = lazy(
  () => import('./pages/SinglePrivateOffer/SinglePrivateOffer'),
);

const PrivateOffersRoutes = () => {
  return (
    <Switch>
      <Route exact path="/private-offers">
        <Suspense fallback={<Loader />}>
          <PrivateOffersList />
        </Suspense>
      </Route>
      <Route path="/private-offers/aws/new">
        <Suspense fallback={<Loader />}>
          <NewAwsPrivateOffer />
        </Suspense>
      </Route>
      <Route path="/private-offers/aws/amend/:offerId">
        <Suspense fallback={<Loader />}>
          <AmendAwsPrivateOffer />
        </Suspense>
      </Route>
      <Route path="/private-offers/aws/:poId/edit">
        <Suspense fallback={<Loader />}>
          <EditAwsPrivateOffer />
        </Suspense>
      </Route>
      <Route path="/private-offers/aws/:poId/clone">
        <Suspense fallback={<Loader />}>
          <CloneAwsPrivateOffer />
        </Suspense>
      </Route>
      <Route path="/private-offers/aws/:poId">
        <Suspense fallback={<Loader />}>
          <ViewAwsPrivateOffer />
        </Suspense>
      </Route>
      <Route path="/private-offers/azure/new">
        <Suspense fallback={<Loader />}>
          <NewMsftPrivateOffer />
        </Suspense>
      </Route>
      <Route path="/private-offers/azure/:poId/edit">
        <Suspense fallback={<Loader />}>
          <EditMsftPrivateOffer />
        </Suspense>
      </Route>
      <Route path="/private-offers/azure/:poId/clone">
        <Suspense fallback={<Loader />}>
          <CloneMsftPrivateOffer />
        </Suspense>
      </Route>
      <Route path="/private-offers/azure/:poId">
        <Suspense fallback={<Loader />}>
          <ViewMsftPrivateOffer />
        </Suspense>
      </Route>
      {/* old routes below. these dont include the cloud after /private-offers/ */}
      <Route path="/private-offers/new">
        <Suspense fallback={<Loader />}>
          <OLDPrivateOffersNew />
        </Suspense>
      </Route>
      <Route path="/private-offers/:poId">
        <Suspense fallback={<Loader />}>
          <OLDPrivateOffersExisting />
        </Suspense>
      </Route>
    </Switch>
  );
};

export default PrivateOffersRoutes;
