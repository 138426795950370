import React from 'react';
import { Box, Drawer, Typography } from 'vendor/material';
import PaymentScheduler from './PaymentScheduler';
import useStyles from './PaymentScheduler.styles';
export interface PaymentScheduleDrawerProps {
  isPaymentScheduleDrawerOpen: boolean;
  closePaymentScheduleDrawer: () => void;
  disablePaymentScheduleToolDurationField?: boolean;
}

const PaymentScheduleDrawer: React.FC<PaymentScheduleDrawerProps> = ({
  isPaymentScheduleDrawerOpen,
  closePaymentScheduleDrawer,
  disablePaymentScheduleToolDurationField = false,
}) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={isPaymentScheduleDrawerOpen}
      onClose={closePaymentScheduleDrawer}
      classes={{ paper: classes.drawer }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography classes={{ root: classes.title }}>
          Create payment schedule
        </Typography>
      </Box>

      <PaymentScheduler
        closePaymentScheduleDrawer={closePaymentScheduleDrawer}
        disablePaymentScheduleToolDurationField={
          disablePaymentScheduleToolDurationField
        }
      />
    </Drawer>
  );
};

export default PaymentScheduleDrawer;
