import { Banner, Button } from '@tackle-io/platform-ui';
import { ComponentProps, FC } from 'react';
import { Drawer } from 'vendor/material';
import FormDrawer from '../components/FormDrawer';

export const AddCustomerForm = ({ onClose }: { onClose: () => void }) => {
  const footerActions = (
    <>
      <Button appearance="primary" variant="outlined" onClick={onClose}>
        Cancel
      </Button>
      <Button
        appearance="primary"
        variant="contained"
        onClick={() => alert('search')}
      >
        Search
      </Button>
    </>
  );
  return (
    <FormDrawer
      footerActions={footerActions}
      title="Add Customer"
      onClose={onClose}
    >
      <Banner
        title="Increase Microsoft acceptance rates by selecting a Microsoft managed account."
        type="info"
      />
    </FormDrawer>
  );
};

/** AddCustomerForm wrapped in a Drawer */
export const AddCustomerFormDrawer: FC<
  ComponentProps<typeof Drawer> & { onClose: () => void }
> = ({ open, onClose, ...props }) => {
  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      onClose={onClose}
      {...props}
      children={<AddCustomerForm onClose={onClose} />}
    />
  );
};
