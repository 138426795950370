/** Salesforce opportunity id */
type CrmId = string;
/** variable name that needs to be found in the opportunity list */
type PendingItemName = string;
type PendingItemType = 'incomingCoSell';

type PendingItemStorageKey = `${CrmId}:${PendingItemType}:${PendingItemName}`;

/** returns a formatted storage key that can be used to store the value in session storage */
export const getStorageKey = ({
  crmId,
  pendingItemType,
  pendingItemName,
}: {
  crmId: CrmId;
  pendingItemType: PendingItemType;
  pendingItemName: PendingItemName;
}): PendingItemStorageKey => `${crmId}:${pendingItemType}:${pendingItemName}`;

/** sets the co-sell that is created to session storage linked to a crmId,
 *  so it can be found when the TackleWidget for this crmId is loaded
 */
export const setIncomingCoSellId = ({
  crmId,
  pendingItem,
}: {
  crmId: CrmId;
  pendingItem: {
    name: 'cloudProviderId';
    id: string;
  };
}) => {
  const storageKey = getStorageKey({
    crmId,
    pendingItemType: 'incomingCoSell',
    pendingItemName: pendingItem.name,
  });
  sessionStorage.setItem(storageKey, pendingItem.id);
};

export const getIncomingCoSellId = (
  storageKey: PendingItemStorageKey,
): string | null => {
  return sessionStorage.getItem(storageKey);
};

export const clearIncomingCoSellId = (key: PendingItemStorageKey) => {
  sessionStorage.removeItem(key);
};
