import TackleCoSellAnimatedHandshake from 'components/AnimatedSvgs/TackleCoSellAnimatedHandshake';
import { Box, Grid, makeStyles, Typography } from 'vendor/material';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    background: 'linear-gradient(to bottom, #FFFFFF99, #DEF0F399)',
    boxSizing: 'border-box',
  },
}));

const NoCoSellYet = () => {
  const classes = useStyles();
  return (
    <Box p={4} pt={8} className={classes.container}>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <TackleCoSellAnimatedHandshake />
        </Grid>
        <Grid item>
          <Typography variant="h6" align="center">
            No co-sells yet
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" align="center">
            We'll add co-sells here when your sellers share this opportunity
            with the cloud. Come back soon to check.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NoCoSellYet;
