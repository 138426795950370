import React from 'react';
import { Box, Typography } from 'vendor/material';
import { ProviderIcon } from '@tackle-io/platform-ui';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import useStyles from './SharedHeader.styles';

export type CloudType = `${DisplayCloudType}`;

interface SharedHeaderProps {
  title: string;
  subtitle?: string;
  cloud: CloudType;
}

const SharedHeader: React.FC<SharedHeaderProps> = ({
  title,
  subtitle,
  cloud,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.nameContainer}>
        <Box className={classes.iconContainer}>
          <ProviderIcon fontSize={'medium'} provider={cloud} />
        </Box>
        {!!title && <Typography className={classes.title}>{title}</Typography>}
      </Box>

      {!!subtitle && (
        <Typography className={classes.subtitle}>{subtitle}</Typography>
      )}
    </>
  );
};

export default SharedHeader;
