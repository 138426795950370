import { useMemo } from 'react';
import { fontWeightMediumLarge } from 'utils/fontWeightConstants';
import { Box, LinearProgress, makeStyles } from 'vendor/material';
import cn from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    alignItems: 'center',
    gap: theme.spacing(2),
    gridTemplateColumns: '1fr auto',

    '& span': {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: fontWeightMediumLarge,
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.BLACK,
    },
  },
  progressBar: {
    height: '1rem',
    borderRadius: '1px',
  },
  default: {
    backgroundColor: theme.palette.NEUTRAL030,
    '& > div': {
      backgroundColor: theme.palette.BLUE400,
    },
  },
  success: {
    backgroundColor: theme.palette.GREEN050,
    '& > div': {
      backgroundColor: theme.palette.GREEN500,
    },
  },
  danger: {
    backgroundColor: theme.palette.RED050,
    '& > div': {
      backgroundColor: theme.palette.RED500,
    },
  },
  info: {
    backgroundColor: theme.palette.BLUE050,
    '& > div': {
      backgroundColor: theme.palette.BLUE400,
    },
  },
  neutral: {
    backgroundColor: theme.palette.NEUTRAL020,
    '& > div': {
      backgroundColor: theme.palette.NEUTRAL300,
    },
  },
}));

export const JobProgressBar = ({
  complete,
  total,
  showCount = false,
  theme,
}: {
  complete: number;
  showCount?: boolean;
  total: number;
  theme?: 'success' | 'danger' | 'info' | 'neutral';
}) => {
  const classes = useStyles();

  const progress = useMemo(() => {
    const percent = (complete / total) * 100;
    return Math.min(Math.max(percent, 0), 100);
  }, [complete, total]);

  return (
    <Box className={classes.container}>
      <LinearProgress
        className={cn(
          classes.progressBar,
          theme ? classes[theme] : classes.default,
        )}
        variant="determinate"
        value={progress}
      />
      {!!showCount && (
        <span>
          {progress === 100
            ? 'Job completed!'
            : `${complete} of ${total} processed`}
        </span>
      )}
    </Box>
  );
};
