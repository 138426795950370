import { fontWeightMediumLarge } from 'utils/fontWeightConstants';
import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.NEUTRAL900,
    lineHeight: '1.5em',
    display: 'grid',
    gap: theme.spacing(0.5),
  },
  heading: {
    fontWeight: fontWeightMediumLarge,
    fontSize: theme.typography.pxToRem(20),
    margin: 0,
    lineHeight: '1.5em',
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(16),
  },
}));

const Header = ({
  description,
  title,
}: {
  description: string;
  title: string;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <h5 className={classes.heading}>{title}</h5>
      <span className={classes.subtitle}>{description}</span>
    </div>
  );
};

export default Header;
