import { toDateTime } from 'utils/dates';
import { DateTime } from 'luxon';

export const formatDate = (date: string) =>
  toDateTime(date).toFormat('MM/dd/yyyy');

// Returns whole-number representing the days between date1 and date2
export const calculateDaysBetweenTwoDates = (date1: string, date2: string) => {
  const difference = DateTime.fromISO(date1).diff(DateTime.fromISO(date2), [
    'days',
  ]);
  return Math.floor(Math.abs(difference?.days));
};

export const calculateDifferenceBetweenTwoDates = (
  date1: string,
  date2: string,
) => {
  const difference = DateTime.fromISO(date1).diff(DateTime.fromISO(date2), [
    'days',
    'hours',
  ]);
  return difference;
};
