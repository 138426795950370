import { useState, useEffect, useMemo } from 'react';
import openInNew from 'components/TackleForSalesforce/Assets/open_in_new.svg';
import { useAmpliFeatureFlags } from '@tackle-io/tackle-auth-tools';
import { useRbac, AREA_PERMISSION_INTEGRATIONS } from 'utils/rbac';
import TackleStandardLogo from '../assets/TackleStandardLogo';
import NotConnectedPage from './NotConnectedPage';
import ErrorIcon from 'components/TackleForSalesforce/Assets/Error.svg';
import {
  Modal,
  RadioGroup,
  Button,
  Banner,
  Loader,
} from '@tackle-io/platform-ui';
import { makeStyles, Grid, Typography, Box, Link } from 'vendor/material';
import {
  VendorEnvironmentEnum,
  INTEGRATIONS_SALESFORCE_DOCS_URL,
} from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  bannerText: {
    color: 'var(--Neutral-Neutral-100, #091E42)',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    marginTop: '8px',
  },
  learnMoreLink: {
    color: 'var(--Blue-Blue-70, #0052CC)',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '22px',
    textDecoration: 'underline',
  },
  header: {
    fontSize: '20px',
    fontWeight: 600,
    color: '#063649',
    marginBottom: theme.spacing(2),
  },
  text: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#091E42',
    marginBottom: theme.spacing(2),
  },
  text2: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#063649',
    marginBottom: theme.spacing(2),
  },
  text3: {
    fontSize: '16px',
    color: '#0052CC',
    marginBottom: theme.spacing(2),
  },
  notAvailableConnectionTitle: {
    color: '#5E6C84',
    fontSize: '14px',
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  notAvailableConnectionVendorNameText: {
    color: '#091E42',
    fontSize: '12px',
    fontWeight: 400,
    marginBottom: theme.spacing(1),
  },
  notAvailableConnectionText: {
    color: '#5E6C84',
    fontSize: '12px',
    fontWeight: 400,
    marginBottom: theme.spacing(1),
  },
  notAvailableConnectionBorderLine: {
    borderBottom: '1px solid #EBECF0',
    marginBottom: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  link: {
    textColor: '#091E42',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  externalIcon: {
    marginLeft: '4px',
    verticalAlign: 'text-bottom',
  },
}));

const ExternalSalesforceDocsLink: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <a
      href={INTEGRATIONS_SALESFORCE_DOCS_URL}
      target="_blank"
      rel="noopener noreferrer"
      className={`${classes.text3} ${classes.link}`}
    >
      {children}
    </a>
  );
};

const ApprovalRequiredModal = ({ isOpen, handleClose }) => {
  const classes = useStyles();

  return (
    <Modal width="medium" open={isOpen} onClose={handleClose}>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <TackleStandardLogo />
        </Grid>
        <Grid item>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding={4}
          >
            <Box display="flex" alignItems="center">
              <Typography className={classes.header}>
                <img
                  src={ErrorIcon}
                  alt="Error"
                  style={{ marginRight: '8px', verticalAlign: 'middle' }}
                />
                Approval required
              </Typography>
            </Box>
            <Typography className={classes.text}>
              It looks like your Tackle account is not approved to connect to
              the new Tackle for Salesforce experience. Request your early
              access today by visiting the{' '}
              <Link
                href="/integrations"
                className={`${classes.text3} ${classes.link}`}
              >
                Integrations page
              </Link>{' '}
              in the Tackle Platform and check out our{' '}
              <ExternalSalesforceDocsLink>
                documentation
              </ExternalSalesforceDocsLink>{' '}
              at Tackle Help.
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box
            mt={2}
            display="flex"
            justifyContent="space-between"
            padding="0 16px"
            width="100%"
          >
            <Button
              appearance="primary"
              variant="contained"
              color="primary"
              style={{ flex: 1, fontWeight: 'bold', minWidth: 220 }}
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

export const isOptionDisabled = (vendor, isSfOrgSandbox) => {
  const isProdVendor = vendor.vendorType === VendorEnvironmentEnum.PROD;
  const isSandboxVendor = [
    VendorEnvironmentEnum.SANDBOX,
    VendorEnvironmentEnum.DEMO,
    VendorEnvironmentEnum.DEV,
    VendorEnvironmentEnum.INTERNAL_TEST,
  ].includes(vendor.vendorType);

  if (vendor.registration) {
    return true;
  } else {
    return (
      (isProdVendor && isSfOrgSandbox) || (isSandboxVendor && !isSfOrgSandbox)
    );
  }
};

export const getFormattedOptions = (
  salesforceConnectionDetails,
  isSfOrgSandbox,
) => {
  const options =
    salesforceConnectionDetails?.map((vendor) => {
      const isDisabled = isOptionDisabled(vendor, isSfOrgSandbox);
      return {
        label: vendor.vendorName,
        value: vendor.vendorId,
        disabled: isDisabled, // Disable based on vendorType and isSandbox
        registration: vendor.registration,
        vendorType: vendor.vendorType,
      };
    }) || [];

  return options;
};

export const renderUnavailableOptions = (
  unavailableOptions,
  classes,
  isSfOrgSandbox,
) => {
  return unavailableOptions.map((option) => {
    const vendorName = option.label;
    let rest = '';
    if (option.registration) {
      rest = ` connected since ${new Date(
        option.registration.createdAt,
      ).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        timeZone: 'UTC',
      })}`;
    } else if (
      option.disabled &&
      option.vendorType === VendorEnvironmentEnum.PROD &&
      isSfOrgSandbox
    ) {
      rest = ' cannot connect to a sandbox environment';
    } else if (
      option.disabled &&
      [
        VendorEnvironmentEnum.SANDBOX,
        VendorEnvironmentEnum.DEMO,
        VendorEnvironmentEnum.DEV,
        VendorEnvironmentEnum.INTERNAL_TEST,
      ].includes(option.vendorType) &&
      !isSfOrgSandbox
    ) {
      rest = ' cannot connect to a production environment';
    }
    return (
      <Typography
        key={option.value}
        className={classes.notAvailableConnectionVendorNameText}
      >
        <b>{vendorName}</b>
        <span className={classes.notAvailableConnectionText}>{rest}</span>
      </Typography>
    );
  });
};

export const AccountConnectionModal = ({
  sfRegistrationInformation,
  onRegistration,
  isSfOrgSandbox,
}) => {
  const classes = useStyles();
  const { flags } = useAmpliFeatureFlags();
  const [isOpen, setIsOpen] = useState(true);
  const { hasPermission, loading } = useRbac();
  const { salesforceConnectionDetails } = sfRegistrationInformation;

  const formattedOptions = useMemo(() => {
    return getFormattedOptions(salesforceConnectionDetails, isSfOrgSandbox);
  }, [salesforceConnectionDetails, isSfOrgSandbox]);

  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    const enabledOptions = formattedOptions.filter(
      (option) => !option.disabled,
    );
    if (enabledOptions.length === 1) {
      setSelectedValue(enabledOptions[0].value);
    } else {
      setSelectedValue('');
    }
  }, [formattedOptions]);

  if (loading) {
    return <Loader />;
  }

  if (!hasPermission(AREA_PERMISSION_INTEGRATIONS)) {
    return (
      <div style={{ padding: '16px' }}>
        <div style={{ marginBottom: '16px' }}>
          <Banner
            type="warning"
            title="Permissions required"
            borderPosition="top"
            body={
              <Typography className={classes.bannerText}>
                Please contact your Tackle admin for access to the Integrations
                page. That access is required for Tackle to connect to
                Salesforce.{' '}
                <a
                  href="https://help.tackle.io/en/articles/10440161-manage-users-and-permissions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.learnMoreLink}
                >
                  Learn more
                  <img
                    src={openInNew}
                    alt="Open in new tab"
                    className={classes.externalIcon}
                  />
                </a>
              </Typography>
            }
          />
        </div>
        <div style={{ transform: 'scale(0.7)', transformOrigin: 'top center' }}>
          <NotConnectedPage />
        </div>
      </div>
    );
  }

  const handleRadioChange = (newValue) => {
    const selectedOption = formattedOptions.find(
      (option) => option.value === newValue,
    );
    if (!selectedOption?.disabled) {
      setSelectedValue(newValue);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    window.close();
  };

  const handleRegistration = async () => {
    try {
      await onRegistration(selectedValue);
      setIsOpen(false);
    } catch (error) {
      window.opener.postMessage(
        JSON.stringify({
          type: 'SALESFORCE_AUTH_ERROR',
          payload: {
            error:
              error instanceof Error
                ? error.message
                : 'An unknown error occurred during registration',
          },
        }),
        '*',
      );
      window.close();
    }
  };

  if (flags['v2_salesforce_enabled'] !== 'on') {
    return <ApprovalRequiredModal isOpen={isOpen} handleClose={handleClose} />;
  }

  const availableOptions = formattedOptions.filter(
    (option) => !option.disabled,
  );
  const unavailableOptions = formattedOptions.filter(
    (option) => option.disabled,
  );

  return (
    <Modal width="medium" open={isOpen} onClose={handleClose}>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <TackleStandardLogo />
        </Grid>
        <Grid item>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            padding={4}
          >
            <Typography className={classes.header}>
              Permission to authenticate
            </Typography>
            <Typography className={classes.text}>
              Providing permission to authenticate allows you to set up the
              Tackle app and sync your Marketplace, Co-Sell, and Prospect data.
            </Typography>
            <Typography className={classes.text2}>Tackle account</Typography>
            {availableOptions.length > 0 ? (
              <Typography className={classes.text}>
                Select the Tackle account you want to associate this Salesforce
                instance to.
              </Typography>
            ) : (
              <Typography className={classes.text}>
                Currently, there are{' '}
                <span>
                  <b>no accounts available</b>
                </span>{' '}
                for connection.
                <br />
                <br />
              </Typography>
            )}
            {availableOptions.length > 0 && (
              <Box ml={2}>
                <RadioGroup
                  options={availableOptions}
                  value={selectedValue}
                  onChange={handleRadioChange}
                  direction="vertical"
                />
              </Box>
            )}
            {unavailableOptions.length > 0 && (
              <>
                <Typography className={classes.notAvailableConnectionTitle}>
                  Not available for connection
                </Typography>
                <Box className={classes.notAvailableConnectionBorderLine} />
                <Box>
                  {renderUnavailableOptions(
                    unavailableOptions,
                    classes,
                    isSfOrgSandbox,
                  )}
                </Box>
              </>
            )}
          </Box>
        </Grid>
        <Grid item>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              appearance="primary"
              variant="outlined"
              onClick={handleClose}
              style={{ marginRight: '12px', fontWeight: 'bold', minWidth: 220 }}
            >
              Cancel
            </Button>
            <Button
              appearance="primary"
              variant="contained"
              color="primary"
              style={{ fontWeight: 'bold', minWidth: 220 }}
              onClick={handleRegistration}
              disabled={
                !selectedValue ||
                formattedOptions.find(
                  (option) => option.value === selectedValue,
                )?.disabled
              }
            >
              Authenticate
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};
