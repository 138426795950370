import {
  MemoryRouter,
  Switch,
  Route,
  useParams,
  useHistory,
  useRouteMatch,
} from 'react-router';
import UnifiedOpportunityDetails from '../pages/UnifiedOpportunityDetails';
import { useMemo } from 'react';
import ButtonLink from 'packages/salesforce-canvas/src/components/ButtonLink';
import useCoSellSfCanvasActions from 'packages/salesforce-canvas/cosell/src/hooks/useCoSellSfCanvasActions';
import { type CoSellCanvasAppContentPayload } from 'packages/salesforce-canvas/src/lib/salesforceCanvasSession';
import invariant from 'tiny-invariant';
import Error from 'pages/Error/Error';
import EditAceOpportunityForm from 'packages/cosell/src/components/UnifiedOpportunityForm/AceOpportunityForm/EditAceOpportunityForm';
import CreateOpportunityPage from './CreateOpportunityPage';
import UnifiedInvitationDetails from '../pages/UnifiedInvitationDetails';
import { ErrorBoundary } from 'components';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import BulkCreateCoSellsPage from './BulkCreateCoSellsPage/BulkCreateCoSellsPage';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import MicrosoftRouter from 'packages/cosell/src/pages/microsoft/MicrosoftRouter';
import { MsftRoutes } from 'packages/cosell/src/pages/microsoft/constants';

/** Router for co-sell when rendered in the Salesforce  CanvasApp
 *  TODO: update the pageData.domain to be `co-sell`instead of cosell.
 */
const CoSellCanvasRouter = ({
  cloud,
  onClose,
  ...pageData
}: CoSellCanvasAppContentPayload & {
  onClose: () => void;
}) => {
  /** this is hardcoded instead of using pageData.domain, bc the SF domain (cosell) does not match the tackle domain (co-sell) */
  const domain = 'co-sell';

  /**
   *  An assumption was made that all clouds would have the same routes and objects.
   *  This is not the case, so we are moving the cloud up in the path to be more flexible
   *  TODO: update AWS to place the cloud before the object.
   */
  const rootPath =
    cloud === DisplayCloudType.AWS || cloud === undefined
      ? `/${domain}/${pageData.object}`
      : `/${domain}/${cloud}`;

  /** set initial route to set the route context */
  const initialRoute = useMemo(() => {
    if (pageData.action === 'bulk-create') {
      return `${rootPath}/bulk-create`;
    }

    if (cloud === DisplayCloudType.MICROSOFT) {
      switch (pageData.action) {
        case 'create':
          return `${rootPath}/${MsftRoutes.referrals}/create/${pageData.crmId}`;
        case 'edit':
          return `${rootPath}/${MsftRoutes.referrals}/${pageData.tackleId}/edit`;
        case 'view':
          return `${rootPath}/${MsftRoutes.referrals}/${pageData.tackleId}`;
      }
    }

    if (pageData.object === 'invitation') {
      return `${rootPath}/${cloud}/${pageData.tackleId}`;
    }
    switch (pageData.action) {
      case 'create':
        return `${rootPath}/${cloud}/create/${pageData.crmId}`;
      case 'edit':
        return `${rootPath}/${cloud}/${pageData.tackleId}/edit`;
      case 'view':
        return `${rootPath}/${cloud}/${pageData.tackleId}`;
    }
  }, [cloud, pageData, rootPath]);

  /** if previous is undefined, user navigated here from Native SF list */
  const isFromList = !pageData.previous;
  return (
    <ErrorBoundary renderError={() => <ErrorPage title="Co-sell" />}>
      <MemoryRouter initialEntries={[initialRoute]}>
        <BackLink fromList={isFromList} object={pageData.object} />
        <Switch>
          <Route
            component={MicrosoftRouter}
            path={`/${domain}/${MsftRoutes.root}`}
          />
          <Route
            component={BulkCreateCoSellsPage}
            path={`/:domain/opportunity/bulk-create`}
          />
          <Route path={`/:domain/opportunity/:cloud/create/:crmId`} exact>
            <CreateOpportunityPage onClose={onClose} />
          </Route>
          <Route
            path={`/:domain/opportunity/:cloud/:tackleOpportunityId`}
            component={OpportunityDetailsPage}
            exact
          />
          <Route
            path={`/:domain/opportunity/:cloud/:tackleOpportunityId/edit`}
            component={EditOpportunityPage}
            exact
          />
          <Route
            path={`/:domain/invitation`}
            component={UnifiedInvitationDetails}
          />
          <Route component={Error} />
        </Switch>
      </MemoryRouter>
    </ErrorBoundary>
  );
};

const OpportunityDetailsPage = () => {
  const params = useParams<{ tackleOpportunityId: string }>();
  invariant(
    params.tackleOpportunityId,
    `The tackleOpportunityId is not defined, but required to view a Co-sell opportunity`,
  );

  return (
    <UnifiedOpportunityDetails
      tackleOpportunityId={params.tackleOpportunityId}
    />
  );
};

const EditOpportunityPage = () => {
  const params = useParams<{ tackleOpportunityId: string }>();
  const { handleCoSellAction } = useCoSellSfCanvasActions();
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const opportunityLink = routeMatch.url.replace('/edit', '');

  invariant(
    params.tackleOpportunityId,
    `The tackleOpportunityId is not defined, but required to edit a Co-sell opportunity`,
  );

  const redirectToOpportunity = () => history.push(opportunityLink);

  const redirectToRoot = () =>
    handleCoSellAction({ id: 'go-back-to-opportunity-root' });

  const goBackLink = () => {
    // if the use navigate to the edit page from the root page, for some reason the history length is 1
    // if the user navigates to the edit page from the details page, the history length is 2
    if (history.length > 1) {
      history.goBack();
    } else {
      redirectToRoot();
    }
  };

  return (
    <EditAceOpportunityForm
      onCancel={goBackLink}
      onUpdateSuccess={redirectToOpportunity}
      opportunityId={params.tackleOpportunityId}
    />
  );
};

const BackLink = ({
  fromList = false,
  object,
}: {
  fromList?: boolean;
  object: 'opportunity' | 'invitation';
}) => {
  const { handleCoSellAction } = useCoSellSfCanvasActions();
  const objectNoun =
    object === 'invitation'
      ? 'invitations'
      : `opportunit${!fromList ? 'y' : 'ies'}`;
  return (
    <ButtonLink onClick={() => handleCoSellAction({ id: 'back' })}>
      {`< back to ${objectNoun}`}
    </ButtonLink>
  );
};

export default CoSellCanvasRouter;
