import React, { useEffect } from 'react';
import { Box, Divider, Grid } from 'vendor/material';
import { Page } from 'components';
import useStyles from './AceInvitationDetails.styles';
import { Banner } from '@tackle-io/platform-ui';
import { AceInvitationCloudStatusEnum } from 'packages/cosell/src/types/enums';
import InvitationDetailsHeader from './components/InvitationDetailsHeader';
import ReceiverResponsibilities from './components/ReceiverResponsibilities';
import AceCustomerDetails from './components/AceCustomerDetails';
import AceProjectDetails from './components/AceProjectDetails';
import RightRailCard from './components/RightRail/RightRailCard';
import { isEmpty } from 'lodash';
import { CoSellActivityFeed } from 'packages/cosell/src/components/CoSellActivityFeed/CoSellActivityFeed';
import { useParams } from 'react-router';
import useAceInvitationEventsQuery from 'packages/cosell/src/hooks/useAceInvitationEventsQuery/useAceInvitationEventsQuery';
import { useAceInvitation } from 'packages/cosell/api/hooks/useAceInvitation';
import { Loader } from '@tackle-io/platform-ui';

const AceInvitationDetails = () => {
  const classes = useStyles();
  const { invitationId } = useParams<{ invitationId: string }>();
  const { aceInvitationQuery } = useAceInvitation({ invitationId });
  const invitation = aceInvitationQuery?.data;

  const { invitationEvents, isInvitationEventsLoading, invitationEventsError } =
    useAceInvitationEventsQuery(invitationId);

  const events = invitationEvents?.events || [];

  useEffect(() => {
    const containerShell = document.querySelector('#root > div > main');
    if (containerShell && containerShell.scrollTop > 0) {
      containerShell.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  if (aceInvitationQuery.isError) {
    return <div>Error loading invitation</div>;
  }

  if (aceInvitationQuery.isInitialLoading || isInvitationEventsLoading) {
    return <Loader />;
  }

  if (!invitation) {
    return <div>No invitation found</div>;
  }

  return (
    <Page className={classes.detailsPage}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Box mt={2}>
            <InvitationDetailsHeader
              title={
                invitation.opportunityInvitationPayload?.customer?.companyName
              }
              status={invitation.status}
              subtitle={invitation.opportunityInvitationPayload?.project?.title}
            />
          </Box>
        </Grid>
        {invitation.status === AceInvitationCloudStatusEnum.PENDING && (
          <Grid item xs={12}>
            <Banner
              borderPosition="top"
              defaultOpen={false}
              isCollapsible={false}
              title="Invitations must be accepted to view all opportunity details."
            />
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          <ReceiverResponsibilities />

          <Box mt={4}>
            <AceCustomerDetails {...invitation?.opportunityInvitationPayload} />
          </Box>
          <Box mt={4}>
            <AceProjectDetails {...invitation?.opportunityInvitationPayload} />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          {/* TODO: Revisit to support any valid errors that can arise */}
          {!isEmpty(invitationEventsError) ? (
            <div>Error loading invitation events</div>
          ) : (
            <RightRailCard invitation={invitation} invitationEvents={events} />
          )}
          <Box mt={4} mb={4}>
            <Divider />
          </Box>
          <CoSellActivityFeed activities={events} />
        </Grid>
      </Grid>
    </Page>
  );
};

export default AceInvitationDetails;
