import { Location } from 'history';

export const SEARCH_KEY = 'q';
export const SORT_KEY = 'sort';
export const PAGE_KEY = 'page';
export const PAGE_SIZE_KEY = 'pageSize';
export const FROM_KEY = 'from';
export const TAB_KEY = 'tab';
export const SOURCE_KEY = 'source';
/**  filter key used for both opportunities and invitations */
export const CLOUD_STATUS_KEY = 'cloudStatus';
export const CLOUD_SALES_STAGE_KEY = 'cloudSalesStage';
export const HAS_ERRORS_KEY = 'hasErrors';
export const HAS_CRM_ID_KEY = 'hasCrmId';
export const SORT_FIELD_KEY = 'sortField';
export const SORT_ORDER_KEY = 'sortOrder';
// Browser URL parameter keys
export const START_DATE_URL_KEY = 'startDate';
export const END_DATE_URL_KEY = 'endDate';
export const DATE_TYPE_KEY = 'dateType';
// API keys to filter
export const START_DATE_API_KEY = 'startCloudCreated';
export const END_DATE_API_KEY = 'endCloudCreated';

type SearchParamNumberKeys = 'page' | 'pageSize' | 'from' | 'source';

export const PAGE_LIMIT = 10;

export const CoSellLandingPageTabs = {
  OPPORTUNITIES: 'opportunities',
  INVITATIONS: 'invitations',
};

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum OpportunityColumnKey {
  PROJECT_NAME = 'title',
  CUSTOMER = 'companyName',
  COSELL_STATUS = 'cloudStatus',
  COSELL_STAGE = 'cloudSalesStage',
  DATE_CREATED = 'cloudCreated',
}

export const DEFAULT_OPPORTUNITIES_SORT_KEY = OpportunityColumnKey.DATE_CREATED;
export const DEFAULT_OPPORTUNITIES_SORT_DIRECTION = SortDirection.DESC;
export interface OpportunitySortState {
  sortKey: OpportunityColumnKey;
  sortDirection: SortDirection;
}

export enum InvitationColumnKey {
  PROJECT_NAME = 'title',
  CUSTOMER = 'companyName',
  INVITATION_STATUS = 'cloudStatus',
  DATE_RECEIVED = 'cloudCreated',
  EXPIRATION_DATE = 'expirationDate',
}

export const DEFAULT_INVITATIONS_SORT_KEY = InvitationColumnKey.DATE_RECEIVED;
export const DEFAULT_INVITATIONS_SORT_DIRECTION = SortDirection.DESC;
export interface InvitationSortState {
  sortKey: InvitationColumnKey;
  sortDirection: SortDirection;
}
export interface InvitationSortState {
  sortKey: InvitationColumnKey;
  sortDirection: SortDirection;
}

export enum Operation {
  FILTER = 'FILTER',
  SORT = 'SORT',
  SEARCH = 'SEARCH',
  TAB = 'TAB',
}

interface Filter {
  value: string | boolean;
  operation: Operation;
  key?: string | OpportunityColumnKey;
  type?: string;
  label?: string;
}

/**
 * Helper function to get the existing URL params and update the search param
 * Then returns the new URL path
 */
export const getUrlParamsPath = ({
  filter,
  location,
}: {
  filter: Filter;
  location: Location;
}): string => {
  const searchParams = new URLSearchParams(location.search);
  switch (filter.operation) {
    case Operation.SEARCH:
      searchParams.delete(SEARCH_KEY);
      if (filter?.value) {
        searchParams.append(SEARCH_KEY, encodeURIComponent(filter.value));
        searchParams.delete(PAGE_KEY);
        searchParams.delete(FROM_KEY);
      }
      break;
    case Operation.TAB:
      searchParams.delete(FROM_KEY);
      searchParams.delete(PAGE_KEY);
      searchParams.delete(TAB_KEY);
      searchParams.delete(SOURCE_KEY);
      searchParams.delete(SEARCH_KEY);
      searchParams.delete(CLOUD_STATUS_KEY);
      searchParams.delete(CLOUD_SALES_STAGE_KEY);
      searchParams.delete(HAS_ERRORS_KEY);
      searchParams.delete(HAS_CRM_ID_KEY);
      searchParams.delete(SORT_FIELD_KEY);
      searchParams.delete(SORT_ORDER_KEY);
      if (filter.value === CoSellLandingPageTabs.INVITATIONS) {
        searchParams.set(TAB_KEY, filter.value as string);
      }
      break;
    case Operation.SORT:
      break;
    case Operation.FILTER:
      break;
  }

  return `${location.pathname}?${searchParams.toString()}`;
};

const SearchParamNumberDefaults = {
  page: 0,
  pageSize: PAGE_LIMIT,
  from: 0,
};

export const getSearchParamNumberValue = ({
  searchParams,
  key,
}: {
  searchParams: URLSearchParams;
  key: SearchParamNumberKeys;
}): number => {
  const searchParamValue = searchParams.get(key);

  let num = parseFloat(searchParamValue);

  if (!isNaN(num) && num > 0) {
    return num;
  } else {
    return SearchParamNumberDefaults[key];
  }
};
