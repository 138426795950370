import { Banner } from '@tackle-io/platform-ui';
import { makeStyles } from 'vendor/material';
import { fontWeightMediumLarge } from 'utils/fontWeightConstants';

export const CONTENT = {
  banner: {
    id: 'co-sell-date-filter-warning',
    title: 'Submission errors excluded',
    message:
      'Opportunities with submission errors are excluded when a date range is selected. Tackle is working to enhance the experience to include submission errors. Stay tuned.',
  },
};

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gap: theme.spacing(1),
    fontWeight: fontWeightMediumLarge,
    lineHeight: theme.typography.pxToRem(20),
    textWrap: 'pretty',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(12),
  },
  container: {
    marginBottom: theme.spacing(4),
  },
}));

const CoSellDateFilterWarning = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Banner
        data-id={CONTENT.banner.id}
        data-testid={CONTENT.banner.id}
        defaultOpen
        isCollapsible
        type="info"
        title={CONTENT.banner.title}
        body={<div className={classes.wrapper}>{CONTENT.banner.message}</div>}
      />
    </div>
  );
};

export default CoSellDateFilterWarning;
