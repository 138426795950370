import { useMutation } from '@tanstack/react-query';
import { crmConnectorSalesforceApi } from 'packages/tackle-api';
import useToast from '../../../../../hooks/useToast/useToast';
import { useCanvasSession } from '../../useCanvasSession';

export function useDisconnectSalesforce() {
  const { context } = useCanvasSession();
  const { toaster } = useToast();

  const mutation = useMutation({
    mutationFn: async ({ instanceUrl }: { instanceUrl: string }) => {
      if (!context) {
        throw new Error('No active Salesforce session found.');
      }

      const response = await crmConnectorSalesforceApi.delete('register');

      if (!response.ok || response.status !== 200) {
        const errorData = await response.json();
        const errorMessage = `Error during deregistration ${errorData.errorCode}: ${errorData.errorMessage}`;
        throw new Error(errorMessage);
      }

      return { instanceUrl };
    },
    onError: (error: Error) => {
      throw error;
    },
  });

  const disconnectSalesforce = async (
    closeModal: () => void,
    instanceUrl: string,
  ): Promise<boolean> => {
    try {
      await mutation.mutateAsync({ instanceUrl });
      toaster({
        message: 'Successfully disconnected from Salesforce!',
        options: {
          type: 'success',
          closeButton: false,
          autoClose: false,
        },
      });
      closeModal();
      return true;
    } catch (error) {
      throw error instanceof Error
        ? error
        : new Error('An unknown error occurred during disconnection');
    }
  };

  return { disconnectSalesforce };
}
