import { Loader } from '@tackle-io/platform-ui';
import { Box } from 'vendor/material';
import { SalesforceCoSellWidgetButtonGroup } from 'packages/cosell/src/components/SalesforceCoSellWidget/SalesforceCoSellWidgetButtonGroup/SalesforceCoSellWidgetButtonGroup';
import { SalesforceCoSellWidgetDetailTable } from 'packages/cosell/src/components/SalesforceCoSellWidget/SalesforceCoSellWidgetDetailTable/SalesforceCoSellWidgetDetailTable';
import { AceCoSellErrorMessagesBanner } from 'packages/cosell/src/pages/AceCoSellErrorMessagesBanner';
import { useAceOpportunity } from 'packages/cosell/api/hooks/useAceOpportunity';
import { shouldRenderActionButtonSection } from 'packages/cosell/src/pages/UnifiedOpportunityDetails/AceOpportunityDetails/rightRail/helper';
import { AceOpportunityStageEnum } from 'packages/cosell/src/types/enums/AceOpportunityStageEnum';

/** displays a summary of the AWS ACE co-sell opportunity */
export default function AwsOpportunitySummary({
  opportunityId,
}: {
  opportunityId: string;
}) {
  const { aceOpportunityQuery } = useAceOpportunity({
    opportunityId,
  });
  if (aceOpportunityQuery.isInitialLoading)
    return (
      <div style={{ position: 'relative', minHeight: '400px' }}>
        <Loader />
      </div>
    );
  if (aceOpportunityQuery.isError) return <div>Error loading opportunity</div>;

  const opportunity = aceOpportunityQuery.data;

  if (
    !opportunity ||
    !opportunity.isCreatedInCloud ||
    !Object.keys(opportunity).length
  )
    return <div>No opportunity found</div>;

  const tackleCoSellId = opportunity.metadata?.id;

  const hasPendingRequest = !!opportunity.metadata?.pendingRequestIds?.length;

  /** actions including edit, submit etc can be performed on opportunity */
  const isActionable =
    opportunity.isCreatedInCloud &&
    shouldRenderActionButtonSection(
      opportunity.lifeCycle?.reviewStatus,
      opportunity.lifeCycle?.stage,
      false,
    ) &&
    !hasPendingRequest;

  /** only allow editing if it used the main edit form,
   * bc the modal edit forms are not implemented in SF canvas yet */
  const isEditable =
    isActionable &&
    opportunity.lifeCycle?.stage !== AceOpportunityStageEnum.LAUNCHED;

  return (
    <Box style={{ display: 'grid', gap: '1rem', minHeight: '250px' }}>
      {!!opportunity.metadata?.hasCloudErrors && !!tackleCoSellId && (
        <AceCoSellErrorMessagesBanner
          defaultCollapsed
          opportunityId={tackleCoSellId}
          defaultError="submission"
        />
      )}
      <SalesforceCoSellWidgetDetailTable
        opportunity={{
          id: tackleCoSellId,
          cloudProviderId: opportunity.id,
          created: opportunity.createdDate,
          title: opportunity.project?.title,
          source: opportunity.source,
          crmId: opportunity.crmId,
          lastModified: opportunity.lastModifiedDate,
          targetCloseDate: opportunity.lifeCycle?.targetCloseDate,
          companyName: opportunity.customer.account.companyName,
          expectedCustomerSpend: opportunity.project.expectedCustomerSpend,
          opportunityType: opportunity.opportunityType,
          hasCloudErrors: opportunity.metadata?.hasCloudErrors,
          stage: opportunity.lifeCycle?.stage,
          status: opportunity.lifeCycle?.reviewStatus,
          reviewComments: opportunity.lifeCycle?.reviewComments,
        }}
      />
      <SalesforceCoSellWidgetButtonGroup
        status={opportunity.lifeCycle?.reviewStatus}
        isEditable={isEditable}
        tackleCoSellId={tackleCoSellId}
      />
    </Box>
  );
}
