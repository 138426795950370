import { Box, makeStyles, Typography } from 'vendor/material';
import { Button, ProviderIcon } from '@tackle-io/platform-ui';
import { Close } from 'mdi-material-ui';
import { fontWeightMediumLarge } from 'utils/fontWeightConstants';

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    color: theme.palette.NEUTRAL080,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto minmax(0, 1fr) auto',
    gap: theme.spacing(1),
    height: '100%',
  },
  content: {
    display: 'grid',
    alignItems: 'start',
  },
  footer: {
    backgroundColor: 'inherit',
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  header: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: fontWeightMediumLarge,
    color: theme.palette.NEUTRAL900,
    textAlign: 'left',
  },
}));

/**
 * A styled form container for rendering in a Drawer
 *  - header with cloud icon, title, and close button
 *  - content area for form fields & content
 *  - footer for action buttons fixed to the bottom of the drawer
 */
const FormDrawer = ({
  children,
  footerActions,
  onClose,
  title,
}: {
  children: React.ReactNode;
  /** pass single or multiple buttons */
  footerActions?: React.ReactNode;
  onClose: () => void;
  title: string;
}) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.container}
      minWidth={400}
      maxWidth={600}
      padding={4}
    >
      <Box component="header" className={classes.header}>
        <ProviderIcon fontSize="medium" provider="microsoft" />
        <Typography component="h5" className={classes.title}>
          {title}
        </Typography>
        <Button
          className={classes.closeIcon}
          startIcon={<Close />}
          variant="text"
          size="small"
          onClick={onClose}
        />
      </Box>
      <Box className={classes.content}>{children}</Box>
      <Box component="footer" className={classes.footer}>
        {footerActions}
      </Box>
    </Box>
  );
};

export default FormDrawer;
