import React from 'react';
import { Box, Grid, makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr auto',
    minHeight: '100svh',
    overflowX: 'clip',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  mainContent: {
    marginInline: theme.spacing(3),
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(3),
  },
  formSections: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    flex: 1,
    marginBottom: theme.spacing(3),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.NEUTRAL020}`,
    backgroundColor: theme.palette.NEUTRAL000,
    display: 'flex',
    gap: theme.spacing(1.25),
    justifyContent: 'flex-end',
  },
}));

/** A form container with sticky footer on bottom */
const FormBox = ({
  header,
  formFields,
  footer,
  sidePanel,
}: {
  header?: React.ReactNode;
  formFields: React.ReactNode;
  footer: React.ReactNode;
  sidePanel: React.ReactNode;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Box className={classes.mainContent}>
        {header ?? null}
        <Grid container spacing={4}>
          <Grid item md={9} className={classes.formSections}>
            {formFields}
          </Grid>
          <Grid item md={3}>
            <Box
              position="sticky"
              top={32}
              display={{ sm: 'none', md: 'block' }}
            >
              {sidePanel}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {footer}
    </div>
  );
};

const Footer = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.footer}
      position="sticky"
      bottom="0"
      paddingX={4}
      paddingY={2}
    >
      {children}
    </Box>
  );
};

FormBox.Footer = Footer;

export default FormBox;
