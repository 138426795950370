interface LabelProps {
  className?: string;
  label: string;
  required?: boolean;
}

export const Label = ({
  className,
  label,
  required = false,
}: LabelProps): JSX.Element => {
  return (
    <label className={className}>
      {label}
      {required ? <span style={{ color: 'red' }}>&nbsp;*</span> : ''}
    </label>
  );
};
