import { Banner, Button, Modal, ProviderIcon } from '@tackle-io/platform-ui';
import { DataId } from '../analytics';
import {
  CircularProgress,
  Drawer,
  Grid,
  Icon,
  makeStyles,
  Typography,
} from 'vendor/material';
import { ArrowLeft, CheckCircle, CloseCircle } from 'mdi-material-ui';
import { Marketplace, OfferType } from '../types/TackleOffer';
import TextField from 'components/FieldsPricing/TextField/TextField';
import React, { useContext, useState } from 'react';
import { AwsPrivateOffer } from '../api/types/AwsPrivateOffer';
import ApiContext from '../ApiContext/apiContext';
import { formatCurrency } from 'utils/currency';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as yup from 'utils/yup-extended';
import { ApiContextProvider } from '../ApiContext/ApiContextProvider';
import TackleLogo from 'images/tackle-logo';

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: '100%',
  },
  container: {
    height: '100%',
    padding: theme.spacing(1),
  },
  contents: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  importingOffersText: {
    fontSize: 12,
    fontWeight: 600,
  },
  greenIcon: {
    color: theme.palette.GREEN400,
  },
  redIcon: {
    color: theme.palette.RED400,
  },
  backIcon: {
    cursor: 'pointer',
    color: theme.palette.BLUE400,
  },
  tackleAppBar: {
    background: theme.palette.TEAL900,
    height: '4.5rem',
    display: 'flex',
    alignItems: 'center',
    paddingInline: '1rem',
    width: '100vw',
  },
}));

interface Values {
  buyerCompanyName: string;
  importId: string;
}

const OFFER_ID_REGEX = /offer-[a-z0-9]{13}$/;
const SELLING_AUTH_ID_REGEX = /resaleauthz-[a-z0-9]{13}$/;

const schema = yup.object<Values>({
  buyerCompanyName: yup.string().required(),
  importId: yup
    .string()
    .required()
    .matches(
      new RegExp(`${OFFER_ID_REGEX.source}|${SELLING_AUTH_ID_REGEX.source}`),
      'Must be a valid offer or authorization ID',
    ),
});

const AssociateImportContents = ({
  onCancelAssociateImportFlow,
  onClose,
  marketplace,
  offerType,
  onEditOffer,
  opportunityId,
}: {
  onCancelAssociateImportFlow: () => void;
  onClose: () => void;
  marketplace: Marketplace;
  offerType: OfferType;
  onEditOffer: (importedOffer: AwsPrivateOffer) => void;
  opportunityId?: string;
}) => {
  const classes = useStyles();

  const {
    awsApi: { importOffer },
    offerImportError,
    setOfferImportError,
  } = useContext(ApiContext);

  const [loading, setLoading] = useState(false);
  const [importedOffer, setImportedOffer] = useState<AwsPrivateOffer | null>();

  return (
    <Formik<Values>
      validationSchema={schema}
      initialValues={{ buyerCompanyName: '', importId: '' }}
      onSubmit={async (values) => {
        setLoading(true);
        setOfferImportError(null);
        try {
          const importedOffer = await importOffer(
            values.importId,
            values.buyerCompanyName,
            opportunityId,
          );
          setImportedOffer(importedOffer || null);
        } catch (error) {
          console.error(error);
          // setOfferImportError(error); // no need, the importOffer function handles this
        }
        setLoading(false);
      }}
    >
      {(formProps) => (
        <Form className={classes.fullHeight}>
          <Grid
            container
            direction="column"
            spacing={1}
            className={classes.fullHeight}
            wrap="nowrap"
          >
            <Grid item>
              <Grid container direction="column">
                <Grid
                  item
                  container
                  spacing={1}
                  onClick={() => {
                    setOfferImportError(null);
                    onCancelAssociateImportFlow();
                  }}
                  className={classes.backIcon}
                >
                  <Grid item>
                    <Icon>
                      <ArrowLeft />
                    </Icon>
                  </Grid>
                  <Grid item>
                    <Typography>Back</Typography>
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item>
                    <ProviderIcon provider={marketplace} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">
                      Import to associate an existing offer
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.contents}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  {offerType === OfferType.Direct ? (
                    <Typography>
                      Enter the offer ID of the offer from the marketplace to
                      import your offer into Tackle. Only <strong>SaaS</strong>{' '}
                      products are supported.
                    </Typography>
                  ) : (
                    <Typography>
                      Enter the selling authorization ID of the offer from the
                      marketplace to import your offer into Tackle. Only{' '}
                      <strong>SaaS</strong> products are supported.
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Field name="buyerCompanyName">
                    {({
                      field,
                      meta,
                    }: FieldProps<Values['buyerCompanyName']>) => (
                      <TextField
                        {...field}
                        error={meta.touched && meta.error}
                        required
                        label="Company name *"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item>
                  <Field name="importId">
                    {({ field, meta }: FieldProps<Values['importId']>) => (
                      <TextField
                        {...field}
                        error={meta.touched && meta.error}
                        required
                        label={
                          offerType === OfferType.Direct
                            ? 'Offer ID *'
                            : 'Selling authorization ID *'
                        }
                        helperText={
                          offerType === OfferType.Direct
                            ? 'Unique identifier assigned by the marketplace for each offer'
                            : 'Marketplace-assigned ID for each selling authorization. Only single-use authorizations are supported.'
                        }
                      />
                    )}
                  </Field>
                </Grid>
                {(loading || offerImportError || importedOffer) && (
                  <Grid item container spacing={1} alignItems="center">
                    <Grid item>
                      {loading && (
                        <CircularProgress
                          size={20}
                          thickness={6}
                          className={classes.greenIcon}
                        />
                      )}
                      {offerImportError && (
                        <CloseCircle className={classes.redIcon} />
                      )}
                      {importedOffer && (
                        <CheckCircle className={classes.greenIcon} />
                      )}
                    </Grid>
                    <Grid item alignContent="center">
                      <Typography className={classes.importingOffersText}>
                        Importing offer from AWS
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {offerImportError && (
                  <Grid item>
                    <Banner
                      type="danger"
                      title="Import failed"
                      body={offerImportError.message}
                    />
                  </Grid>
                )}
                {importedOffer && (
                  <Grid item container spacing={1} direction="column">
                    <Grid item>
                      <Typography variant="body2">
                        <strong>
                          {importedOffer.offerName ?? importedOffer.offerId}
                        </strong>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        <strong>
                          Total contract value:{' '}
                          {importedOffer?.pricing?.totalContractValue &&
                            formatCurrency(
                              parseFloat(
                                importedOffer?.pricing?.totalContractValue,
                              ),
                              importedOffer?.pricing?.currencyCode,
                            )}
                        </strong>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        Edit the offer to add buyer registration and extra
                        details to have a complete bookable artifact and Tackle
                        contract. Then you can send the offer acceptance email
                        to your buyer.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs alignContent="flex-end">
              <Grid item container justifyContent="flex-end" spacing={1}>
                {!importedOffer && (
                  <>
                    <Grid item>
                      <Button
                        variant="outlined"
                        appearance="primary"
                        onClick={() => {
                          setOfferImportError(null);
                          onClose();
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        appearance={
                          offerImportError ? 'destructive' : 'primary'
                        }
                        type="submit"
                        disabled={
                          loading || !formProps.dirty || !formProps.isValid
                        }
                        loading={formProps.isSubmitting || loading}
                      >
                        {offerImportError ? 'Try again?' : 'Import'}
                      </Button>
                    </Grid>
                  </>
                )}
                {importedOffer && (
                  <>
                    <Grid item>
                      <Button
                        variant="outlined"
                        appearance="primary"
                        onClick={() => {
                          setOfferImportError(null);
                          onClose();
                        }}
                      >
                        Save and close
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        appearance="primary"
                        onClick={() => onEditOffer(importedOffer)}
                      >
                        Edit offer
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export const CreateOfferAssociateImportModal = (
  props: React.ComponentProps<typeof AssociateImportContents>,
) => {
  return (
    <ApiContextProvider>
      <Modal
        data-id={DataId.CreateOfferModal}
        open={true}
        width="medium"
        onClose={props.onClose}
        onExited={props.onClose}
      >
        <AssociateImportContents {...props} />
      </Modal>
    </ApiContextProvider>
  );
};

export const CanvasAssociateImportDrawer = (
  props: React.ComponentProps<typeof AssociateImportContents> & {
    showDrawer: boolean;
  },
) => {
  const classes = useStyles();
  return (
    <ApiContextProvider>
      <Drawer anchor="right" variant="temporary" open={props.showDrawer}>
        <div className={classes.tackleAppBar}>
          <TackleLogo theme="light" width="104px" />
        </div>
        <div className={classes.container}>
          <AssociateImportContents {...props} />
        </div>
      </Drawer>
    </ApiContextProvider>
  );
};
