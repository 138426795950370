import { Loader } from '@tackle-io/platform-ui';
import { useCanvasSession } from './useCanvasSession';
import { useCanvasAppStyles } from './CanvasApp.styles';
import { AwsOpportunityActionsProvider } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/AwsOpportunityActionsProvider';
import CanvasTackleIntegrationStatus from './components/CanvasTackleIntegrationStatus';
import TackleSidebarWidgetForSalesforce from './components/TackleSidebarWidgetForSalesforce';
import ErrorBoundary from 'components/ErrorBoundary';
import CoSellCanvasApp from 'packages/salesforce-canvas/cosell/src/components/CoSellCanvasApp';
import OffersCanvasApp from 'packages/salesforce-canvas/offers/OffersCanvasApp';
import Error from 'pages/Error/Error';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import NotConnectedPage from './components/NotConnectedPage';
import { useInitializeAmplitude } from 'packages/salesforce-canvas/src/providers/AmplitudeFeatureFlagsProvider';

const AuthorizedCanvasApp = () => {
  const { context, payload, vendorId, vendorType, organizationId } =
    useCanvasSession();
  const componentId = context?.parameters?.componentId;
  const domain = payload?.domain;

  // We must boot Intercom manually after the authorized Canvas session is retrieved
  // as the Intercom hash is only available to use after we retrive the Canvas session from TAS
  const { boot } = useIntercom();

  const intercomBootParameters = {
    name: context?.user?.fullName,
    email: context?.user?.email,
    userId: context?.user?.intercomUserId,
    company: {
      companyId: vendorId,
      customAttributes: {
        vendor_type: vendorType,
      },
    },
    userHash: context?.user?.intercomHash,
  };

  boot(intercomBootParameters);

  useInitializeAmplitude({
    vendorId,
    organizationId,
  });

  if (componentId === 'Setup') {
    return <CanvasTackleIntegrationStatus />;
  }

  if (componentId === 'TackleWidget') {
    return <TackleSidebarWidgetForSalesforce />;
  }

  if (componentId === 'CanvasApp' && domain === 'cosell') {
    return <CoSellCanvasApp />;
  }

  if (
    componentId === 'CanvasApp' &&
    (domain === 'offers' || domain === 'offer')
  ) {
    return <OffersCanvasApp />;
  }

  return (
    <div>
      No component found for {componentId} with domain of {domain}.
    </div>
  );
};

const CanvasApp = () => {
  const { context, isAuthorized, isLoadingCanvasSession, initialComponentId } =
    useCanvasSession();
  const classes = useCanvasAppStyles();
  const componentId = context?.parameters?.componentId;
  const isConnected = context?.parameters?.isConnected;

  if (isLoadingCanvasSession) {
    return <Loader />;
  }

  if (!isConnected && initialComponentId !== 'Setup') {
    return <NotConnectedPage />;
  }

  if (!isAuthorized) {
    return <CanvasTackleIntegrationStatus />;
  }

  if (!componentId) {
    return <div>No context provided.</div>;
  }

  return (
    <ErrorBoundary renderError={Error}>
      <AwsOpportunityActionsProvider>
        <div className={classes.canvasAppContainer}>
          <AuthorizedCanvasApp />
        </div>
      </AwsOpportunityActionsProvider>
    </ErrorBoundary>
  );
};

const CanvasAppWithSessionProvider = () => {
  const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

  return (
    <IntercomProvider
      appId={INTERCOM_APP_ID}
      shouldInitialize={true}
      // Intercom is manually "booted" in the <AuthorizedCanvasApp /> component, as there is only a
      // Intercom hash available to use after we retrive the Canvas session from TAS
      autoBoot={false}
    >
      <CanvasApp />
    </IntercomProvider>
  );
};

export default CanvasAppWithSessionProvider;
