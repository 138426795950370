import { MenuButton } from 'components';
import { useCoSellHistory } from 'pages/CoSell/utilities/hooks';
import { Button } from '@tackle-io/platform-ui';
import { DisplayCloudType, DisplayLabelCloudType } from '../../types/enums';
import { COSELL_PATH } from '../../utilities/constants';
import { coSellLandingPageDataId } from '../../utilities/intercomEnums';

const BUTTON_LABEL = 'Create Co-Sell';

export type Entitlement = 'AWS' | 'MICROSOFT' | 'GCP';
interface CreateOpportunityButtonProps {
  entitlements: Entitlement[];
}

export const CreateOpportunityButton = ({
  entitlements,
}: CreateOpportunityButtonProps) => {
  const history = useCoSellHistory();

  const redirectToOpportunityForm = (cloud: Entitlement) => {
    history.push({
      pathname: `${COSELL_PATH}/opportunity/${cloud.toLowerCase()}`,
    });
  };

  const menuButtonItems = entitlements.map((entitlement) => ({
    key: DisplayCloudType[entitlement],
    text: DisplayLabelCloudType[entitlement],
  }));

  const handleMenuButtonSelect = (selectedItem: {
    key: Entitlement;
    text: string;
  }): void => {
    redirectToOpportunityForm(selectedItem.key);
  };

  if (entitlements?.length > 1) {
    return (
      <MenuButton items={menuButtonItems} onSelect={handleMenuButtonSelect}>
        {BUTTON_LABEL}
      </MenuButton>
    );
  }

  return (
    <Button
      id={coSellLandingPageDataId.CREATE_BUTTON}
      data-id={coSellLandingPageDataId.CREATE_BUTTON}
      appearance="primary"
      onClick={(): void => redirectToOpportunityForm(entitlements[0] || 'AWS')}
    >
      {BUTTON_LABEL}
    </Button>
  );
};
