import React, { Fragment } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
  Tooltip,
  Typography,
} from 'vendor/material';
import useStyles from './RadioCheckboxGroupFormField.styles';
import { InformationOutline } from 'mdi-material-ui';
import { useFormikContext } from 'formik';
import { UnifiedOpportunityFormValues } from '../UnifiedOpportunityForm';
import { Label } from '../Label';

interface RadioCheckboxGroupFormFieldProps {
  label: string;
  name: string;
  id: string;
  required?: boolean;
  tooltipText?: string;
  helperText?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  radioOptions?: Array<{ display: string; value: any; helperText?: string }>;
}

export const RadioCheckboxGroupFormField: React.FC<
  RadioCheckboxGroupFormFieldProps
> = ({
  label,
  name,
  id,
  tooltipText,
  required,
  onChange,
  disabled,
  radioOptions = [],
  helperText,
}) => {
  const classes = useStyles();
  const { setFieldValue, setFieldTouched, values, touched, errors } =
    useFormikContext<UnifiedOpportunityFormValues>();

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
    setFieldValue(name, event.target.value);
    setFieldTouched(name, true);
  };
  const valueIsValidOption = radioOptions.some(
    (option) => option.value === values[name],
  );

  return (
    <FormControl
      aria-disabled={disabled}
      disabled={disabled}
      error={touched[name] && errors[name] && !values?.[name]}
      id={id}
      data-id={id}
    >
      <FormLabel className={classes.formLabel} data-id={`${id}Label`}>
        <Label label={label} required={required} />
        {tooltipText && (
          <Tooltip className={classes.tooltip} title={tooltipText}>
            <InformationOutline fontSize="small" />
          </Tooltip>
        )}
      </FormLabel>
      {helperText && (
        <Typography className={classes.helperText}>{helperText}</Typography>
      )}
      <MuiRadioGroup
        name={id}
        className={classes.radioGroup}
        onChange={handleFieldChange}
        value={values[name]}
      >
        {radioOptions.map((radioOption) => {
          return (
            <Fragment key={radioOption.value}>
              <FormControlLabel
                role={'presentation'}
                data-testid={`${id}_${radioOption.display}`}
                id={`${id}_${radioOption.display}`}
                data-id={`${id}_${radioOption.display}`}
                name={`${id}_${radioOption.display}`}
                label={radioOption.display}
                value={radioOption.value}
                className={classes.radioButton}
                control={<Radio />}
              />
              <Typography className={classes.radioHelperText}>
                {radioOption.helperText}
              </Typography>
            </Fragment>
          );
        })}
      </MuiRadioGroup>
      {touched[name] && errors[name] && !valueIsValidOption ? (
        <FormHelperText error>{errors[name]}</FormHelperText>
      ) : null}
    </FormControl>
  );
};
