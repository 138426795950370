import { datadogRum } from '@datadog/browser-rum';

import buildEnvironment from '../buildEnvironment';
import { User } from './types';

const datadogEnabled = (): boolean => {
  const applicationId = process.env.REACT_APP_DATADOG_APP_ID;
  const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;

  return Boolean(applicationId && clientToken);
};

const initDatadog = () => {
  const applicationId = process.env.REACT_APP_DATADOG_APP_ID;
  const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
  const version = process.env.REACT_APP_RELEASE_SHA;
  const env = buildEnvironment.environment;
  const upstreamApiUrl = process.env.REACT_APP_API_URL;
  const offersApiUrl = process.env.REACT_APP_OFFERS_API_URL;
  const billingApiUrl = process.env.REACT_APP_BILLING_API_URL;
  const graphqlApiURL = process.env.REACT_APP_GRAPHQL_SERVER_URL;

  if (!datadogEnabled()) return;

  datadogRum.init({
    applicationId,
    clientToken,
    env,
    site: 'datadoghq.com',
    service: 'downstream-app',
    version: version,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [
      upstreamApiUrl,
      billingApiUrl,
      graphqlApiURL,
      offersApiUrl,
    ],
    enableExperimentalFeatures: ['feature_flags'],
  });

  datadogRum.startSessionReplayRecording();
};

const setUser = (user: User): void => {
  if (!datadogEnabled()) return;

  datadogRum.setUser({
    id: user.id,
    name: user.name,
    email: user.email,
    ...user.metadata,
  });

  datadogRum.addRumGlobalContext('vendorid', user.metadata?.vendorid);
};

const removeUser = (): void => {
  if (!datadogEnabled()) return;

  datadogRum.removeUser();
};

const addError = datadogRum.addError;
const addAction = datadogRum.addAction;

export { initDatadog, setUser, removeUser, addError, addAction };
export default initDatadog;
