import * as Yup from 'yup';
import { REQUIRED } from './FormValidationConstants';
import { AceOpportunityDeliveryModelEnum } from 'packages/cosell/src/types/enums';

export const launchFormSchema = Yup.object().shape({
  solutions: Yup.array().required(REQUIRED),
  targetCloseDate: Yup.string().required(REQUIRED),
  customerSoftwareValue: Yup.string()
    .nullable(true)
    .test(
      'is-required-when-currencyCode',
      'Customer software value is required when Currency is provided.',
      function (value) {
        const { currencyCode } = this.parent;
        if (currencyCode && !value) {
          return false;
        }
        return true;
      },
    ),
  currencyCode: Yup.string()
    .nullable(true)
    .test(
      'is-required-when-customerSoftwareValue',
      'Currency is required when Customer software value is provided.',
      function (value) {
        const { customerSoftwareValue } = this.parent;
        if (customerSoftwareValue && !value) {
          return false;
        }
        return true;
      },
    ),
});
export const launchFormWithSaasDocRequiredSchema = Yup.object().shape({
  solutions: Yup.array().required(REQUIRED),
  deliveryModel: Yup.string().required(REQUIRED),
  customerSoftwareValue: Yup.string().required(REQUIRED),
  currencyCode: Yup.string().required(REQUIRED),
  effectiveDate: Yup.string().required(REQUIRED),
  expirationDate: Yup.string().required(REQUIRED),
});
export const closeLostFormSchema = Yup.object().shape({
  closedLostReason: Yup.string().required(REQUIRED),
});

export const editLaunchFormSchema = Yup.object().shape({
  deliveryModels: Yup.array()
    .min(1, 'At least one delivery model is required')
    .required(REQUIRED),
});

export const editLaunchFormWithSaasDocRequiredSchema = Yup.object().shape({
  deliveryModels: Yup.array()
    .min(1, 'At least one delivery model is required')
    .required(REQUIRED),
  deliveryModel: Yup.string().when('deliveryModels', {
    is: (deliveryModels: AceOpportunityDeliveryModelEnum[]) =>
      deliveryModels.includes(AceOpportunityDeliveryModelEnum.SAAS_or_PAAS),
    then: Yup.string().required(REQUIRED),
  }),
  customerSoftwareValue: Yup.string()
    .nullable(true)
    .when('deliveryModels', {
      is: (deliveryModels: AceOpportunityDeliveryModelEnum[]) =>
        deliveryModels.includes(AceOpportunityDeliveryModelEnum.SAAS_or_PAAS),
      then: Yup.string().required(REQUIRED),
    }),
  currencyCode: Yup.string()
    .nullable(true)
    .when('deliveryModels', {
      is: (deliveryModels: AceOpportunityDeliveryModelEnum[]) =>
        deliveryModels.includes(AceOpportunityDeliveryModelEnum.SAAS_or_PAAS),
      then: Yup.string().required(REQUIRED),
    }),
  effectiveDate: Yup.string()
    .nullable(true)
    .when('deliveryModels', {
      is: (deliveryModels: AceOpportunityDeliveryModelEnum[]) =>
        deliveryModels.includes(AceOpportunityDeliveryModelEnum.SAAS_or_PAAS),
      then: Yup.string().required(REQUIRED),
    }),
  expirationDate: Yup.string()
    .nullable(true)
    .when('deliveryModels', {
      is: (deliveryModels: AceOpportunityDeliveryModelEnum[]) =>
        deliveryModels.includes(AceOpportunityDeliveryModelEnum.SAAS_or_PAAS),
      then: Yup.string().required(REQUIRED),
    }),
});
